import { useCallback, useEffect, useState } from "react";
import { subDays, subHours, subMinutes, subMonths } from "date-fns";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
import { AccountBillingSettings } from "src/sections/dashboard/account/account-billing-settings";
import { AccountGeneralSettings } from "src/sections/dashboard/account/account-general-settings";
import { AccountNotificationsSettings } from "src/sections/dashboard/account/account-notifications-settings";
import { AccountTeamSettings } from "src/sections/dashboard/account/account-team-settings";
import { AccountSecuritySettings } from "src/sections/dashboard/account/account-security-settings";
import {
  API_SERVICE_BACKEND,
  API_SERVICE_BACKEND_2,
  API_SERVICE_BACKEND_3,
  cronofy,
} from "src/config";
import { Router, useLocation, useNavigate } from "react-router";
import axios from "axios";
import { cronofyApi } from "src/api/cronofy";
import { toast } from "react-hot-toast";
import SaveIcon from "src/components/logos/saveIcon.png";
import { getAuth, updateProfile } from "firebase/auth";
import { useDispatch } from "react-redux";
import { saveFullName } from "src/slices/user";
import { getSessionStorage, setSessionStorage } from "src/utils/storage";

const isAccess=sessionStorage.getItem("access")

let maxCallsAllowedToGetAccessToken = 3;
const now = new Date();

const tabs = [
  { label: "General", value: "general" },
  // { label: 'Billing', value: 'billing' },
  // { label: 'Team', value: 'team' },
  // { label: 'Notifications', value: 'notifications' },
  // { label: 'Security', value: 'security' }
];
async function generateHMAC(message, key) {
  const encoder = new TextEncoder();
  const keyData = encoder.encode(key);
  const messageData = encoder.encode(message);

  const cryptoKey = await crypto.subtle.importKey(
    "raw",
    keyData,
    { name: "HMAC", hash: { name: "SHA-256" } },
    false,
    ["sign"]
  );

  const signature = await crypto.subtle.sign("HMAC", cryptoKey, messageData);
  return Array.from(new Uint8Array(signature))
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
}

const hash = await generateHMAC(
  getSessionStorage("userEmail"),
  process.env.REACT_APP_INTERCOM_HASH_KEY
);

const saveToDB = async (email, key, value) => {
  // console.log(key, value)
  const res = await axios.patch(`${API_SERVICE_BACKEND}/patchUser`, {
    email,
    payload: {
      [key]: { generatedAt: Date.now(), ...value },
    },
  });
  // console.log(res.data);
  if (res.status !== 200) return false;
  return true;
};

export const getUserInfo = async (email) => {
  try {
    const res = await axios.get(
      `${API_SERVICE_BACKEND}/getuserdetail/${email}`
    );
    if (!res.data.status || (res.data.status && res.data.data?.accountStatus !== 1)) return;
    return res.data.data;
  } catch (err) {
    console.log({ err });
    return;
  }
};

const Page = () => {
  const dispatch = useDispatch();
  const user = {
    name: getSessionStorage("fullName"),
    email: getSessionStorage("userEmail"),
    avatar:
      "https://www.kindpng.com/picc/m/252-2524695_dummy-profile-image-jpg-hd-png-download.png",
  };
  const [currentTab, setCurrentTab] = useState("general");

  usePageView();

  const location = useLocation();
  const navigate = useNavigate();
  // console.log("location", location);
  const query = new URLSearchParams(location.search);
  const queryParams = {};
  query.forEach((value, key) => (queryParams[key] = value));

  const { code, from } = queryParams;
  if (code) {
    console.log(`Received 'code' from ${from}`);
  }
  const MyState = queryParams.state ? JSON.parse(queryParams.state) : {};
  const campaign_id = MyState.campaign_id;

  const fullName = getSessionStorage("fullName").split(" ");

  const redirect_uri = `${cronofy.redirect_uri}${location.pathname}?from=cronofy`;
  const [infoUser, setInfoUser] = useState("");
  const [accessToken, setAccessToken] = useState(null);
  const [calendarToken, setCalendarToken] = useState(null);
  const [availabilityToken, setAvailabilityToken] = useState(null);

  const [userCompanyName, setuserCompanyName] = useState("");
  const [userCompanyAddress, setuserCompanyAddress] = useState("");
  const [userDocumentId, setuserDocumentId] = useState("");
  const [userMeetingId, setuserMeetingId] = useState("");
  const [userMeetingData, setUserMeetingData] = useState();
  const [add, setAdd] = useState(false);
  const [userPhoneNumber, setuserPhoneNumber] = useState("");
  const [userMeetingDate, setuserMeetingDate] = useState("");
  const [userMeetingTime, setuserMeetingTime] = useState("");
  const [userCompanyLogo, setuserCompanyLogo] = useState("");
  const [userFirstName, setUserFirstName] = useState(fullName[0]);
  const [userLastName, setUserLastName] = useState(fullName[1]);
  const [showAlert, setShowAlert] = useState(false);
  const [updateUserMeetingDate, setupdateUserMeetingDate] =
    useState(userMeetingDate);
  const [updateUserMeetingTime, setupdateUserMeetingTime] =
    useState(userMeetingTime);
  // console.log(updateUserMeetingDate, updateUserMeetingTime, "timing")
  const [userMeetingLink, setuserMeetingLink] = useState("");
  const [dialogState, setDialogState] = useState({
    open: false,
    loadUrl: "",
  });
  const [conferencingLinked, setConferencingLinked] = useState({
    linked: true,
    accessToken: null,
  });
  useEffect(() => {
    const storedCompanyAddress = getSessionStorage("companyAddress");
    const storedFirstName = getSessionStorage("firstName");
    const storedLastName = getSessionStorage("lastName");
    if (storedCompanyAddress !== null) {
      setuserCompanyAddress(storedCompanyAddress);
    }
    if (storedFirstName !== null) {
      setUserFirstName(storedFirstName);
    }
    if (storedLastName !== null) {
      setUserLastName(storedLastName);
    }
  }, []);

  const openUrl = () => {
    window.open(dialogState.loadUrl, "_self", "noopener,noreferrer");
    setDialogState({ open: false, loadUrl: "" });
  };

  const linkConferencingService = async (accessTokenCur) => {
    if (!accessTokenCur) {
      accessTokenCur = accessToken;
    }
    const conferencingService = await cronofyApi.conferencing({
      token: accessTokenCur.access_token,
      redirect_uri,
      email: getSessionStorage("userEmail"),
      profile_email: accessTokenCur.linking_profile.profile_name,
    });

    if (conferencingService.linked) {
      return;
    }
    if (conferencingService.url) {
      setDialogState({ open: true, loadUrl: conferencingService?.url });
    }
  };

  // link calendar
  useEffect(() => {
    console.log("code from idm", code, from, campaign_id);
    if (!code || from !== "cronofy" || campaign_id) {
      window.Intercom("update", {
        email: getSessionStorage("userEmail"),
        name: getSessionStorage("fullName"),
        user_hash: hash,
        calendar_synced: false,
      });
      return;
    }
    console.log(`campaign Getting Access Token using the code: ${code}`);
    cronofyApi
      .getAccessToken({
        code,
        redirect_uri,
      })
      .then(async (accessToken) => {
        if (!accessToken) return;
        if (accessToken.linking_profile.provider_name !== "google") {
          await linkConferencingService(accessToken);
        }
        console.log(`Saving Access token to db`);
        window.Intercom("trackEvent", "calendar_synced", {
          email: getSessionStorage("userEmail"),
        });
        window.Intercom("update", {
          email: getSessionStorage("userEmail"),
          name: getSessionStorage("fullName"),
          user_hash: hash,
          calendar_synced: true,
        });
        const opSuc = saveToDB(user.email, "extra.accessToken", accessToken);
        if (!opSuc) throw new Error("Access Token couldn't be saved");
        setAccessToken(accessToken);
        navigate(location.pathname, {
          replace: true,
          preventScrollReset: true,
        });

        // console.log(`Getting the Element token using sub from Access token`);
        // const elementToken = await cronofyApi.getElementToken({
        //   sub: accessToken.sub,
        //   permissions: ["account_management"],
        //   origin: cronofy.redirect_uri,
        // });
        // if (!elementToken) return;
        // setCalendarToken(elementToken.element_token.token);
      });
  }, []);

  const getAccessToken = async () => {
    // console.log(`Getting the Element token using sub from Access token`);
    maxCallsAllowedToGetAccessToken -= 1;
    // console.log(maxCallsAllowedToGetAccessToken);

    if (user && user.email) {
      try {
        const userInfo = await getUserInfo(user.email);
        const accessToken = userInfo?.extra?.accessToken;
        if (
          userInfo?.extra?.accessToken?.linking_profile?.provider_name !==
          "google"
        ) {
          const conferencingArray = userInfo?.extra?.conferencing_linked?.find(
            (item) => item.email === accessToken?.linking_profile.profile_name
          );
          if (conferencingArray) {
            setConferencingLinked({
              linked: true,
              accessToken: accessToken,
            });
          } else {
            setConferencingLinked({
              linked: false,
              accessToken: accessToken,
            });
          }
        }
        if (accessToken) {
          window.Intercom("update", {
            email: getSessionStorage("userEmail"),
            name: getSessionStorage("fullName"),
            user_hash: hash,
            calendar_synced: true,
          });
        }
        if (!accessToken && maxCallsAllowedToGetAccessToken > 0) {
          console.log(`Access Token not found, Trying again!`);
          window.Intercom("update", {
            email: getSessionStorage("userEmail"),
            name: getSessionStorage("fullName"),
            user_hash: hash,
            calendar_synced: false,
          });
          setTimeout(getAccessToken, 1000);
          return;
        }
        setInfoUser(userInfo);
        setuserCompanyName(userInfo?.companyName);
        setuserCompanyAddress(userInfo?.companyAddress);
        setuserDocumentId(userInfo?._id);
        setuserPhoneNumber(userInfo?.phone);
        setuserCompanyLogo(userInfo?.profile_image);

        const calendarToken = await cronofyApi.getElementToken({
          sub: accessToken?.sub,
          permissions: ["account_management"],
          origin: cronofy.redirect_uri,
        });
        if (calendarToken) {
          setCalendarToken(calendarToken.element_token.token);
        }

        const availabilityToken = await cronofyApi.getElementToken({
          sub: accessToken?.sub,
          permissions: ["managed_availability"],
          origin: cronofy.redirect_uri,
        });
        if (availabilityToken) {
          setAvailabilityToken(availabilityToken.element_token.token);
        }
      } catch (ex) {
        console.log({ ex });
      }
    }
  };

  // get calendar link data
  useEffect(() => {
    maxCallsAllowedToGetAccessToken = 5;
    console.log(
      `Getting the Element token using sub from Access token`,
      accessToken
    );
    getAccessToken();
  }, [accessToken]);

  useEffect(() => {
    (async () => {
      try {
        const userInfo = await getUserInfo(user?.email);
        const companyName = userInfo.companyName.toLowerCase();
        if (companyName === "no company name") {
          setShowAlert(true);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    })();
  }, [user]);

  useEffect(() => {
    try {
      const fullName = getSessionStorage("fullName");
      dispatch(saveFullName(fullName));
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  const handleTabsChange = useCallback((event, value) => {
    setCurrentTab(value);
  }, []);

  const updateCompany = async () => {
    try {
      var dataSend = {
        userCompanyName,
        userCompanyAddress,
      };
      const responseDataLog = await fetch(
        `${API_SERVICE_BACKEND_2}/updateusercompanydetails/${userDocumentId}`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataSend),
        }
      );
      if (responseDataLog.status === 200 || responseDataLog.status === 201) {
        setSessionStorage("companyName", userCompanyName);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const updateCompanyAddress = async () => {
    try {
      var dataSend = {
        userCompanyAddress,
      };
      const responseDataLog = await fetch(
        `${API_SERVICE_BACKEND_2}/updateusercompanyaddress/${userDocumentId}`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataSend),
        }
      );
      if (responseDataLog.status === 200 || responseDataLog.status === 201) {
      }
    } catch (err) {
      console.log(err);
    }
  };
  const updateUserPhoneNumber = async () => {
    try {
      var dataSend = {
        userPhoneNumber,
      };
      const responseDataLog = await fetch(
        `${API_SERVICE_BACKEND_2}/updateuserphonenumber/${userDocumentId}`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataSend),
        }
      );
      if (responseDataLog.status === 200 || responseDataLog.status === 201) {
      }
    } catch (err) {
      console.log(err);
    }
  };
  const updateUserProfileName = async () => {
    if (!userFirstName || userFirstName === "") {
      toast.error("Invalid First Name");
      return;
    }
    if (!userLastName || userLastName === "") {
      toast.error("Invalid Last Name");
      return;
    }
    const auth = getAuth();
    updateProfile(auth.currentUser, {
      displayName: userFirstName + "" + userLastName,
    })
      .then(async () => {
        try {
          var dataSend = {
            name: userFirstName + " " + userLastName,
          };
          const email = getSessionStorage("userEmail");

          const responseDataLog = await fetch(
            `${API_SERVICE_BACKEND}/updateuserbasicdetails/${email}`,
            {
              method: "PUT",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(dataSend),
            }
          );
          if (
            responseDataLog.status === 200 ||
            responseDataLog.status === 201
          ) {
            setSessionStorage("fullName", userFirstName + " " + userLastName);
            setSessionStorage("firstName", userFirstName);
            setSessionStorage("lastName", userLastName);
            dispatch(saveFullName(userFirstName + " " + userLastName));
          }
        } catch (err) {
          console.log(err);
        }
      })
      .catch((error) => {
        toast.error("Error updating User Name:", error.message);
      });
  };

  const updateSettings = () => {
    Promise.all([
      updateUserProfileName(),
      updateCompany(),
      updateCompanyAddress(),
      updateUserPhoneNumber(),
    ])
      .then(() => {
        setShowAlert(false);
        toast.success("Settings updated successfully");
      })
      .catch((error) => {
        toast.error("Something went wrong" + error);
      });
  };

  // useEffect(() => {
  //   const getmeetingschedule = async () => {
  //     try {
  //       const responseDataLog = await fetch(
  //         `${API_SERVICE_BACKEND_3}
  //         /getmeetingschedule/${userDocumentId}`,
  //         {
  //           method: "GET",
  //           headers: {
  //             Accept: "application/json",
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       ).then((res) => res.json());
  //       setUserMeetingData(responseDataLog.data);
  //     } catch (err) {
  //       console.log(
  //         "🚀 ~ file: account.js:153 ~ getmeetingschedule ~ err:",
  //         err
  //       );
  //     }
  //   };
  //   getmeetingschedule();
  // }, [add, userDocumentId]);

  const updateUserMeetingScheduleLink = async () => {
    try {
      var dataSend = {
        meeting_link: userMeetingLink,
      };
      const responseDataLog = await fetch(
        `${API_SERVICE_BACKEND_3}/updateusermeetingschedule/${userDocumentId}`, // use this in production for leadingly_backend
        // `${API_SERVICE_BACKEND}/updateusermeetingschedule/${userDocumentId}`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataSend),
        }
      );
      if (responseDataLog.status === 200 || responseDataLog.status === 201) {
        toast.success("Meeting Schedule Link updated successfully");
        setuserMeetingLink("");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Seo title="B2B Rocket Dashboard" />

      <Dialog
        open={dialogState?.open}
        onClose={(event, reason) => {
          if (
            reason &&
            (reason === "backdropClick" || reason === "escapeKeyDown")
          ) {
            return;
          }
          setDialogState({ open: false, loadUrl: "" });
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Choose the Default Meeting Platform."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Conference services are not linked to the connected calendar. Please
            click on "Authorize" to establish a connection with your default
            meeting scheduling platform.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Disagree</Button> */}
          <Button onClick={openUrl} autoFocus>
            Authorize
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          //py: 8,
          //backgroundColor: "#F8F7FC",
        }}
      >
        <Container maxWidth="xl" style={{padding: 0 }}>
          <Stack spacing={3} sx={{ mb: 2 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{ color: "#111927", fontSize: "32px", fontWeight: "700" }}
              >
                Settings
              </Typography>
              <Button
                id="save-settings-btn"
                variant="contained"
                size="small"
                sx={{
                  borderRadius: "8px",
                  backgroundColor: "#5761FE",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#5761FE",
                  },
                }}
                startIcon={<img src={SaveIcon} />}
                onClick={updateSettings}
                disabled={isAccess==="read"}
              >
                Save
              </Button>
            </div>

            <div style={{display: "none"}}>
              {/* <Tabs
                indicatorColor="primary"
                onChange={handleTabsChange}
                scrollButtons="auto"
                textColor="primary"
                value={currentTab}
                variant="scrollable"
              >
                {tabs.map((tab) => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </Tabs> */}
              <Divider />
            </div>
          </Stack>
          {currentTab === "general" && (
            <AccountGeneralSettings
              showAlert={showAlert}
              setAdd={setAdd}
              add={add}
              updateCompanyAddress={updateCompanyAddress}
              setuserCompanyAddress={setuserCompanyAddress}
              userCompanyAddress={userCompanyAddress}
              userCompanyLogo={userCompanyLogo}
              updateCompany={updateCompany}
              setuserCompanyName={setuserCompanyName}
              userCompanyName={userCompanyName}
              updateUserPhoneNumber={updateUserPhoneNumber}
              setuserPhoneNumber={setuserPhoneNumber}
              userPhoneNumber={userPhoneNumber}
              setuserMeetingDate={setuserMeetingDate}
              userMeetingDate={userMeetingDate}
              userMeetingTime={userMeetingTime}
              setuserMeetingTime={setuserMeetingTime}
              userMeetingLink={userMeetingLink}
              setuserMeetingLink={setuserMeetingLink}
              updateUserMeetingScheduleLink={updateUserMeetingScheduleLink}
              updateUserMeetingDate={updateUserMeetingDate}
              setupdateUserMeetingDate={setupdateUserMeetingDate}
              updateUserMeetingTime={updateUserMeetingTime}
              setupdateUserMeetingTime={setupdateUserMeetingTime}
              setuserMeetingId={setuserMeetingId}
              userMeetingData={userMeetingData}
              // getmeetingschedule={getmeetingschedule}
              avatar={user.avatar}
              email={user.email || ""}
              name={user.name || ""}
              userFirstName={userFirstName}
              userLastName={userLastName}
              setUserLastName={setUserLastName}
              setUserFirstName={setUserFirstName}
              element_tokens={{ calendarToken, availabilityToken }}
              cronofy_options={{ redirect_uri }}
              conferencingLinked={conferencingLinked}
              handleConferencingLink={linkConferencingService}
              setInfoUser={setInfoUser}
              infoUser={infoUser}
              getAccessToken={getAccessToken}
            />
          )}
          {currentTab === "billing" && (
            <AccountBillingSettings
              plan="standard"
              invoices={[
                {
                  id: "5547409069c59755261f5546",
                  amount: 4.99,
                  createdAt: subMonths(now, 1).getTime(),
                },
                {
                  id: "a3e17f4b551ff8766903f31f",
                  amount: 4.99,
                  createdAt: subMonths(now, 2).getTime(),
                },
                {
                  id: "28ca7c66fc360d8203644256",
                  amount: 4.99,
                  createdAt: subMonths(now, 3).getTime(),
                },
              ]}
            />
          )}
          {currentTab === "team" && (
            <AccountTeamSettings
              members={[
                {
                  avatar: "/assets/avatars/avatar-cao-yu.png",
                  email: "cao.yu@devias.io",
                  name: "Cao Yu",
                  role: "Owner",
                },
                {
                  avatar: "/assets/avatars/avatar-siegbert-gottfried.png",
                  email: "siegbert.gottfried@devias.io",
                  name: "Siegbert Gottfried",
                  role: "Standard",
                },
              ]}
            />
          )}
          {currentTab === "notifications" && <AccountNotificationsSettings />}
          {currentTab === "security" && (
            <AccountSecuritySettings
              loginEvents={[
                {
                  id: "1bd6d44321cb78fd915462fa",
                  createdAt: subDays(
                    subHours(subMinutes(now, 5), 7),
                    1
                  ).getTime(),
                  ip: "95.130.17.84",
                  type: "Credential login",
                  userAgent: "Chrome, Mac OS 10.15.7",
                },
                {
                  id: "bde169c2fe9adea5d4598ea9",
                  createdAt: subDays(
                    subHours(subMinutes(now, 25), 9),
                    1
                  ).getTime(),
                  ip: "95.130.17.84",
                  type: "Credential login",
                  userAgent: "Chrome, Mac OS 10.15.7",
                },
              ]}
            />
          )}
        </Container>
      </Box>
    </>
  );
};

export default Page;
