import React, { useEffect, useState } from 'react'
import { Link, Table, TableBody, TableCell, TableHead, TableRow, alpha, useTheme } from "@mui/material";
import moment from "moment";


const ExpandedRow = (props) => {
    const { _id,
        emailStatus,
        firstName,
        lastName,
        email,
        emailBody,
        openedAt,
        clickedAt,
        meetingBookedAt,
        conversationAt,
        sentAt,
        nextFollowUpStep,
        enrolledFor,
        emailSeqStatus,
        setTab,
        viewSentEmail,
        setExpand,
        setTimelineData,
        filters,
        setTimelineOpen,
        emailStageStatus,
        respondedAt,
        pageViewAt,
        videoViewDuration,
        videoViewed,
        videoViewedAt,
        isStats } = props

    const theme = useTheme();
    const [VideoAtFormatedDate, setVideoAtFormatedDate] = useState("")

    useEffect(() => {
        if (videoViewedAt && videoViewedAt?.length > 0) {
            const VideoAt = videoViewedAt[videoViewedAt?.length - 1]
            setVideoAtFormatedDate(VideoAt ? moment(VideoAt).format("LLLL") : "");
        }
    }, [videoViewedAt])

    const formattedSentEmailDate = new Date(
        sentAt
    ).toLocaleDateString("en-US", {
        // weekday: 'short',
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
    });

    return (
        <TableRow>
            <TableCell
                colSpan={
                    filters.emailsSent.active
                        ? "16"
                        : "14"
                }
            >
                <Table
                    sx={{
                        backgroundColor: alpha(
                            theme.palette.primary.main,
                            0.05
                        ),
                        borderRadius: 1,
                        border: `1px solid ${alpha(
                            theme.palette.primary.main,
                            0.1
                        )}`,
                    }}
                >
                    <TableHead>
                        <TableRow
                            sx={{
                                "& .MuiTableCell-root": {
                                    color:
                                        theme.palette
                                            .neutral[500],
                                },
                            }}
                        >
                            {/* <TableCell align="left">
                                Sent
                            </TableCell> */}
                            <TableCell align="left">
                                Opened
                            </TableCell>
                            <TableCell align="left">
                                Clicked
                            </TableCell>
                            <TableCell align="left">
                                Replied
                            </TableCell>
                            <TableCell align="left">
                                Conversation
                            </TableCell>
                            <TableCell align="left">
                                Page Viewed
                            </TableCell>
                            {/* <TableCell align="left">
                                                      Video Viewed
                                                    </TableCell> */}
                            <TableCell align="left">
                                Meeting Booked
                            </TableCell>
                            <TableCell align="left">
                                Video Duration
                            </TableCell>
                            <TableCell align="left">
                                Video Viewed
                            </TableCell>
                            <TableCell align="left">
                                Video View At
                            </TableCell>
                            <TableCell align="left">
                                Stage
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow
                            key={_id}
                            sx={{
                                "&:last-child td, &:last-child th":
                                {
                                    border: 0,
                                },
                                "& .MuiTableCell-root": {
                                    color:
                                        theme.palette
                                            .neutral[900],
                                    fontWeight: "500",
                                },
                            }}
                        >
                            {/* <TableCell
                                align="left"
                                sx={{
                                    "&:hover": {
                                        cursor:sentAt ? "pointer" : "auto",
                                    },
                                }}
                            >
                                {sentAt ? (
                                    <Link
                                        onClick={() =>
                                            viewSentEmail({
                                                name: `${firstName} ${lastName}`,
                                                email: emailBody,
                                                address: email,
                                            })
                                        }
                                    >
                                        {" "}
                                        {
                                            formattedSentEmailDate
                                        }
                                    </Link>
                                ) : (
                                    "-"
                                )}
                            </TableCell> */}
                            <TableCell align="left">
                                {openedAt?.length !== 0
                                    ? openedAt?.length
                                    : "-"}
                            </TableCell>
                            <TableCell align="left">
                                {clickedAt?.length !== 0 && clickedAt !== undefined
                                    ? clickedAt?.length
                                    : "-"}
                            </TableCell>
                            <TableCell align="left" >
                                {respondedAt?.length !== 0 && respondedAt !== undefined ? respondedAt?.length : '-'}
                            </TableCell>
                            {isStats ?
                                <TableCell
                                    align="left"
                                >
                                    {conversationAt?.length !== 0 && conversationAt !== undefined ? conversationAt?.length : "-"}
                                </TableCell> :
                                <TableCell
                                    align="left"
                                    sx={{
                                        "&:hover": {
                                            cursor: conversationAt?.length ? "pointer" : "auto",
                                        },
                                    }}
                                >
                                    {conversationAt?.length !==
                                        0 && conversationAt !== undefined ? (
                                        <Link
                                            key={_id}
                                            onClick={(e) => {
                                                setTab(
                                                    "conversations"
                                                );
                                                setExpand(false);
                                            }}
                                        >
                                            {
                                                conversationAt?.length
                                            }
                                        </Link>
                                    ) : (
                                        "-"
                                    )}
                                </TableCell>}
                            <TableCell align="left">
                                {pageViewAt?.length !== 0 && pageViewAt !== undefined
                                    ? pageViewAt?.length : "-"}
                            </TableCell>
                            {isStats ?
                                <TableCell align="left">
                                    {meetingBookedAt?.length !== 0 && meetingBookedAt !== undefined ?
                                        meetingBookedAt?.length
                                        : "-"}
                                </TableCell>
                                : <TableCell
                                    align="left"
                                    sx={{
                                        "&:hover": {
                                            cursor: meetingBookedAt?.length ? "pointer" : "auto",
                                        },
                                    }}
                                >
                                    {meetingBookedAt?.length !==
                                        0 &&
                                        meetingBookedAt !==
                                        undefined ? (
                                        <Link
                                            key={_id}
                                            onClick={(e) => {
                                                setTab("meetings");
                                                setExpand(false);
                                            }}
                                        >
                                            {
                                                meetingBookedAt?.length
                                            }
                                        </Link>
                                    ) : (
                                        "-"
                                    )}
                                </TableCell>}
                            <TableCell align="left">
                                {videoViewDuration ? `${videoViewDuration} sec` : "-"}
                            </TableCell>
                            <TableCell align="left">
                                {videoViewed === true ? "Yes" : "No"}
                            </TableCell>
                            <TableCell align="left">
                                {VideoAtFormatedDate ?? "-"}
                            </TableCell>
                            <TableCell
                                align="left"
                                sx={{
                                    "&:hover": {
                                        cursor: "pointer",
                                    },
                                }}
                            >
                                {enrolledFor ? (
                                    <Link
                                        onClick={() => {
                                            setTimelineData({
                                                nextFollowUpStep,
                                                enrolledFor,
                                                emailSeqStatus,
                                                emailStatus,
                                            });
                                            setTimelineOpen(
                                                true
                                            );
                                        }}
                                    >
                                        {emailStageStatus(
                                            enrolledFor
                                        )}
                                    </Link>
                                ) : (
                                    "-"
                                )}
                            </TableCell>

                        </TableRow>
                    </TableBody>
                </Table>
            </TableCell>
        </TableRow>
    );
};

export default ExpandedRow;