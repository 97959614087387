import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SvgIcon } from "@mui/material";
import BarChartSquare02Icon from "src/icons/untitled-ui/duocolor/bar-chart-square-02";
import Users02 from "@untitled-ui/icons-react/build/esm/Users02";
import MessageChatSquareIcon from "src/icons/untitled-ui/duocolor/message-chat-square";
import Announcement01Icon from "@untitled-ui/icons-react/build/esm/Announcement01";
import MessageTextSquareIcon from "@untitled-ui/icons-react/build/esm/MessageTextSquare02";
import CalendarPlus02Icon from "@untitled-ui/icons-react/build/esm/CalendarPlus02";
import Mail01Icon from "@untitled-ui/icons-react/build/esm/Mail01";
import FlashIcon from "@untitled-ui/icons-react/build/esm/Flash";
import GraduationHat01Icon from "@untitled-ui/icons-react/build/esm/GraduationHat02";
import Headphones02Icon from "@untitled-ui/icons-react/build/esm/Headphones02";
import Link01Icon from "@untitled-ui/icons-react/build/esm/Link01";
import CampaignIcon from "@mui/icons-material/Campaign";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import FeedIcon from "@mui/icons-material/Feed";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import ArticleIcon from "@mui/icons-material/Article";
import { tokens } from "src/locales/tokens";
import { paths } from "src/paths";
import { Contacts } from "@mui/icons-material";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import axios from "axios";
import {
  ADMIN_SERVER,
  API_SERVICE_BACKEND,
  API_SERVICE_BACKEND_2,
} from "src/config";
import { useState } from "react";
import { useEffect } from "react";
import { jobsApi } from "src/api/jobs";
import { getSessionStorage } from "src/utils/storage";

async function generateHMAC(message, key) {
  const encoder = new TextEncoder();
  const keyData = encoder.encode(key);
  const messageData = encoder.encode(message);

  const cryptoKey = await crypto.subtle.importKey(
    "raw",
    keyData,
    { name: "HMAC", hash: { name: "SHA-256" } },
    false,
    ["sign"]
  );

  const signature = await crypto.subtle.sign("HMAC", cryptoKey, messageData);
  return Array.from(new Uint8Array(signature))
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
}

const hash = await generateHMAC(
  getSessionStorage("userEmail"),
  process.env.REACT_APP_INTERCOM_HASH_KEY
);

export const useSections = () => {
  const { t } = useTranslation();

  const clientEmail = getSessionStorage("userEmail");
  const organizationId = getSessionStorage("organization_id");

  const [convoUnreadCount, setConvoUnreadCount] = useState(null);
  const getConvoUnreadCount = async () => {
    try {
      const campaigns = await jobsApi.getUserCampaigns(clientEmail);
      const campaignIds = campaigns?.data?.map(({ _id }) => _id);

      let url = `${API_SERVICE_BACKEND}/getCampConvo`;
      url += `/all?campaignIdListStr=${campaignIds.join(",")}`;
      // console.log(url);
      const response = await fetch(url);
      const data = await response.json();
      // console.log("old -> ", data);
      let unreadCount = 0;
      data.conversations.forEach((convo) => {
        unreadCount += convo.meta.unread || 0;
      });
      setConvoUnreadCount(unreadCount);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    async function fetchAndSetCampaignsCount() {
      try {
        const campaigns = await jobsApi.getUserCampaigns(
          getSessionStorage("userEmail")
        );
        window.Intercom("update", {
          email: getSessionStorage("userEmail"),
          name: getSessionStorage("fullName"),
          user_hash: hash,
          ai_agents_created: campaigns?.data?.length,
          campaigns_created: campaigns?.data?.length,
        });
      } catch (error) {
        console.error("Error fetching campaigns:", error);
      }
    }
    if (getSessionStorage("userEmail")) {
      fetchAndSetCampaignsCount();
    }
  }, []);

  const [meetUnseenCount, setMeetUnseenCount] = useState(null);
  const getMeetUnreadCount = async () => {
    try {
      const campaigns = await jobsApi.getUserCampaigns(clientEmail);
      const campaignIds = campaigns?.data?.map(({ _id }) => _id);
      let url = `${API_SERVICE_BACKEND}/dashboard-chart`;
      url += `/?campaignIds=${campaignIds.join(",")}`;
      const response = await fetch(url);
      const data = await response.json();
      var meetings = data?.data?.meetings;
      const isWithinLastDays = (date, days) => {
        const today = new Date();
        const dateBefore = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - days
        );
        return date > dateBefore;
      };

      // Calculate meetings in the last 7 and 30 days
      const calculateMeetings = (meetings, days) => {
        return meetings
          .filter((meeting) => {
            const meetingDate = new Date(meeting._id);
            return isWithinLastDays(meetingDate, days);
          })
          .reduce((total, meeting) => total + meeting.count, 0);
      };
      const totalMeetingsLast7Days = calculateMeetings(meetings, 7);
      const totalMeetingsLast30Days = calculateMeetings(meetings, 30);
      window.Intercom("update", {
        email: getSessionStorage("userEmail"),
        name: getSessionStorage("fullName"),
        user_hash: hash,
        meetings_booked_7days: totalMeetingsLast7Days,
        meetings_booked_30days: totalMeetingsLast30Days,
      });
      var tempUnseenCount = 0;

      meetings.map((item) => {
        item.seen === false && tempUnseenCount++;
      });
      // console.log(tempUnseenCount);
    } catch (err) {
      console.log(err);
    }
  };

  const [aiAgentHidden, setAiAgentHidden] = useState(true);
  useEffect(() => {
    getConvoUnreadCount();
    getMeetUnreadCount();
    axios
      .get(
        `${ADMIN_SERVER}/customer/getPolicyDetails/${clientEmail}/${organizationId}`
      )
      .then((res) => {
        // console.log(res);
        const showAiAgent = res.data.policyControls["AI Agent"];
        setAiAgentHidden(!showAiAgent);
        // console.log(!showAiAgent);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  return useMemo(() => {
    return [
      {
        subheader: "BDR AI Agent",
        items: [
          // {
          //   title: t("Dashboard"),
          //   fromDashBoard: true,
          //   path: paths.dashboard.index,
          //   icon: (
          //     <SvgIcon fontSize="medium">
          //       <BarChartSquare02Icon />
          //     </SvgIcon>
          //   ),
          // },
          // {
          //   title: t("Dashboard"),
          //   path: paths.dashboard.dashboard,
          //   icon: (
          //     <SvgIcon fontSize="medium">
          //       <BarChartSquare02Icon />
          //     </SvgIcon>
          //   ),
          // },
          {
            title: t("AI Campaigns"),
            path: paths.dashboard.campaigns,
            icon: (
              <SvgIcon fontSize="medium">
                <Announcement01Icon />
              </SvgIcon>
            ),
            childPaths: [
              paths.dashboard.contacts,
              `${paths.dashboard.campaigns}/create`,
              `${paths.dashboard.campaigns}/edit`,
              `${paths.dashboard.campaigns}/chat-campaign`,
            ],
          },

          {
            title: t("Analytics"),
            path: paths.dashboard.analytics,
            icon: (
              <SvgIcon fontSize="medium">
                <BarChartSquare02Icon />
              </SvgIcon>
            ),
            options: {
              unreadCount: convoUnreadCount,
            },
          },
          {
            title: t("Leads"),
            path: `${paths.dashboard.leads}/all`,
            icon: (
              <SvgIcon fontSize="medium">
                <GroupOutlinedIcon />
              </SvgIcon>
            ),
          },
          {
            title: t("Email Replies"),
            path: paths.dashboard.emailReplies,
            icon: (
              <SvgIcon fontSize="medium">
                <MessageTextSquareIcon />
              </SvgIcon>
            ),
          },
          {
            title: t("Conversations"),
            path: paths.dashboard.conversations,
            icon: (
              <SvgIcon fontSize="medium">
                <MessageTextSquareIcon />
              </SvgIcon>
            ),
          },
          {
            title: t("Calendar"),
            path: paths.dashboard.calendar,
            icon: (
              <SvgIcon fontSize="medium">
                <CalendarPlus02Icon />
              </SvgIcon>
            ),
            options: {
              unseenCount: meetUnseenCount,
            },
          },
          // {
          //   title: t("AI Agents"),
          //   path: paths.dashboard.campaigns,
          //   icon: (
          //     <SvgIcon fontSize="medium">
          //       <Announcement01Icon />
          //     </SvgIcon>
          //   ),
          //   // hidden: aiAgentHidden,
          // },

          // {
          //   title: t("Analytics"),
          //   path: paths.dashboard.analytics,
          //   icon: (
          //     <SvgIcon fontSize="medium">
          //       <BarChartSquare02Icon />
          //     </SvgIcon>
          //   ),
          //   options: {
          //     unreadCount: convoUnreadCount,
          //   },
          // },
          // {
          //   title: t("Leads"),
          //   path: `${paths.dashboard.leads}/all`,
          //   icon: (
          //     <SvgIcon fontSize="medium">
          //       <GroupOutlinedIcon />
          //     </SvgIcon>
          //   ),
          // },
          // {
          //   title: t("Contacts"),
          //   path: paths.dashboard.contacts,
          //   icon: (
          //     <SvgIcon fontSize="medium">
          //       <PeopleAltIcon />
          //     </SvgIcon>
          //   )
          // },
          // {
          //   title: t("Conversations"),
          //   path: paths.dashboard.conversations,
          //   icon: (
          //     <SvgIcon fontSize="medium">
          //       <MessageTextSquareIcon />
          //     </SvgIcon>
          //   ),
          // },
          // {
          //   title: t("ICP Form"),
          //   path: "/dashboard/icp",
          //   icon: (
          //     <SvgIcon fontSize="medium">
          //       <FeedIcon />
          //     </SvgIcon>
          //   ),
          // },
          // {
          //   title: t("Chat Support"),
          //   path: paths.dashboard.chat,
          //   icon: (
          //     <SvgIcon fontSize="small">
          //       <MessageChatSquareIcon />
          //     </SvgIcon>
          //   ),
          // },
          // {
          //   title: t("Conversations"),
          //   path: "/dashboard/userconversations",
          //   icon: (
          //     <SvgIcon fontSize="small">
          //       <ArticleIcon />
          //     </SvgIcon>
          //   ),
          // },
          // {
          //   title: t("Calendar"),
          //   path: paths.dashboard.calendar,
          //   icon: (
          //     <SvgIcon fontSize="medium">
          //       <CalendarPlus02Icon />
          //     </SvgIcon>
          //   ),
          //   options: {
          //     unseenCount: meetUnseenCount,
          //   },
          // },
          // {
          //   title: t("Integrations"),
          //   path: paths.dashboard.integrations,
          //   icon: (
          //     <SvgIcon fontSize="medium">
          //       <Link01Icon />
          //     </SvgIcon>
          //   ),
          // },
          // {
          //   title: t("Support Center"),
          //   path: paths.dashboard.supportCenter,
          //   icon: (
          //     <SvgIcon fontSize="medium">
          //       <Headphones02Icon />
          //     </SvgIcon>
          //   ),
          // },
          // {
          //   title: t("Academy"),
          //   path: paths.dashboard.academy.index,
          //   icon: (
          //     <SvgIcon fontSize="medium">
          //       <GraduationHat01Icon />
          //     </SvgIcon>
          //   ),
          // },
          // {
          //   title: t("Chat Support"),
          //   path: paths.dashboard.chat,
          //   icon: (
          //     <SvgIcon fontSize="medium">
          //       <MessageChatSquareIcon />
          //     </SvgIcon>
          //   ),
          // },

          // {
          //   title: t(tokens.nav.analytics),
          //   path: paths.dashboard.analytics,
          //   icon: (
          //     <SvgIcon fontSize="small">
          //       <BarChartSquare02Icon />
          //     </SvgIcon>
          //   )
          // },
          // {
          //   title: t(tokens.nav.ecommerce),
          //   path: paths.dashboard.ecommerce,
          //   icon: (
          //     <SvgIcon fontSize="small">
          //       <LineChartUp04Icon />
          //     </SvgIcon>
          //   )
          // },
          // {
          //   title: t(tokens.nav.crypto),
          //   path: paths.dashboard.crypto,
          //   icon: (
          //     <SvgIcon fontSize="small">
          //       <CurrencyBitcoinCircleIcon />
          //     </SvgIcon>
          //   ),
          //   label: (
          //     <Chip
          //       color="primary"
          //       label="New"
          //       size="small"
          //     />
          //   )
          // },
          // {
          //   title: t(tokens.nav.account),
          //   path: paths.dashboard.account,
          //   icon: (
          //     <SvgIcon fontSize="small">
          //       <HomeSmileIcon />
          //     </SvgIcon>
          //   )
          // }
        ],
      },
    /*  {
        subheader: "Prospects",
        items: [
          {
            title: t("Lists"),
            path: paths.dashboard.lists,
            icon: (
              <SvgIcon fontSize="medium">
                <GroupOutlinedIcon />
              </SvgIcon>
            ),
          },
          {
            title: t("Search"),
            path: paths.dashboard.search,
            icon: (
              <SvgIcon fontSize="medium">
                <GroupOutlinedIcon />
              </SvgIcon>
            ),
          },
          {
            title: t("Enrich"),
            path: paths.dashboard.enrich,
            icon: (
              <SvgIcon fontSize="medium">
                <GroupOutlinedIcon />
              </SvgIcon>
            ),
          },
          {
            title: t("Validate"),
            path: paths.dashboard.validate,
            icon: (
              <SvgIcon fontSize="medium">
                <GroupOutlinedIcon />
              </SvgIcon>
            ),
          },
          {
            title: t("Clean Up"),
            path: paths.dashboard.cleanUp,
            icon: (
              <SvgIcon fontSize="medium">
                <GroupOutlinedIcon />
              </SvgIcon>
            ),
          },
          {
            title: t("Intent Data"),
            path: paths.dashboard.intentData,
            icon: (
              <SvgIcon fontSize="medium">
                <GroupOutlinedIcon />
              </SvgIcon>
            ),
          },
        ],
      },
      {
        subheader: "Engage",
        items: [
          {
            title: t("Engage"),
            path: paths.dashboard.engage,
            icon: (
              <SvgIcon fontSize="medium">
                <Mail01Icon />
              </SvgIcon>
            ),
          },
        ],
      },
      {
        subheader: "Free Tools",
        items: [
          {
            title: t("Spam Checker"),
            path: paths.dashboard.freeTools.spamChecker,
            icon: (
              <SvgIcon fontSize="medium">
                <FlashIcon />
              </SvgIcon>
            ),
          },
          {
            title: t("Meeting Scheduler"),
            path: paths.dashboard.freeTools.meetingScheduler.index,
            icon: (
              <SvgIcon fontSize="medium">
                <FlashIcon />
              </SvgIcon>
            ),
          },
          {
            title: t("Calendar"),
            path: paths.dashboard.calendar,
            icon: (
              <SvgIcon fontSize="medium">
                <CalendarPlus02Icon />
              </SvgIcon>
            ),
            options: {
              unseenCount: meetUnseenCount,
            },
          },
        ],
      }, */
      // {
      //   subheader: t(tokens.nav.concepts),
      //   items: [
      //     {
      //       title: t(tokens.nav.customers),
      //       path: paths.dashboard.customers.index,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <Users03Icon />
      //         </SvgIcon>
      //       ),
      //       items: [
      //         {
      //           title: t(tokens.nav.list),
      //           path: paths.dashboard.customers.index
      //         },
      //         {
      //           title: t(tokens.nav.details),
      //           path: paths.dashboard.customers.details
      //         },
      //         {
      //           title: t(tokens.nav.edit),
      //           path: paths.dashboard.customers.edit
      //         }
      //       ]
      //     },
      //     {
      //       title: t(tokens.nav.productList),
      //       path: paths.dashboard.products.index,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <ShoppingBag03Icon />
      //         </SvgIcon>
      //       ),
      //       items: [
      //         {
      //           title: t(tokens.nav.list),
      //           path: paths.dashboard.products.index
      //         },
      //         {
      //           title: t(tokens.nav.create),
      //           path: paths.dashboard.products.create
      //         }
      //       ]
      //     },
      //     {
      //       title: t(tokens.nav.orderList),
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <ShoppingCart01Icon />
      //         </SvgIcon>
      //       ),
      //       path: paths.dashboard.orders.index,
      //       items: [
      //         {
      //           title: t(tokens.nav.list),
      //           path: paths.dashboard.orders.index
      //         },
      //         {
      //           title: t(tokens.nav.details),
      //           path: paths.dashboard.orders.details
      //         }
      //       ]
      //     },
      //     {
      //       title: t(tokens.nav.invoiceList),
      //       path: paths.dashboard.invoices.index,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <ReceiptCheckIcon />
      //         </SvgIcon>
      //       ),
      //       items: [
      //         {
      //           title: t(tokens.nav.list),
      //           path: paths.dashboard.invoices.index
      //         },
      //         {
      //           title: t(tokens.nav.details),
      //           path: paths.dashboard.invoices.details
      //         }
      //       ]
      //     },
      //     {
      //       title: t(tokens.nav.logistics),
      //       path: paths.dashboard.logistics.index,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <Truck01Icon />
      //         </SvgIcon>
      //       ),
      //       items: [
      //         {
      //           title: t(tokens.nav.dashboard),
      //           path: paths.dashboard.logistics.index
      //         },
      //         {
      //           title: t(tokens.nav.fleet),
      //           path: paths.dashboard.logistics.fleet
      //         }
      //       ]
      //     },
      //     {
      //       title: t(tokens.nav.academy),
      //       path: paths.dashboard.academy.index,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <GraduationHat01Icon />
      //         </SvgIcon>
      //       ),
      //       items: [
      //         {
      //           title: t(tokens.nav.dashboard),
      //           path: paths.dashboard.academy.index
      //         },
      //         {
      //           title: t(tokens.nav.course),
      //           path: paths.dashboard.academy.courseDetails
      //         }
      //       ]
      //     },
      //     {
      //       title: t(tokens.nav.jobList),
      //       path: paths.dashboard.jobs.index,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <Building04Icon />
      //         </SvgIcon>
      //       ),
      //       items: [
      //         {
      //           title: t(tokens.nav.browse),
      //           path: paths.dashboard.jobs.index
      //         },
      //         {
      //           title: t(tokens.nav.details),
      //           path: paths.dashboard.jobs.companies.details
      //         },
      //         {
      //           title: t(tokens.nav.create),
      //           path: paths.dashboard.jobs.create
      //         }
      //       ]
      //     },
      //     {
      //       title: t(tokens.nav.socialMedia),
      //       path: paths.dashboard.social.index,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <Share07Icon />
      //         </SvgIcon>
      //       ),
      //       items: [
      //         {
      //           title: t(tokens.nav.profile),
      //           path: paths.dashboard.social.profile
      //         },
      //         {
      //           title: t(tokens.nav.feed),
      //           path: paths.dashboard.social.feed
      //         }
      //       ]
      //     },
      //     {
      //       title: t(tokens.nav.blog),
      //       path: paths.dashboard.blog.index,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <LayoutAlt02Icon />
      //         </SvgIcon>
      //       ),
      //       items: [
      //         {
      //           title: t(tokens.nav.postList),
      //           path: paths.dashboard.blog.index
      //         },
      //         {
      //           title: t(tokens.nav.postDetails),
      //           path: paths.dashboard.blog.postDetails
      //         },
      //         {
      //           title: t(tokens.nav.postCreate),
      //           path: paths.dashboard.blog.postCreate
      //         }
      //       ]
      //     },
      //     {
      //       title: t(tokens.nav.fileManager),
      //       path: paths.dashboard.fileManager,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <Upload04Icon />
      //         </SvgIcon>
      //       )
      //     },
      //     {
      //       title: t(tokens.nav.kanban),
      //       path: paths.dashboard.kanban,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <CheckDone01Icon />
      //         </SvgIcon>
      //       )
      //     },
      //     {
      //       title: t(tokens.nav.mail),
      //       path: paths.dashboard.mail,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <Mail03Icon />
      //         </SvgIcon>
      //       )
      //     },
      //     {
      //       title: t(tokens.nav.chat),
      //       path: paths.dashboard.chat,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <MessageChatSquareIcon />
      //         </SvgIcon>
      //       )
      //     },
      //     {
      //       title: t(tokens.nav.calendar),
      //       path: paths.dashboard.calendar,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <CalendarIcon />
      //         </SvgIcon>
      //       )
      //     }
      //   ]
      // },
      // {
      //   subheader: t(tokens.nav.pages),
      //   items: [
      //     {
      //       title: t(tokens.nav.auth),
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <Lock01Icon />
      //         </SvgIcon>
      //       ),
      //       items: [
      //         {
      //           title: t(tokens.nav.login),
      //           items: [
      //             {
      //               title: 'Classic',
      //               path: paths.authDemo.login.classic
      //             },
      //             {
      //               title: 'Modern',
      //               path: paths.authDemo.login.modern
      //             }
      //           ]
      //         },
      //         {
      //           title: t(tokens.nav.register),
      //           items: [
      //             {
      //               title: 'Classic',
      //               path: paths.authDemo.register.classic
      //             },
      //             {
      //               title: 'Modern',
      //               path: paths.authDemo.register.modern
      //             }
      //           ]
      //         },
      //         {
      //           title: t(tokens.nav.forgotPassword),
      //           items: [
      //             {
      //               title: 'Classic',
      //               path: paths.authDemo.forgotPassword.classic
      //             },
      //             {
      //               title: 'Modern',
      //               path: paths.authDemo.forgotPassword.modern
      //             }
      //           ]
      //         },
      //         {
      //           title: t(tokens.nav.resetPassword),
      //           items: [
      //             {
      //               title: 'Classic',
      //               path: paths.authDemo.resetPassword.classic
      //             },
      //             {
      //               title: 'Modern',
      //               path: paths.authDemo.resetPassword.modern
      //             }
      //           ]
      //         },
      //         {
      //           title: t(tokens.nav.verifyCode),
      //           items: [
      //             {
      //               title: 'Classic',
      //               path: paths.authDemo.verifyCode.classic
      //             },
      //             {
      //               title: 'Modern',
      //               path: paths.authDemo.verifyCode.modern
      //             }
      //           ]
      //         }
      //       ]
      //     },
      //     {
      //       title: t(tokens.nav.pricing),
      //       path: paths.pricing,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <CreditCard01Icon />
      //         </SvgIcon>
      //       )
      //     },
      //     {
      //       title: t(tokens.nav.checkout),
      //       path: paths.checkout,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <LogOut01Icon />
      //         </SvgIcon>
      //       )
      //     },
      //     {
      //       title: t(tokens.nav.contact),
      //       path: paths.contact,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <Mail04Icon />
      //         </SvgIcon>
      //       )
      //     },
      //     {
      //       title: t(tokens.nav.error),
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <XSquareIcon />
      //         </SvgIcon>
      //       ),
      //       items: [
      //         {
      //           title: '401',
      //           path: paths['401']
      //         },
      //         {
      //           title: '404',
      //           path: paths['404']
      //         },
      //         {
      //           title: '500',
      //           path: paths['500']
      //         }
      //       ]
      //     }
      //   ]
      // },
      // {
      //   subheader: 'Misc',
      //   items: [
      //     {
      //       title: 'Level 0',
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <AlignLeft02Icon />
      //         </SvgIcon>
      //       ),
      //       items: [
      //         {
      //           title: 'Level 1a',
      //           items: [
      //             {
      //               title: 'Level 2a',
      //               items: [
      //                 {
      //                   title: 'Level 3a'
      //                 },
      //                 {
      //                   title: 'Level 3b',
      //                   disabled: true
      //                 }
      //               ]
      //             },
      //             {
      //               title: 'Level 2b'
      //             }
      //           ]
      //         },
      //         {
      //           title: 'Level 1b'
      //         }
      //       ]
      //     },
      //     {
      //       title: 'Disabled',
      //       disabled: true,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <XSquareIcon />
      //         </SvgIcon>
      //       )
      //     },
      //     {
      //       title: 'Label',
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <File01Icon />
      //         </SvgIcon>
      //       ),
      //       label: (
      //         <Chip
      //           color="primary"
      //           label="New"
      //           size="small"
      //         />
      //       )
      //     },
      //     {
      //       title: 'Blank',
      //       path: paths.dashboard.blank,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <File01Icon />
      //         </SvgIcon>
      //       )
      //     },
      //     {
      //       title: 'External Link',
      //       path: 'https://devias.io',
      //       external: true,
      //       icon: (
      //         <SvgIcon fontSize="small">
      //           <File01Icon />
      //         </SvgIcon>
      //       )
      //     }
      //   ]
      // }
    ];
  }, [t, aiAgentHidden, convoUnreadCount]);
};
