import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import ChevronDownIcon from "@untitled-ui/icons-react/build/esm/ChevronDown";
import ChevronUpIcon from "@untitled-ui/icons-react/build/esm/ChevronUp";
import ChevronRightIcon from "@untitled-ui/icons-react/build/esm/ChevronRight";
import {
  Badge,
  Box,
  ButtonBase,
  Collapse,
  Stack,
  SvgIcon,
  Menu,
  Tooltip
} from "@mui/material";
import { RouterLink } from "src/components/router-link";
import { paths } from "src/paths";
import { useLocation } from "react-router";

const badgeMap = {
  Calendar: "unseenCount",
  "AI Agents Conversations": "unreadCount",
};

export const SideNavItem = (props) => {
  const {
    active,
    children,
    depth = 0,
    disabled,
    external,
    icon,
    label,
    open: openProp,
    path,
    title,
    options,
    shrink,
    expand,
    setExpand
  } = props;

  const badgeCount = options?.[badgeMap[title]];


  const [anchorEl, setAnchorEl] = useState(null);
  const childMenuOpen = Boolean(anchorEl);
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };


  const { pathname } = useLocation();

  // const handleToggle = useCallback(() => {
  //   setOpen((prevOpen) => !prevOpen);
  // }, []);

  const handleToggle = () => {
    if(expand === title) {
      setExpand("")
    }
    else {
      setExpand(title)
    }
  }

  useEffect(()=>{
    if(shrink){
      //setOpen(false);
      setExpand("")
    }
    else{
      setAnchorEl(null)
    }

  },[shrink])
  const open = true; // expand === title;

  // Icons can be defined at top level only, deep levels have bullets instead of actual icons.

  let startIcon;

  if (depth === 0) {
    startIcon = icon;
  } else {
    startIcon = null;
    // startIcon = (
    //   <Box
    //     sx={{
    //       alignItems: "center",
    //       display: "center",
    //       height: 20,
    //       justifyContent: "center",
    //       width: 20,
    //     }}
    //   >
    //     <Box
    //       sx={{
    //         backgroundColor: "var(--nav-item-icon-color)",
    //         borderRadius: "50%",
    //         height: 4,
    //         opacity: 0,
    //         width: 4,
    //         ...(active && {
    //           backgroundColor: "var(--nav-item-icon-active-color)",
    //           height: 6,
    //           opacity: 1,
    //           width: 6,
    //         }),
    //       }}
    //     />
    //   </Box>
    // );
  }

  const offset = depth === 0 ? 0 : (depth - 1) * 16;

  // Branch

  if (children) {
    return (
      <li 
        style ={{
          backgroundColor: shrink ? (active && depth === 0) ? "rgba(88, 98, 255, 0.1)" : "transparent" : "transparent", 
          borderRadius: "8px"
        }}
      >
        <ButtonBase
          disabled={disabled}
          disableRipple
          onClick={shrink ? handleClickMenu: handleToggle}
          sx={{
            alignItems: "center",
            borderRadius: 1,
            display: "flex",
            justifyContent: "flex-start",
            pl: `${16 + offset}px`,
            //pr: "16px",
            py: "12px",
            textAlign: "left",
            width: "100%",
            ...(active && {
              ...(depth === 0 && {
                //backgroundColor: "var(--nav-item-active-bg)",
                //backgroundColor: "rgba(88, 98, 255, 0.1)",
                color: "var(--nav-item-active-bg)",
              }),
            }),
            "&:hover": {
              //backgroundColor: "var(--nav-item-hover-bg)",
            },
          }}
        >
          {startIcon && (
            <Box
              component="span"
              sx={{
                alignItems: "center",
                color: "var(--nav-item-color)",
                display: "inline-flex",
                justifyContent: "center",
                mr: 2,
                minWidth: 0,
                ml: shrink ? "8px" : 0,
                ...(active && {
                  color: "var(--nav-item-active-bg)",
                }),
              }}
            >
              <Tooltip title={shrink ? title: ""} placement="right" arrow>
                {startIcon}
              </Tooltip>
            </Box>
          )}
          <Box
            component="span"
            sx={{
              opacity: shrink ? 0 : 1,
              color: "var(--nav-item-color)",
              flexGrow: 1,
              fontFamily: (theme) => theme.typography.fontFamily,
              fontSize: depth > 0 ? 16 : 18,
              fontWeight: depth > 0 ? 400 : 500,
              lineHeight: "24px",
              whiteSpace: "nowrap",
              ...(active && {
                color: "var(--nav-item-active-bg)",
              }),
              ...(disabled && {
                color: "var(--nav-item-disabled-color)",
              }),
            }}
          >
            {title}
          </Box>
          <SvgIcon
            sx={{
              display: "none", // temporary
              opacity: shrink ? 0 : 1,
              color: active ? "var(--nav-item-active-bg)" : "var(--nav-item-color)",
              fontSize: 18,
              ml: 2,
              mr: 1
            }}
          >
            {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </SvgIcon>
        </ButtonBase>
        <Collapse 
          in={shrink? false: open} 
          sx={{ pr: shrink ? 0 : open ? 1 : 0, pb: shrink ? 0 : open ? 1 : 0 }}
        >
          {children}
        </Collapse>
        <Menu
          anchorEl={anchorEl}
          open={shrink ? childMenuOpen : false}
          onClose={handleCloseMenu}
          sx={{
            '& .MuiList-root': { p: 1, pl: 0 },
          }}
        >
          {children}
        </Menu>
      </li>
    );
  }

  // Leaf

  const linkProps = path
    ? external
      ? {
          component: "a",
          href: path,
          target: "_blank",
        }
      : {
          component: RouterLink,
          href: path,
          state: { prev: path },
          key: title
        }
    : {};

    function handleAuxClick(event) {
      if(depth === 0){
        setExpand("")
      }
      if (event.button === 1) {
        event.preventDefault(); // Prevent the default behavior of opening links in new tabs
      }
      if (event.metaKey) {
        event.preventDefault(); // Prevent the default behavior if Command key is pressed
      }
  }

  return (
    <li 
      style={{
        ...(depth === 1 && {marginLeft: "16px"})
      }}
    >
      <ButtonBase
        disableTouchRipple
        disabled={disabled}
        onClick={handleAuxClick}
        onAuxClick={handleAuxClick}
        sx={{
          alignItems: "center",
          borderRadius: 1,
          display: "flex",
          justifyContent: "flex-start",
          pl: `${16 + offset}px`,
          pr: "16px",
          py: "12px",
          textAlign: "left",
          textTransform: "capitalize",
          width: "100%",
          ...(active && {
            ...(depth === 0 && {
              //backgroundColor: "var(--nav-item-active-bg)",
              backgroundColor: "rgba(88, 98, 255, 0.1)",
            }),
          }),
          "&:hover": {
            //backgroundColor: "var(--nav-item-hover-bg)",
            textDecoration: "none",
          },
          "&:active, :focus": {
            textDecoration: "none",
          },
          "&::before": {
            content: '""',
            display: depth === 0 ? "none" : "block",
            position: "absolute",
            zIndex: 1,
            left: "9.5px",
            height: "100%",
            width: "1.5px",
            opacity: 1,
            background: "hsl(215, 15%, 92%)",
          },
          "&::after": {
            content: '""',
            display: depth === 0 ? "none" : "block",
            position: "absolute",
            zIndex: 1,
            left: "9.5px",
            height: "50%",
            width: "2px",
            opacity: 1,
            background: active ? "hsl(210, 100%, 60%)" : "transparent",
          },
          ...(depth === 1 && {pt: 0, pr: 0, pb: 0}),
        }}
        {...linkProps}
      >
        {startIcon && (
          <Box
            component="span"
            sx={{
              alignItems: "center",
              //color: "var(--nav-item-icon-color)",
              display: "inline-flex",
              justifyContent: "center",
              color: "var(--nav-item-color)",
              mr: 2,
              minWidth: 0,
              ml: shrink ? "8px" : 0,
              ...(active && {
                color: "var(--nav-item-active-bg)",
              }),
            }}
          >
            <Tooltip title={shrink ? title: ""} placement="right" arrow>
              {startIcon}
            </Tooltip>
          </Box>
        )}
        <Box
          component="span"
          sx={{
            opacity: shrink ? 0 : 1,
            color: "var(--nav-item-color)",
            flexGrow: 1,
            fontFamily: (theme) => theme.typography.fontFamily,
            fontSize: depth > 0 ? 16 : 18,
            fontWeight: depth > 0 ? 400 : 500,
            lineHeight: "24px",
            ...(depth === 1 && {
              opacity: 1,
              px: "12px",
              py: "8px",
              borderRadius: "10px",
              ml: 1,
              backgroundColor: active ? (shrink ? "rgba(88, 98, 255, 1)" : "var(--nav-item-active-bg)") : "auto"
            }),
            whiteSpace: "nowrap",
            ...(active && {
              color: depth === 0 ? "var(--nav-item-active-bg)" : (shrink ? "#ffffff" : "var(--nav-item-active-color)"),
            }),
            ...(disabled && {
              color: "var(--nav-item-disabled-color)",
            }),
          }}
        >
          {title}
        </Box>
        {label && (
          <Box component="span" sx={{ ml: 2, opacity: shrink ? 0 : 1, }}>
            {label}
          </Box>
        )}
        {Boolean(badgeCount) && (
          <Box component="span">
            <Stack
              ml={1}
              justifyContent="center"
              alignItems="center"
              style={{
                opacity: shrink ? 0 : 1,
                fontSize: "10px",
                height: "20px",
                width: "20px",
                color: "white",
                borderRadius: "50%",
                backgroundColor: "#007ad4",
              }}
            >
              <b>{badgeCount}</b>
            </Stack>
          </Box>
        )}
      </ButtonBase>
    </li>
  );
};

SideNavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number,
  disabled: PropTypes.bool,
  external: PropTypes.bool,
  icon: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
};
