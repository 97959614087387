import PropTypes from "prop-types";
import { Box, Stack } from "@mui/material";
import { SideNavItem } from "./side-nav-item";

const renderItems = ({ depth = 0, items, pathname, shrink, expand, setExpand }) =>
  items.reduce(
    (acc, item) =>
      reduceChildRoutes({
        acc,
        depth,
        item,
        pathname,
        shrink,
        expand,
        setExpand
      }),
    []
  );

const reduceChildRoutes = ({ acc, depth, item, pathname, shrink, expand, setExpand }) => {
  const checkPath = !!(item.path && pathname);
  const partialMatch = checkPath ? pathname.includes(item.path) : false;
  const exactMatch = checkPath ? (pathname === item.path || item.childPaths?.includes(pathname)) : false;

  if (item.hidden) return acc;

  if (item.items) {
    acc.push(
      <SideNavItem
        active={partialMatch}
        depth={depth}
        disabled={item.disabled}
        icon={item.icon}
        key={item.title}
        label={item.label}
        open={partialMatch}
        title={item.title}
        options={item.options}
        shrink={shrink}
        expand={expand}
        setExpand={setExpand}
      >
        <Stack
          component="ul"
          spacing={0.5}
          sx={{
            listStyle: "none",
            m: 0,
            p: 0,
          }}
        >
          {renderItems({
            depth: depth + 1,
            items: item.items,
            pathname,
            shrink,
            expand,
            setExpand
          })}
        </Stack>
      </SideNavItem>
    );
  } else {
    acc.push(
      <SideNavItem
        active={exactMatch}
        depth={depth}
        disabled={item.disabled}
        external={item.external}
        icon={item.icon}
        key={item.title}
        label={item.label}
        path={item.path}
        title={item.title}
        options={item.options}
        shrink={shrink}
        expand={expand}
        setExpand={setExpand}
      />
    );
  }

  return acc;
};

export const SideNavSection = (props) => {
  const { items = [], pathname, subheader = "", title = "", path, icon, open, expand, setExpand, ...other } = props;
  const childPaths = items?.map((e) => e?.path)?.concat(items?.reduce((acc,e) => acc.concat(e.childPaths || []), [] ));
  const isSectionActive = subheader ? childPaths?.some((path) => pathname === path) : pathname === path ;

  return (
    <Stack
      component="ul"
      //spacing={0.5}
      sx={{
        listStyle: "none",
        m: 0,
        p: 0,
      }}
      {...other}
    >
      {subheader ? (
        <SideNavItem
          shrink={!open}
          expand={expand}
          setExpand={setExpand}
          active={isSectionActive}
          depth={0}
          //disabled={item.disabled}
          icon={items[0].icon}
          //key={item.title}
          label={subheader}
          open={isSectionActive}
          title={subheader}
          //options={item.options}
        >
          {renderItems({depth: 1, items, pathname, shrink: !open, expand, setExpand })}
        </SideNavItem>
      ) : 
      (
        <SideNavItem
          shrink={!open}   
          expand={expand}
          setExpand={setExpand}
          active={isSectionActive}
          depth={0}
          //disabled={item.disabled}
          icon={icon}
          //key={item.title}
          label={subheader}
          // open={isSectionActive}
          path={path}
          title={title}
        />
      )}
    </Stack>
  );
};

SideNavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  subheader: PropTypes.string,
};
