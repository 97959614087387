import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import ArrowRightIcon from "@untitled-ui/icons-react/build/esm/ArrowRight";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  Slider,
  Stack,
  SvgIcon,
  Switch,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Badge,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  Radio,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from "@mui/material";
// import { MobileDatePicker } from "@mui/x-date-pickers";
import {
  FILTERS,
  INDUSTRY_OPTIONS,
  initAddFilters,
  moveRange,
  industryTypes,
  MANAGEMENT_LEVELS,
  CHANGED_JOBS_WITHIN,
  YearsOfExperience,
  companyList,
  educationMajor,
  degree as degreeList,
  DEPARTMENT,
  initAdditionalFilters,
  SIC_CODES,
  NAICS_CODE,
  initApolloAdditionalFilters,
  initApolloAddFilters,
  APOLLOFILTERS,
} from "../../../data/campaign";
import {
  AddCircle,
  Check,
  DatasetSharp,
  InputRounded,
  CloseOutlined,
  UploadFileOutlined,
  ChevronRightOutlined,
  InfoOutlined,
  DeleteOutlined,
  WarningAmberOutlined,
} from "@mui/icons-material";
import DownloadIcon from '@mui/icons-material/Download';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { COUNTRIES } from "src/data/countries";
import { VariableSizeList } from "react-window";
import { alpha, color } from "@mui/system";
import Settings from "src/components/logos/settings.png";
import LayerFilter from "./layer-filter";
import MultiSelect from "./multi-select";
import MultiInput from "./multi-input";
import { ApolloUpdates } from "./apolloUpdates";
import { OldFilters } from "./oldFilters";
import CsvImport from "src/sections/dashboard/jobs/csvImport";
import FlashIcon from "src/components/logos/flash.png";
import SampleLeads from "src/assets/samples/sample-leads.csv";
import Mapping from "src/assets/mapping.png";
import "./style.css";

const isAccess=sessionStorage.getItem("access")

const LISTBOX_PADDING = 8; // px code

const leadSourceTypes = [
  { label: "Use Filters", value: "auto" },
  { label: "Import From CSV File", value: "csv" },
];

const mapTitleToValue = (options, title) => {
  const found = options.find((option) => option.title === title);
  return found ? found.value : null;
};

const CustomTextField = ({ state, setState }) => {
  const [text, setText] = useState(state);
  useEffect(() => setText(state), [state]);
  return (
    <TextField
      // type="number"
      placeholder="Enter a name"
      variant="outlined"
      value={text}
      spellCheck={true}
      onChange={(e) => setText(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          const refinedName = e.target.value.trim();
          setState(refinedName);
          setText(refinedName);
        }
      }}
      sx={{
        backgroundColor: "white",
        borderRadius: 1,
        width: "100%",
      }}
    />
  );
};

export const CustomCompSize = ({ handleCustomSize, fields, setFields }) => {
  const [minSize, setMinSize] = useState(fields?.min || 0);
  const [maxSize, setMaxSize] = useState(fields?.max || 0);
  const [minError, setMinError] = useState("");
  const [maxError, setMaxError] = useState("");

  const handleEnter = () => {
    const isMinNumber = /^\d+$/.test(minSize);
    const isMaxNumber = /^\d+$/.test(maxSize);
    let newSize = { min: 0, max: 0 };

    if (isMinNumber && isMaxNumber) {
      if (Number(minSize) < Number(maxSize)) {
        newSize.min = Number(minSize);
        newSize.max = Number(maxSize);
        setMinError("");
        setMaxError("");
        setFields((pre) => ({
          ...pre,
          customCompSize: newSize,
        }));
        return;
      } else {
        setMaxError("Max cannot be less than Min");
        setMinError("");
      }
    } else {
      if (minSize === "" && maxSize === "") {
        setMaxError("Max Value is required");
        setMinError("Min Value is required");
        return;
      } else {
        if (maxSize === "") {
          setMinError("");
          setMaxError("Max Value is required");
        }

        if (minSize === "") {
          setMinError("Min Value is required");
          setMaxError("");
        }
      }

      if (!isMaxNumber && !isMinNumber) {
        setMinError("Invalid input");
        setMaxError("Invalid input");
        return;
      } else {
        if (!isMaxNumber) {
          setMinError("");
          setMaxError("Invalid input");
        }
        if (!isMinNumber) {
          setMinError("Invalid input");
          setMaxError("");
        }
      }
    }
  };

  useEffect(() => {
    handleEnter();
  }, [minSize, maxSize]);
  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: "space-between",
        mt: 0.8,
        p: 1,
      }}
    >
      <Stack sx={{ width: "48%" }}>
        <TextField
          className="custom-input"
          type="number"
          variant="outlined"
          value={minSize}
          size="small"
          error={!!minError}
          helperText={minError}
          onChange={(e) => setMinSize(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              // handleCustomSize(e, "min");
              handleEnter();
            }
          }}
          // onBlur={handleBlur}
          placeholder="Min"
          inputProps={
            {
              // step: 10,
              // min: 1,
              // max: 1000000000,
              // type: "number",
            }
          }
          sx={{
            pt: 0.5,
            input: {
              // paddingLeft: "80px",
            },
          }}
        />
      </Stack>
      <Stack sx={{ width: "48%" }}>
        <TextField
          type="number"
          className="custom-input"
          variant="outlined"
          value={maxSize}
          size="small"
          placeholder="Max"
          error={!!maxError}
          helperText={maxError}
          // onChange={(e) => handleCustomSize(e, "max")}
          onChange={(e) => setMaxSize(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              // handleCustomSize(e, "max");
              handleEnter();
            }
          }}
          inputProps={
            {
              // step: 10,
              // min: 1,
              // max: 1000000000,
              // type: "number",
            }
          }
          sx={{
            pt: 0.5,
            input: {
              // paddingLeft: "80px",
            },
          }}
        />
      </Stack>
    </Stack>
  );
};

export const CustomExperience = ({ experience, setExperience }) => {
  const initialMinExp = experience.includes("-")
    ? experience.split("-")[0]
    : experience.split("")[0];
  const initialMaxExp = experience.includes("-")
    ? experience.split("-")[1]
    : experience.split("")[1];
  const [minExp, setMinExp] = useState(initialMinExp || "");
  const [maxExp, setMaxExp] = useState(initialMaxExp || "");

  useEffect(() => {
    if (experience === "") {
      setMinExp("");
      setMaxExp("");
    }
  }, [experience]);

  const handleCustomExp = () => {
    const isMinNumber = /^\d+$/.test(minExp);
    const isMaxNumber = /^\d+$/.test(maxExp);

    if (isMinNumber && isMaxNumber) {
      if (Number(minExp) < Number(maxExp)) {
        setExperience(`${minExp}-${maxExp}`);
      }
    } else {
      if (isMinNumber && maxExp === "") {
        setExperience(`${minExp}<`);
      }
      if (isMaxNumber && minExp === "") {
        setExperience(`<${maxExp}`);
      }
      if (minExp === "" && maxExp === "") {
        setExperience("");
      }
    }
  };

  useEffect(() => {
    if (Number(minExp) < Number(maxExp)) {
      setExperience(`${minExp}-${maxExp}`);
    }
  }, [minExp, maxExp]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        gap={1}
        sx={{ mt: 1 }}
      >
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          placeholder="Min"
          value={minExp}
          error={!/^[\d\s]+$/.test(minExp) && minExp !== ""}
          onChange={(e) => setMinExp(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleCustomExp();
            }
          }}
          inputProps={
            {
              // type: "number",
            }
          }
          sx={{
            "&.MuiTextField-root .MuiOutlinedInput-root": {
              backgroundColor: "white",
            },
          }}
          helperText="Min: 0"
        />
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          placeholder="Max"
          value={maxExp}
          error={!/^[\d\s]+$/.test(maxExp) && maxExp !== ""}
          onChange={(e) => setMaxExp(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleCustomExp();
            }
          }}
          inputProps={
            {
              // type: "number",
            }
          }
          sx={{
            "&.MuiTextField-root .MuiOutlinedInput-root": {
              backgroundColor: "white",
            },
          }}
          helperText="Max: 50"
        />
      </Stack>
    </>
  );
};

function renderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];

  const option = dataSet[1];
  const state = dataSet[3];
  // {index, selected, inputValue}

  return (
    <Stack direction="row">
      <Checkbox
        // icon={checkboxOfIcon}
        // checkedIcon={checkboxOnIcon}
        // style={{ marginRight: 8 }}
        checked={state.selected}
      />
      <Typography component="li" {...dataSet[0]} noWrap>
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          {/* {!Boolean(option.grpId) ? (
          <img
            loading="lazy"
            width={20}
            src={`https://flagcdn.com/w20/${option.value?.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.value?.toLowerCase()}.png 2x`}
            alt=""
          />
        ) : (
          <span style={{ marginLeft: `60px`}}></span>
        )} */}
          {option.title} ({option.children?.length || 0})
        </Box>
      </Typography>
    </Stack>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <List ref={ref} {...props} {...outerProps} />;
});

// function useResetCache(data) {
//   const ref = React.useRef(null);
//   React.useEffect(() => {
//     if (ref.current != null) {
//       ref.current.resetAfterIndex(0, true);
//     }
//   }, [data]);
//   return ref;
// }

const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props;
  const itemData = children;

  const itemCount = itemData.length;
  const itemSize = 48;

  const getChildSize = (child) => {
    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  // const gridRef = useResetCache(itemCount);

  return (
    <div
      style={{
        paddingLeft: `${8 * 1}px`,
        backgroundColor: "#f6f6f7",
      }}
      ref={ref}
    >
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          // ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType={ListItem}
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// const { min: minRevenue, max: maxRevenue } = FILTERS.find(
//   ({ id }) => id === "revenue"
// )?.options?.range;

export const CustomRenderOption = (props) => {
  const {
    extra,
    option,
    state,
    ownerState,
    setOwnerState,
    setValue: onChange,
    vid,
    setShowSubHeadings,
    singleSelect,
  } = props;

  const [expanded, setExpanded] = useState(false);
  const [selected, setSelected] = useState(
    ownerState[vid].findIndex(({ title }) => title === option.title) !== -1
  );

  useEffect(() => {
    setSelected(
      ownerState[vid].findIndex(({ title }) => title === option.title) !== -1
    );
  }, [ownerState]);
  const handleChange = (e) => {
    setSelected(e.target.checked);
    if (e.target.checked) {
      if (!option.grpId) {
        let allSelected = COUNTRIES.filter((countryObj) => {
          return countryObj.grpId === option.title;
        });
        setOwnerState((prev) => {
          const curr = prev[vid];
          const uniqueCheck = new Set(curr.map((val) => val.title));
          allSelected = allSelected.filter(
            ({ title, grpId }) => !uniqueCheck.has(title)
          );
          const newValue = [...curr, ...allSelected, option];
          return {
            ...prev,
            [vid]: singleSelect ? newValue.slice(-1) : newValue,
          };
        });
      } else {
        setOwnerState((prev) => {
          const newValue = [...prev[vid], option];
          const totCount = COUNTRIES.filter(
            ({ grpId }) => grpId === option.grpId
          ).length;

          let headSubSelCount = 0;
          newValue.forEach(({ title, grpId }) => {
            if (grpId === option.grpId) {
              headSubSelCount += 1;
            }
          });
          if (totCount === headSubSelCount) {
            newValue.push({ title: option.grpId });
          }
          return {
            ...prev,
            [vid]: singleSelect ? newValue.slice(-1) : newValue,
          };
        });
      }
    } else {
      if (!option.grpId) {
        setOwnerState((prev) => {
          const newValue = prev[vid].filter(
            ({ grpId, title }) =>
              grpId !== option.title && !(title === option.title)
          );
          return {
            ...prev,
            [vid]: singleSelect ? newValue.slice(-1) : newValue,
          };
        });
      } else {
        setOwnerState((prev) => {
          let newValue = prev[vid].filter(
            ({ title }) => title !== option.title
          );

          const totCount = COUNTRIES.filter(
            ({ grpId }) => grpId === option.grpId
          ).length;

          let headSubSelCount = 0;
          newValue.forEach(({ title, grpId }) => {
            if (grpId === option.grpId) {
              headSubSelCount += 1;
            }
          });
          if (totCount !== headSubSelCount) {
            newValue = newValue.filter(
              ({ title, grpId }) => title !== option.grpId
            );
          }
          return {
            ...prev,
            [vid]: singleSelect ? newValue.slice(-1) : newValue,
          };
        });
      }
    }
  };

  useEffect(() => {
    if (expanded) {
      setShowSubHeadings((prev) => [...prev, option.title]);
    } else {
      setShowSubHeadings((prev) => prev.filter((val) => option.title !== val));
    }
  }, [expanded]);

  return (
    <>
      <Box
        component="li"
        sx={{
          "& > img": {
            mr: 2,
            flexShrink: 0,
          },
        }}
        {...extra}
      >
        <Stack sx={{ width: "100%", ml: Boolean(option.grpId) ? 2 : 0 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center">
              <Checkbox
                // icon={checkboxOfIcon}
                // checkedIcon={checkboxOnIcon}
                style={{ marginRight: 8 }}
                // checked={state.selected}
                checked={selected}
                onChange={handleChange}
              />
              {/* <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${option.value?.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${option.value?.toLowerCase()}.png 2x`}
              alt=""
            /> */}
              <Typography
                sx={{ fontSize: "14px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setExpanded((prev) => !prev);
                }}
              >
                {option.title}{" "}
                {!option.grpId && (
                  <>
                    (
                    {
                      COUNTRIES.filter(({ grpId }) => grpId === option.title)
                        .length
                    }
                    )
                  </>
                )}
              </Typography>
            </Stack>
            {!Boolean(option.grpId) && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setExpanded((prev) => !prev);
                }}
              >
                {!expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
            )}
          </Stack>
          {/* <div style={{ marginLeft: "25%" }}>
            {
              expanded &&
                // <Stack>
                COUNTRIES.map(({ title, grpId }) => {
                  if (grpId !== option.title) return;
                  return (
                    <RenderSublist title={title} ownerState={ownerState} />
                  );
                })
              // </Stack>
            }
          </div> */}
        </Stack>
      </Box>
    </>
  );
};

export const RevenueSelect = ({
  onChange,
  state,
  editCampaign,
  vid,
  setState,
  revenueOption,
  disabled,
  customRevenue,
  setCustomRevenue,
}) => {
  const selectedRevenue = state.revenue;
  return (
    <FormControl sx={{ width: "100%" }}>
      <Select
        disabled={disabled}
        id="revenue"
        sx={{ backgroundColor: "white" }}
        // multiple
        value={customRevenue ? "Select" : selectedRevenue.join(",")}
        onChange={(e) => {
          const customValue = e.target.value;
          const newValue = e.target.value.split(",").map(Number);
          if (customValue === "Custom") {
            setCustomRevenue(true);
          } else {
            setCustomRevenue(false);
            onChange(vid, newValue);
          }
        }}
        input={<OutlinedInput id="select-multiple-chip" />}
      >
        <MenuItem key="Select" value="Select">
          Select
        </MenuItem>
        {revenueOption.values.map((range, index) => {
          const ref = {
            10000000: "< $10M",
            50000000: "$10M -> $50M",
            100000000: "$50M -> $100M",
            500000000: "$100M -> $500M",
            1000000000: "$500M -> $1B",
            5000000000: "$1B -> $5B",
            10000000000: "$5B+",
          };
          let label = `${ref[range[1]]}`;
          // if (range[1] === 1000000000) label = `${range[0] - 1} +`;
          return (
            <MenuItem key={index} value={range.join(",")}>
              {label}
              {/* {range} */}
            </MenuItem>
          );
        })}
        <MenuItem key="Custom" value="Custom">
          Custom
        </MenuItem>
      </Select>
    </FormControl>
  );
};

const LocationSlider = ({ setRadius, radius }) => {
  const marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 25,
      label: "25",
    },
    {
      value: 50,
      label: "50",
    },
    {
      value: 75,
      label: "75",
    },
    {
      value: 100,
      label: "100",
    },
  ];

  function valuetext(value) {
    return `${value}`;
  }
  return (
    <Box>
      <Typography sx={{ fontSize: "14px" }}>
        Radius
      </Typography>
      <Slider
        sx={{ px: 1, width: "92%" }}
        aria-label="Custom marks"
        value={radius[0] || 0}
        getAriaValueText={valuetext}
        onChange={(e) => setRadius([e.target.value])}
        step={25}
        valueLabelDisplay="auto"
        marks={marks}
      />
    </Box>
  );
};

export const Filter = (props) => {
  const {
    name,
    view,
    type,
    vid,
    customVID,
    state,
    setState,
    onChange,
    singleSelect,
    options,
    extraFilters,
    fields,
    setFields,
    editCampaign,
    error,
    locationError,
    islgUp,
    ismdUp,
  } = props;
  const [employeOption, setEmployeOption] = useState(options);
  const [abc, setAbc] = useState(true);
  const disabled = view === true;
  const isRangeSlider = type === "range-slider";

  const isCustomRevenueExist =
    vid === "revenue" &&
    (state.revenue.length === 0 ||
      FILTERS[2].options.values.findIndex(
        (rev) => rev[0] === state.revenue?.[0] && rev[1] === state.revenue?.[1]
      ) === -1);
  const [customRevenue, setCustomRevenue] = useState(isCustomRevenueExist);

  useEffect(() => {
    setCustomRevenue(isCustomRevenueExist);
  }, [isCustomRevenueExist]);
  // for rangeSliders

  // convert actual values in the range 0-100 for Slider
  const [revInpRes, setRevInpRes] = useState(
    isRangeSlider ? Boolean(state[vid]?.length) : false
  );
  const sliderRange = [0, 100];
  // const minmax =
  //   vid === "revenue"
  //     ? state.addFilters.revenue || [100000, 50000000]
  //     : vid === "funding" || [100000, 50000000]
  //     ? state.addFilters.funding
  //     : [100000, 50000000];
  const [temp, setTemp] = useState();
  // const [revenueCheck, setRevenueCheck] = useState(false || revInpRes);
  const [revenueCheck, setRevenueCheck] = useState(true);
  const [minRevError, setMinRevError] = useState("");
  const [maxRevError, setMaxRevError] = useState("");
  const [minFundError, setMinFundError] = useState("");
  const [maxFundError, setMaxFundError] = useState("");

  useEffect(() => {
    if (!isRangeSlider || Boolean(state[vid]?.length)) return;
    onChange(vid, revenueCheck ? [options.range.min, options.range.max] : []);
  }, [revenueCheck]);

  useEffect(() => {
    setRevInpRes(isRangeSlider ? Boolean(state[vid]?.length) : false);
  }, [state]);

  useEffect(() => {
    // setRevenueCheck(false || revInpRes);
    setRevenueCheck(true);
  }, [revInpRes]);

  const [showSubHeadings, setShowSubHeadings] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  if (isRangeSlider) {
  }
  const findNonMatchingPairs = (inputArray) => {
    for (let i = 0; i < inputArray.length; i++) {
      const arrRange = inputArray[i].split(",");

      if (arrRange.length === 2) {
        const part1 = parseInt(arrRange[0]);
        const part2 = parseInt(arrRange[1]);

        let found = false;
        for (const option of FILTERS[1].options) {
          const lowerBound = option[0];
          const upperBound = option[1];
          if (part1 === lowerBound && part2 === upperBound) {
            found = true;
            break;
          }
        }
        if (!found) {
          inputArray[i] = "Custom";
          return [inputArray, [part1, part2]];
        }
      }
    }
    return [inputArray, null];
  };
  switch (true) {
    case ["location", "blockedLocations"].includes(vid):
      let tempValue = state[customVID ? customVID : vid];
      const locationValue = tempValue.map((location) => {
        if (location.title) {
          if (location?.title?.includes("Orlando")) {
            location.title = '"FL > Orlando Area"';
          } else if (location.title?.includes("Los Angeles")) {
            location.title = '"CA > Los Angeles Area"';
          }
          return location;
        } else {
          const country = location?.toString().split("::~")[0];
          return {
            title: country,
            value: mapTitleToValue(COUNTRIES, country),
          };
        }

        //  return title;
      });
      return (
        <Box sx={{ width: "100%" }}>
          <Autocomplete
            freeSolo
            disabled={disabled}
            multiple
            id="filter-location"
            sx={{ width: "100%", mt: 0 }}
            options={COUNTRIES}
            disableCloseOnSelect
            getOptionLabel={(option) => option.title || option}
            groupBy={(option) => option.grpId || option.title}
            renderInput={(params) => (
              <Stack spacing={1}>
                {/* <Typography sx={{ fontSize: "14px", color: "#050C46" }}>
      {vid === "blockedLocations"
        ? "Exclude Locations"
        : "Choose Locations"}
    </Typography> */}
                <TextField
                  {...params}
                  label={
                    vid === "blockedLocations"
                      ? "Exclude Locations"
                      : view
                        ? "Locations"
                        : "Choose Locations"
                  }
                  variant="outlined"
                  style={{ backgroundColor: "white", borderRadius: "8px" }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              </Stack>
            )}
            renderOption={
              // (props, option, state) => [props, option, state.index, state]
              (props, option, persState) => {
                if (
                  option.grpId &&
                  !showSubHeadings.includes(option.grpId) &&
                  !isSearching
                )
                  return;
                return (
                  <CustomRenderOption
                    extra={props}
                    option={option}
                    state={persState}
                    ownerState={state}
                    vid={customVID ? customVID : vid}
                    setShowSubHeadings={setShowSubHeadings}
                    setValue={onChange}
                    setOwnerState={setState}
                    singleSelect={singleSelect}
                  />
                );
              }
            }
            filterOptions={(options, state) => {
              setIsSearching(true);
              if (!state.inputValue) {
                setIsSearching(false);
                return options;
              }
              return options.filter((val) =>
                new RegExp(state.inputValue, "ig").test(val.title)
              );
            }}
            // value={state[customVID ? customVID : vid]}
            value={locationValue}
            onChange={(event, newValue, reason) => {
              // onChange(
              //   customVID ? customVID : vid,
              //   // singleSelect ? finalRes.slice(-1) : finalRes
              //   singleSelect ? newValue.slice(-1) : newValue
              // );
              if (reason === "removeOption") {
                onChange(
                  customVID ? customVID : vid,
                  // singleSelect ? finalRes.slice(-1) : finalRes
                  singleSelect ? newValue.slice(-1) : newValue
                );
              }
              if (reason === "clear") {
                onChange(customVID ? customVID : vid, []);
              }
            }}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  label={option.title}
                  {...getTagProps({ index })}
                  sx={{
                    backgroundColor: "#111927",
                    color: "white",
                    "& .MuiChip-deleteIcon": {
                      backgroundColor: "transparent !important",
                      color: "white !important",
                      display: view ? "none" : "",
                      "&:hover": {
                        color: "white",
                      },
                    },
                  }}
                />
              ))
            }
          />
          {locationError && (
            <Typography color="error" sx={{ fontSize: "12px" }}>
              {locationError}
            </Typography>
          )}

          {!view && !singleSelect && (
            <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
              <Button
                sx={{
                  cursor: "pointer",
                  fontSize: "14px",
                  fontWeight: 600,
                  mr: 0.5,
                  // mt: 1,
                  color: "blue",
                  "&:hover": { color: "darkblue" },
                }}
                onClick={() => {
                  onChange(vid, COUNTRIES);
                }}
              >
                Select all
              </Button>
              <Button
                sx={{
                  cursor: "pointer",
                  fontSize: "14px",
                  fontWeight: 600,
                  mr: 0.5,
                  // mt: 1,
                  color: "blue",
                  "&:hover": { color: "red" },
                }}
                onClick={() => {
                  onChange(vid, []);
                }}
              >
                Clear all
              </Button>
            </Box>
          )}
        </Box>
      );
    case ["funding"].includes(vid):
      const minFunding = new Intl.NumberFormat("en-US").format(
        state[vid]?.[0] || 0
      );
      const maxFunding = new Intl.NumberFormat("en-US").format(
        state[vid]?.[1] || 0
      );
      return (
        <Box
          sx={{
            // border: "1px solid #e4e7eb",
            borderRadius: 1,
          }}
        >
          {state[vid] && (
            <>
              {view && (
                <Typography
                  sx={{
                    color: view ? " #a19e9e" : "#111927",
                    fontSize: "14px",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  Funding
                </Typography>
              )}
              <Stack direction="row" justifyContent="space-between" gap={1}>
                <TextField
                  variant="outlined"
                  disabled={view ? true : false}
                  value={
                    state[vid]?.[0] === 0
                      ? state[vid]?.[0]
                      : state[vid]?.[0] || ""
                  }
                  placeholder="From"
                  // value={minFunding}
                  onChange={(e) => {
                    if (e.target.value < 0) {
                      setMinFundError("Number is too small");
                    } else {
                      setMinFundError("");
                      onChange(vid, [parseInt(e.target.value), state[vid][1]]);
                    }
                  }}
                  size="small"
                  sx={{
                    "&.MuiTextField-root .MuiOutlinedInput-root": {
                      backgroundColor: "white",
                    },
                  }}
                  // onChange={handleInputChange}
                  // onBlur={handleBlur}
                  // inputProps={
                  //   {
                  //     // step: 10,
                  //     // min: 0,
                  //     // max: 100,
                  //     // type: "number",
                  //   }
                  // }
                  inputProps={{
                    //  step: 10,
                    min: 0, // options.range.min,
                    max: 1000000000, // options.range.max,
                    type: "number",
                  }}
                  // InputProps={{
                  //   startAdornment: (
                  //     <InputAdornment position="start">From:</InputAdornment>
                  //   ),
                  //   //  readOnly: true,
                  //   // endAdornment: (
                  //   //   <InputAdornment position="end">$0</InputAdornment>
                  //   // ),
                  // }}
                  error={!!minFundError}
                  helperText={!!minFundError ? minFundError : "Min"}
                />

                <TextField
                  variant="outlined"
                  value={
                    state[vid]?.[1] === 0
                      ? state[vid]?.[1]
                      : state[vid]?.[1] || ""
                  }
                  // value={maxFunding}
                  placeholder="To"
                  disabled={view ? true : false}
                  onChange={(e) => {
                    if (e.target.value > 10000000000) {
                      setMaxFundError("Number is too large");
                    } else {
                      setMaxFundError("");
                      onChange(vid, [state[vid][0], parseInt(e.target.value)]);
                    }
                  }}
                  size="small"
                  sx={{
                    "&.MuiTextField-root .MuiOutlinedInput-root": {
                      backgroundColor: "white",
                    },
                  }}
                  // onChange={handleInputChange}
                  // onBlur={handleBlur}
                  // inputProps={
                  //   {
                  //     // step: 10,
                  //     // min: 0,
                  //     // max: 100,
                  //     // type: "number",
                  //   }
                  // }
                  inputProps={{
                    // step: 10,
                    min: 0, // options.range.min,
                    max: 1000000000, // options.range.max,
                    type: "number",
                  }}
                  error={!!maxFundError}
                  helperText={maxFundError ? maxFundError : "Max"}
                />
              </Stack>

              {!view && (
                <Stack direction={"row"} justifyContent={"flex-end"}>
                  <Button
                    sx={{
                      cursor: "pointer",
                      fontSize: "14px",
                      fontWeight: 600,
                      mr: 0.5,
                      // mt: 1,
                      color: "blue",
                      "&:hover": { color: "red" },
                    }}
                    onClick={() => {
                      onChange(vid, []);
                      setMaxFundError("");
                      setMinFundError("");
                    }}
                  >
                    Clear
                  </Button>
                </Stack>
              )}
            </>
          )}
        </Box>
      );
    case ["revenue"].includes(vid):
      const minRevenue = new Intl.NumberFormat("en-US").format(
        state[vid]?.[0] || 0
      );
      const maxRevenue = new Intl.NumberFormat("en-US").format(
        state[vid]?.[1] || 0
      );
      return (
        <Box
          sx={{
            borderRadius: 1,
          }}
        >
          {/* <Stack direction="row" alignItems="center" marginBottom={0.7}>
            <Checkbox
              disabled={disabled}
              checked={revenueCheck}
              onChange={(e) => {
                setRevenueCheck(e.target.checked);
                onChange(
                  vid,
                  e.target.checked ? [options.range.min, options.range.max] : []
                );
              }}
              inputProps={{ "aria-label": "revenue-select" }}
            />
            <Typography sx={{ fontSize: "14px", color: "#050C46" }}>
              {name}
            </Typography>
          </Stack> */}
          {revenueCheck && (
            <>
              {!view && (
                <RevenueSelect
                  onChange={onChange}
                  state={state}
                  editCampaign={editCampaign}
                  vid={vid}
                  setState={setState}
                  revenueOption={options}
                  disabled={disabled}
                  customRevenue={customRevenue}
                  setCustomRevenue={setCustomRevenue}
                />
              )}
              {customRevenue && (
                <>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      marginBottom: "10px",
                    }}
                  >
                    Revenue
                  </Typography>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    gap={1}
                    sx={{ mt: 1 }}
                  >
                    <TextField
                      variant="outlined"
                      value={
                        state[vid]?.[0] === 0
                          ? state[vid]?.[0]
                          : state[vid]?.[0] || ""
                      }
                      disabled={view ? true : false}
                      placeholder="From"
                      // value={minRevenue}
                      size="small"
                      fullWidth
                      onChange={(e) => {
                        if (e.target.value < 0) {
                          setMinRevError("Number is too small");
                        } else {
                          setMinRevError("");

                          onChange(vid, [
                            parseInt(e.target.value),
                            state[vid][1],
                          ]);
                        }
                      }}
                      // onBlur={handleBlur}
                      inputProps={{
                        //  step: 10,
                        min: 0, // options.range.min,
                        max: 1000000000, // options.range.max,
                        type: "number",
                      }}
                      sx={{
                        "&.MuiTextField-root .MuiOutlinedInput-root": {
                          backgroundColor: "white",
                        },
                      }}
                      // InputProps={{
                      //   startAdornment: (
                      //     <InputAdornment position="start">
                      //       From:
                      //     </InputAdornment>
                      //   ),
                      //   //  readOnly: true,
                      //   // endAdornment: (
                      //   //   <InputAdornment position="end">$0</InputAdornment>
                      //   // ),
                      // }}
                      error={!!minRevError}
                      helperText={!!minRevError ? minRevError : "Min"}
                    />
                    <TextField
                      variant="outlined"
                      value={
                        state[vid]?.[1] === 0
                          ? state[vid]?.[1]
                          : state[vid]?.[1] || ""
                      }
                      disabled={view ? true : false}
                      placeholder="To"
                      // value={maxRevenue}
                      size="small"
                      onChange={(e) => {
                        if (e.target.value > 10000000000) {
                          setMaxRevError("Number is too large");
                        } else {
                          setMaxRevError("");
                          onChange(vid, [
                            state[vid][0],
                            parseInt(e.target.value),
                          ]);
                        }
                      }}
                      fullWidth
                      // onChange={handleInputChange}
                      // onBlur={handleBlur}
                      inputProps={{
                        //  step: 10,
                        min: 0, // options.range.min,
                        max: 1000000000, // options.range.max,
                        type: "number",
                      }}
                      sx={{
                        "&.MuiTextField-root .MuiOutlinedInput-root": {
                          backgroundColor: "white",
                        },
                      }}
                      // InputProps={{
                      //   startAdornment: (
                      //     <InputAdornment position="start">To:</InputAdornment>
                      //   ),
                      //   //  readOnly: true,
                      //   // endAdornment: (
                      //   //   <InputAdornment position="end">
                      //   //     $1,000,000,000
                      //   //   </InputAdornment>
                      //   // ),
                      // }}
                      error={!!maxRevError}
                      helperText={!!maxRevError ? maxRevError : "Max"}
                    />
                  </Stack>

                  {!view && (
                    <Stack direction={"row"} justifyContent={"flex-end"}>
                      <Button
                        sx={{
                          cursor: "pointer",
                          fontSize: "14px",
                          fontWeight: 600,
                          mr: 0.5,
                          // mt: 1,
                          color: "blue",
                          "&:hover": { color: "red" },
                        }}
                        onClick={() => {
                          onChange(vid, []);
                          setMaxRevError("");
                          setMinRevError("");
                        }}
                      >
                        Clear
                      </Button>
                    </Stack>
                  )}
                </>
              )}
            </>
          )}
        </Box>
      );
    case ["compSize"].includes(vid):
      // if (state[vid].length === 0) state[vid] = [[], null];
      // else
      // if (!Array.isArray(state[vid][0]))
      //   state[vid] = findNonMatchingPairs(state[vid]);
      let emplyCount;

      if (editCampaign) {
        emplyCount =
          state.compSize &&
          state.compSize.map((item) => {
            if (
              item ===
              `${state.customCompSize?.min},${state.customCompSize?.max}`
            ) {
              return "Custom";
            }
            // If not "Custom", leave the item unchanged
            return item;
          });
      } else {
        let compSizeArray = state.compSize;
        if (Array.isArray(compSizeArray) && compSizeArray?.length > 0) {
          let compSizeString = compSizeArray[0];
          let [lowerBound, upperBound] = compSizeString.split(",");
          let formattedCompSize = [`${lowerBound}-${upperBound}`];
          emplyCount = formattedCompSize;
        } else {
          emplyCount = state.compSize;
        }
      }

      // const formattedRanges = emplyCount.map((range) => {
      //   const [start, end] = range.split(",");
      //   if (end === "1000000000") {
      //     return `${start - 1} +`;
      //   } else {
      //     return `${start} to ${end}`;
      //   }
      // });

      const handleCustomSize = (e, name) => {
        const { value } = e.target;

        setFields((pre) => ({
          ...pre,
          customCompSize: { ...pre.customCompSize, [name]: value },
        }));
      };

      return (
        <Box sx={{ width: "100%" }}>
          {/* <Typography
            sx={{ fontSize: "14px", color: "#050C46", fontWeight: "500" }}
          >
            {name}
          </Typography> */}
          {view ? (
            <>
              <Autocomplete
                disabled={true}
                multiple
                variant="outlined"
                id="compSize"
                options={INDUSTRY_OPTIONS}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                      sx={{
                        color: "#5862FF",
                        "&.Mui-checked": {
                          color: "#5862FF",
                        },
                      }}
                    />
                    {option}
                  </li>
                )}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Number of employees"
                    variant="outlined"
                  />
                )}
                value={emplyCount}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      label={option}
                      {...getTagProps({ index })}
                      sx={{
                        backgroundColor: "#111927",
                        color: "white",
                        "& .MuiChip-deleteIcon": {
                          backgroundColor: "transparent !important",
                          color: "white !important",
                          display: "none",
                          "&:hover": {
                            color: "white",
                          },
                        },
                      }}
                    />
                  ))
                }
              />
            </>
          ) : (
            <>
              <FormControl sx={{ width: "100%" }}>
                <Select
                  disabled={disabled}
                  id="company-size"
                  sx={{ backgroundColor: "white" }}
                  multiple
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  value={emplyCount || []}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const filteredValue = newValue.filter(
                      (item) => item !== "Custom"
                    );
                    const isCustomSelected =
                      filteredValue.length !== newValue.length;
                    const updatedState = isCustomSelected ? newValue : newValue;
                    onChange(vid, updatedState);
                  }}
                  input={<OutlinedInput id="select-multiple-chip" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected?.length === 0 ? (
                        <span>Select</span>
                      ) : (
                        selected.map((range, index) => {
                          const arrRange = range.split(",");
                          let label;
                          if (range === "Custom") {
                            label = range;
                          } else {
                            label = range.split(",").join(" to ");
                          }
                          // let label = `${arrRange[0]} to ${arrRange[1]}`;
                          // if (arrRange[0] === "Custom") label = "Custom";
                          // if (arrRange[1] === 1000000000)
                          // label = `${arrRange[0] - 1} +`;
                          return (
                            <Chip
                              key={index}
                              label={label}
                              sx={{
                                backgroundColor: "#111927",
                                color: "white",
                                "& .MuiChip-deleteIcon": {
                                  backgroundColor: "transparent !important",
                                  color: "white !important",
                                  "&:hover": {
                                    color: "white",
                                  },
                                },
                              }}
                              onMouseDown={(e) => {
                                e.stopPropagation();
                              }}
                              onDelete={(e) => {
                                const temp = emplyCount.filter(
                                  (r) => !(r === range.toString())
                                );
                                // Check if the label is "Custom" and set state[vid][1] to null if true
                                const updatedState =
                                  label === "Custom" ? temp : temp;
                                onChange(vid, updatedState);
                                if (label === "Custom") {
                                  setState((pre) => ({
                                    ...pre,
                                    customCompSize: { min: 0, max: 0 },
                                  }));
                                }
                              }}
                            />
                          );
                        })
                      )}
                    </Box>
                  )}
                // MenuProps={MenuProps}
                >
                  <MenuItem key="placeholder-compsize" disabled value="">
                    Select
                  </MenuItem>
                  {employeOption.map((range, index) => {
                    let label = `${new Intl.NumberFormat("en-US", {
                      maximumSignificantDigits: 3,
                    }).format(range[0])} -> ${new Intl.NumberFormat("en-US", {
                      maximumSignificantDigits: 3,
                    }).format(range[1])}`;
                    // if (range[1] === 1000000000) label = `${range[0] - 1} +`;
                    return (
                      <MenuItem key={index} value={range.toString()}>
                        {label}
                      </MenuItem>
                    );
                  })}
                  <MenuItem key="Custom" value="Custom">
                    Custom
                  </MenuItem>
                </Select>
              </FormControl>
              {((state?.compSize && state?.compSize.includes("Custom")) ||
                state?.compSize.includes(
                  `${state?.customCompSize?.min},${state?.customCompSize?.max}`
                )) && (
                  <CustomCompSize
                    handleCustomSize={handleCustomSize}
                    fields={fields}
                    setFields={setFields}
                  />
                )}
            </>
          )}
          {!view && (
            <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
              <Button
                sx={{
                  cursor: "pointer",
                  fontSize: "14px",
                  fontWeight: 600,
                  mr: 0.5,
                  // mt: 1,
                  color: "blue",
                  "&:hover": { color: "darkblue" },
                }}
                onClick={() => {
                  onChange(
                    vid,
                    options.map((range) => range.toString())
                  );
                }}
              >
                Select all
              </Button>
              <Button
                sx={{
                  cursor: "pointer",
                  fontSize: "14px",
                  fontWeight: 600,
                  mr: 0.5,
                  // mt: 1,
                  color: "blue",
                  "&:hover": { color: "red" },
                }}
                onClick={() => {
                  setState((pre) => ({
                    ...pre,
                    customCompSize: { min: 0, max: 0 },
                  }));
                  onChange(vid, []);
                }}
              >
                Clear all
              </Button>
            </Box>
          )}
        </Box>
      );
    case ["keywords", "blockedKeywords"].includes(vid):
      const keywordLimit = 15;
      return (
        <>
          {/* <Typography
            sx={{
              fontSize: "14px",
              color: "#050C46",
              fontWeight: "500",
            }}
          >
            {name}
          </Typography> */}
          <Stack
            // mt={1}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <TextField
              variant="outlined"
              disabled={disabled}
              sx={{ backgroundColor: "white", borderRadius: 1 }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  document.getElementById(`${vid}Button`).click();
                }
              }}
              InputProps={{
                startAdornment:
                  view &&
                  state[vid]?.map((val, index) => (
                    <Chip
                      disabled={view ? true : false}
                      key={index}
                      label={val}
                      onDelete={() => {
                        const temp = state[vid];
                        temp.splice(index, 1);
                        onChange(vid, temp);
                      }}
                      variant="outlined"
                      sx={{
                        backgroundColor: "#111927",
                        color: "white",
                        "& .MuiChip-deleteIcon": {
                          backgroundColor: "transparent !important",
                          color: "white !important",
                          display: view ? "none" : "",
                          "&:hover": {
                            color: "white",
                          },
                        },
                      }}
                    />
                  )),
                endAdornment: (
                  <>
                    {!view && (
                      <InputAdornment position="end">
                        <Button
                          disabled={state[vid].length === keywordLimit}
                          variant="contained"
                          id={`${vid}Button`}
                          sx={{
                            ml: 2,
                            borderRadius: 1,
                            backgroundColor: "#5761FE",
                            padding: "8px 14px 8px 10px",
                            "& .MuiSvgIcon-root": { color: "#FFFFFF" },
                          }}
                          onClick={(e) => {
                            const inpEle = document.getElementById(
                              `${vid}Input`
                            );
                            const keywords = inpEle.value
                              .split(",")
                              .map((val) => val.trim().toLowerCase());
                            const uniqueKeywords = new Set([
                              ...keywords,
                              ...state[vid],
                            ]);
                            const temp = [];
                            for (let keyword of uniqueKeywords) {
                              if (!keyword) continue;
                              temp.push(keyword);
                            }
                            onChange(vid, temp.sort());
                            inpEle.value = "";
                          }}
                        >
                          <AddIcon />
                          Add
                        </Button>
                      </InputAdornment>
                    )}
                  </>
                ),
              }}
              id={`${vid}Input`}
              fullWidth
              title={name}
              // label={name}
              label={
                ismdUp
                  ? name
                  : name.title?.length <= 5
                    ? name
                    : name.slice(0, 11) + " ..."
              }
            />
          </Stack>
          {state[vid].length === keywordLimit && (
            <Typography color="error" style={{ fontSize: "12px" }}>
              {name} limit reached
            </Typography>
          )}
          <Stack
            direction={islgUp ? "column" : "row"}
            alignItems="flex-start"
            justifyContent="space-between"
          >
            {!view && (
              <Stack
                mt={1}
                alignItems="center"
                direction="row"
                flexWrap="wrap"
                gap={1}
              >
                {state[vid]?.map((val, index) => (
                  <Chip
                    disabled={view ? true : false}
                    key={index}
                    label={val}
                    onDelete={() => {
                      const temp = state[vid];
                      temp.splice(index, 1);
                      onChange(vid, temp);
                    }}
                    variant="outlined"
                    sx={{
                      backgroundColor: "#111927",
                      color: "white",
                      "& .MuiChip-deleteIcon": {
                        backgroundColor: "transparent !important",
                        color: "white !important",
                        display: view ? "none" : "",
                        "&:hover": {
                          color: "white",
                        },
                      },
                    }}
                  />
                ))}
              </Stack>
            )}
            {/* <Box sx={{ display: "flex", flexDirection: "row-reverse" }}> */}
            {!view && (
              <Button
                sx={{
                  display: state[vid]?.length === 0 && "none",
                  cursor: "pointer",
                  fontSize: "14px",
                  fontWeight: 600,
                  alignSelf: "end",
                  mr: 0.5,
                  // mt: 1,
                  color: "blue",
                  "&:hover": { color: "red" },
                }}
                onClick={() => {
                  onChange(vid, []);
                }}
              >
                Clear all
              </Button>
            )}
            {/* </Box> */}
          </Stack>
        </>
      );
    case ["blockedDomains"].includes(vid):
      const limit = 15;
      return (
        <>
          <Stack spacing={1}>
            {/* <Typography
              sx={{
                fontSize: "14px",
                color: "#050C46",
                fontWeight: "500",
              }}
            >
              {name}
            </Typography> */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <TextField
                disabled={disabled}
                sx={{ backgroundColor: "white", borderRadius: 1 }}
                variant="outlined"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    document.getElementById(`${vid}Button`).click();
                  }
                }}
                id={`${vid}Input`}
                fullWidth
                title={name}
                // label={name}
                label={
                  ismdUp
                    ? name
                    : name.title?.length <= 5
                      ? name
                      : name.slice(0, 11) + " ..."
                }
                InputProps={{
                  startAdornment:
                    view &&
                    state[vid]?.map((val, index) => (
                      <Chip
                        key={index}
                        label={val}
                        disabled={view ? true : false}
                        onDelete={() => {
                          const temp = state[vid];
                          temp.splice(index, 1);
                          onChange(vid, temp);
                        }}
                        variant="outlined"
                        sx={{
                          backgroundColor: "#111927",
                          color: "white",
                          "& .MuiChip-deleteIcon": {
                            backgroundColor: "transparent !important",
                            color: "white !important",
                            display: view ? "none" : "",
                            display: "none",
                            "&:hover": {
                              color: "white",
                            },
                          },
                        }}
                      />
                    )),
                  endAdornment: (
                    <>
                      {!view && (
                        <InputAdornment position="end">
                          {!view && (
                            <Button
                              disabled={state[vid].length === limit}
                              variant="contained"
                              // color={"inherit"}
                              id={`${vid}Button`}
                              sx={{
                                ml: 2,
                                borderRadius: 1,
                                backgroundColor: "#5761FE",
                                padding: "8px 14px 8px 10px",
                                "& .MuiSvgIcon-root": { color: "#FFFFFF" },
                              }}
                              onClick={(e) => {
                                const inpEle = document.getElementById(
                                  `${vid}Input`
                                );
                                const blockedField = inpEle.value
                                  .split(",")
                                  .map((val) => val.trim().toLowerCase());
                                const uniqueBlockedDomains = new Set([
                                  ...blockedField,
                                  ...state[vid],
                                ]);
                                const temp = [];
                                for (let domain of uniqueBlockedDomains) {
                                  if (!domain) continue;
                                  temp.push(domain);
                                }
                                onChange(vid, temp.sort(), blockedField);
                                inpEle.value = "";
                              }}
                            // variant={"contained}
                            >
                              <AddIcon />
                              Add
                            </Button>
                          )}
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
              />
            </Stack>
            {state[vid]?.length === limit && (
              <Typography color="error" style={{ fontSize: "12px" }}>
                Domains limit reached
              </Typography>
            )}
          </Stack>
          <Stack
            direction={islgUp ? "column" : "row"}
            alignItems="flex-start"
            justifyContent="space-between"
          >
            {!view && (
              <Stack
                mt={1}
                alignItems="center"
                direction="row"
                flexWrap="wrap"
                gap={1}
              >
                {state[vid]?.map((val, index) => (
                  <Chip
                    key={index}
                    label={val}
                    disabled={view ? true : false}
                    onDelete={() => {
                      const temp = state[vid];
                      temp.splice(index, 1);
                      onChange(vid, temp);
                    }}
                    variant="outlined"
                    sx={{
                      backgroundColor: "#111927",
                      color: "white",
                      "& .MuiChip-deleteIcon": {
                        backgroundColor: "transparent !important",
                        color: "white !important",
                        display: view ? "none" : "",
                        display: "none",
                        "&:hover": {
                          color: "white",
                        },
                      },
                    }}
                  />
                ))}
              </Stack>
            )}
            {/* <Box sx={{ display: "flex", flexDirection: "row-reverse" }}> */}
            {!view && (
              <Button
                sx={{
                  display: state[vid]?.length === 0 && "none",
                  cursor: "pointer",
                  fontSize: "14px",
                  fontWeight: 600,
                  mr: 0.5,
                  // mt: 1,
                  color: "blue",
                  "&:hover": { color: "red" },
                }}
                onClick={() => {
                  onChange(vid, []);
                }}
              >
                Clear all
              </Button>
            )}
            {/* </Box> */}
          </Stack>
        </>
      );
    default:
      return <Typography variant="subtitle1">{name} (Coming Soon)</Typography>;
  }
};

export const JobDetailsStep = (props) => {
  const {
    tempCsvFiles,
    setTempCsvFiles,
    campaignId,
    leadSource,
    setLeadSource,
    onBack,
    onNext,
    fields,
    onChange,
    editCampaign,
    setSamplelead,
    setTraget,
    target,
    setCampaignForm,
    onClear,
    apolloFormData,
    ...other
  } = props;

  // console.log("onChange :: ", onChange);
  // Modal.setAppElement('#root');
  // const islgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const ismdUp = useMediaQuery((theme) => theme.breakpoints.up("xl"));

  const [name, setName] = useState(fields.additionalFilters?.name || "");
  const [managementLevels, setManagementLevels] = useState(
    fields.additionalFilters?.managementLevels || []
  );

  const [radius, setRadius] = useState(fields.additionalFilters?.radius || [0]);

  const [tag, setTag] = useState([]);
  const [tags, setTags] = useState([]);
  const [sic, setSic] = useState([]);

  const [changedJob, setChangedJob] = useState(
    fields.additionalFilters?.changedJob || ""
  );
  const [departments, setDepartments] = useState(
    fields.additionalFilters?.departments || []
  );

  const [skills, setSkills] = useState(fields.additionalFilters?.skills || []);

  const [schools, setSchools] = useState(
    fields.additionalFilters?.schools || []
  );
  const [majors, setMajors] = useState(fields.additionalFilters?.majors || []);
  const [degree, setDegree] = useState(fields.additionalFilters?.degree || []);

  const [naicsCode, setNaicsCode] = useState([]);
  const [naicsCodes, setNaicsCodes] = useState([]);
  const initialExp = fields?.additionalFilters?.experience;
  const [experience, setExperience] = useState(initialExp || "");

  const isCustomExp =
    !YearsOfExperience.includes(experience) && experience !== "";
  const [customExperience, setCustomExperience] = useState(isCustomExp);

  // const initialMinExp = experience.includes("-")
  //   ? experience.split("-")[0]
  //   : experience.split("")[0];
  // const initialMaxExp = experience.includes("-")
  //   ? experience.split("-")[1]
  //   : experience.split("")[1];
  // const [minExp, setMinExp] = useState(initialMinExp || "");
  // const [maxExp, setMaxExp] = useState(initialMaxExp || "");

  const [companies, setCompanies] = useState(
    fields.additionalFilters?.companies || []
  );
  const [includePast, setIncludePast] = useState(
    fields.additionalFilters?.includePast || false
  );

  // const [companyType, setCompanyType] = useState(
  //   fields.additionalFilters?.companyType || ""
  // );

  const [excludeContacts, setExcludeContacts] = useState(
    fields.additionalFilters?.excludeContacts || false
  );

  // const [contactInfo, setContactInfo] = useState(
  //   fields.additionalFilters?.contactInfo || ""
  // );
  const [socialLink, setSocialLink] = useState(
    fields.additionalFilters?.socialLink || ""
  );

  const [startDate, setStartDate] = useState(new Date("2022-09-22T11:41:50"));
  const [endDate, setEndDate] = useState(new Date("2023-01-11T12:41:50"));

  // filter counts
  const occupationCount =
    (tags?.length > 0 ||
      departments?.length > 0 ||
      managementLevels?.length > 0 ||
      changedJob !== ""
      ? 1
      : 0) +
    (skills.length > 0 ? 1 : 0) +
    (experience !== "" ? 1 : 0);

  const employerFilterCount =
    (fields?.addFilters?.compSize?.length > 0 ? 1 : 0) +
    (companies.length > 0 ? 1 : 0) +
    (fields?.addFilters?.revenue?.length !== 0 ? 1 : 0) +
    (fields?.addFilters?.funding?.length !== 0 ? 1 : 0) +
    (fields?.industry?.length > 0 || sic.length > 0 || naicsCodes.length > 0
      ? 1
      : 0);
  // (companyType !== "" ? 1 : 0);
  const blockedDomainsCount = fields?.addFilters?.blockedDomains?.length;
  const blockedLocationsCount = fields?.addFilters?.blockedLocations?.length;
  const blockedIndustriesCount = fields?.addFilters?.blockedIndustries?.length;
  const blockedKeywordsCount = fields?.addFilters?.blockedKeywords?.length;
  const keywordsCount = fields?.addFilters?.keywords?.length;
  const otherFiltersCount =
    (keywordsCount > 0 ? 1 : 0) +
    (excludeContacts ||
      blockedDomainsCount > 0 ||
      blockedIndustriesCount > 0 ||
      blockedLocationsCount > 0 ||
      blockedKeywordsCount > 0
      ? 1
      : 0);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const toggleAnchorEl = (event) => {
    setAnchorEl((prev) => (prev ? null : event.currentTarget));
  };
  const [selectedFilters, setSelectedFilters] = useState(
    FILTERS.map(({ id }) => id)
  );
  const [selectedapolloFilters, setSelectedapolloFilters] = useState(
    APOLLOFILTERS.map(({ id }) => id)
  );
  const selectFilters = (e, id) => {
    e.stopPropagation();
    const exists = selectedFilters.findIndex((ele) => ele === id);
    if (exists === -1) {
      setSelectedFilters((prev) => [...prev, id]);
    } else {
      setSelectedFilters((prev) => prev.filter((ele) => ele !== id));
    }
  };

  // const [linkObj, setLinkObj] = useState({
  //   url: "",
  //   enabled: false,
  // });
  // const onLinkObjChange = (field, value) => {
  //   setLinkObj((prev) => ({ ...prev, [field]: value }));
  // };

  const [extraFilters, setExtraFilters] = useState(initAddFilters);
  const [additionalFilters, setAdditionalFilters] = useState(
    initAdditionalFilters
  );

  const [apolloExtraFilters, setApolloExtraFilters] =
    useState(initApolloAddFilters);
  const [apolloAdditionalFilters, setApolloAdditionalFilters] = useState(
    initApolloAdditionalFilters
  );
  const [sicCode, setSicCode] = useState();
  const [apolloTag, setApolloTag] = useState([]);
  const [apolloTechnology, setApolloTechnology] = useState([]);
  const [apolloAllTechnology, setApolloAllTechnology] = useState([]);
  const [apolloIndustry, setApolloIndustry] = useState([]);
  const [apolloFilters, setApolloFilters] = useState({});

  useEffect(() => {
    setApolloFilters({
      addFilters: apolloExtraFilters,
      additionalFilters: apolloAdditionalFilters,
      industry: apolloIndustry,
      targets: apolloTag,
      sicCode: sicCode,
      technology: apolloTechnology,
      allTechnology: apolloAllTechnology,
    });
  }, [
    apolloExtraFilters,
    apolloAdditionalFilters,
    apolloTag,
    sicCode,
    apolloTechnology,
    apolloAllTechnology,
  ]);

  const onFiltersChange = (field, value) => {
    // if (field === "industry") {
    //   setIndustries(value);
    // }
    setExtraFilters((prev) => ({ ...prev, [field]: value }));
  };

  const onApolloFiltersChange = (field, value) => {
    if (field === "selectedIndustries") {
      const industryValues = [];
      value.forEach((item) => {
        industryValues.push(item?.id);
      });
      setApolloExtraFilters((prev) => ({ ...prev, industry: industryValues }));
    } else if (field === "blockedIndustries") {
      const industryValues = [];
      value.forEach((item) => {
        industryValues.push(item?.id);
      });

      setApolloExtraFilters((prev) => ({
        ...prev,
        blockedIndustries: industryValues,
      }));
    } else if (field === "funding") {
      const fundingValues = value.map((item) => item?.value);
      setApolloExtraFilters((prev) => ({ ...prev, funding: fundingValues }));
    } else if (field === "location") {
      const locationValues = [];
      value.forEach((item) => {
        locationValues.push(item.title);
      });
      setApolloExtraFilters((prev) => ({ ...prev, location: locationValues }));
    } else if (field === "blockedLocations") {
      const locationValues = [];
      value.forEach((item) => {
        locationValues.push(item.title);
      });
      setApolloExtraFilters((prev) => ({
        ...prev,
        blockedLocations: locationValues,
      }));
    } else if (field === "blockedDomains") {
      const domainValues = [];
      value.forEach((item) => {
        domainValues.push(item.id);
      });
      setApolloExtraFilters((prev) => ({
        ...prev,
        blockedDomains: domainValues,
        selectedDomains: value,
      }));
    } else {
      setApolloExtraFilters((prev) => ({ ...prev, [field]: value }));
    }
  };

  const onAdditionalFiltersChange = (field, value) => {
    setAdditionalFilters((prev) => ({ ...prev, [field]: value }));
  };

  const handleStartDateChange = useCallback((date) => {
    setStartDate(date);
  }, []);

  const handleEndDateChange = useCallback((date) => {
    setEndDate(date);
  }, []);

  const handleTagAdd = useCallback((tag) => {
    setTags((prevState) => {
      const newTags = [];
      const uniqueTags = new Set([...prevState, tag]);
      for (let value of uniqueTags.values()) {
        newTags.push(value);
      }
      return newTags;
    });
  }, []);

  const handleSicAdd = useCallback((tag) => {
    setSic((prevState) => {
      const newTags = [];
      const uniqueTags = new Set([...prevState, tag]);
      for (let value of uniqueTags.values()) {
        newTags.push(Number(value));
      }
      return newTags;
    });
  }, []);

  const handleNaicsAdd = useCallback((tag) => {
    setNaicsCodes((prevState) => {
      const newTags = [];
      const uniqueTags = new Set([...prevState, tag]);
      for (let value of uniqueTags.values()) {
        newTags.push(Number(value));
      }
      return newTags;
    });
  }, []);

  const handleTagDelete = useCallback((tag) => {
    let isEmpty = false;
    setTags((prevState) => {
      const newTags = prevState.filter((t) => t !== tag);
      if (newTags.length === 0) {
        isEmpty = true;
      }
      return newTags;
    });
    if (isEmpty) {
      onChange("targets", []);
    }
  }, []);

  const handleSicDelete = useCallback((tag) => {
    setSic((prevState) => {
      return prevState.filter((t) => t !== tag);
    });
  }, []);

  const handleNaicsDelete = useCallback((tag) => {
    setNaicsCodes((prevState) => {
      return prevState.filter((t) => {
        return t !== tag;
      });
    });
  }, []);

  const [industries, setIndustries] = useState(fields.industry);
  const [blockedIndustries, setBlockedIndustries] = useState(
    fields.addFilters.blockedIndustries
  );

  const handleIndustryDelete = useCallback((delInd) => {
    setIndustries((prev) => {
      return prev.filter((ind) => ind !== delInd);
    });
  }, []);

  // const [blockedIndustries, setBlockedIndustries] = useState(
  //   fields.addFilters?.blockedIndustries
  // );

  useEffect(() => {
    if (fields.targets) {
      setTags(fields.targets);
    }
    if (fields.addFilters) {
      // setExtraFilters(fields.addFilters);
      setExtraFilters({
        ...fields.addFilters,
        funding: fields.addFilters.funding ?? [],
      });
    }
    if (fields.additionalFilters) {
      setAdditionalFilters(fields.additionalFilters);
    }
    if (fields.sicCode) {
      setSic(fields.sicCode);
    }
    if (fields?.naicsCode) {
      setNaicsCodes(fields.naicsCode);
    }
  }, []);

  useEffect(() => {
    if (tags.length === 0) return;
    onChange("targets", tags);
  }, [tags]);

  useEffect(() => {
    onChange("industry", industries);
  }, [industries]);

  // useEffect(() => {
  //   onChange("naicsCode", naicsCode);
  // }, [naicsCode]);

  useEffect(() => {
    // if (sic.length === 0) return;
    onChange("sicCode", sic);
  }, [sic]);

  useEffect(() => {
    // if (naicsCodes.length === 0) {
    //   onChange("naicsCode", naicsCodes);
    //   return;
    // }
    onChange("naicsCode", naicsCodes);
  }, [naicsCodes]);

  // useEffect(() => {
  //   onChange("sicCode", sicCode);
  // }, [sicCode]);

  useEffect(() => {
    onFiltersChange("blockedIndustries", blockedIndustries);
  }, [blockedIndustries]);

  useEffect(() => {
    onChange("addFilters", extraFilters);
  }, [extraFilters]);

  useEffect(() => {
    onChange("additionalFilters", additionalFilters);
  }, [additionalFilters]);

  useEffect(() => {
    onChange("apollo", apolloFilters);
  }, [apolloFilters]);
  useEffect(() => {
    onChange("dataProvider", "APOLLO");
  }, [apolloFilters]);

  // update additional filters
  useEffect(() => {
    onAdditionalFiltersChange("name", name);
    onAdditionalFiltersChange("managementLevels", managementLevels);
    onAdditionalFiltersChange("changedJob", changedJob);
    onAdditionalFiltersChange("departments", departments);
    onAdditionalFiltersChange("skills", skills);
    onAdditionalFiltersChange("schools", schools);
    onAdditionalFiltersChange("majors", majors);
    onAdditionalFiltersChange("degree", degree);
    onAdditionalFiltersChange("experience", experience);
    onAdditionalFiltersChange("companies", companies);
    onAdditionalFiltersChange("includePast", includePast);
    // onAdditionalFiltersChange("companyType", companyType);
    onAdditionalFiltersChange("excludeContacts", excludeContacts);
    // onAdditionalFiltersChange("contactInfo", contactInfo);
    onAdditionalFiltersChange("socialLink", socialLink);
    onAdditionalFiltersChange("radius", radius);
  }, [
    radius,
    name,
    managementLevels,
    changedJob,
    departments,
    skills,
    schools,
    majors,
    degree,
    experience,
    companies,
    includePast,
    // companyType,
    excludeContacts,
    // contactInfo,
    socialLink,
  ]);

  // useEffect(() => {
  //   onChange("link", linkObj);
  // }, [linkObj]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      document.getElementById("addButton").click();
    }
  };

  const handleKeyPressSic = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      document.getElementById("addSicbutton").click();
    }
  };

  const handleKeyPressNaics = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      document.getElementById("addNaicsbutton").click();
    }
  };
  const [industryError, setIndustryError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [noEmployError, setNoEmployError] = useState("");
  const limit = 15;

  const handleValidation = () => {
    const validations = [
      // {
      //   field: fields.industry,
      //   setError: setIndustryError,
      //   message: "Industry is required",
      // },
      // {
      //   field: fields.targets,
      //   setError: setTitleError,
      //   message: "Titles/Roles is required",
      // },
      // {
      //   field: fields.addFilters.compSize,
      //   setError: setNoEmployError,
      //   message: "Number of employees is required",
      // },
      // {
      //   field: fields.addFilters.location,
      //   setError: setLocationError,
      //   message: "Location is required",
      // },
    ];
    let isValid = true;
    validations.forEach(({ field, setError, message }) => {
      if (field.length === 0) {
        setError(message);
        isValid = false;
      } else {
        setError("");
      }
    });
    return isValid;
  };

  // const [customField, setCustomField] = useState({ min: "", max: "" });

  // useEffect(() => {
  //   if (extraFilters?.customCompSize) {
  //     setExtraFilters({
  //       min: extraFilters?.customCompSize?.min,
  //       max: extraFilters?.customCompSize?.max,
  //     });
  //   }
  // }, [extraFilters?.customCompSize]);

  const handleNext = () => {
    if (handleValidation()) {
      onNext();
      setSamplelead(true);
    }
  };

  const handleBackPage = () => {
    onBack();
    setSamplelead(false);
  };

  const handleClearAllFilters = () => {
    setExtraFilters(initAddFilters);
    onClear({
      addFilters: {
        location: [],
        revenue: [],
        funding: [],
        compSize: [],
        customCompSize: { min: 0, max: 0 },
        keywords: [],
        blockedDomains: [],
        blockedLocations: [],
        blockedIndustries: [],
        blockedKeywords: [],
      },
      additionalFilters: {
        name: "",
        managementLevels: [],
        changedJob: "",
        departments: [],
        skills: [],
        schools: [],
        majors: [],
        degree: [],
        experience: "",
        companies: [],
        includePast: false,
        // companyType: [],
        excludeContacts: false,
        // contactInfo: "",
        socialLink: "",
        radius: [],
      },
      industry: [],
      targets: [],
      naicsCode: [],
      sicCode: [],
    });
    setName("");
    setDegree([]);
    setMajors([]);
    // setCompanyType("");
    setManagementLevels([]);
    setIndustries([]);
    setSicCode([]);
    setApolloTechnology([]);
    setApolloAllTechnology([]);
    setNaicsCode([]);
    setSic([]);
    setNaicsCodes([]);
    setTags([]);
    setChangedJob("");
    setDepartments([]);
    setSkills([]);
    setSchools([]);
    setExperience("");
    setCompanies([]);
    setIncludePast(false);
    setExcludeContacts(false);
    setSocialLink("");
    setBlockedIndustries([]);
    // setContactInfo("");
    setRadius([]);
    setCustomExperience(false);
    // setMinExp("");
    // setMaxExp("");
    setExtraFilters((pre) => ({
      ...pre,
      customCompSize: { min: 0, max: 0 },
    }));
  };

  // import leads
  // const predefinedOptions = [
  // { label: 'Do not import', value: null },
  // { label: 'First Name', value: 'firstName' },
  // { label: 'Last Name', value: 'lastName' },
  // { label: 'Email', value: 'email' },
  // { label: 'Profile Picture', value: 'profile_pic' },
  // { label: 'Description', value: 'description' },
  // { label: 'LinkedIn URL', value: 'linkedin_url' },
  // { label: 'Organization', value: 'organization' },
  // { label: 'Website Domains', value: 'website_domains' },
  // { label: 'Industry', value: 'industry' },
  // { label: 'Address', value: 'address' },
  // { label: 'City', value: 'city' },
  // { label: 'State', value: 'state' },
  // { label: 'Country', value: 'country'},
  // { label: 'Organization Founded Year', value: 'org_founded_year' },
  // { label: 'Number of Employees', value: 'org_num_employees' },
  // { label: 'Organization Description', value: 'org_description' }
  // ];

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [columns, setColumns] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const [isImportLeadsDialogOpen, setIsImportLeadsDialogOpen] = useState(false);
  const [isLeadStatusDialogOpen, setIsLeadStatusDialogOpen] = useState(false);
  const [isLeadMappingDialogOpen, setIsLeadMappingDialogOpen] = useState(false);
  const [selectedLeadStatusObj, setSelectedLeadStatusObj] = useState({});
  const [selectedLeadMappingObj, setSelectedLeadMappingObj] = useState({});

  const handleClickOpenImportLeadsDialog = () => {
    setIsImportLeadsDialogOpen(true);
  };

  const handleCloseOpenImportLeadsDialog = () => {
    setIsImportLeadsDialogOpen(false);
    setSelectedFile(null);
    setColumns([]);
    setFileInputKey(Date.now());
    setUploadProgress(0);
  };
  const handleOpenLeadStatusDialog = (leadStatusObj) => {
    setSelectedLeadStatusObj(leadStatusObj);
    setIsLeadStatusDialogOpen(true);
  };
  const handleOpenLeadMappingDialog = (leadMappingObj) => {
    setSelectedLeadMappingObj(leadMappingObj)
    setIsLeadMappingDialogOpen(true)
  };

  const handleCloseLeadStatusDialog = () => {
    setIsLeadStatusDialogOpen(false);
  };

  const handleCloseLeadStatusMapping = () => {
    setIsLeadMappingDialogOpen(false);
  };

  const handleDeleteFile = (fileUrl) => {
    const filteredTempCsvFiles =
      tempCsvFiles?.filter((file) => file !== fileUrl) || [];
    const filteredUploadedLeads =
      fields?.uploadedLeads?.filter((obj) => obj?.url !== fileUrl) || [];
    setTempCsvFiles(filteredTempCsvFiles);
    onChange("uploadedLeads", filteredUploadedLeads);
  };
  /*
   const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "text/csv") {
      setSelectedFile(file);
      Papa.parse(file, {
        complete: (results) => {
          const headers = results.data[0]; 
          const columnMappings = headers.reduce((accumulator, header) => {
            const mappedField = predefinedOptions.find(option => option.label === header)?.value || 'doNotImport';
            accumulator[header] = mappedField;
            return accumulator;
          }, {});
          setColumns(columnMappings); 
        },
        preview: 1
      });
      setModalIsOpen(true);
    } else {
      alert('Please select a CSV file.');
    }
  };
  
  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedFile(null);
    setColumns([]);
    setFileInputKey(Date.now());
    setUploadProgress(0);
  };

  useEffect(() => {
    if (selectedFile) {
      Papa.parse(selectedFile, {
        complete: function(results) {
          const headers = results.data[0];
          const columnMappings = headers.reduce((accumulator, header) => {
            const mappedField = predefinedOptions.find(option => option.label === header)?.value || 'doNotImport';
            accumulator[header] = mappedField;
            return accumulator;
          }, {});
          setColumns(columnMappings);
        },
        preview: 1
      });
    }
  }, [selectedFile]);


  const handleSelectChange = (selectedValue, header) => {
    setColumns({
      ...columns,
      [header]: selectedValue
    });
  };
  

  const handleMappingsSubmit = () => {
    console.log('Column mappings:', columns);
    handleFileUpload()
    setModalIsOpen(false);
  };

AWS.config.update({
  region: 'us-east-1', 
  accessKeyId: process.env.REACT_APP_WASABI_ID,
  secretAccessKey: process.env.REACT_APP_WASABI_ACCESS
});

const s3 = new AWS.S3({
  endpoint: 's3.wasabisys.com', 
  s3ForcePathStyle: true,
  signatureVersion: 'v4'
});

const uploadToS3 = async (file) => {
  const uploadParams = {
      Bucket: 'b2b-rocket',
      Key: file.name,
      Body: file
  };

  return new Promise((resolve, reject) => {
      s3.upload(uploadParams, (err, data) => {
          if (err) {
              console.error('Upload failed:', err);
              reject(err);
          } else {
              console.log('Upload Success:', data);
              resolve(data.Location);
          }
      });
  });
};

const getS3SignedUrl = async (key) => {
  const now = new Date();
  const params = {
      Bucket: 'b2b-rocket',
      Key: key,
      Expires: 60 * 60 * 24 * 7,
      // Expires: 60 * 5
  };

  return new Promise((resolve, reject) => {
      s3.getSignedUrl('getObject', params, (err, url) => {
          if (err) {
              reject(err);
          } else {
              resolve(url);
          }
      });
  });
};

  const handleFileUpload = async () => {
    if (!selectedFile) {
      console.log('No file selected.');
      return;
    }
    try {
      const uploadUrl = await uploadToS3(selectedFile);
      console.log('File uploaded:', uploadUrl);
      const signedUrl = await getS3SignedUrl(selectedFile.name);
      console.log(columns)
      console.log('Signed URL:', signedUrl);
    //   setCampaignForm(prevForm => ({
    //     ...prevForm,
    //     uploadedLeads: {
    //         ...prevForm.uploadedLeads,
    //         url: signedUrl,
    //         processed: false,
    //         mappings: columns, 
    //         result: {
    //             totalLeads: "", 
    //             savedLeads: "", 
    //             failedLeads: "", 
    //             duplicates: "", 
    //         }
    //     }
    // }));
    const uploadedLeads = {
      ...fields.uploadedLeads,
      url: signedUrl,
      processed: false,
      mappings: columns, 
      result: {
          totalLeads: "", 
          savedLeads: "", 
          failedLeads: "", 
          duplicates: "", 
      }
    }
    onChange("uploadedLeads", uploadedLeads);
  } catch (error) {
      console.error('Error during file upload:', error);
    }
  };
*/

  const importedFiles = fields?.uploadedLeads?.map((file) => {
    return (
      decodeURIComponent(file?.url?.split("/")?.pop()?.split("?")?.[0]) || ""
    );
  });
  const isImportedFileExist = fields?.uploadedLeads?.some((file) => file?.url);

  const handleLeadSourceChange = (e, option) => {
    if (e.target.checked) {
      setLeadSource(option.value);
    }
  };
  useEffect(() => {
    onChange("leadSource", leadSource);
  }, [leadSource]);

  const keyDisplayNameMapping = {
    firstName: 'First Name',
    lastName: 'Last Name',
    title : 'Title',
    organization:'Organization',
    email:'Email',
    industry:'Industry',
  };

  return (
    <Stack rowGap={2}>
      <Stack spacing={2} {...other} className="contentBorder">
        <Typography
          variant="h6"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <img src={FlashIcon} style={{ marginRight: "5px" }} />
          Select Source
        </Typography>

        <Typography
          className="subtitle"
          sx={{ fontSize: "14px", fontWeight: "500" }}
        >
          Select the action you want to target your audience.
        </Typography>
        <Divider sx={{ borderColor: alpha("#111927", 0.1) }} />
        <Stack rowGap={1} sx={{ mt: 2 }}>
          {leadSourceTypes.map((option, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 0.5,
                px: 1,
                py: 0.5,
                border: `1px solid ${alpha("#111927", 0.1)}`,
                borderRadius: 1,
                backgroundColor: "#ffffff",
              }}
            >
              <Radio
                checked={leadSource === option.value}
                onChange={(e) => handleLeadSourceChange(e, option)}
                name="radio-buttons"
                inputProps={{ "aria-label": "A" }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexGrow: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "700",
                  }}
                >
                  {option.label}
                </Typography>
              </Box>
            </Box>
          ))}
        </Stack>
      </Stack>
      <Stack spacing={2} {...other} className="contentBorder">
        {/* <OldFilters
          handleClearAllFilter={handleClearAllFilters}
          fields={fields}
          locationError={locationError}
          selectedFilters={selectedFilters}
          extraFilters={extraFilters}
          setExtraFilters={setExtraFilters}
          onFiltersChange={onFiltersChange}
          editCampaign={editCampaign}
          occupationCount={occupationCount}
          departments={departments}
          managementLevels={managementLevels}
          handleKeyPress={handleKeyPress}
          changedJob={changedJob}
          industryError={industryError}
          setIndustries={setIndustries}
          sic={sic}
          setSic={setSic}
          tags={tags}
          onChange={onChange}
          target={target}
          setTraget={setTraget}
          setSamplelead={setSamplelead}
          onClear={onClear}
          setBlockedIndustries={setBlockedIndustries}
          excludeContacts={excludeContacts}
          blockedIndustries={blockedIndustries}
          setExcludeContacts={setExcludeContacts}
          otherFiltersCount={otherFiltersCount}
          limit={limit}
          tag={tag}
          setTag={setTag}
          handleTagAdd={handleTagAdd}
          titleError={titleError}
          handleTagDelete={handleTagDelete}
          setTags={setTags}
          naicsCodes={naicsCodes}
          setDepartments={setDepartments}
          setNaicsCodes={setNaicsCodes}
          skills={skills}
          setSkills={setSkills}
          customExperience={customExperience}
          experience={experience}
          setManagementLevel={setManagementLevels}
          onAdditionalFiltersChange={onAdditionalFiltersChange}
          setManagementLevels={setManagementLevels}
          setChangedJob={setChangedJob}
          setCustomExperience={setCustomExperience}
          setExperience={setExperience}
          employerFilterCount={employerFilterCount}
          companies={companies}
          setCompanies={setCompanies}
          includePast={includePast}
          setIncludePast={setIncludePast}
          noEmployError={noEmployError}
          industries={industries}
          majors={majors}
          schools={schools}
          degree={degree}
          educationMajor={educationMajor}
          setSchools={setSchools}
          setMajors={setMajors}
          degreeList={degreeList}
          setDegree={setDegree}
          // islgUp={islgUp}
          // ismdUp={ismdUp}
        /> */}
        <Box
          className="contentBorder"
          sx={{ display: leadSource === "csv" ? "block" : "none" }}
        >
          <Stack rowGap={1}>
            <Button
              fullWidth
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "&:hover": {
                  backgroundColor: "#164694",
                  boxShadow: 10,
                },
                textAlign: "left",
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "18px",
                letterSpacing: "0em",
                color: "white",
                backgroundColor: "#5761FE",
                borderRadius: "8px",
                px: 1.5,
              }}
              variant="outlined"
              size="large"
              disabled={isAccess==="read"}
              onClick={handleClickOpenImportLeadsDialog}
            >
              Import Leads
            </Button>
            {isImportedFileExist && (
              <Box>
                <Typography sx={{ fontSize: "14px", fontWeight: 600, mb: 0.5 }}>
                  Imported files:
                </Typography>
                <Stack rowGap={1}>
                  {importedFiles?.map((file, index) => {
                    const fileObj = fields?.uploadedLeads[index] || {};
                    return (
                      <Box
                      className="uploadList"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Typography key={index} sx={{ fontSize: "14px" }}>
                          {index + 1}.{" "}
                          {file.split("_")?.slice(2)?.join("_") || file}
                        </Typography>
                        <Box
                        className="filterBox"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          {tempCsvFiles?.includes(fileObj?.url) && (
                            <IconButton
                              sx={{ p: 0 }}
                              onClick={() => handleDeleteFile(fileObj?.url)}
                            >
                              <DeleteOutlined
                                sx={{ fontSize: "18px", color: "red" }}
                              />
                            </IconButton>
                          )}
                          <a
                            href={fileObj?.url}
                            download
                            style={{
                              textDecoration: "none",
                              fontSize: "14px",
                              fontWeight: 500,
                            }}
                          >
                            <IconButton
                              sx={{ p: 0 }}
                            >
                              <DownloadIcon
                                sx={{ fontSize: "18px", color: "#8800ff" }}
                              />
                            </IconButton>
                          </a>
                          <IconButton onClick={() => { handleOpenLeadMappingDialog({ ...fileObj }) }}>
                            <img src={Mapping} style={{ height: '20px', width: '20px' }} />
                          </IconButton>
                          <IconButton
                            sx={{ p: 0 }}
                            onClick={() =>
                              handleOpenLeadStatusDialog({
                                ...fileObj,
                                fileName:
                                  file.split("_")?.slice(2)?.join("_") || file,
                              })
                            }
                          >
                            <InfoOutlined sx={{ fontSize: "18px" }} />
                          </IconButton>
                        </Box>
                      </Box>
                    );
                  })}
                </Stack>
              </Box>
            )}
          </Stack>
        </Box>
        <ApolloUpdates
          // handleClearAllFilter={handleClearAllFilters}
          leadSource={leadSource}
          fields={fields}
          apolloFormData={apolloFormData}
          locationError={locationError}
          selectedFilters={selectedFilters}
          extraFilters={apolloExtraFilters}
          setExtraFilters={setApolloExtraFilters}
          onFiltersChange={onApolloFiltersChange}
          editCampaign={editCampaign}
          occupationCount={occupationCount}
          setApolloAdditionalFilters={setApolloAdditionalFilters}
          setApolloIndustry={setApolloIndustry}
          setApolloTag={setApolloTag}
          SetSicCode={setSicCode}
          setApolloTechnology={setApolloTechnology}
          setApolloAllTechnology={setApolloAllTechnology}
          managementLevels={managementLevels}
          handleKeyPress={handleKeyPress}
          industryError={industryError}
          setIndustries={setIndustries}
          onChange={onChange}
          target={target}
          setTraget={setTraget}
          setSamplelead={setSamplelead}
          onClear={onClear}
          setBlockedIndustries={setBlockedIndustries}
          blockedIndustries={blockedIndustries}
          otherFiltersCount={otherFiltersCount}
          limit={limit}
          handleTagAdd={handleTagAdd}
          titleError={titleError}
          handleTagDelete={handleTagDelete}
          setManagementLevel={setManagementLevels}
          onAdditionalFiltersChange={onAdditionalFiltersChange}
          setManagementLevels={setManagementLevels}
          employerFilterCount={employerFilterCount}
          noEmployError={noEmployError}
          industries={industries}
          educationMajor={educationMajor}
          degreeList={degreeList}
          ismdUp={ismdUp}
        />
        {/* <Stack alignItems="center" direction="row" spacing={2}>
          <Button
            variant="outlined"
            onClick={onBack}
            fullWidth
            className="BackButton"
          >
            Back
          </Button>
          <Button
            endIcon={
              <SvgIcon>
                <ArrowRightIcon />
              </SvgIcon>
            }
            onClick={handleNext}
            variant="contained"
            sx={{ backgroundColor: "#5761FE" }}
            fullWidth
          >
            {editCampaign ? "Continue" : "Continue"}
          </Button>
        </Stack> */}
        <Dialog
          open={isImportLeadsDialogOpen}
          onClose={handleCloseOpenImportLeadsDialog}
          maxWidth="md"
          sx={{
            backgroundColor: "rgba(4, 4, 30, 0.5)",
            "& .MuiDialog-paper": {
              minWidth: "300px",
            },
          }}
        >
          <DialogTitle>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: 700,
                      lineHeight: "28px",
                      color: "#28287B",
                    }}
                  >
                    Import Leads
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mr: 0.5,
                    }}
                  >
                    <ChevronRightOutlined />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "28px",
                      color: "#8181B0",
                    }}
                  >
                    Import CSV File
                  </Typography>
                </Box>
              </Box>

              <IconButton
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={handleCloseOpenImportLeadsDialog}
              >
                <CloseOutlined />
              </IconButton>
            </Box>
            <Typography
              sx={{
                fontSize: "14px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <WarningAmberOutlined sx={{ color: "red", fontSize: "18px" }} />
              Please ensure that the uploaded file is in the correct format.
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography sx={{ fontSize: "14px" }}>
                Refer to the sample format provided here:
              </Typography>
              <a
                href={SampleLeads}
                download
                style={{
                  textDecoration: "none",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                Sample Leads
              </a>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box>
              <CsvImport
                tempCsvFiles={tempCsvFiles}
                setTempCsvFiles={setTempCsvFiles}
                setIsImportLeadsDialogOpen={setIsImportLeadsDialogOpen}
                onChange={onChange}
                fields={fields}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                fileInputKey={fileInputKey}
                leadSource={leadSource}
                setLeadSource={setLeadSource}
                campaignId={campaignId}
              />
            </Box>
          </DialogContent>
        </Dialog>
        <Dialog
          open={isLeadStatusDialogOpen}
          onClose={handleCloseLeadStatusDialog}
          maxWidth="md"
          sx={{
            backgroundColor: "rgba(4, 4, 30, 0.5)",
            "& .MuiDialog-paper": {
              minWidth: "300px",
            },
          }}
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography variant="h6">Uploaded Leads Status</Typography>
            <IconButton
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={handleCloseLeadStatusDialog}
            >
              <CloseOutlined />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Stack rowGap={0.5}>
              {[
                { label: "File Name", value: selectedLeadStatusObj?.fileName },
                {
                  label: "Processed",
                  value: selectedLeadStatusObj?.processed ? "Yes" : "No",
                },
                {
                  label: "Duplicates",
                  value:
                    selectedLeadStatusObj?.result?.duplicates === ""
                      ? "N/A"
                      : selectedLeadStatusObj?.result?.duplicates,
                },
                {
                  label: "Failed Leads",
                  value:
                    selectedLeadStatusObj?.result?.failedLeads === ""
                      ? "N/A"
                      : selectedLeadStatusObj?.result?.failedLeads,
                },
                {
                  label: "Saved Leads",
                  value:
                    selectedLeadStatusObj?.result?.savedLeads === ""
                      ? "N/A"
                      : selectedLeadStatusObj?.result?.savedLeads,
                },
                {
                  label: "Total Leads",
                  value:
                    selectedLeadStatusObj?.result?.totalLeads === ""
                      ? "N/A"
                      : selectedLeadStatusObj?.result?.totalLeads,
                },
                {
                  label: "Reason Of Failed",
                  value: selectedLeadStatusObj?.result?.failedReason.length
                    ? selectedLeadStatusObj?.result?.failedReason.join(",")
                    : "N/A",
                },
              ].map((stat, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Typography sx={{ width: "120px", fontSize: "14px" }}>
                    {stat.label}
                  </Typography>
                  <span>:</span>
                  <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                    {stat.value}
                  </Typography>
                </Box>
              ))}
            </Stack>
          </DialogContent>
        </Dialog>
        <Dialog
          open={isLeadMappingDialogOpen}
          onClose={handleCloseLeadStatusMapping}
          maxWidth="md"
          sx={{
            backgroundColor: "rgba(4, 4, 30, 0.5)",
            "& .MuiDialog-paper": {
              minWidth: "300px",
            },
          }}
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography variant="h6">Uploaded Leads Mapping Status</Typography>
            <IconButton
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={handleCloseLeadStatusMapping}
            >
              <CloseOutlined />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{width:'540px'}}>
            {selectedLeadMappingObj?.mappings !== undefined && selectedLeadMappingObj?.mappings !== null &&
              <Stack rowGap={0.5}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                    <TableCell>CSV Headers</TableCell>
                    <TableCell>Map To</TableCell>
                    </TableHead>
                    <TableBody>
                      {Object.entries(selectedLeadMappingObj?.mappings).map(([key, value]) => (
                        <TableRow key={key}>
                          <TableCell>{ key}:</TableCell>
                          <TableCell>{value === "doNotImport" ? "-" : keyDisplayNameMapping[value] || value}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            }
          </DialogContent>
        </Dialog>
      </Stack>
    </Stack>
  );
};

JobDetailsStep.propTypes = {
  onBack: PropTypes.func,
  onNext: PropTypes.func,
};
