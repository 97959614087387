import React, { useState, useEffect } from "react";
import PropTypes, { number } from "prop-types";
import Users from "src/components/logos/Users.png";
import CopyCampaign from "src/components/logos/copycampaign.png";
import Calendar from "src/components/logos/calendar.png";
import HealthIcon from "src/components/logos/healthIcon.png";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import LandingIcon from "src/components/logos/landingIcon.png";
import { useNavigate, Link } from "react-router-dom";
import ConfirmationPopup from "./confirmationPopup";
import axios from "axios";
import { jobsApi } from "src/api/jobs";

import {
  Box,
  Button,
  Chip,
  Divider,
  Stack,
  TableRow,
  Tooltip,
  Typography,
  alpha,
  FormControlLabel,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Checkbox
} from "@mui/material";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import { paths } from "src/paths";
import { toast } from "react-hot-toast";
import { CTableCell, copyToClipboard } from "src/utils/misc";

import {
  newIndustryTypes,
  FUNDING,
  TECHNOLOGYOPTIONS,
  DEPARTMENTSOPTIONS,
  MANAGEMENT_OPTIONS,
} from "../../../data/campaign";
import {
  PlayArrowOutlined,
  PauseOutlined,
  BadgeOutlined,
  AttachMoney,
  SavingsOutlined,
  ManageAccountsOutlined,
  KeyOutlined,
  BiotechOutlined,
  PublicOutlined,
  PersonOutlined,
  WorkOutlineOutlined,
  GroupOutlined,
  CloseOutlined,
  WarningAmberOutlined,
  MoreVertOutlined,
  DeleteOutline,
  LanguageOutlined,
  ArchiveOutlined,
  DraftsOutlined,
  SignalCellularAltOutlined,
  TuneOutlined,
  VideocamOutlined,
  CalendarTodayOutlined,
  Groups
} from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import _ from "lodash";
import { API_SERVICE_BACKEND } from "src/config";

const isAccess=sessionStorage.getItem("access")


const MoreMenu = ({
  anchorEl,
  open,
  handleClose,
  options
}) => {
  const menuStyle = (label) => {
    return {
      py: 1.5,
      mx: 1,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      color: "#101828",
      fontSize: "14px",
      fontWeight: 500,
      "&.MuiMenuItem-root .MuiListItemIcon-root .MuiSvgIcon-root": {
        color: "#101828",
      },
      "&:hover": {
        color: label === "Delete" ? "red" : "black",
        backgroundColor:
          label === "Delete" ? alpha("#f44336", 0.1) : alpha("#2196f3", 0.1),
        "&.MuiMenuItem-root .MuiListItemIcon-root .MuiSvgIcon-root": {
          color: label === "Delete" ? "red" : "black",
        },
      },
      borderRadius: 1,
    };
  };

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiListItemIcon-root": {
            mr: 0,
          },
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {options?.map((option, index) => <MenuItem key={index}
        onClick={option?.handleClick}
        disabled={option?.disabled}
        sx={{...menuStyle(option?.label), display: option.visible ? "flex" : "none" }}
      >
        <ListItemIcon>
          {option?.icon}
        </ListItemIcon>
        {option?.label}
      </MenuItem>)}
    </Menu>
  );
};

const Badge = ({ count, text }) => {
  const [showRemaining, setShowRemaining] = useState(false);

  const handleshow = () => {
    // console.log("clicked");
    setShowRemaining(true);
  };
  return (
    <>
      {!showRemaining && (
        <Tooltip
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                "& .MuiTooltip-arrow": {
                  color: "#050C46",
                },
              },
            },
          }}
        >
          <span>
            <Button
              onClick={handleshow}
              style={{
                backgroundColor: "#050C46",
                color: "#ffffff",
                borderRadius: "26px",
                padding: "0 0px 0 0px",
                fontWeight: "500",
                fontSize: "12px",
                marginLeft: "5px",
                minWidth: "35px",
              }}
            >
              +{count}
            </Button>
          </span>
        </Tooltip>
      )}

      {showRemaining && <span style={{ marginLeft: "5px" }}>{text}</span>}

      {showRemaining && <span style={{ marginLeft: "5px" }}>{text}</span>}
    </>
  );
};

const CampaignRowCard = ({
  refetchArchivedCampaigns,
  refetchDraftCampaigns,
  handleOpen,
  campaignData,
  navigate,
  handleEditCampaign,
  tempPaused,
  campStatus,
  togglePaused,
  linkToLeads,
  salesAgentURL,
  emailStatus,
  userDetail,
  remaining,
  isActive,
  isSaveAsDraft,
  tempStatus,
  leadsCount,
  campMailBoxes,
  setIsDraftDelete,
  setIsUnarchive,
  refreshList,
  setRefreshList,
  handleOpenC
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isStartNewDialogOpen, setIsStartNewDialogOpen] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const { ctaSelection, apollo, analytics,lastSavedAt } = campaignData;
  const industry = apollo?.industry || [];
  const targets = apollo?.targets || [];
  const technology = apollo?.technology || [];
  const compSize = apollo?.addFilters?.compSize || [];
  const location = apollo?.addFilters?.location || [];
  const revenue = apollo?.addFilters?.revenue || [];
  const funding = apollo?.addFilters?.funding || [];
  const keywords = apollo?.addFilters?.keywords || [];
  const companyOrkeywords = apollo?.addFilters?.andCompanyKeywords || [];
  const companyAllKeywords = apollo?.addFilters?.allKeywords || [];
  const excludeKeywords = apollo?.addFilters?.blockedKeywords || [];
  const managementLevels = apollo?.additionalFilters?.managementLevels || [];
  const departments = apollo?.additionalFilters?.departments || [];
  const mappedIndustries =
    industry?.length > 0
      ? newIndustryTypes
        .filter((ind) => industry.includes(ind.id))
        .map((ind) => ind.cleaned_name)
      : [];
  const mappedFunding =
    funding?.length > 0
      ? FUNDING.filter((fund) => funding.includes(fund.value)).map(
        (fund) => fund.title
      )
      : [];
  const mappedTechnology =
    technology?.length > 0
      ? TECHNOLOGYOPTIONS.filter((fund) => technology.includes(fund.key)).map(
        (fund) => fund.title
      )
      : [];

  const mappedDepartments =
    departments?.length > 0
      ? DEPARTMENTSOPTIONS.filter((dept) => departments.includes(dept.key)).map(
        (dept) => dept.title
      )
      : [];

  const mappedManagements =
    managementLevels.length > 0
      ? MANAGEMENT_OPTIONS.filter((manage) =>
        managementLevels.includes(manage.key)
      ).map((manage) => manage.title)
      : [];

  const companySizeFiltered = compSize.filter(
    (size) => !size.includes("undefined")
  );

  const companySize = companySizeFiltered
    .map((size) => size.replace(",", "-"))
    .join(", ");

  const departmentsToShow =
    mappedDepartments.length > 8
      ? mappedDepartments.slice(0, 8)
      : mappedDepartments;
  const remainingDept =
    mappedDepartments.length > 8
      ? mappedDepartments.slice(8, mappedDepartments.length)
      : [];

  const managementLevelsToShow =
    mappedManagements.length > 12
      ? mappedManagements.slice(0, 12)
      : mappedManagements;
  const remainingMange =
    mappedManagements.length > 12
      ? mappedManagements.slice(12, mappedManagements.length)
      : [];

  const technologyToShow =
    mappedTechnology.length > 10
      ? mappedTechnology.slice(0, 10)
      : mappedTechnology;
  const remainingTech =
    technology.length > 10
      ? mappedTechnology.slice(10, mappedTechnology.length)
      : [];

  const keywordsToShow =
    keywords.length > 10 ? keywords.slice(0, 10) : keywords;
  const remainingkeywords =
    keywords.length > 10 ? keywords.slice(10, keywords.length) : [];
  const companyOrKeywordsToShow =
    companyOrkeywords.length > 10
      ? companyOrkeywords.slice(0, 10)
      : companyOrkeywords;
  const remainingCompanykeywords =
    companyOrkeywords.length > 10
      ? companyOrkeywords.slice(10, companyOrkeywords.length)
      : [];

  const allKeywordsToShow =
    companyAllKeywords.length > 10
      ? companyAllKeywords.slice(0, 10)
      : companyAllKeywords;
  const remainingallkeywords =
    companyAllKeywords.length > 10
      ? companyAllKeywords.slice(10, companyAllKeywords.length)
      : [];

  const excludeKeywordsToShow =
    excludeKeywords.length > 10
      ? excludeKeywords.slice(0, 10)
      : excludeKeywords;
  const remainingExcludeKeywords =
    excludeKeywords.length > 10
      ? excludeKeywords.slice(10, excludeKeywords.length)
      : [];

  const fundingToShow =
    mappedFunding.length > 11 ? mappedFunding.slice(0, 11) : mappedFunding;
  const remainingfunding =
    mappedFunding.length > 11
      ? mappedFunding.slice(11, mappedFunding.length)
      : [];

  const targetsToShow = targets.length > 12 ? targets.slice(0, 12) : targets;
  const remainingtargets =
    targets.length > 12 ? targets.slice(12, targets.length) : [];

  const industryToShow =
    mappedIndustries.length > 8
      ? mappedIndustries.slice(0, 8)
      : mappedIndustries;
  const remainingindustry =
    mappedIndustries.length > 8
      ? mappedIndustries.slice(8, mappedIndustries.length)
      : [];

  const [open, setOpen] = React.useState(false);
  const [isArchived, setIsArchived] = React.useState(false);

  const handleClose = () => {
    isArchived ? setIsArchived(false) : setOpen(false);
  };
  const handleOk = async () => {
    try {
      // console.log("isArchived: ", isArchived);
      setIsUnarchive(false);
      setIsDraftDelete(false);
      if (isArchived) {
        const res = await axios.put(
          `${API_SERVICE_BACKEND}/unarchive-campaign/${campaignData._id}/false`,
          {}
        );
        setIsUnarchive(true);
        setOpen(false);
        await refetchArchivedCampaigns();
      } else {
        const res = await axios.delete(
          `${API_SERVICE_BACKEND}/saveAsDraftDeleteCampaign/${campaignData._id}`,
          {}
        );
        setIsDraftDelete(true);
        setOpen(false);
        await refetchDraftCampaigns();
      }
      //setIsDraftDelete(true);

      //window.location = window.location.href;
    } catch (error) {
      toast.error(error.message);
    }
    setOpen(false);
  };
  const updateCampaign = async (campaignId) => {
    // console.log(campaignId, payload);
    const payload = { archived: true };
    try {
      const res = await axios.patch(`${API_SERVICE_BACKEND}/updateCampaign`, {
        id: campaignId,
        payload,
      });
      if (res.status === 204) {
        return true;
      }
    } catch (e) {
      console.log(e.message);
    }
    return false;
  };

  let locationList = [];
  location.forEach((item) => {
    if (_.isObject(item)) {
      locationList.push(item?.title);
    } else {
      locationList.push(item);
    }
  });
  const locationToShow =
    locationList.length > 10 ? locationList.slice(0, 10) : locationList;
  const remaininglocation =
    locationList.length > 10 ? locationList.slice(10, locationList.length) : [];

  const handleCopyCampaign = (archive, simpleCopy = false) => {
    const cloneCampaign = _.cloneDeep(campaignData);
    delete cloneCampaign._id;
    delete cloneCampaign.subscription;
    delete cloneCampaign.operationStat;
    delete cloneCampaign.leads;
    delete cloneCampaign.uploadedLeads;
    delete cloneCampaign.analytics;
    delete cloneCampaign.updatedAt;
    delete cloneCampaign.lastSavedAt;
    delete cloneCampaign.createdAt;
    delete cloneCampaign.paused;
    delete cloneCampaign.archived;
    delete cloneCampaign.isEnterprise;
    delete cloneCampaign.status;
    delete cloneCampaign.extra;
    delete cloneCampaign.__v;

    if (simpleCopy) {
      return navigate("/dashboard/campaigns/create?isSaveAsDraft=true", {
        state: {
          campaignId: campaignData?._id,
          campaignData: cloneCampaign,
          allowCampCreation: Boolean(remaining),
          isCopy: "copy",
          shouldArchive: false,
        },
      });
    }

    if (userDetail?.freeSubscription === true) {
      const updated = updateCampaign(campaignData?._id);
      return navigate("/dashboard/campaigns/create?isSaveAsDraft=true", {
        state: {
          campaignId: campaignData?._id,
          campaignData: cloneCampaign,
          allowCampCreation: Boolean(remaining),
          isCopy: "copy",
          shouldArchive: archive,
        },
      });
    }
    // if (userDetail && userDetail.limitExceeded) {
    //   toast.error(
    //     "Limit Exceeded Please upgrade plan or add additional agents"
    //   );
    // }
    else {
      const updated = updateCampaign(campaignData?._id);
      navigate("/dashboard/campaigns/create?isSaveAsDraft=true", {
        state: {
          campaignId: campaignData?._id,
          campaignData: cloneCampaign,
          allowCampCreation: Boolean(remaining),
          isCopy: "copy",
          shouldArchive: archive,
        },
      });
    }
  };

  const deleteSaveAsDraftData = async (archiveSet = false) => {
    // console.log("archiveSet: ", archiveSet);
    if (archiveSet) {
      // console.log("archiveSet: ", false);
      setIsArchived(true);
    }
    else {
      setOpen(true);
    }
  };
  const handleCreateAgent = (archive) => {
    if (userDetail?.freeSubscription === true) {
      const updated = updateCampaign(campaignData?._id);
      return (
        <Link
          to="/dashboard/campaigns/create?isSaveAsDraft=true"
          state={{
            campaignId: campaignData?._id,
            shouldArchive: archive,
            createNewFromEdit: true,
            isCreateAI: true,
          }}
        >
          <Button disabled={isAccess==="read"} variant="contained" sx={{ borderRadius: 1 }}>
            Create a New Campaign
          </Button>
        </Link>
      );
    }
    // if (userDetail && userDetail?.limitExceeded) {
    //   return (
    //     <Button
    //       variant="contained"
    //       sx={{ borderRadius: 1 }}
    //       onClick={() =>
    //         toast.error(
    //           "Limit Exceeded Please upgrade plan or add additional agents"
    //         )
    //       }
    //     >
    //       Create a New Agent
    //     </Button>
    //   );
    // } else
    {
      const updated = updateCampaign(campaignData?._id);
      return (
        <Link
          to="/dashboard/campaigns/create?isSaveAsDraft=true"
          state={{
            campaignId: campaignData?._id,
            shouldArchive: archive,
            createNewFromEdit: true,
            isCreateAI: true,
          }}
        >
          <Button variant="contained" sx={{ borderRadius: 1 }}>
            Create a New Campaign
          </Button>
        </Link>
      );
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
  const handleCloseStartNewDialog = () => {
    setIsStartNewDialogOpen(false);
  };

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

  CircularProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
    color: PropTypes.string,
  };
  // useEffect(() => {
  //   if (handleOpenC) {
  //     handleOpen("viewCampaignMailBoxes")
  //   }
  // }, [handleOpenC])
  const emailSentValue = parseInt((emailStatus * 100) / leadsCount);

  const callToActionModels = {
    personlizedVideo: "Video",
    chatAgentOnly: "Chat",
    customNoLink: "Traditional",
    customLinkable: "Redirect",
  };

  const formatDate = (date) => {
    if (date) {
      return new Date(date).toISOString().slice(0, 19).replace("T", " ");
    }
  };
  const formattedSentEmailDate = new Date(lastSavedAt).toLocaleDateString("en-US", {
    // weekday: 'short',
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  const Analytics = () => {
    return (
      <Stack rowGap={1}>
        <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
          DataProvider:{" "}
          <b style={{ fontWeight: "600px", fontSize: "14px" }}>
            {analytics?.dataProvider === "APOLLO" ? "B2B Rocket " : "-"}
          </b>
        </Typography>
        <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
          Leads Available For Enrichment:{" "}
          <b style={{ fontWeight: "600px", fontSize: "14px" }}>
            {analytics?.leadsAvailableForEnrichment || "0"}
          </b>
        </Typography>
        <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
          Leads Available To Email:{" "}
          <b style={{ fontWeight: "600px", fontSize: "14px" }}>
            {analytics?.leadsAvailableToEmail || "0"}
          </b>
        </Typography>
        <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
          Updated At:{" "}
          <b style={{ fontWeight: "600px", fontSize: "14px" }}>
            {formatDate(analytics?.updatedAt) || "-"}
          </b>
        </Typography>
      </Stack>
    );
  };

  // const [userType, setUserType] = useState(true);
  // const history = useNavigate();
  // const [userTypeRef, setUserTypeRef] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const handleUserTypeChange = async (e, data, status) => {
  //   setUserTypeRef(status);
  //   console.log("userType===>", data, status);
  //   let isEnterpriseStatus;
  //   if (e.target.checked) {
  //     isEnterpriseStatus = true;
  //     setUserTypeRef(true);
  //   } else {
  //     isEnterpriseStatus = false;
  //     setUserTypeRef(false);
  //   }
  //   try {
  //     setLoading(true);
  //     let response = await axios.patch(
  //       `${API_SERVICE_BACKEND}/updateCampaign`,
  //       {
  //         id: data,
  //         payload: { isEnterprise: isEnterpriseStatus },
  //       }
  //     );
  //     console.log("response check", response);
  //     if (response.status) {
  //       toast.success("Campaign Status Changed!");
  //       // await jobsApi.getUserCampaigns("billing@b2brocket.ai")
  //       // history("/dashboard/campaigns", { replace: true })
  //       window.location.reload();
  //       setLoading(false);
  //     } else {
  //       toast.error("Some things wrong!");
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     toast.error("Internal server error!");
  //     setLoading(false);
  //     console.log(error);
  //   }
  // };
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const menuOpen = Boolean(menuAnchorEl);
  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = (e) => {
    if(e && e.stopPropagation) e.stopPropagation(); 
    setMenuAnchorEl(null);
  };
  const handlePagePreview = async () => {
    if (await copyToClipboard(salesAgentURL)) {
      toast.success("Copied to clipboard");
    } else {
      toast.error("Something went wrong");
    }
  }
  const menuOptions = [
    {
      label: "Landing Page Preview",
      handleClick: handlePagePreview,
      icon: <LanguageOutlined />,
      disabled: isSaveAsDraft,
      visible: true
    },
    // {
    //   label: "Agent Info",
    //   handleClick: () => handleOpen("viewCampaign"),
    //   icon: <InfoOutlinedIcon />,
    //   disabled: false,
    // },
    // {
    //   label: tempPaused ? "Launch" : "Pause",
    //   handleClick: () => togglePaused(campaignData?._id),
    //   icon:
    //     tempPaused || campStatus ? (
    //       <PlayArrowOutlined style={{ color: "#00AA38" }} />
    //     ) : (
    //       <PauseOutlined style={{ color: "#FF5C1F" }} />
    //     ),
    //   disabled: isSaveAsDraft,
    // },
    {
      label: campaignData?.archived ? "Unarchive" : "Archive",
      handleClick: campaignData?.archived
        ? () => deleteSaveAsDraftData(true)
        : () => {},
      icon: <ArchiveOutlined />,
      disabled: false,
      visible: campaignData?.archived
    },
    {
      label: isSaveAsDraft ? "Delete" : "Convert to Draft",
      handleClick: isSaveAsDraft ? () => deleteSaveAsDraftData() : () => {},
      icon: isSaveAsDraft ? <DeleteOutline /> : <DraftsOutlined />,
      disabled: false,
      visible: isSaveAsDraft
    },
  ];
  const hasNoFilters =
    !location?.length > 0 &&
    !industry?.length > 0 &&
    !targets?.length > 0 &&
    !compSize?.length > 0 &&
    !revenue?.length > 0 &&
    !mappedFunding?.length > 0 &&
    !departments?.length > 0 &&
    !managementLevels?.length > 0 &&
    !technology?.length > 0 &&
    !keywords?.length > 0 &&
    !companyOrKeywordsToShow?.length > 0 &&
    !companyAllKeywords?.length > 0 &&
    !excludeKeywords?.length > 0;
  return (
    <>
      {open === true && (
        <ConfirmationPopup
          setOpen={setOpen}
          open={open}
          handleOk={handleOk}
          archiveSet={isArchived}
          handleClose={handleClose}
        />
      )}
      {isArchived === true && (
        <ConfirmationPopup
          setOpen={setIsArchived}
          open={isArchived}
          handleOk={handleOk}
          archiveSet={isArchived}
          handleClose={handleClose}
        />
      )}
      <TableRow 
        sx={{
          cursor: isSaveAsDraft ? "default" : "pointer",
          boxShadow: "1px 3px 14px 1px rgba(0, 0, 0, 0.1)",
          backgroundColor: "rgba(255, 255, 255, 1)", 
          borderRadius: "10px",
          "& td": {
            //borderTop: "1px solid rgba(224, 224, 229, 1)",
            borderBottom: "1px solid rgba(224, 224, 229, 1)",
            borderRight: "none !important", 
          },
          "& td:first-child": {
            borderTopLeftRadius: "10px",
            borderBottomLeftRadius: "10px",
            borderLeft: "1px solid rgba(224, 224, 229, 1)",
            borderRight: "none"
          },
          "& td:last-child": {
            borderTopRightRadius: "10px",
            borderBottomRightRadius: "10px",
            borderRight: "1px solid rgba(224, 224, 229, 1) !important",
            borderLeft: "none"
          }
        }}
        disabled={isAccess==="read"}
        onClick={() => {
          if(isSaveAsDraft) {
            return;
          }
          else {
            // navigate(linkToLeads);
            handleEditCampaign();
          }
        }}
      >
        {/* <CTableCell>
          <Checkbox />
        </CTableCell> */}
        <CTableCell 
          align="center" 
          sx={{ 
            display: "none",
            width: "65%", 
            borderRight: "none !important", 
            verticalAlign: "top"
          }}
          colSpan={2}
        >
          <Stack
            rowGap={1}
            sx={{
              width: "100%",
              borderRadius: 2,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  color: "#050C46",
                  fontWeight: "500",
                  fontSize: "14px",
                  textAlign: "left",
                }}
              >
                {campaignData.responsibility || "N/A"}
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                {/* {isSaveAsDraft && (
                  <Button
                    style={{
                      fontSize: "16px",
                      marginRight: "5px",
                      height: "25px",
                      cursor: "pointer",
                    }}
                    color="error"
                    onClick={() => deleteSaveAsDraftData()}
                  >
                    Delete
                  </Button>
                )} */}
                {isSaveAsDraft && (
                  <Chip
                    label="Draft"
                    style={{
                      fontSize: "10px",
                      marginRight: "5px",
                      height: "25px",
                      backgroundColor: "#f1f116",
                    }}
                  // color="error"
                  />
                )}
                {isActive && !campaignData?.archived && (
                  <Chip
                    label="Active"
                    style={{
                      fontSize: "10px",
                      marginRight: "5px",
                      height: "25px",
                    }}
                    color="success"
                  />
                )}
                {campaignData?.archived && (
                  <>
                    {/* <Button
                      style={{
                        fontSize: "16px",
                        marginRight: "5px",
                        height: "25px",
                        cursor: "pointer",
                      }}
                      color="error"
                      onClick={() => deleteSaveAsDraftData(true)}
                    >
                      Unarchive
                    </Button> */}
                    {" "}
                    <Chip
                      label="Archived"
                      style={{
                        fontSize: "10px",
                        marginRight: "5px",
                        height: "25px",
                      }}
                      color="error"
                    />
                  </>
                )}
                <InfoOutlinedIcon
                  fontSize="small"
                  sx={{
                    cursor: "pointer",
                    color: "#7E82A5",
                    "&:hover": {
                      color: "#5761FE",
                    },
                  }}
                  onClick={async () => {
                    if (await copyToClipboard(campaignData._id)) {
                      toast.success("Copied to clipboard");
                    } else {
                      toast.error("Something went wrong");
                    }
                  }}
                />
                <Typography>
                  ...
                  {campaignData._id?.substring(
                    campaignData._id?.length - 5,
                    campaignData?._id?.length
                  ) || ""}
                </Typography>
                <IconButton
                  onClick={handleMenuClick}
                  size="small"
                  aria-controls={menuOpen ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={menuOpen ? "true" : undefined}
                  sx={{
                    color: "#28287B",
                    borderRadius: 1,
                    p: "10px",
                    width: "40px",
                    height: "40px",
                  }}
                >
                  <MoreVertOutlined />
                </IconButton>
                <MoreMenu
                  anchorEl={menuAnchorEl}
                  open={menuOpen}
                  handleClose={handleMenuClose}
                  options={menuOptions}
                />
              </div>
            </div>
            {!hasNoFilters && <Divider sx={{ width: "100%", borderColor: "#ECE9F5" }} />}

            {location?.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "100px",
                    width: "100px",
                  }}
                >
                  <PublicOutlined
                    sx={{ fontSize: "18px", color: "#757DFD", mr: "5px" }}
                  />
                  <Typography
                    sx={{
                      color: "#050C46",
                      fontWeight: "600",
                      fontSize: "12px",
                      textAlign: "left"
                    }}
                  >
                    Location:{" "}
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#55597A",
                    fontWeight: "600",
                    fontSize: "12px",
                    textAlign: "left",
                    marginLeft: "10px",
                  }}
                >
                  {/* {console.log(locationToShow, "locationToShow")} */}
                  {locationToShow.join(", ")}
                  {remaininglocation.length > 0 && (
                    <Badge
                      count={remaininglocation.length}
                      text={remaininglocation.join(", ")}
                    />
                  )}
                </Typography>
              </Box>
            )}
            {industry?.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "100px",
                    width: "100px",
                  }}
                >
                  {/* <img src={Industries} style={{ marginRight: '5px' }} /> */}
                  <WorkOutlineOutlined
                    sx={{ fontSize: "18px", color: "#757DFD", mr: "5px" }}
                  />
                  <Typography
                    sx={{
                      color: "#050C46",
                      fontWeight: "600",
                      fontSize: "12px",
                      textAlign: "left"
                    }}
                  >
                    Industries:
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#55597A",
                    fontWeight: "600",
                    fontSize: "12px",
                    textAlign: "left",
                    marginLeft: "10px",
                  }}
                >
                  {industryToShow.join(", ")}
                  {remainingindustry.length > 0 && (
                    <Badge
                      count={remainingindustry.length}
                      text={remainingindustry.join(", ")}
                    />
                  )}
                </Typography>
              </Box>
            )}
            {targets?.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "100px",
                    width: "100px",
                  }}
                >
                  <PersonOutlined
                    sx={{ fontSize: "18px", color: "#757DFD", mr: "5px" }}
                  />{" "}
                  <Typography
                    sx={{
                      color: "#050C46",
                      fontWeight: "600",
                      fontSize: "12px",
                      textAlign: "left"
                    }}
                  >
                    Titles:
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#55597A",
                    fontWeight: "600",
                    fontSize: "12px",
                    marginLeft: "10px",
                    textAlign: "left",
                  }}
                >
                  {targetsToShow.join(", ")}
                  {remainingtargets.length > 0 && (
                    <Badge
                      count={remainingtargets.length}
                      text={remainingtargets.join(", ")}
                    />
                  )}
                </Typography>
              </Box>
            )}
            {compSize?.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "100px",
                    width: "100px",
                  }}
                >
                  <BadgeOutlined
                    sx={{ fontSize: "18px", color: "#757DFD", mr: "5px" }}
                  />{" "}
                  <Typography
                    sx={{
                      color: "#050C46",
                      fontWeight: "600",
                      fontSize: "12px",
                      textAlign: "left"
                    }}
                  >
                    Employees:
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#55597A",
                    fontWeight: "600",
                    fontSize: "12px",
                    marginLeft: "10px",
                    textAlign: "left",
                  }}
                >
                  {companySize}
                </Typography>
              </Box>
            )}
            {revenue?.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "100px",
                    width: "100px",
                  }}
                >
                  <AttachMoney
                    sx={{ fontSize: "18px", color: "#757DFD", mr: "5px" }}
                  />{" "}
                  <Typography
                    sx={{
                      color: "#050C46",
                      fontWeight: "600",
                      fontSize: "12px",
                      textAlign: "left"
                    }}
                  >
                    Revenue:
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#55597A",
                    fontWeight: "600",
                    fontSize: "12px",
                    marginLeft: "10px",
                  }}
                >
                  {`$${new Intl.NumberFormat("en-US").format(
                    revenue[0]
                  )} - $${new Intl.NumberFormat("en-US").format(revenue[1])}`}
                </Typography>
              </Box>
            )}
            {mappedFunding?.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "100px",
                    width: "100px",
                  }}
                >
                  <SavingsOutlined
                    sx={{ fontSize: "18px", color: "#757DFD", mr: "5px" }}
                  />{" "}
                  <Typography
                    sx={{
                      color: "#050C46",
                      fontWeight: "600",
                      fontSize: "12px",
                      textAlign: "left"
                    }}
                  >
                    Funding:
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#55597A",
                    fontWeight: "600",
                    fontSize: "12px",
                    marginLeft: "10px",
                    textAlign: "left",
                  }}
                >
                  {fundingToShow.join(", ")}
                  {remainingfunding.length > 0 && (
                    <Badge
                      count={remainingfunding.length}
                      text={remainingfunding.join(", ")}
                    />
                  )}
                </Typography>
              </Box>
            )}
            {departments?.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "100px",
                    width: "100px",
                  }}
                >
                  <GroupOutlined
                    sx={{ fontSize: "18px", color: "#757DFD", mr: "5px" }}
                  />{" "}
                  <Typography
                    sx={{
                      color: "#050C46",
                      fontWeight: "600",
                      fontSize: "12px",
                      textAlign: "left"
                    }}
                  >
                    Departments:
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#55597A",
                    fontWeight: "600",
                    fontSize: "12px",
                    marginLeft: "10px",
                    textAlign: "left",
                  }}
                >
                  {departmentsToShow.join(", ")}
                  {remainingDept.length > 0 && (
                    <Badge
                      count={remainingDept.length}
                      text={remainingDept.join(", ")}
                    />
                  )}
                </Typography>
              </Box>
            )}
            {managementLevels?.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "100px",
                    width: "100px",
                  }}
                >
                  <ManageAccountsOutlined
                    sx={{ fontSize: "18px", color: "#757DFD", mr: "5px" }}
                  />{" "}
                  <Typography
                    sx={{
                      color: "#050C46",
                      fontWeight: "600",
                      fontSize: "12px",
                      overflowWrap: "break-word",
                      textAlign: "left",
                    }}
                  >
                    Management Levels:
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#55597A",
                    fontWeight: "600",
                    fontSize: "12px",
                    marginLeft: "10px",
                    textAlign: "left",
                  }}
                >
                  {managementLevelsToShow.join(", ")}
                  {remainingMange.length > 0 && (
                    <Badge
                      count={remainingMange.length}
                      text={remainingMange.join(", ")}
                    />
                  )}
                </Typography>
              </Box>
            )}
            {technology?.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "100px",
                    width: "100px",
                  }}
                >
                  <BiotechOutlined
                    sx={{ fontSize: "18px", color: "#757DFD", mr: "5px" }}
                  />{" "}
                  <Typography
                    sx={{
                      color: "#050C46",
                      fontWeight: "600",
                      fontSize: "12px",
                      textAlign: "left"
                    }}
                  >
                    Technology:
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#55597A",
                    fontWeight: "600",
                    fontSize: "12px",
                    marginLeft: "10px",
                    textAlign: "left",
                  }}
                >
                  {technologyToShow.join(", ")}
                  {remainingTech.length > 0 && (
                    <Badge
                      count={remainingTech.length}
                      text={remainingTech.join(", ")}
                    />
                  )}
                </Typography>
              </Box>
            )}
            {keywords?.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "100px",
                    width: "100px",
                  }}
                >
                  <KeyOutlined
                    sx={{ fontSize: "18px", color: "#757DFD", mr: "5px" }}
                  />{" "}
                  <Typography
                    sx={{
                      color: "#050C46",
                      fontWeight: "600",
                      fontSize: "12px",
                      textAlign: "left"
                    }}
                  >
                    Keywords:
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#55597A",
                    fontWeight: "600",
                    fontSize: "12px",
                    marginLeft: "10px",
                    textAlign: "left",
                  }}
                >
                  {keywordsToShow.join(", ")}
                  {remainingkeywords.length > 0 && (
                    <Badge
                      count={remainingkeywords.length}
                      text={remainingkeywords.join(", ")}
                    />
                  )}
                </Typography>
              </Box>
            )}
            {companyOrKeywordsToShow?.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    minWidth: "100px",
                    width: "100px",
                  }}
                >
                  <KeyOutlined
                    sx={{ fontSize: "18px", color: "#757DFD", mr: "5px" }}
                  />{" "}
                  <Typography
                    sx={{
                      color: "#050C46",
                      fontWeight: "600",
                      fontSize: "12px",
                      textAlign: "left"
                    }}
                  >
                    Include Company Keywords (OR):
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#55597A",
                    fontWeight: "600",
                    fontSize: "12px",
                    marginLeft: "10px",
                    textAlign: "left",
                  }}
                >
                  {companyOrKeywordsToShow.join(", ")}
                  {remainingCompanykeywords.length > 0 && (
                    <Badge
                      count={remainingCompanykeywords.length}
                      text={remainingCompanykeywords.join(", ")}
                    />
                  )}
                </Typography>
              </Box>
            )}
            {companyAllKeywords?.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    minWidth: "100px",
                    width: "100px",
                  }}
                >
                  <KeyOutlined
                    sx={{ fontSize: "18px", color: "#757DFD", mr: "5px" }}
                  />{" "}
                  <Typography
                    sx={{
                      color: "#050C46",
                      fontWeight: "600",
                      fontSize: "12px",
                      textAlign: "left"
                    }}
                  >
                    Include All Company Keywords (AND):
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#55597A",
                    fontWeight: "600",
                    fontSize: "12px",
                    marginLeft: "10px",
                    textAlign: "left",
                  }}
                >
                  {allKeywordsToShow.join(", ")}
                  {remainingallkeywords.length > 0 && (
                    <Badge
                      count={remainingallkeywords.length}
                      text={remainingallkeywords.join(", ")}
                    />
                  )}
                </Typography>
              </Box>
            )}

            {excludeKeywords?.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    minWidth: "100px",
                    width: "100px",
                  }}
                >
                  <KeyOutlined
                    sx={{ fontSize: "18px", color: "#757DFD", mr: "5px" }}
                  />{" "}
                  <Typography
                    sx={{
                      color: "#050C46",
                      fontWeight: "600",
                      fontSize: "12px",
                      textAlign: "left"
                    }}
                  >
                    Exclude Keywords:
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#55597A",
                    fontWeight: "600",
                    fontSize: "12px",
                    marginLeft: "10px",
                    textAlign: "left",
                  }}
                >
                  {excludeKeywordsToShow.join(", ")}
                  {remainingExcludeKeywords.length > 0 && (
                    <Badge
                      count={remainingExcludeKeywords.length}
                      text={remainingExcludeKeywords.join(", ")}
                    />
                  )}
                </Typography>
              </Box>
            )}
            <Divider sx={{ width: "100%", borderColor: "#ECE9F5" }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                backgroundColor: "rgba(88, 98, 255, 0.05)",
                borderRadius: "10px",
                px: "12px",
                py: "6px",
                border: "1px solid rgba(88, 98, 255, 0.5)"
              }}
            >
              <Typography
                sx={{ color: "#050C46", fontWeight: "600", fontSize: "12px" }}
              >
                Call-To-Action Model :{" "}
              </Typography>
              <Typography
                sx={{
                  color: "#55597A",
                  fontWeight: "600",
                  fontSize: "12px",
                  ml: 1,
                }}
              >
                {callToActionModels[ctaSelection] || "N/A"}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <Typography
                sx={{ color: "#050C46", fontWeight: "600", fontSize: "12px" }}
              >
                Last Updated At :{" "}
              </Typography>
              <Typography
                sx={{
                  color: "#55597A",
                  fontWeight: "600",
                  fontSize: "12px",
                  ml: 1,
                }}
              >
                {formattedSentEmailDate}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 1
              }}
            >
              {campaignData?.archived !== true && (
                <Button
                  sx={{
                    width: "30%",
                    borderRadius: 1,
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                  variant="contained"
                  onClick={() => setIsDialogOpen(true)}
                >
                  Edit
                </Button>
              )}
              <Button
                sx={{
                  width: campaignData?.archived === true ? "45%" : "30%",
                  color: "#050C46",
                  fontWeight: "500",
                  fontSize: "14px",
                  borderRadius: 1,
                  borderColor: "#B3B5C7",
                  "&:hover": {
                    backgroundColor: alpha("#B3B5C7", 0.2),
                    borderColor: "#B3B5C7",
                  },
                }}
                variant="outlined"
                onClick={() => handleOpen("viewCampaign")}
              >
                View
              </Button>
              <Button
                sx={{
                  width: campaignData?.archived === true ? "45%" : "30%",
                  borderColor: alpha("#0071F6", 0.2),
                  backgroundColor: alpha("#0071F6", 0.1),
                  color: "#050C46",
                  fontWeight: "500",
                  fontSize: "14px",
                  borderRadius: 1,
                  "&:hover": {
                    backgroundColor: alpha("#0071F6", 0.2),
                    borderColor: alpha("#0071F6", 0.3),
                  },
                }}
                variant="outlined"
                disabled={isAccess==="read"}
                onClick={() => handleCopyCampaign(false, true)}
              >
                <img src={CopyCampaign} style={{ marginRight: "10px" }} />
                Copy
              </Button>
              <Button
                //fullWidth
                variant="outlined"
                sx={{
                  borderColor: alpha("#00AA38", 0.2),
                  backgroundColor: alpha("#00AA38", 0.1),
                  color: "#050C46",
                  fontWeight: "500",
                  fontSize: "14px",
                  borderRadius: 1,
                  minWidth: "230px",
                  "&:hover": {
                    backgroundColor: alpha("#00AA38", 0.2),
                    borderColor: alpha("#00AA38", 0.3),
                  },
                }}
                disabled={isSaveAsDraft}
                onClick={() => {
                  // navigate(linkToLeads);
                  handleEditCampaign();
                }}
              >
                <SignalCellularAltOutlined style={{ marginRight: "10px", color: "rgba(18, 185, 129, 1)" }} />
                Agent Real-Time Stats
              </Button>
            </Box>
          </Stack>
        </CTableCell>
        <CTableCell sx={{ minWidth: "180px", display: "none"  }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              height: "100%",
              gap: 3,
            }}
          >
            {campaignData?.archived !== true && (
              <Button
                onClick={() => {
                  togglePaused(campaignData?._id);
                }}
                fullWidth
                disabled={isSaveAsDraft}
                sx={{
                  borderRadius: 1,
                  backgroundColor:
                    tempPaused || campStatus
                      ? alpha("#00AA38", 0.1)
                      : alpha("#FF5C1F", 0.1),
                  border: `1px solid ${tempPaused || campStatus
                    ? alpha("#00AA38", 0.2)
                    : alpha("#FF5C1F", 0.2)
                    }`,
                  color: tempPaused || campStatus ? "#00AA38" : "#FF5C1F",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                {tempPaused || campStatus ? (
                  <PlayArrowOutlined />
                ) : (
                  <PauseOutlined />
                )}
                <Typography
                  style={{
                    color: "#050C46",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  {" "}
                  {tempPaused ? "Launch" : "Pause"}
                </Typography>
              </Button>
            )}
            <Stack rowGap={1} sx={{ width: "100%" }}>
              <Tooltip title={<Analytics />} arrow placement="top">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                    border: `1px solid ${alpha("#050C46", 0.2)}`,
                    borderRadius: 1,
                    padding: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "#5761FE",
                        width: "8px",
                        height: "8px",
                        borderRadius: "4px",
                      }}
                    ></Box>
                    <Typography
                      sx={{
                        color: "#55597A",
                        fontWeight: "500",
                        fontSize: "12px",
                      }}
                    >
                      Leads Generated
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      color: "#101828",
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    {leadsCount || 0}
                  </Typography>
                </Box>
              </Tooltip>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                  border: `1px solid ${alpha("#050C46", 0.2)}`,
                  borderRadius: 1,
                  padding: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#FF5C1F",
                      width: "8px",
                      height: "8px",
                      borderRadius: "4px",
                    }}
                  ></Box>
                  <Typography
                    sx={{
                      color: "#55597A",
                      fontWeight: "500",
                      fontSize: "12px",
                    }}
                  >
                   Leads Processed 
                  </Typography>
                </Box>
                <Typography
                  sx={{ color: "#101828", fontWeight: "600", fontSize: "14px" }}
                >
                  {emailStatus || 0}
                </Typography>
              </Box>
              {/* {!isSaveAsDraft && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                  border: `1px solid ${alpha("#050C46", 0.2)}`,
                  borderRadius: 1,
                  padding: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#FF5C1F",
                      width: "8px",
                      height: "8px",
                      borderRadius: "4px",
                    }}
                  ></Box>
                  General/Enterprise
                </Box>

                <FormControlLabel
                  control={
                    <Switch
                      checked={campaignData?.isEnterprise}
                      onChange={(e) => { handleUserTypeChange(e, campaignData._id, campaignData?.isEnterprise) }}
                      name="userType"
                      color="primary"
                      disabled={campMailBoxes?.length < 1 ? false : true}
                    />
                  }
                // label="Custom Enterprise Mode"
                />
                {loading && (<CircularProgress color="inherit" sx={{ margin: "auto" }} />)}
              </Box>
            )
            } */}
            </Stack>
          </Box>
        </CTableCell>
        <CTableCell align="center" sx={{display: "none"}}>
          <Stack
            sx={{
              minWidth: "260px",
              p: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              backgroundColor: "rgba(248, 248, 249, 1)",
              border: "1px solid rgba(224, 224, 229, 1)",
              borderRadius: "12px",
              height: "100%"
            }}
            rowGap={1}
          >
            <Button
              fullWidth
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                border: `1px solid rgba(224, 224, 229, 1)`,
                backgroundColor: "rgba(255, 255, 255, 1)"
              }}
            >
              <Typography
                sx={{ color: "#050C46", fontWeight: "500", fontSize: "14px" }}
              >
                {tempStatus === 2 || tempStatus === 1
                  ? "Agent Health"
                  : tempStatus === 0
                    ? "Process Halted"
                    : null}
              </Typography>
              <Typography variant="caption">
                {tempStatus === 2
                  ? null
                  : tempStatus === 1
                    ? null
                    : tempStatus === 0
                      ? "Not enough leads for applied filters"
                      : null}
              </Typography>
              {tempStatus === 0 && (
                <WarningAmberRoundedIcon
                  style={{ marginLeft: "3px", color: "#FE9C0A" }}
                />
              )}
              {(tempStatus === 1 || tempStatus === 2) && (
                <img src={HealthIcon} style={{ marginLeft: "5px" }} />
              )}
            </Button>
            <Button
              fullWidth
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                border: `1px solid rgba(224, 224, 229, 1)`,
                backgroundColor: "rgba(255, 255, 255, 1)"
              }}
              onClick={() => {}}
            >
              <Typography
                sx={{ color: "#050C46", fontWeight: "500", fontSize: "14px" }}
              >
                Video
              </Typography>
              <Box
                sx={{
                  width: "32px",
                  height: "32px",
                  border: `1px solid rgba(0, 113, 246, 0.2)`,
                  borderRadius: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "rgba(0, 113, 246, 0.1)"
                }}
              >
                <VideocamOutlined style={{color: "rgba(0, 113, 246, 1)"}} sx={{fontSize: "22px"}} />
              </Box>
            </Button>
            <Button
            disabled={isAccess==="read"}
              onClick={() => {
                navigate(
                  `${paths.dashboard.account}?campaign_id=${campaignData?._id}&title=${campaignData?.title}`,
                  { state: { extra: campaignData?.extra } }
                );
              }}
              fullWidth
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                border: `1px solid rgba(224, 224, 229, 1)`,
                backgroundColor: "rgba(255, 255, 255, 1)"
              }}
            >
              <Typography
                style={{
                  color: "#050C46",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                {" "}
                Calendar
              </Typography>
              <Box
                sx={{
                  width: "32px",
                  height: "32px",
                  border: `1px solid rgba(255, 92, 31, 0.2)`,
                  borderRadius: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "rgba(255, 92, 31, 0.1)"
                }}
              >
                <CalendarTodayOutlined style={{color: "rgba(255, 92, 31, 1)"}} sx={{fontSize: "18px"}} />
              </Box>
            </Button>
            <Button
              fullWidth
              // disabled={!campMailBoxes || !campMailBoxes?.length}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                border: `1px solid rgba(224, 224, 229, 1)`,
                backgroundColor: "rgba(255, 255, 255, 1)"
              }}
              onClick={() => handleOpen("viewCampaignMailBoxes")}
            >
              <Typography
                sx={{ color: "#050C46", fontWeight: "500", fontSize: "14px" }}
              >
                Mailboxes
              </Typography>
              {/* {console.log(campaignData, "emailStatus")} */}
              <Box
                sx={{
                  width: "32px",
                  height: "32px",
                  border: `1px solid rgba(87, 97, 254, 0.2)`,
                  borderRadius: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "rgba(87, 97, 254, 0.1)"
                }}
              >
                <Typography
                  sx={{
                    color: "rgba(87, 97, 254, 1)",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  {campMailBoxes ? campMailBoxes.length : 0}
                </Typography>
              </Box>
            </Button>

            {/* <Button
              fullWidth
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                border: `1px solid rgba(224, 224, 229, 1)`,
                backgroundColor: "rgba(255, 255, 255, 1)"
              }}
              onClick={async () => {
                if (await copyToClipboard(salesAgentURL)) {
                  toast.success("Copied to clipboard");
                } else {
                  toast.error("Something went wrong");
                }
              }}
              disabled={isSaveAsDraft}
            >
              <Typography
                sx={{ color: "#050C46", fontWeight: "500", fontSize: "14px" }}
              >
                Landing Page Preview
              </Typography>
              <img src={LandingIcon} style={{ marginLeft: "5px" }} />
            </Button> */}

            {/* <Button
            fullWidth
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              border: `1px solid ${alpha("#050C46", 0.2)}`,
            }}
          >
            <Stack spacing={1} divider={<Divider />}>
              <Tooltip
                title={`${leadsCount ? leadsCount : 0} leads generated!`}
                placement="top"
                arrow
              >
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                  spacing={0.5}
                >
                  <Typography color="success" noWrap variant="overline">
                    Generating Leads
                  </Typography>
                  {tempStatus === 2 ? (
                    <SvgIcon
                      sx={{
                        color: "background.paper",
                        "& path": {
                          fill: (theme) => theme.palette.success.main,
                          fillOpacity: 1,
                        },
                        height: "36px",
                        width: "auto",
                      }}
                    >
                      <CheckVerified01 />
                    </SvgIcon>
                  ) : tempStatus === 1 ? (
                    `${leadsCount ? leadsCount : 0} leads`
                  ) : null}
                  <Stack>
                    {/* <Typography
                      variant="caption"
                      sx={
                        tempStatus === 0 && {
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          borderRadius: "8px",
                          border: "1px solid #FE9C0A",
                          padding: "4px",
                          background: "rgba(254, 156, 10, 0.10)",
                          fontSize: "12px",
                        }
                      }
                    >
                      {tempStatus === 0 && (
                        <WarningAmberRoundedIcon
                          style={{ marginLeft: "3px", color: "#FE9C0A" }}
                        />
                      )}
                      {tempStatus === 2
                        ? null
                        : tempStatus === 1
                        ? null
                        : tempStatus === 0
                        ? "Not enough leads for applied filters"
                        : null}
                    </Typography> 
                  </Stack>
                </Stack>
              </Tooltip>

              <Tooltip title={`${emailStatus} emails sent !`} arrow>
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  direction="row"
                  spacing={0.5}
                  backgroundColor="#F8F7FC"
                >
                  {campStatus ? (
                    <SvgIcon
                      sx={{
                        color: "background.paper",
                        "& path": {
                          fill: (theme) => theme.palette.success.main,
                          fillOpacity: 1,
                        },
                        height: "36px",
                        width: "auto",
                      }}
                    >
                      <CheckVerified01 />
                    </SvgIcon>
                  ) : (
                    <>
                      <CircularProgressWithLabel
                        color={tempPaused ? "warning" : "primary"}
                        value={isNaN(emailSentValue) ? 0 : emailSentValue}
                      />
                    </>
                  )}
                  <Typography color="success" noWrap variant="overline">
                    {campStatus
                      ? "Email Send"
                      : tempPaused
                      ? "Emails Paused"
                      : "Sending Emails"}
                  </Typography>
                </Stack>
              </Tooltip>
            </Stack>
          </Button> */}
          </Stack>
        </CTableCell>
        <CTableCell sx={{maxWidth: "380px"}}>
          <Tooltip title={campaignData?.senderName} placement="top-start" arrow>
            <Typography sx={{width: "auto", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", fontWeight: 500, fontSize: "14px"}}>
              {campaignData.responsibility || "N/A"}
            </Typography>
          </Tooltip>
        </CTableCell>
        <CTableCell>
          {isSaveAsDraft && (
            <Chip
              label="Draft"
              style={{
                fontSize: "14px",
                marginRight: "5px",
                height: "25px",
                backgroundColor: "#f1f116",
              }}
            // color="error"
            />
          )}
          {isActive && !campaignData?.archived && (
            <Chip
              label="Active"
              style={{
                fontSize: "14px",
                marginRight: "5px",
                height: "25px",
              }}
              color="success"
            />
          )}
          {campaignData?.archived && (
            <>
              <Chip
                label="Archived"
                style={{
                  fontSize: "14px",
                  marginRight: "5px",
                  height: "25px",
                }}
                color="error"
              />
            </>
          )}
        </CTableCell>
        <CTableCell align="center">
          <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} gap={1}>
            {emailStatus || 0} / {leadsCount || 0}
            {/* <Box 
              sx={{ 
                position: "relative",       
                display: "flex",
                alignItems: "center",
                justifyContent: "center", 
              }}
            >
              <CircularProgress
                variant="determinate"
                sx={{
                  color: (theme) =>
                    theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
                }}
                size={40}
                thickness={4}
                value={100}
              />
              <CircularProgress
                variant="determinate"
                disableShrink
                sx={{
                  color: (theme) => (theme.palette.mode === "light" ? "#1a90ff" : "#308fe8"),
                  position: "absolute",
                  left: 0,
                }}
                size={40}
                thickness={4}
                value={isNaN((emailStatus / leadsCount) * 100) ? 0 : (emailStatus / leadsCount) * 100}
              />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Groups sx={{fontSize: "16px", color: "#5761FE"}} />
              </Box>
            </Box> */}
          </Stack>
        </CTableCell>
        <CTableCell>
          <Typography
            sx={{
              fontSize: "14px",
            }}
          >
            {callToActionModels[ctaSelection] || "N/A"}
          </Typography>
        </CTableCell>
        <CTableCell>
          <Typography
            sx={{
              fontSize: "14px",
            }}
          >
            {formattedSentEmailDate}
          </Typography>
        </CTableCell>
        <CTableCell>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1
            }}
          >
            {campaignData?.archived !== true && (
              <Button
                sx={{
                  //width: "30%",
                  borderRadius: 1,
                  fontWeight: "500",
                  fontSize: "14px",
                  backgroundColor: "#5761FE",
                  flexGrow: 1
                }}
                disabled={isAccess==="read"}
                variant="contained"
                onClick={(e) => {
                  if(e && e.stopPropagation) e.stopPropagation(); 
                  setIsDialogOpen(true)
                }}
              >
                Edit
              </Button>
            )}
            <Button
              sx={{
                //width: campaignData?.archived === true ? "45%" : "30%",
                fontWeight: "500",
                fontSize: "14px",
                borderRadius: 1,
                flexGrow: 1
              }}
              disabled={isAccess==="read"} 
              variant="outlined"
              onClick={(e) => {
                if(e && e.stopPropagation) e.stopPropagation(); 
                handleCopyCampaign(false, true);
              }}
            >
              Copy
            </Button>
            {campaignData?.archived !== true && (
              <Tooltip title={tempPaused ? "Launch" : "Pause"} placement="top" arrow>
                <IconButton
                disabled={isAccess==="read"||isSaveAsDraft}
                  onClick={(e) => {
                    if(e && e.stopPropagation) e.stopPropagation(); 
                    togglePaused(campaignData?._id);
                  }}
                  sx={{
                    borderRadius: 1,
                    color: tempPaused || campStatus ? "#00AA38" : "#FF5C1F",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  {tempPaused || campStatus ? (
                    <PlayArrowOutlined />
                  ) : (
                    <PauseOutlined />
                  )}
                </IconButton>
              </Tooltip>
            )}
            <>
              <IconButton
              disabled={isAccess==="read"||isSaveAsDraft}
                onClick={(e) => {
                  if(e && e.stopPropagation) e.stopPropagation(); 
                  handleMenuClick(e);
                }}
                size="small"
                aria-controls={menuOpen ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={menuOpen ? "true" : undefined}
                sx={{
                  color: "#28287B",
                  borderRadius: 1,
                  p: "10px",
                  width: "40px",
                  height: "40px",
                }}
              >
                <MoreVertOutlined />
              </IconButton>
              <MoreMenu
                anchorEl={menuAnchorEl}
                open={menuOpen}
                handleClose={handleMenuClose}
                options={menuOptions}
              />
            </>
          </Box>
        </CTableCell>
      </TableRow>
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Edit Campaign</Typography>
          <IconButton   disabled={isAccess==="read"} onClick={handleCloseDialog} sx={{ px: 1 }}>
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack rowGap={2}>
            <Typography>Choose the action you want to take</Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Button
                // disabled={isAccess==="read"||isSaveAsDraft}
                disabled={isAccess==="read"}
                onClick={() => {
                  setIsDialogOpen(false);
                  handleEditCampaign();
                }}
                variant="contained"
                sx={{ borderRadius: 1 }}
              >
                Edit Existing Campaign
              </Button>
              <Button
                disabled={isAccess==="read"||isSaveAsDraft}
                onClick={() => {
                  setIsConfirmed(false);
                  setIsStartNewDialogOpen(true);
                }}
                variant="contained"
                sx={{ borderRadius: 1 }}
              >
                Start New Campaign
              </Button>
            </Box>
          </Stack>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isStartNewDialogOpen}
        onClose={handleCloseStartNewDialog}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 1,
            }}
          >
            {!isConfirmed && <WarningAmberOutlined />}
            Start a New Campaign
          </Typography>
          <IconButton disabled={isAccess==="read"} onClick={handleCloseStartNewDialog} sx={{ px: 1 }}>
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            minHeight: "150px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isConfirmed ? (
            <Stack rowGap={2}>
              <Typography>
                Choose the action you want to take to start a new campaign.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                {/* <Button 
                  onClick={()=>{
                    setIsStartNewDialogOpen(false);
                    handleCreateAgent()
                  }}
                  variant="contained"
                  sx={{borderRadius: 1}}
                >
                  Create a New Agent
                </Button> */}
                {handleCreateAgent(isConfirmed)}
                <Button
                  disabled={isAccess==="read"}
                  onClick={() => {
                    setIsStartNewDialogOpen(false);
                    handleCopyCampaign(isConfirmed);
                  }}
                  variant="contained"
                  sx={{ borderRadius: 1 }}
                >
                  Copy Existing Campaign
                </Button>
              </Box>
            </Stack>
          ) : (
            <Stack rowGap={2}>
              <Typography>
                If you click <b>continue</b>, your old campaign will stop
                immediately.
              </Typography>
              <Typography>Do you still wish to continue?</Typography>
              <Button  disabled={isAccess==="read"} onClick={() => setIsConfirmed(true)} variant="contained">
                Continue
              </Button>
            </Stack>
          )}
        </DialogContent>
      </Dialog>
    </>

    // </CardContent>
    // </Card>
  );
};

export default CampaignRowCard;
