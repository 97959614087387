import axios from "axios";
import { stubFalse } from "lodash";
import {
  API_LOCAL_IP,
  API_SERVICE_BACKEND,
  API_SERVICE_BACKEND_2,
} from "src/config";
import { deepCopy } from "src/utils/deep-copy";
// import { companies, company } from './data';

class JobsApi {
  getCampaignDetails = async (campaignId, isSaveAsDraft, isCopy = "") => {
    const url =
      isSaveAsDraft === "true"
        ? `${API_SERVICE_BACKEND}/filterSaveAsDraftCampaigns`
        : `${API_SERVICE_BACKEND}/filterCampaigns`;
    const res = await axios.get(url, {
      params: { _id: campaignId, isCopy: isCopy },
    });
    if (res.status === 200) {
      return res.data;
    } else {
      throw new Error(res.data);
    }
  };

  getCampaignStats = async (campaignId) => {
    const res = await axios.get(
      `${API_SERVICE_BACKEND}/getStats/${campaignId}`
    );
    if (res.status === 200) {
      return res.data;
    } else {
      throw new Error(res.data);
    }
  };

  getDashBoardStats = async (campaignId) => {
    const res = await axios.get(
      `${API_SERVICE_BACKEND}/getDashBoard/${campaignId}`
    );
    if (res.status === 200) {
      return res.data;
    } else {
      throw new Error(res.data);
    }
  };

  getUserCampaigns = async (email, search = "", page = 0, limit = 0, status = "all") => {
    // console.log(email)
    try {
      const res = await axios.get(
        `${API_SERVICE_BACKEND}/userCampaigns/${email}?search=${encodeURIComponent(search)}&page=${page}&limit=${limit}&status=${status}`
      );

      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(res.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  getCampaignLeads = async (
    campaignId,
    page,
    limit = 100,
    abortController,
    email,
    sortOptions,
    exportBool
  ) => {
    // console.log(campaignId)
    console.warn(sortOptions);
    const res = await axios.get(
      // `http://192.168.18.60:8080/api/v1/main/campaignLeads/${campaignId}`,
      `${API_SERVICE_BACKEND}/campaignLeads/${campaignId}`,
      {
        params: {
          email,
          page,
          limit,
          sortJSON: encodeURI(JSON.stringify(sortOptions)),
          exportBool,
        },
        signal: abortController?.signal,
      }
    );
    if (res.status === 200) {
      return [...res.data.leads, { count: res.data.count }];
    } else {
      throw new Error(res.data);
    }
  };

  getUserSaveAsDraftCampaigns = async (email, search = "",page,limit) => {
    // console.log(email)
    try {
      const res = await axios.get(
        `${API_SERVICE_BACKEND}/userSaveAsDraftCampaigns/${email}?search=${encodeURIComponent(
          search
        )}&page=${page}&limit=${limit}`
      );

      if (res.status === 200) {
        return res.data.data;
      } else {
        throw new Error(res.data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  getCampaignLeadsV2 = async (
    campaignId,
    page,
    limit = 100,
    abortController,
    email,
    sortOptions,
    exportBool,
    search,
    dateSelect
  ) => {

    const payload = {
      email,
      page,
      limit,
      sortJSON: encodeURI(JSON.stringify(sortOptions)),
      exportBool,
      search,
      }
      if(dateSelect.from && dateSelect.to){
      payload.dateSelect = dateSelect
      }
    // console.log(campaignId)
    console.warn(sortOptions);
    const res = await axios.get(
      `${API_SERVICE_BACKEND}/campaignLeadsSearch/${campaignId}`,
      {
        params: payload,
        signal: abortController?.signal,
      }
    );
    if (res.status === 200) {
      return { leads: res.data.data.leads, metaData: res.data.data.metaData };
    } else {
      throw new Error(res.data);
    }
  };

  getChartData = async (email, campaignIds, params) => {
    // console.log(campaignId)
    const res = await axios.get(`${API_SERVICE_BACKEND}/dashboard-chart`, {
      params: { clientEmail: email, campaignIds, ...(params ? params : {}) },
    });
    if (res.status === 200) {
      return res.data.data;
    } else {
      throw new Error(res.data);
    }
  };

  updateCampaign = async (campaignId, payload) => {
    // console.log(campaignId, payload);
    try {
      const res = await axios.patch(`${API_SERVICE_BACKEND}/updateCampaign`, {
        id: campaignId,
        payload,
      });
      if (res.status === 204) {
        return true;
      }
    } catch (e) {
      console.log(e.message);
    }
    return false;
  };

  getLeadsTotalData = async (id,dateRange) => {
    const res = await axios.post(`${API_SERVICE_BACKEND}/getLeadsTotalData`, {
      campaignIds : id,
      dateRange : dateRange
    });
    if (res.status === 200) {
      return res.data.data;
    } else {
      throw new Error(res.data);
    }
  }
}

export const jobsApi = new JobsApi();
