import User01Icon from "@untitled-ui/icons-react/build/esm/User01";
import {
  Avatar,
  Box,
  ButtonBase,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
  Stack
} from "@mui/material";

import { usePopover } from "src/hooks/use-popover";
import { AccountPopover } from "./account-popover";
import { useSelector } from "react-redux";
import { getSessionStorage } from "src/utils/storage";

export const AccountButton = () => {
  const fullName = useSelector((state) => state.user.fullName);
  const photoURL = getSessionStorage("photoUrl") || null;
  const ownerEmail = getSessionStorage("ownerEmail") || null
  console.log({ photoURL });

  const user = {
    name: fullName,
    ownerEmail: ownerEmail,
    email: getSessionStorage("userEmail"),
    avatar: photoURL
      ? photoURL
      : "https://www.kindpng.com/picc/m/252-2524695_dummy-profile-image-jpg-hd-png-download.png",
  };
  // console.log(user);
  const popover = usePopover();
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
    <Stack alignItems='center' direction='row' spacing={2}  onClick={popover.handleOpen} sx={{cursor: "pointer"}}>
      <Box
        component={ButtonBase}
        //onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: "divider",
          height: 40,
          width: 40,
          borderRadius: "50%",
        }}
      >
        <Avatar
          sx={{
            height: 32,
            width: 32,
          }}
          src={user.avatar}
        >
          <SvgIcon>
            <User01Icon />
          </SvgIcon>
        </Avatar>
      </Box>
      <Box>
        <Typography
          variant="body1"
          //color="text.primary"
          sx={{ fontWeight: 700, color: "var(--nav-item-color)", fontSize: "18px" }}
        >
          {user.name}
        </Typography>
        <Typography variant="body2" sx={{color: "var(--nav-item-color)", fontSize: "16px"}}>
          {user.ownerEmail || user.email}
        </Typography>
      </Box>
    </Stack>
      <AccountPopover
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </>
  );
};
