import { useState } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const { Accordion, alpha, AccordionSummary, Typography, AccordionDetails, Badge } = require("@mui/material");

const Wrapper = (props) => {
    const [expanded, setExpanded] = useState(false);
  
    const handleChange = () => {
      setExpanded(!expanded);
    };
  
    return (
      <Accordion
        expanded={expanded}
        onChange={handleChange}
        sx={{
          borderRadius: props.variant === "h1" ? "8px" : "0",
          ...props.style,
          "& MuiPaper-root-MuiAccordion-root:last-of-type": {
            m: 1,
            my: props.variant === "h1" ? 1 : 0,
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
          },
          "&.Mui-expanded": {
            border:
              props.variant === "h2"
                ? `1px solid ${alpha("#5862ff", 0.1)}`
                : "auto",
            margin: props.variant === "h1" ? "8px 0" : 0,
          },
          "&:before": {
            //display: props.variant === "h2" ? "none" : "block",
            display: "none",
          },
          my: props.variant === "h1" ? 1 : 0,
          // border:
          //   props.variant === "h1"
          //     ? `1px solid ${alpha("#6C737F", 0.3)}`
          //     : "none",
          boxShadow:
            props.variant === "h1"
              ? `0px 0px 1px 1px ${alpha("#6C737F", 0.2)}`
              : "none",
          "& .MuiPaper-root-MuiAccordion-root .MuiSvgIcon-root": {
            color: "#28287B",
          },
          "& .MuiAccordionDetails-root": { pt: 0 },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            "&.Mui-expanded": { minHeight: "48px" },
            "& .MuiAccordionSummary-content.Mui-expanded": { my: "12px" },
            backgroundColor: props.variant === "h2" ? "#F5F2FF" : "auto",
            border: props.variant === "h2" ? "1px solid #ECE9F5" : "auto",
            mb: props.variant === "h2" && expanded ? 2 : "auto",
            ...props.style,
            "& .MuiSvgIcon-root": {
              color: expanded ? "#5761FE" : "#111927",
            },
          }}
        >
          <Typography
            sx={{
              width: "100%",
              fontSize: "14px",
              color: "#111927",
              fontWeight: "500",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Typography
              sx={{
                fontWeight:
                  props.variant === "h1"
                    ? "700"
                    : props.variant === "h2"
                      ? "600"
                      : "auto",
                fontSize:
                  props.variant === "h1"
                    ? "14px"
                    : props.variant === "h2"
                      ? "14px"
                      : "auto",
                color: expanded ? "#5761FE" : "#111927",
              }}
            >
              {props.label}
              {!!props.count && (
                <Badge
                 variant={ props.isDot && "dot"}
                  sx={{ ml: 2 }}
                  badgeContent={props.count}
                  color="primary"
                />
              )}
            </Typography>
  
            {props.isError && !expanded && (
              <ErrorOutlineIcon style={{ color: "red", fontSize: "16px" }} />
            )}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>{props.children}</AccordionDetails>
      </Accordion>
    );
  };

  export default Wrapper