import React, { useEffect, useState } from "react";
import SaveIcon from "src/components/logos/saveIcon.png";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  Box,
  Button,
  TextField,
  Modal,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Toolbar,
  Radio,
  CircularProgress,
  Pagination,
  IconButton,
  Stack,
} from "@mui/material";
import axios from "axios";
import { API_SERVICE_BACKEND } from "src/config";
import toast from "react-hot-toast";

const AibdrDetails = () => {
  const [titles, setTitles] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentTitle, setCurrentTitle] = useState({
    title: "",
    agentName: "",
    email: "",
    _id: null,
    saveAsDefault: false
  });
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const rowperPage = 15;
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [titleToDelete, setTitleToDelete] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [loading, setLoading] = useState(true);
  // Create debounced version of getBdrProfiles
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getBdrProfiles();
    }, 2000);

    // Cleanup function to clear timeout when component unmounts
    // or when searchTerm changes before the delay is finished
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchTerm, page]);

  const getBdrProfiles = async () => {
    setLoading(true); // Start loader
    try {
      const res = await axios.get(
        `${API_SERVICE_BACKEND}/get-bdr-profiles/${sessionStorage.getItem(
          "userEmail"
        )}?search=${encodeURIComponent(searchTerm)}&page=${page}&limit=${rowperPage}`
      );
      const modifiedData = res?.data?.data?.map((data) => ({
        _id: data?._id,
        email: data?.notificationEmail,
        title: data?.profileName,
        agentName: data?.senderName,
        saveAsDefault: data?.saveAsDefault
      }));
      setPage(res?.data?.metaData?.page)
      setCount(res?.data?.metaData?.count)
      setSelectedValue(res?.data?.data?.find(data => data.saveAsDefault)?._id)
      setTitles(modifiedData);
    } catch (error) {
      console.error(error?.message);
    } finally {
      setLoading(false); // Stop loader
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleOpenModal = () => {
    setCurrentTitle({ title: "", agentName: "", email: "", _id: null });
    setEditMode(false);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSave = async () => {
    const { email, agentName, title, _id } = currentTitle;
    try {
      if (editMode) {
        const res = await axios.put(
          `${API_SERVICE_BACKEND}/update-bdr-profile/${_id}`,
          {
            notificationEmail: email,
            profileName: title,
            senderName: agentName,
          }
        );
        setTitles((prevTitles) =>
          prevTitles.map((item) =>
            item._id === currentTitle._id
              ? {
                ...item,
                title: currentTitle.title,
                agentName: currentTitle.agentName,
                email: currentTitle.email,
              }
              : item
          )
        );
        toast.success(res?.data?.message, {
          duration: 3000
        });
      } else {
        const res = await axios.post(
          `${API_SERVICE_BACKEND}/generate-bdr-profile`,
          {
            clientEmail: sessionStorage.getItem("userEmail"),
            notificationEmail: email,
            profileName: title,
            senderName: agentName,
          }
        );
        setTitles((prevTitles) => [
          ...prevTitles,
          {
            title: currentTitle.title,
            agentName: currentTitle.agentName,
            email: currentTitle.email,
            _id: res?.data?.data?._id,
          },
        ]);
        toast.success(res?.data?.message, {
          duration: 3000
        });
      }
    } catch (error) {
      console.error(error?.message);
      toast.error(error?.message, {
        duration: 3000
      });
    } finally {
      handleCloseModal();
    }
  };

  const handleEdit = (title) => {
    setCurrentTitle(title);
    setEditMode(true);
    setModalOpen(true);
  };

  const handleSaveAsDefault = (id) => {
    // Logic to save the item as default can be implemented here
    console.log(`Saving item ${id} as default`);
  };

  const handleDeleteOpen = (id) => {
    setTitleToDelete(id);
    setDeleteModalOpen(true);
  };

  const handleDelete = async () => {
    try {
      const res = await axios.delete(
        `${API_SERVICE_BACKEND}/delete-bdr-profile/${titleToDelete}`
      );
      setTitles((prevTitles) =>
        prevTitles.filter((item) => item._id !== titleToDelete)
      );
      toast.success(res?.data?.message, {
        duration: 3000
      });
    } catch (error) {
      console.log(error?.message);
      toast.error(error?.message, {
        duration: 3000
      });
    } finally {
      setDeleteModalOpen(false);
    }
  };

  const handleRadioChange = async (event, id) => {
    event.stopPropagation(); // Prevent the click event from bubbling up to the TableRow
    try {
      const res = await axios.put(
        `${API_SERVICE_BACKEND}/update-bdr-profile/${id}`,
        {
          clientEmail: sessionStorage.getItem("userEmail"),
          saveAsDefault: true
        }
      );
      setSelectedValue(id); // Update the selected value
      toast.success("ai bdr saved as default", {
        duration: 3000
      });
    } catch (error) {
      console.log(error?.message)
      toast.error(error?.message, {
        duration: 3000
      });
    }
    finally {
      setSelectedValue(id); // Update the selected value
    }
  };
  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    return regex.test(email);
  };

  const handleDeleteClose = () => {
    setDeleteModalOpen(false);
    setTitleToDelete(null);
  };

  const isDisabled = !currentTitle?.title || !currentTitle?.agentName || !currentTitle?.email || !validateEmail(currentTitle?.email);

  return (
    <Box sx={{ padding: 2 }}>
      <Box position="static">
        <Toolbar>
          <TextField
            label="Search"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearch}
            sx={{ width: "300px", marginRight: 2 }} // Set width of the search input
          />
          <Button variant="contained" color="primary" sx={{ backgroundColor: "#5761FE !important", borderColor: "#5761FE !important" }} onClick={handleOpenModal}>
            Add
          </Button>
        </Toolbar>
      </Box>

      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Save As Default</TableCell>
              <TableCell>Profile</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody>
              <TableCell></TableCell>
              <TableCell>
                <CircularProgress />
              </TableCell>
              <TableCell></TableCell>
            </TableBody>
          ) : (
            titles?.length ? <TableBody>
              {titles?.map((item) => (
                <TableRow>
                  <TableCell>
                    <Radio
                      checked={selectedValue === item._id} // Control which radio button is selected
                      onChange={(e) => handleRadioChange(e, item._id)} // Handle radio button change
                      value={item._id}
                      name="radio-buttons"
                      inputProps={{ "aria-label": item.title }} // For accessibility
                    />
                  </TableCell>
                  <TableCell
                    key={item._id}
                  >
                    {item.title}
                  </TableCell>
                  <TableCell>
                    <Button variant="outlined" color="primary" sx={{ color: "#5761FE !important", borderColor: "#5761FE !important" }} onClick={() => handleEdit(item)}>
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent the click event from bubbling up to the TableRow
                        handleDeleteOpen(item._id); // Call your delete function
                      }}
                      sx={{ marginLeft: 1 }} // Adds some spacing
                      startIcon={<DeleteForeverIcon />}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody> :
              <TableBody>
                <TableCell></TableCell>
                <TableCell>
                  No Data found
                </TableCell>
                <TableCell></TableCell>
              </TableBody>
          )}

        </Table>
      </TableContainer>


      <Stack
        direction={"row"}
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{ my: 1 }}
      >
        <Pagination
          count={Math.ceil(count / rowperPage)}
          page={page}
          onChange={(event, page) => setPage(page)}
          showFirstButton
          showLastButton
          variant="outlined"
          shape="rounded"
          size="large"
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        />
      </Stack>

      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            width: 500,
            height: 330,
            bgcolor: "white",
            borderRadius: 2,
            boxShadow: 24,
            padding: 3,
            margin: "auto",
            marginTop: "10%",
          }}
        >
          <Typography variant="h6">
            {editMode ? "Edit Details" : "Add Details"}
          </Typography>

          {/* Title Input */}
          <TextField
            label="Profile Name"
            variant="outlined"
            fullWidth
            value={currentTitle.title}
            onChange={(e) =>
              setCurrentTitle({ ...currentTitle, title: e.target.value })
            }
            sx={{ marginTop: 2 }}
          />

          {/* SalesGPT Agent Name Input */}
          <TextField
            label="SalesGPT Agent Name"
            variant="outlined"
            fullWidth
            value={currentTitle.agentName}
            onChange={(e) =>
              setCurrentTitle({ ...currentTitle, agentName: e.target.value })
            }
            sx={{ marginTop: 2 }}
          />

          {/* Notification Email Input */}
          <TextField
            label="Notification Email"
            variant="outlined"
            fullWidth
            value={currentTitle.email}
            onChange={(e) =>
              setCurrentTitle({ ...currentTitle, email: e.target.value })
            }
            sx={{ marginTop: 2 }}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={isDisabled}
            sx={{
              marginTop: 2,
              marginRight: 2,
              backgroundColor: isDisabled ? "#CCCCCC" : "#5761FE",
              borderColor: isDisabled ? "#CCCCCC" : "#5761FE",
              '&:hover': {
                backgroundColor: isDisabled ? "#CCCCCC" : "#4a54e1", // Optional hover color when enabled
              },
            }}
            // sx={{ marginTop: 2, marginRight: 2, backgroundColor:"#5761FE !important" , borderColor:"#5761FE !important" }}
            startIcon={<img src={SaveIcon} />}
          >
            Save
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={handleCloseModal}
            sx={{ marginTop: 2, backgroundColor: "#5761FE !important", borderColor: "#5761FE !important" }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>

      {/* Confirmation Modal for Deletion */}
      <Modal open={deleteModalOpen} onClose={handleDeleteClose}>
        <Box
          sx={{
            width: 400,
            bgcolor: "white",
            borderRadius: 2,
            boxShadow: 24,
            padding: 3,
            margin: "auto",
            marginTop: "20%",
            textAlign: "center",
          }}
        >
          <Typography variant="h6">
            Are you sure you want to delete this item?
          </Typography>
          <Box sx={{ marginTop: 2 }}>
            <Button variant="contained" color="error" onClick={handleDelete}>
              Delete
            </Button>
            <Button
              variant="outlined"
              onClick={handleDeleteClose}
              sx={{ marginLeft: 2 }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default AibdrDetails;
