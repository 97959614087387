import { CheckCircleIcon } from "src/assets/general/CheckCircleIcon";
import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
const Features = ({
  featureList,
  plan,
  data,
  setAddPrice,
  priceid,
  inputVisible,
  details,
  billingMonthly,
  subscriptionData,
  additionalData
}) => {
  console.log(subscriptionData, '===subscriptionData')
  const [showInputBox, setShowInputBox] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  const numberFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal'
  });

  const generatePlanFeature = (plan) => {
    let leadsLimit,
      emailsLimit,
      agents,
      agentsText,
      leadsText,
      emailsText,
      boostCost;
    switch (plan) {
      case "starter":
        leadsLimit = data ? data.Leads : "5,500";
        emailsLimit = data ? data.Emails : "24,000";
        agents = data ? data.Agents : "3";
        agentsText = "Triple the hustle, triple the results";
        leadsText = "Dive into a sea of opportunities";
        emailsText = "More touchpoints, more conversions";
        boostCost = additionalData ? additionalData?.unit_amount / 100 : "599";

        break;
      case "Growth Package":
        leadsLimit = data ? data.Leads : "9,000";
        emailsLimit = data ? data.Emails : "40,000";
        agents = data ? data.Agents : "5";
        agentsText = "The dream team of outreach";
        leadsText = "Potential at every corner";
        emailsText = "Make every email count";
        boostCost = additionalData ? additionalData?.unit_amount / 100 : "473";
        break;
      case "Scale Package":
        leadsLimit = data ? data.Leads : "18,000";
        emailsLimit = data ? data.Emails : "80,000";
        agents = data ? data.Agents : "10";
        agentsText = "The sales army";
        leadsText = "Conquer every opportunity";
        emailsText = "Emails that don't just talk, they convert";
        boostCost = additionalData ? additionalData?.unit_amount / 100 : "420";
        break;
      default:
        leadsLimit = "0";
        emailsLimit = "0";
        agents = "0";
        agentsText = "The sales army";
        leadsText = "Conquer every opportunity";
        emailsText = "Emails that don't just talk, they convert";
        boostCost = "0";
        break;
    }
    return [
      {
        icon: <CheckCircleIcon />,
        subTitle: `${agents} Agents: `,
        text: agentsText,
      },
      {
        icon: <CheckCircleIcon />,
        subTitle: `${numberFormatter?.format(leadsLimit)}+/est. New Leads Monthly: `,
        text: leadsText,
      },
      {
        icon: <CheckCircleIcon />,
        subTitle: `${numberFormatter?.format(emailsLimit)}/est. Total Emails Monthly: `,
        text: emailsText,
      },
      {
        icon: <CheckCircleIcon />,
        subTitle: `Boost with Extra Agents: `,
        text: `${currencyFormatter?.format(boostCost)} to add-on any additional agents`,
      },
    ];
  };
  const generateTitleText = (plan) => {
    switch (plan) {
      case "starter":
        return "Jumpstart Your Sales Rocket:";
      case "Growth Package":
        return "Turbocharge Your Brand:";
      case "Scale Package":
        return "Total Market Dominance:";
      default:
        return "Includes";
    }
  };

  useEffect(() => {
    setShowInputBox(false);
    setInputValue("");
  }, [billingMonthly]);

  // const featureList = generatePlanFeature(plan);
  const titleText = generateTitleText(plan);
  return (
    <Box>
      <Typography
        sx={{
          fontWeight: "500",
          fontSize: "14px",
          lineHeight: "24px",
          color: "var(--text-grey, #55597A)",
          fontFamily: "DM Sans",
        }}
      >
        {titleText}
      </Typography>
      <Box sx={{ mt: 1 }}>
        {featureList.length && featureList.map((feature, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 2,
              py: 0.5,
            }}
          >
            <Box><CheckCircleIcon /></Box>

            <Typography
              sx={{
                color: "#28287B",
                fontSize: "13px",
                fontWeight: "500",
                fontFamily: "DM Sans",
              }}
            >
              <strong>{feature?.name}</strong>
            </Typography>
          </Box>
        ))}
        {subscriptionData &&
          subscriptionData?.additionalQuentity &&
          subscriptionData?.additionalQuentity !== "0" && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 2,
                py: 1,
              }}
            >
              <Box>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="24" height="24" rx="12" fill="#00AA38"></rect>
                  <path
                    d="M16 9L10.5 14.5L8 12"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </Box>

              <Typography
                sx={{
                  color: "#28287B",
                  fontSize: "13px",
                  fontWeight: "500",
                  fontFamily: "DM Sans",
                }}
              >
                <strong>
                  {subscriptionData?.additionalQuentity +
                    " " +
                    "Additional Agents"}
                </strong>
              </Typography>
            </Box>
          )}
      </Box>
    </Box>
  );
};
export default Features;
