import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import CreditCard01Icon from "@untitled-ui/icons-react/build/esm/CreditCard01";
import Settings04Icon from "@untitled-ui/icons-react/build/esm/Settings04";
import User03Icon from "@untitled-ui/icons-react/build/esm/User03";
import {
  Box,
  Button,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  SvgIcon,
  Typography,
} from "@mui/material";
import { RouterLink } from "src/components/router-link";
import { useAuth } from "src/hooks/use-auth";
import { useMockedUser } from "src/hooks/use-mocked-user";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import { Issuer } from "src/utils/auth";
import { API_ADMIN_SERVICE_BACKEND, API_SERVICE_BACKEND } from "src/config";
import axios from "axios";
import {
  clearLocalStorage,
  clearSessionStorage,
  getSessionStorage,
} from "src/utils/storage";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import LogOut01Icon from "@untitled-ui/icons-react/build/esm/LogOut01";

export const AccountPopover = (props) => {
  const { anchorEl, onClose, open, ...other } = props;
  const router = useRouter();
  const auth = useAuth();
  // const [popoverOpen, setPopoverOpen] = useState(false);
  // const [anchor, setAnchorEl] = useState(null);
  const [userData, setUserData] = useState([]);
  const [userDat, setUserDat] = useState([]);
  const user = {
    name: getSessionStorage("fullName"),
    email: getSessionStorage("userEmail"),
    ownerEmail: getSessionStorage("ownerEmail") || null,
  };

  const handleLogout = useCallback(async () => {
    clearLocalStorage();
    clearSessionStorage();
    if (window.Intercom) {
      window.Intercom("shutdown");
      window.intercomSettings = {};
    }
    try {
      onClose?.();
      clearLocalStorage();
      switch (auth.issuer) {
        case Issuer.Amplify: {
          await auth.signOut();
          break;
        }
        case Issuer.Auth0: {
          await auth.logout();
          break;
        }

        case Issuer.Firebase: {
          await auth.signOut();

          break;
        }
        case Issuer.JWT: {
          await auth.signOut();
          break;
        }

        default: {
          console.warn("Using an unknown Auth Issuer, did not log out");
        }
      }

      router.push(paths.index);
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong!");
    }
    if (window.Intercom) {
      window.Intercom("boot", {
        app_id: "qaecvjwi",
      });
    }
  }, [auth, router, onClose]);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [anchor, setAnchorEl] = useState(null);
  const [orgId, setOrgId] = useState("");
  const handleAccount = (event) => {
    setPopoverOpen(true);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setPopoverOpen(false);
  };
  const id = popoverOpen ? "popover-button" : undefined;

  // const handleClick = (event) => {
  //   event.preventDefault();
  //   console.log("🚀 ~ event-->", event.currentTarget)
  //   setPopoverOpen(true);
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setPopoverOpen(false);
  // };
  // const id = popoverOpen ? 'popover-button' : undefined;

  const getUserInfo = async (email) => {
    try {
      const res = await axios.get(
        `${API_SERVICE_BACKEND}/getuserdetail/${email}`
      );
      if (!res.data.status) return;

      setUserData(res.data.data.organizationList);
      setOrgId(res.data.data.organization_id);
      return res.data.data;
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    if (user?.email) {
      getUserInfo(user.email);
    }
  }, []);

  const generateJWTToken = async (email, invitationTo) => {
    let payload = {
      email: email,
      userEmail: invitationTo,
    };
    console.log("payload", payload);
    try {
      const res = await axios.post(
        `${API_ADMIN_SERVICE_BACKEND}/getJWTAuthToken`,
        {
          payload,
        }
      );

      return res.data.token;
    } catch (err) {
      console.log(err);
      alert(`Generation token failed!`);
    }
  };

  const getUserDetails = async (email, orgId) => {
    try {
      const response = await fetch(
        `${API_SERVICE_BACKEND}/getusercampagindetails/${email}/${orgId}`
      );
      const data = await response.json();

      if (data.data === "No Data Found") {
        console.log(data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleManageUser = async (email, organization_id, invitationTo) => {
    getUserDetails(email, organization_id);

    let token = await generateJWTToken(email, invitationTo);

    console.log("token --------------------", token);
    window.open(`https://app.b2brocket.ai/auth-main/login/v2?token=${token}`);
    //window.open(`http://localhost:3000/auth-main/login/v2?token=${token}`);
    // window.location.href = `http://localhost:3001/auth-main/login/v2?token=${token}`;
  };
  function handleAuxClick(event) {
    if (event.button === 1) {
      event.preventDefault(); // Prevent the default behavior of opening links in new tabs
    } else if (event.metaKey) {
      event.preventDefault(); // Prevent the default behavior if Command key is pressed
    } else {
      onClose();
    }
  }
  const getUserDetailsMember = async () => {
    await axios
      .get(
        // `${API_SERVICE_BACKEND}/getuserdetail/${user.email}`
        `${API_SERVICE_BACKEND}/list-inviteTo/${user.email}`
      )
      .then((res) => {
        // setUserData(res.data?.data?.invites);
        console.log("res====>", res);
        setUserDat(res.data?.data);
      })
      .catch((err) => {
        console.log("🚀 ~ err-->", err);
      });
  };
  useEffect(() => {
    getUserDetailsMember();
  }, []);

  console.log("userDat====>", userDat);
  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 250 } }}
      {...other}
    >
      {/* <Box sx={{ p: 2 }}>
        <Typography variant="body1">{user.name}</Typography>
        <Typography color="text.secondary" variant="body2">
          {user?.ownerEmail ? user?.ownerEmail : user.email}
        </Typography>
      </Box>
      <Divider /> */}
      {!user?.ownerEmail && (
        <Box sx={{ p: 1 }}>
          <ListItemButton
            onClick={handleAccount}
            sx={{
              borderRadius: 1,
              px: 1,
              py: 0.5,
            }}
          >
            <ListItemIcon>
              <SvgIcon fontSize="small">
                <SwitchAccountIcon />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="body1">Switch Account</Typography>}
            />
          </ListItemButton>
        </Box>
      )}
      <Divider />
      <Popover
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        disableScrollLock
        // PaperProps={{ sx: { width: 300 } }}
        id={id}
        open={popoverOpen}
        anchorEl={anchor}
        onClose={handleClose}
      >
        <Box sx={{ p: 2 }}>
          {userDat && userDat.length ? (
            userDat?.map((data) => (
              <Box sx={{ p: 1 }}>
                <Typography
                  color="text.secondary"
                  sx={{ cursor: "pointer" }}
                  onClick={() =>
                    handleManageUser(data.inviteBy, orgId, data.invitationTo)
                  }
                >
                  {data.inviteByCompanyName}
                </Typography>
              </Box>
            ))
          ) : (
            <Box sx={{ p: 1 }}>
              <Typography>Not in any organization</Typography>
            </Box>
          )}
          {/* <Typography color="text.secondary">widgiwdgw</Typography> */}
        </Box>
      </Popover>

      {/* <Box sx={{ p: 2 }}> */}
      {/* <Typography >User List</Typography> */}
      {/* <Typography color="text.secondary">widgiwdgw</Typography> */}
      {/* {userData?.map((email)=> (
                <Box>
                  <Button color="text.secondary"
                  //  onClick={handleManageUser}
                   >
                    {email}
                    </Button>
                </Box>
              ))} */}
      {/* </Box> */}
      {/* <Box sx={{ p: 1 }}>
        <ListItemButton
          component={RouterLink}
          href={paths.dashboard.account}
          // onClick={onClose}
          onClick={handleAuxClick}
          onAuxClick={handleAuxClick}
          sx={{
            borderRadius: 1,
            px: 1,
            py: 0.5,
          }}
        >
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <Settings04Icon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">Settings</Typography>}
          />
        </ListItemButton>
        <ListItemButton
          component={RouterLink}
          href={paths.dashboard.index}
          onClick={onClose}
          sx={{
            borderRadius: 1,
            px: 1,
            py: 0.5
          }}
        >
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <CreditCard01Icon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={(
              <Typography variant="body1">
                Billing
              </Typography>
            )}
          />
        </ListItemButton>
      </Box> */}
      {/* <Divider sx={{ my: "0 !important" }} /> */}
      <Box
        sx={{
          display: "flex",
          p: 1,
          justifyContent: "center",
        }}
      >
        {/* <Button color="inherit" onClick={handleLogout} size="small">
          Log out
        </Button> */}
        <ListItemButton
          onClick={handleLogout}
          sx={{
            borderRadius: 1,
            px: 1,
            py: 0.5,
          }}
        >
          <ListItemIcon>
            <SvgIcon fontSize="small">
            <LogOut01Icon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">Log Out</Typography>}
          />
        </ListItemButton>
      </Box>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
