import { useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { CloseTwoTone, CloudUploadTwoTone, TaskAlt } from "@mui/icons-material";
import LinearProgress from "@mui/material/LinearProgress";
import { toast } from "react-hot-toast";
import AWS from "aws-sdk";
import { UploadColoredIcon } from "src/components/logos/UploadColoredIcon";
import Papa from "papaparse";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const selections = [
  { label: "Do not import", value: "doNotImport" },
  { label: "First Name", value: "firstName" },
  { label: "Last Name", value: "lastName" },
  { label: "Email", value: "email" },
  { label: "Title", value: "title" },
  //  { label: "Profile Picture", value: "profile_pic" },
  { label: "Description", value: "description" },
  { label: "LinkedIn URL", value: "linkedin_url" },
  { label: "Company", value: "organization" },
  { label: "Website Domains", value: "website_domains" },
  { label: "Industry", value: "industry" },
  { label: "Address", value: "address" },
  { label: "City", value: "city" },
  { label: "State", value: "state" },
  { label: "Country", value: "country" },
  { label: "Company Founded Year", value: "org_founded_year" },
  { label: "Number of Employees", value: "org_num_employees" },
  { label: "Company Description", value: "org_description" },
];

const companyLabels = [
  { label: "Company Name", value: "organization" },
  { label: "Company Name for Emails", value: "organization" },
  { label: "Organisation", value: "organization" },
  { label: "Organisation Name", value: "organization" },
];

const FileUploadRow = ({
  column,
  sample,
  selections,
  onChange,
  isUploading,
}) => {
  const combinedLabels = [...selections, ...companyLabels];
  const initialSelected = combinedLabels.find((s) => s.label === column) || selections[0];
  const [selected, setSelected] = useState(initialSelected);

  const handleChange = (event) => {
    const selection = selections.find((s) => s.value === event.target.value);
    setSelected(selection);
    onChange(selection);
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 240,
      },
    },
  };

  return (
    <TableRow>
      <TableCell>{column}</TableCell>
      <TableCell width={240}>
        <FormControl fullWidth size="small" sx={{ maxHeight: "300px" }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selected.value}
            onChange={handleChange}
            variant="outlined"
            MenuProps={MenuProps}
            disabled={isUploading}
          >
            {selections.map((selection, index) => (
              <MenuItem value={selection.value} key={index}>
                {selection.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>{sample}</TableCell>
    </TableRow>
  );
};

const CsvImport = ({
  tempCsvFiles,
  setTempCsvFiles,
  setIsImportLeadsDialogOpen,
  onChange,
  fields,
  selectedFile,
  setSelectedFile,
  fileInputKey,
  leadSource,
  setLeadSource,
  campaignId,
}) => {
  const fileInputRef = useRef(null);
  const [isDragActive, setIsDragActive] = useState(false);
  const [closeButtonMouseEnter, setCloseButtonMouseEnter] = useState(false);
  const [samples, setSamples] = useState([]);
  const [fileColumns, setFileColumns] = useState([]);
  const [columnMappings, setColumnMappings] = useState({});
  const [progress, setProgress] = useState(0);
  const [csvData, setCsvData] = useState([]);
  const [leads, setLeads] = useState([]);

  const [isUploading, setIsUploading] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragActive(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragActive(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragActive(false);

    const file = e.dataTransfer.files[0];
    handleFileUpload(file);
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    handleFileUpload(file);
  };

  // const updateLeads = (data) => {
  //   const updatedLeads = data.map((row) => {
  //     const lead = {};
  //     row.forEach(
  //       (cell) =>
  //         cell.type !== "doNotImport" && (lead[cell.type] = cell.value)
  //     );
  //     return lead;
  //   });
  //   setLeads(updatedLeads);
  // };

  const handleSelectTypeChange = (column, selection) => {
    // const updatedCsvData = csvData.map((row) =>
    //   row.map((cell) => {
    //     if (cell.column === column) return { ...cell, type: selection.value };
    //     return cell;
    //   })
    // );

    // setCsvData(updatedCsvData);
    // updateLeads(updatedCsvData);
    setColumnMappings({
      ...columnMappings,
      [column]: selection.value,
    });
  };

  const handleFileUpload = (file) => {
    setProgress(0);
    if (file) {
      const allowedTypes = ["text/csv"];

      if (allowedTypes.includes(file.type)) {
        const reader = new FileReader();

        reader.onprogress = (event) => {
          const { total, loaded } = event;
          setProgress((loaded / total) * 100);
        };

        reader.onload = (event) => {
          const csv = event.target.result;
          const lines = csv.split(/\r?\n/);
          if (lines?.length - 1 > 50000) {
            alert("Cannot import more than 50,000 leads!");
            const input = document.getElementById('import-leads-input');
            input.value = null;
            setSelectedFile(null)
            return;
          }
          setSelectedFile(file);
          const columns = lines[0].split(",");
          const combinedLabels = [...selections, ...companyLabels];
          const columnMapping = columns.reduce((accumulator, header) => {
            const mappedField =
            combinedLabels.find((option) => option.label === header)?.value ||
              "doNotImport";
            accumulator[header] = mappedField;
            return accumulator;
          }, {});
          setFileColumns(columns);
          setColumnMappings(columnMapping);
          let isSampleSet = false;

          const csvData = [];
          for (let i = 1; i < lines.length; i++) {
            const line = lines[i].split(",");
            if (!line.some(Boolean)) continue;

            if (!isSampleSet) {
              setSamples(line);
              isSampleSet = true;
            }

            //  const row = [];
            //  for (let j = 0; j < line.length; j++) {
            //    const type =
            //      selections.find((s) => s.label === columns[j])?.value ||
            //      selections[0].value;

            //    if (!line[j]) continue;

            //    row.push({
            //      type,
            //      column: columns[j],
            //      value: line[j],
            //    });
            //  }
            //  csvData.push(row);
          }

          const data = Papa.parse(csv, {
            header: true,
          });
          // setCsvData(csvData);
          // updateLeads(csvData);
          setCsvData(data.data);
        };

        reader.readAsText(file);
      } else {
        alert("Only .csv files are allowed.");
      }
    }
  };

  const handleDeleteFile = () => {
    fileInputRef.current.value = "";
    setSelectedFile(null);
    setCloseButtonMouseEnter(false);
    setProgress(0);
    setFileColumns([]);
    setColumnMappings({});
    setCsvData([]);
    setLeads([]);
  };

  const handleMappingsSubmit = () => {
    console.log("Column mappings:", columnMappings);
    handleFileUploadAll();
  };

  AWS.config.update({
    region: "us-east-1",
    accessKeyId: process.env.REACT_APP_WASABI_ID,
    secretAccessKey: process.env.REACT_APP_WASABI_ACCESS,
  });

  const s3 = new AWS.S3({
    endpoint: "s3.wasabisys.com",
    s3ForcePathStyle: true,
    signatureVersion: "v4",
  });

  const uploadToS3 = async (file, fileName) => {
    const uploadParams = {
      Bucket: "b2b-rocket",
      Key: `UploadedLeads/${fileName}`,
      Body: file,
    };

    return new Promise((resolve, reject) => {
      s3.upload(uploadParams, (err, data) => {
        if (err) {
          console.error("Upload failed:", err);
          reject(err);
        } else {
          console.log("Upload Success:", data);
          resolve(data.Location);
        }
      });
    });
  };

  const getS3SignedUrl = async (key) => {
    const params = {
      Bucket: "b2b-rocket",
      Key: `UploadedLeads/${key}`,
      Expires: 60 * 60 * 24 * 7,
      // Expires: 60 * 5
    };

    return new Promise((resolve, reject) => {
      s3.getSignedUrl("getObject", params, (err, url) => {
        if (err) {
          reject(err);
        } else {
          resolve(url);
        }
      });
    });
  };

  const getKeyByValue = (object, value) => {
    return object.find((el) => el.value === value)?.label;
  };

  const mapLeadData = (unmapped, mappingObj) => {
    const ref = unmapped;
    const mapped = {};
    // Object.keys(mappingObj).forEach((mapping,index)=>{
    //   if(mappingObj[mapping]==='doNotImport'){
    //     delete mapped[mapping];
    //   }
    //   else{
    //     const keyToBeMapped = getKeyByValue(selections, mappingObj[mapping]);
    //     const isKeyExistsInCsv = fileColumns.includes(keyToBeMapped);
    //     if(isKeyExistsInCsv){
    //       mapped[mapping] = ref[keyToBeMapped]
    //     }
    //     else{
    //       mapped[mapping] = '-'
    //     }
    //   }
    // })
    Object.keys(mappingObj).forEach((mapping, index) => {
      if (mappingObj[mapping] !== "doNotImport") {
        const keyToBeMapped = getKeyByValue(selections, mappingObj[mapping]);
        const valueToBeMapped = ref[mapping];
        mapped[keyToBeMapped] = valueToBeMapped;
      }
    });
    return mapped;
  };

  const handleFileUploadAll = async () => {
    if (!selectedFile) {
      console.log("No file selected.");
      return;
    }
    try {
      setIsUploading(true);

      const now = new Date().toISOString();
      const newName = `${campaignId}_${now}_${selectedFile.name}`;

      const uploadUrl = await uploadToS3(selectedFile, newName);
      console.log("File uploaded:", uploadUrl);
      const signedUrl = await getS3SignedUrl(newName);
      console.log(fileColumns);
      console.log("Signed URL:", signedUrl);
      const mappedCsvData = csvData
        .slice(0, 25)
        .map((data) => mapLeadData(data, columnMappings));
      const columnsToBeImported = Object.keys(columnMappings).filter(
        (mapping) => columnMappings[mapping] !== "doNotImport"
      );
      const dataObj = {
        data: columnsToBeImported.length > 0 ? mappedCsvData : [],
        total: columnsToBeImported.length > 0 ? csvData.length : 0,
      };

      console.log("fields: ", fields);

      let uploadedLeads = [
        {
          url: signedUrl,
          processed: false,
          mappings: columnMappings,
          result: {
            totalLeads: "",
            savedLeads: "",
            failedLeads: "",
            duplicates: "",
          },
          preview: dataObj,
        },
      ];

      if (fields?.uploadedLeads) {
        uploadedLeads.push(...fields?.uploadedLeads);
      }

      setTempCsvFiles([...tempCsvFiles, signedUrl]);
      onChange("uploadedLeads", uploadedLeads);
      setIsUploading(false);
      setIsImportLeadsDialogOpen(false);
      toast.success("Successfully uploaded!");
    } catch (error) {
      setIsUploading(false);
      console.error("Error during file upload:", error);
      toast.success("Something went wrong!");
    }
  };
  const isValid = ["firstName", "email", "organization"].every((key) =>
    Object.keys(columnMappings).some((column) => columnMappings[column] === key)
  );

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Paper
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        sx={{
          width: "100%",
          height: "260px",
          borderRadius: "12px",
          border: "1px dashed #0071F6",
          mt: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          position: "relative",
          boxShadow: "0px 12px 15px 0px #4B71970D",
          backgroundColor: "#F2F4F6",
        }}
        onClick={() => {
          !closeButtonMouseEnter && fileInputRef.current.click();
        }}
      >
        <input
          key={fileInputKey}
          type="file"
          // multiple
          id="import-leads-input"
          style={{ display: "none" }}
          onChange={handleFileInputChange}
          ref={fileInputRef}
          accept=".csv"
          disabled={isUploading}
        />

        {selectedFile ? (
          <>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 700,
                lineHeight: "25px",
                color: "#0071F6",
              }}
            >
              Size: {(selectedFile.size / 1000).toFixed(2)} KB
            </Typography>
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <UploadColoredIcon />
          </Box>
        )}

        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "25px",
            color: "#0071F6",
          }}
        >
          {selectedFile
            ? selectedFile.name
            : isDragActive
            ? "Drop the files here ..."
            : "Drag files to this area or click to pick files."}
        </Typography>
        {selectedFile && (
          <IconButton
            onMouseEnter={() => {
              setCloseButtonMouseEnter(true);
            }}
            onMouseLeave={() => {
              setCloseButtonMouseEnter(false);
            }}
            sx={{ position: "absolute", right: 0, top: 0, m: 1 }}
            onClick={handleDeleteFile}
            disabled={isUploading}
          >
            <CloseTwoTone sx={{ color: "black" }} />
          </IconButton>
        )}
        {selectedFile && (
          <Box
            sx={{ width: "80%", mt: 2, display: progress === 100 && "none" }}
          >
            <LinearProgress variant="determinate" value={progress} />
          </Box>
        )}
      </Paper>
      {selectedFile && progress === 100 && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              mt: 2,
            }}
          >
            <TaskAlt sx={{ color: "rgb(33, 111, 237)", mr: 1 }} />
            <Typography sx={{ fontWeight: 600, color: "rgb(33, 111, 237)" }}>
              Files Processed
            </Typography>
          </Box>
          <Typography
            sx={{
              my: 2,
              p: 1,
              border: "1px solid red",
              borderRadius: 1,
              fontSize: "14px",
            }}
          >
            *Mandatory Keys to be selected: <b>First Name, Email, Company</b>
          </Typography>
          <TableContainer sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width={360}>CSV Headers</TableCell>
                  <TableCell width={360}>Map to</TableCell>
                  <TableCell>Samples</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fileColumns.map((column, index) => (
                  <FileUploadRow
                    key={index}
                    column={column}
                    sample={samples[index]}
                    selections={selections}
                    onChange={(s) => handleSelectTypeChange(column, s)}
                    isUploading={isUploading}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            variant="contained"
            sx={{
              fontSize: "16px",
              px: 3,
              py: 1.5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 1,
            }}
            onClick={handleMappingsSubmit}
            disabled={!isValid}
          >
            {isUploading ? (
              <CircularProgress size={20} sx={{ color: "white", mr: 1 }} />
            ) : (
              <CloudUploadTwoTone sx={{ mr: 1 }} />
            )}
            Submit Mappings
          </Button>
        </>
      )}
    </Box>
  );
};

export default CsvImport;
