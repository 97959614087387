export const INDUSTRY_OPTIONS = [
  // "Accounting",
  // "Airlines/Aviation",
  // "Agriculture",
  // "Alternative Dispute Resolution",
  // "Alternative Medicine",
  // "Animation",
  // "Apparel/Fashion",
  // "Architecture/Planning",
  // "Arts/Crafts",
  // "Automotive",
  // "Aviation/Aerospace",
  // "Banking/Mortgage",
  // "Biotechnology/Greentech",
  // "Broadcast Media",
  // "Building Materials",
  // "Business Supplies/Equipment",
  // "Capital Markets/Hedge Fund/Private Equity",
  // "Chemicals",
  // "Civic/Social Organization",
  // "Civil Engineering",
  // "Commercial Real Estate",
  // "Computer Games",
  // "Computer Hardware",
  // "Computer Networking",
  // "Computer Software/Engineering",
  // "Computer/Network Security",
  // "Construction",
  // "Consumer Electronics",
  // "Consumer Goods",
  // "Consumer Services",
  // "Cosmetics",
  // "Dairy",
  // "Defense/Space",
  // "Design",
  // "E-Learning",
  // "Education Management",
  // "Electrical/Electronic Manufacturing",
  // "Entertainment/Movie Production",
  // "Environmental Services",
  // "Events Services",
  // "Executive Office",
  // "Facilities Services",
  // "Farming",
  // "Financial Services",
  // "Fine Art",
  // "Fishery",
  // "Food Production",
  // "Food/Beverages",
  // "Fundraising",
  // "Furniture",
  // "Gambling/Casinos",
  // "Glass/Ceramics/Concrete",
  // "Government Administration",
  // "Government Relations",
  // "Graphic Design/Web Design",
  // "Health/Fitness",
  // "Higher Education/Acadamia",
  // "Hospital/Health Care",
  // "Hospitality",
  // "Human Resources/HR",
  // "Import/Export",
  // "Individual/Family Services",
  // "Industrial Automation",
  // "Information Services",
  // "Appointment Scheduling/IT",
  // "Insurance",
  // "International Affairs",
  // "International Trade/Development",
  // "Internet",
  // "Investment Banking/Venture",
  // "Investment Management/Hedge Fund/Private Equity",
  // "Judiciary",
  // "Law Enforcement",
  // "Law Practice/Law Firms",
  // "Legal Services",
  // "Legislative Office",
  // "Leisure/Travel",
  // "Library",
  // "Logistics/Procurement",
  // "Luxury Goods/Jewelry",
  // "Machinery",
  // "Management Consulting",
  // "Maritime",
  // "Market Research",
  // "Marketing/Advertising/Sales",
  // "Mechanical or Industrial Engineering",
  // "Media Production",
  // "Medical Equipment",
  // "Medical Practice",
  // "Mental Health Care",
  // "Military Industry",
  // "Mining/Metals",
  // "Motion Pictures/Film",
  // "Museums/Institutions",
  // "Music",
  // "Nanotechnology",
  // "Newspapers/Journalism",
  // "Non-Profit/Volunteering",
  // "Oil/Energy/Solar/Greentech",
  // "Online Publishing",
  // "Other Industry",
  // "Outsourcing/Offshoring",
  // "Package/Freight Delivery",
  // "Packaging/Containers",
  // "Paper/Forest Products",
  // "Performing Arts",
  // "Pharmaceuticals",
  // "Philanthropy",
  // "Photography",
  // "Plastics",
  // "Political Organization",
  // "Primary/Secondary Education",
  // "Printing",
  // "Professional Training",
  // "Program Development",
  // "Public Relations/PR",
  // "Public Safety",
  // "Publishing Industry",
  // "Railroad Manufacture",
  // "Ranching",
  // "Real Estate/Mortgage",
  // "Recreational Facilities/Services",
  // "Religious Institutions",
  // "Renewables/Environment",
  // "Research Industry",
  // "Restaurants",
  // "Retail Industry",
  // "Security/Investigations",
  // "Semiconductors",
  // "Shipbuilding",
  // "Sporting Goods",
  // "Sports",
  // "Staffing/Recruiting",
  // "Supermarkets",
  // "Telecommunications",
  // "Textiles",
  // "Think Tanks",
  // "Tobacco",
  // "Translation/Localization",
  // "Transportation",
  // "Utilities",
  // "Venture Capital/VC",
  // "Veterinary",
  // "Warehousing",
  // "Wholesale",
  // "Wine/Spirits",
  // "Wireless",
  // "Writing/Editing",
  // "Online Media",
  "Agriculture",
  "Crops",
  "Farming Animals & Livestock",
  "Fishery & Aquaculture",
  "Ranching",
  "Accounting & Accounting Services",
  "Auctions",
  "Business Services - General",
  "Call Centers & Business Centers",
  "Career Planning",
  "Career",
  "Commercial Printing",
  "Debt Collection",
  "Design",
  "Event Services",
  "Facilities Management & Services",
  "Food Service",
  "Fraud Detection",
  "Geography & Positioning",
  "Human Resources & Staffing",
  "Information Services",
  "Management Consulting",
  "Marketing & Advertising",
  "Multimedia & Graphic Design",
  "Outsourcing",
  "Sales",
  "Security & Investigations Products & Services",
  "Staffing & Recruiting",
  "Translation & Localization",
  "Writing & Editing",
  "Cities Towns & Municipalities - General",
  "Communities",
  "Local",
  "Parking",
  "Public Safety",
  "Architecture Engineering & Design",
  "Construction - General",
  "Mechanical Engineering",
  "Mechanical or Industrial Engineering",
  "Car & Truck Rental",
  "Child Care",
  "Consumer Services - General",
  "Funeral Homes & Funeral Related Services",
  "Hair Salons",
  "Laundry & Dry Cleaning Services",
  "Photography",
  "Privacy",
  "Travel Agencies & Services",
  "Veterinary Care",
  "Wedding",
  "Cultural - General",
  "Fine Arts",
  "Libraries",
  "Museums & Art Galleries",
  "Performing Arts",
  "Colleges & Universities",
  "E-learning",
  "Education - General",
  "Higher Education",
  "K-12 Schools",
  "Professional Training & Coaching",
  "Training",
  "Electricity & Energy",
  "Environmental Services",
  "Forestry",
  "Natural Resources",
  "Nuclear",
  "Oil & Gas Exploration & Services",
  "Renewables & Environment",
  "Utilities",
  "Water Energy & Waste Treatment",
  "Energy, Utilities & Waste Treatment - General",
  "Banking",
  "Credit Cards & Transaction Processing",
  "Finance - General",
  "Financial Services",
  "Investment Banking",
  "Venture Capital & Private Equity",
  "Corrections Facilities",
  "Government - General",
  "International Affairs",
  "Law Enforcement",
  "Law Firms & Legal Services",
  "Military",
  "Public Policy",
  "Biotechnology",
  "Clinical Trials",
  "Drug Manufacturing & Research",
  "Drug Stores & Pharmacies",
  "Emergency Medical Transportation & Services",
  "Hospitals & Healthcare",
  "Medical Practice",
  "Medicine",
  "Mental Health Care",
  "Pharmaceuticals",
  "Psychology",
  "Healthcare - General",
  "Amusement Parks Arcades & Attractions",
  "Cruise Lines",
  "Elder & Disabled Care",
  "Entertainment",
  "Fitness & Dance Facilities",
  "Gambling & Casinos",
  "Gaming",
  "Hospitality - General",
  "Hotels and Resorts",
  "Lodging & Resorts",
  "Movie Theaters",
  "Recreation",
  "Restaurants",
  "Sports",
  "Zoos & National Parks",
  "Aerospace & Defense",
  "Apparel & Fashion",
  "Appliances",
  "Automotive",
  "Boats & Submarines",
  "Building Materials",
  "Business Supplies & Equipment",
  "Chemicals",
  "Cleaning Products",
  "Computer Hardware",
  "Consumer Electronics",
  "Consumer Goods",
  "Cosmetics Beauty Supply & Personal Care Products",
  "Dairy",
  "Electronics & Electronics Manufacturing",
  "Flash Storage",
  "Food & Beverages",
  "Furniture",
  "Glass & Concrete",
  "Hand Power and Lawn-care Tools",
  "Health & Nutrition Products",
  "Health Wellness & Fitness",
  "Household Goods",
  "Industrial Machinery Equipment & Automation",
  "Lumber & Wood Production",
  "Luxury Goods & Jewelry",
  "Manufacturing - General",
  "Maritime",
  "Medical Devices & Equipment",
  "Miscellaneous Building Materials",
  "Network Security Hardware & Software",
  "Office Products",
  "Paper & Forest Products",
  "Personal Computers & Peripherals",
  "Pet Products",
  "Petrochemicals",
  "Plastics & Packaging & Containers",
  "Plumbing & HVAC Equipment",
  "Power Conversion & Protection Equipment",
  "Semiconductor & Semiconductor Equipment",
  "Shipbuilding",
  "Telecommunication Equipment",
  "Test & Measurement Equipment",
  "Textiles & Apparel",
  "Tires & Rubber",
  "Tobacco",
  "Toys & Games",
  "Wine & Spirits",
  "Wire & Cable",
  "Adult",
  "Animation",
  "Broadcasting & Media",
  "Classifieds",
  "Ebook & Audiobooks",
  "Ecommerce & Internet Retail",
  "Film Video & Media Production & Services",
  "Internet & Digital Media",
  "Internet-related Services",
  "Music & Music Related Services",
  "Newspapers & News Services",
  "Podcast",
  "Public Relations & Communication",
  "Publishing",
  "Radio Stations",
  "Search Engines & Internet Portals",
  "Social Media",
  "Software Engineering",
  "Streaming",
  "Television Stations",
  "Video Chat & Online Messaging",
  "Media & Internet - General",
  "Metals & Mining - General",
  "Mining",
  "Non-Profit & Charitable Organizations & Foundations",
  "Organizations - General",
  "Parenting & Youth",
  "Philanthropy",
  "Religious Organizations",
  "Commercial Real Estate",
  "Real Estate - General",
  "AR/VR",
  "Artificial Intelligence & Machine Learning",
  "Cryptocurrency",
  "Data & Big Data",
  "Drones",
  "Genetics",
  "Mobile",
  "Nanotechnology",
  "Neuroscience",
  "Quantum Computing",
  "Research - General",
  "Robotics",
  "Sharing Economy",
  "Technology",
  "Think Tanks",
  "Department Stores Shopping Centers & Superstores",
  "Flowers Gifts & Specialty",
  "Footwear",
  "Gas Stations Convenience & Liquor Stores",
  "Gift & Gift Products",
  "Grocery & Supermarkets",
  "Home Improvement & Hardware",
  "Optometry & Eyewear",
  "Rental - Other - Furniture A/V Construction & Industrial Equipment",
  "Rental - Video & DVD",
  "Retail - General",
  "Sporting & Recreational Equipment",
  "Vitamins Supplements & Health Stores",
  "Record, Video & Book Stores",
  "Jewelry & Watch Retail",
  "Consumer Electronics & Computers Retail",
  "Apparel & Accessories Retail",
  "Business Intelligence Software",
  "Cloud Software",
  "Computer Games",
  "Content & Collaboration Software",
  "Customer Relationship Management Software(CRM)",
  "Database & File Management Software",
  "Enterprise Resource Planning Software(ERP)",
  "Software & Technical Consulting",
  "Software - General",
  "Storage & System Management Software",
  "Supply Chain Management Software",
  "Cable & Satellite",
  "Telecommunications - General",
  "Wireless",
  "Import & Export & Trade",
  "Logistics & Supply Chain",
  "Warehousing",
  "Wholesale",
  "Airlines & Aviation",
  "Delivery",
  "Freight & Logistics Services",
  "Rail Bus & Taxi",
  "Shipping",
  "Transportation - General",
  "Trucking Moving & Storage",
];

export const industryTypes = [
  //1
  { title: "Agriculture & Fishing" },
  { title: "Agriculture", grpId: "Agriculture & Fishing" },
  { title: "Crops", grpId: "Agriculture & Fishing" },
  { title: "Farming Animals & Livestock", grpId: "Agriculture & Fishing" },
  { title: "Fishery & Aquaculture", grpId: "Agriculture & Fishing" },
  { title: "Ranching", grpId: "Agriculture & Fishing" },
  //2
  { title: "Business Services" },
  { title: "Accounting & Accounting Services", grpId: "Business Services" },
  { title: "Auctions", grpId: "Business Services" },
  { title: "Business Services - General", grpId: "Business Services" },
  { title: "Call Centers & Business Centers", grpId: "Business Services" },
  { title: "Career Planning", grpId: "Business Services" },
  { title: "Career", grpId: "Business Services" },
  { title: "Commercial Printing", grpId: "Business Services" },
  { title: "Debt Collection", grpId: "Business Services" },
  { title: "Design", grpId: "Business Services" },
  { title: "Event Services", grpId: "Business Services" },
  { title: "Facilities Management & Services", grpId: "Business Services" },
  { title: "Food Service", grpId: "Business Services" },
  { title: "Fraud Detection", grpId: "Business Services" },
  { title: "Geography & Positioning", grpId: "Business Services" },
  { title: "Human Resources & Staffing", grpId: "Business Services" },
  { title: "Information Services", grpId: "Business Services" },
  { title: "Management Consulting", grpId: "Business Services" },
  { title: "Marketing & Advertising", grpId: "Business Services" },
  { title: "Multimedia & Graphic Design", grpId: "Business Services" },
  { title: "Outsourcing", grpId: "Business Services" },
  { title: "Sales", grpId: "Business Services" },
  {
    title: "Security & Investigations Products & Services",
    grpId: "Business Services",
  },
  { title: "Staffing & Recruiting", grpId: "Business Services" },
  { title: "Translation & Localization", grpId: "Business Services" },
  { title: "Writing & Editing", grpId: "Business Services" },
  //3
  { title: "Chambers of Commerce" },
  //4
  { title: "Cities, Towns & Municipalities" },
  {
    title: "Cities Towns & Municipalities - General",
    grpId: "Cities, Towns & Municipalities",
  },
  {
    title: "Communities",
    grpId: "Cities, Towns & Municipalities",
  },
  {
    title: "Local",
    grpId: "Cities, Towns & Municipalities",
  },
  {
    title: "Parking",
    grpId: "Cities, Towns & Municipalities",
  },
  {
    title: "Public Safety",
    grpId: "Cities, Towns & Municipalities",
  },
  //5
  { title: "Construction" },
  { title: "Architecture Engineering & Design", grpId: "Construction" },
  { title: "Construction - General", grpId: "Construction" },
  { title: "Mechanical Engineering", grpId: "Construction" },
  { title: "Mechanical or Industrial Engineering", grpId: "Construction" },
  //6 consumer services
  { title: "Consumer Services" },
  {
    title: "Car & Truck Rental",
    grpId: "Consumer Services",
  },
  {
    title: "Child Care",
    grpId: "Consumer Services",
  },
  {
    title: "Consumer Services - General",
    grpId: "Consumer Services",
  },
  {
    title: "Funeral Homes & Funeral Related Services",
    grpId: "Consumer Services",
  },
  {
    title: "Hair Salons",
    grpId: "Consumer Services",
  },
  {
    title: "Laundry & Dry Cleaning Services",
    grpId: "Consumer Services",
  },
  {
    title: "Photography",
    grpId: "Consumer Services",
  },
  {
    title: "Privacy",
    grpId: "Consumer Services",
  },
  {
    title: "Travel Agencies & Services",
    grpId: "Consumer Services",
  },
  {
    title: "Veterinary Care",
    grpId: "Consumer Services",
  },
  {
    title: "Wedding",
    grpId: "Consumer Services",
  },

  //7 cultural
  { title: "Cultural" },
  {
    title: "Cultural - General",
    grpId: "Cultural",
  },
  {
    title: "Fine Arts",
    grpId: "Cultural",
  },
  {
    title: "Libraries",
    grpId: "Cultural",
  },
  {
    title: "Museums & Art Galleries",
    grpId: "Cultural",
  },
  {
    title: "Performing Arts",
    grpId: "Cultural",
  },

  //8 education
  { title: "Education" },
  {
    title: "Colleges & Universities",
    grpId: "Education",
  },
  {
    title: "E-learning",
    grpId: "Education",
  },
  {
    title: "Education - General",
    grpId: "Education",
  },
  {
    title: "Higher Education",
    grpId: "Education",
  },
  {
    title: "K-12 Schools",
    grpId: "Education",
  },
  {
    title: "Professional Training & Coaching",
    grpId: "Education",
  },
  {
    title: "Training",
    grpId: "Education",
  },

  //9 "Energy, Utilities & Waste Treatment"
  { title: "Energy, Utilities & Waste Treatment" },
  {
    title: "Electricity & Energy",
    grpId: "Energy, Utilities & Waste Treatment",
  },
  {
    title: "Environmental Services",
    grpId: "Energy, Utilities & Waste Treatment",
  },
  {
    title: "Forestry",
    grpId: "Energy, Utilities & Waste Treatment",
  },
  {
    title: "Natural Resources",
    grpId: "Energy, Utilities & Waste Treatment",
  },
  {
    title: "Nuclear",
    grpId: "Energy, Utilities & Waste Treatment",
  },
  {
    title: "Oil & Gas Exploration & Services",
    grpId: "Energy, Utilities & Waste Treatment",
  },
  {
    title: "Renewables & Environment",
    grpId: "Energy, Utilities & Waste Treatment",
  },
  {
    title: "Utilities",
    grpId: "Energy, Utilities & Waste Treatment",
  },
  {
    title: "Water Energy & Waste Treatment",
    grpId: "Energy, Utilities & Waste Treatment",
  },
  {
    title: "Energy, Utilities & Waste Treatment - General",
    grpId: "Energy, Utilities & Waste Treatment",
  },

  //10 finance
  { title: "Finance" },
  {
    title: "Banking",
    grpId: "Finance",
  },
  {
    title: "Credit Cards & Transaction Processing",
    grpId: "Finance",
  },
  {
    title: "Finance - General",
    grpId: "Finance",
  },
  {
    title: "Financial Services",
    grpId: "Finance",
  },
  {
    title: "Investment Banking",
    grpId: "Finance",
  },
  {
    title: "Venture Capital & Private Equity",
    grpId: "Finance",
  },

  //11 government
  { title: "Government" },
  {
    title: "Corrections Facilities",
    grpId: "Government",
  },
  {
    title: "Government - General",
    grpId: "Government",
  },
  {
    title: "International Affairs",
    grpId: "Government",
  },
  {
    title: "Law Enforcement",
    grpId: "Government",
  },
  {
    title: "Military",
    grpId: "Government",
  },
  {
    title: "Public Policy",
    grpId: "Government",
  },

  // 12 healthcare
  { title: "Healthcare" },

  {
    title: "Biotechnology",
    grpId: "Healthcare",
  },
  {
    title: "Clinical Trials",
    grpId: "Healthcare",
  },
  {
    title: "Drug Manufacturing & Research",
    grpId: "Healthcare",
  },
  {
    title: "Drug Stores & Pharmacies",
    grpId: "Healthcare",
  },
  {
    title: "Emergency Medical Transportation & Services",
    grpId: "Healthcare",
  },
  {
    title: "Hospitals & Healthcare",
    grpId: "Healthcare",
  },
  {
    title: "Medical Practice",
    grpId: "Healthcare",
  },
  {
    title: "Medicine",
    grpId: "Healthcare",
  },
  {
    title: "Mental Health Care",
    grpId: "Healthcare",
  },
  {
    title: "Pharmaceuticals",
    grpId: "Healthcare",
  },
  {
    title: "Psychology",
    grpId: "Healthcare",
  },
  {
    title: "Healthcare - General",
    grpId: "Healthcare",
  },

  //13 hospitality
  { title: "Hospitality" },

  {
    title: "Amusement Parks Arcades & Attractions",
    grpId: "Hospitality",
  },
  {
    title: "Cruise Lines",
    grpId: "Hospitality",
  },
  {
    title: "Elder & Disabled Care",
    grpId: "Hospitality",
  },
  {
    title: "Entertainment",
    grpId: "Hospitality",
  },
  {
    title: "Fitness & Dance Facilities",
    grpId: "Hospitality",
  },
  {
    title: "Gambling & Casinos",
    grpId: "Hospitality",
  },
  {
    title: "Gaming",
    grpId: "Hospitality",
  },
  {
    title: "Hospitality - General",
    grpId: "Hospitality",
  },
  {
    title: "Hotels and Resorts",
    grpId: "Hospitality",
  },
  {
    title: "Lodging & Resorts",
    grpId: "Hospitality",
  },
  {
    title: "Movie Theaters",
    grpId: "Hospitality",
  },
  {
    title: "Recreation",
    grpId: "Hospitality",
  },
  {
    title: "Restaurants",
    grpId: "Hospitality",
  },
  {
    title: "Sports",
    grpId: "Hospitality",
  },
  {
    title: "Zoos & National Parks",
    grpId: "Hospitality",
  },

  // 14 insurance
  { title: "Insurance" },
  // 15 Law Firms & Legal Services
  { title: "Law Firms & Legal Services" },
  //16 manufacturing
  { title: "Manufacturing" },

  {
    title: "Aerospace & Defense",
    grpId: "Manufacturing",
  },
  {
    title: "Apparel & Fashion",
    grpId: "Manufacturing",
  },
  {
    title: "Appliances",
    grpId: "Manufacturing",
  },
  {
    title: "Automotive",
    grpId: "Manufacturing",
  },
  {
    title: "Boats & Submarines",
    grpId: "Manufacturing",
  },
  {
    title: "Building Materials",
    grpId: "Manufacturing",
  },
  {
    title: "Business Supplies & Equipment",
    grpId: "Manufacturing",
  },
  {
    title: "Chemicals",
    grpId: "Manufacturing",
  },
  {
    title: "Cleaning Products",
    grpId: "Manufacturing",
  },
  {
    title: "Computer Hardware",
    grpId: "Manufacturing",
  },
  {
    title: "Consumer Electronics",
    grpId: "Manufacturing",
  },
  {
    title: "Consumer Goods",
    grpId: "Manufacturing",
  },
  {
    title: "Cosmetics Beauty Supply & Personal Care Products",
    grpId: "Manufacturing",
  },
  {
    title: "Dairy",
    grpId: "Manufacturing",
  },
  {
    title: "Electronics & Electronics Manufacturing",
    grpId: "Manufacturing",
  },
  {
    title: "Flash Storage",
    grpId: "Manufacturing",
  },
  {
    title: "Food & Beverages",
    grpId: "Manufacturing",
  },
  {
    title: "Furniture",
    grpId: "Manufacturing",
  },
  {
    title: "Glass & Concrete",
    grpId: "Manufacturing",
  },
  {
    title: "Hand Power and Lawn-care Tools",
    grpId: "Manufacturing",
  },
  {
    title: "Health & Nutrition Products",
    grpId: "Manufacturing",
  },
  {
    title: "Health Wellness & Fitness",
    grpId: "Manufacturing",
  },
  {
    title: "Household Goods",
    grpId: "Manufacturing",
  },
  {
    title: "Industrial Machinery Equipment & Automation",
    grpId: "Manufacturing",
  },
  {
    title: "Lumber & Wood Production",
    grpId: "Manufacturing",
  },
  {
    title: "Luxury Goods & Jewelry",
    grpId: "Manufacturing",
  },
  {
    title: "Manufacturing - General",
    grpId: "Manufacturing",
  },
  {
    title: "Maritime",
    grpId: "Manufacturing",
  },
  {
    title: "Medical Devices & Equipment",
    grpId: "Manufacturing",
  },
  {
    title: "Miscellaneous Building Materials",
    grpId: "Manufacturing",
  },
  {
    title: "Network Security Hardware & Software",
    grpId: "Manufacturing",
  },
  {
    title: "Office Products",
    grpId: "Manufacturing",
  },
  {
    title: "Paper & Forest Products",
    grpId: "Manufacturing",
  },
  {
    title: "Personal Computers & Peripherals",
    grpId: "Manufacturing",
  },
  {
    title: "Pet Products",
    grpId: "Manufacturing",
  },
  {
    title: "Petrochemicals",
    grpId: "Manufacturing",
  },
  {
    title: "Plastics & Packaging & Containers",
    grpId: "Manufacturing",
  },
  {
    title: "Plumbing & HVAC Equipment",
    grpId: "Manufacturing",
  },
  {
    title: "Power Conversion & Protection Equipment",
    grpId: "Manufacturing",
  },
  {
    title: "Semiconductor & Semiconductor Equipment",
    grpId: "Manufacturing",
  },
  {
    title: "Shipbuilding",
    grpId: "Manufacturing",
  },
  {
    title: "Telecommunication Equipment",
    grpId: "Manufacturing",
  },
  {
    title: "Test & Measurement Equipment",
    grpId: "Manufacturing",
  },
  {
    title: "Textiles & Apparel",
    grpId: "Manufacturing",
  },
  {
    title: "Tires & Rubber",
    grpId: "Manufacturing",
  },
  {
    title: "Tobacco",
    grpId: "Manufacturing",
  },
  {
    title: "Toys & Games",
    grpId: "Manufacturing",
  },
  {
    title: "Wine & Spirits",
    grpId: "Manufacturing",
  },
  {
    title: "Wire & Cable",
    grpId: "Manufacturing",
  },

  // 17 media & internet
  { title: "Media & Internet" },

  {
    title: "Adult",
    grpId: "Media & Internet",
  },
  {
    title: "Animation",
    grpId: "Media & Internet",
  },
  {
    title: "Broadcasting & Media",
    grpId: "Media & Internet",
  },
  {
    title: "Classifieds",
    grpId: "Media & Internet",
  },
  {
    title: "Ebook & Audiobooks",
    grpId: "Media & Internet",
  },
  {
    title: "Ecommerce & Internet Retail",
    grpId: "Media & Internet",
  },
  {
    title: "Film Video & Media Production & Services",
    grpId: "Media & Internet",
  },
  {
    title: "Internet & Digital Media",
    grpId: "Media & Internet",
  },
  {
    title: "Internet-related Services",
    grpId: "Media & Internet",
  },
  {
    title: "Music & Music Related Services",
    grpId: "Media & Internet",
  },
  {
    title: "Newspapers & News Services",
    grpId: "Media & Internet",
  },
  {
    title: "Podcast",
    grpId: "Media & Internet",
  },
  {
    title: "Public Relations & Communication",
    grpId: "Media & Internet",
  },
  {
    title: "Publishing",
    grpId: "Media & Internet",
  },
  {
    title: "Radio Stations",
    grpId: "Media & Internet",
  },
  {
    title: "Search Engines & Internet Portals",
    grpId: "Media & Internet",
  },
  {
    title: "Social Media",
    grpId: "Media & Internet",
  },
  {
    title: "Software Engineering",
    grpId: "Media & Internet",
  },
  {
    title: "Streaming",
    grpId: "Media & Internet",
  },
  {
    title: "Television Stations",
    grpId: "Media & Internet",
  },
  {
    title: "Video Chat & Online Messaging",
    grpId: "Media & Internet",
  },
  {
    title: "Media & Internet - General",
    grpId: "Media & Internet",
  },
  //18 metals & mining
  { title: "Metals & Mining" },

  {
    title: "Metals & Mining - General",
    grpId: "Metals & Mining",
  },
  {
    title: "Mining",
    grpId: "Metals & Mining",
  },
  //19 organizations
  { title: "Organizations" },

  {
    title: "Non-Profit & Charitable Organizations & Foundations",
    grpId: "Organizations",
  },
  {
    title: "Organizations - General",
    grpId: "Organizations",
  },
  {
    title: "Parenting & Youth",
    grpId: "Organizations",
  },
  {
    title: "Philanthropy",
    grpId: "Organizations",
  },
  {
    title: "Religious Organizations",
    grpId: "Organizations",
  },
  //20 real estate
  { title: "Real Estate" },

  {
    title: "Commercial Real Estate",
    grpId: "Real Estate",
  },
  {
    title: "Real Estate - General",
    grpId: "Real Estate",
  },
  //21 research & technology
  { title: "Research & Technology" },

  {
    title: "AR/VR",
    grpId: "Research & Technology",
  },
  {
    title: "Artificial Intelligence & Machine Learning",
    grpId: "Research & Technology",
  },
  {
    title: "Cryptocurrency",
    grpId: "Research & Technology",
  },
  {
    title: "Data & Big Data",
    grpId: "Research & Technology",
  },
  {
    title: "Drones",
    grpId: "Research & Technology",
  },
  {
    title: "Genetics",
    grpId: "Research & Technology",
  },
  {
    title: "Mobile",
    grpId: "Research & Technology",
  },
  {
    title: "Nanotechnology",
    grpId: "Research & Technology",
  },
  {
    title: "Neuroscience",
    grpId: "Research & Technology",
  },
  {
    title: "Quantum Computing",
    grpId: "Research & Technology",
  },
  {
    title: "Research - General",
    grpId: "Research & Technology",
  },
  {
    title: "Robotics",
    grpId: "Research & Technology",
  },
  {
    title: "Sharing Economy",
    grpId: "Research & Technology",
  },
  {
    title: "Technology",
    grpId: "Research & Technology",
  },
  {
    title: "Think Tanks",
    grpId: "Research & Technology",
  },
  //22 Retail
  { title: "Retail" },

  {
    title: "Department Stores Shopping Centers & Superstores",
    grpId: "Retail",
  },
  {
    title: "Flowers Gifts & Specialty",
    grpId: "Retail",
  },
  {
    title: "Footwear",
    grpId: "Retail",
  },
  {
    title: "Gas Stations Convenience & Liquor Stores",
    grpId: "Retail",
  },
  {
    title: "Gift & Gift Products",
    grpId: "Retail",
  },
  {
    title: "Grocery & Supermarkets",
    grpId: "Retail",
  },
  {
    title: "Home Improvement & Hardware",
    grpId: "Retail",
  },
  {
    title: "Optometry & Eyewear",
    grpId: "Retail",
  },
  {
    title: "Rental - Other - Furniture A/V Construction & Industrial Equipment",
    grpId: "Retail",
  },
  {
    title: "Rental - Video & DVD",
    grpId: "Retail",
  },
  {
    title: "Retail - General",
    grpId: "Retail",
  },
  {
    title: "Sporting & Recreational Equipment",
    grpId: "Retail",
  },
  {
    title: "Vitamins Supplements & Health Stores",
    grpId: "Retail",
  },
  {
    title: "Record, Video & Book Stores",
    grpId: "Retail",
  },
  {
    title: "Jewelry & Watch Retail",
    grpId: "Retail",
  },
  {
    title: "Consumer Electronics & Computers Retail",
    grpId: "Retail",
  },
  {
    title: "Apparel & Accessories Retail",
    grpId: "Retail",
  },
  //23 software
  { title: "Software" },

  {
    title: "Business Intelligence Software",
    grpId: "Software",
  },
  {
    title: "Cloud Software",
    grpId: "Software",
  },
  {
    title: "Computer Games",
    grpId: "Software",
  },
  {
    title: "Content & Collaboration Software",
    grpId: "Software",
  },
  {
    title: "Customer Relationship Management Software(CRM)",
    grpId: "Software",
  },
  {
    title: "Database & File Management Software",
    grpId: "Software",
  },
  {
    title: "Enterprise Resource Planning Software(ERP)",
    grpId: "Software",
  },
  {
    title: "Software & Technical Consulting",
    grpId: "Software",
  },
  {
    title: "Software - General",
    grpId: "Software",
  },
  {
    title: "Storage & System Management Software",
    grpId: "Software",
  },
  {
    title: "Supply Chain Management Software",
    grpId: "Software",
  },
  //24 telecommunications
  { title: "Telecommunications" },

  {
    title: "Cable & Satellite",
    grpId: "Telecommunications",
  },
  {
    title: "Telecommunications - General",
    grpId: "Telecommunications",
  },
  {
    title: "Wireless",
    grpId: "Telecommunications",
  },
  //25 trade, supply
  { title: "Trade, Supply Chain & Commerce" },
  {
    title: "Import & Export & Trade",
    grpId: "Trade, Supply Chain & Commerce",
  },
  {
    title: "Logistics & Supply Chain",
    grpId: "Trade, Supply Chain & Commerce",
  },
  {
    title: "Warehousing",
    grpId: "Trade, Supply Chain & Commerce",
  },
  {
    title: "Wholesale",
    grpId: "Trade, Supply Chain & Commerce",
  },
  //26 transportation
  { title: "Transportation" },

  {
    title: "Airlines & Aviation",
    grpId: "Transportation",
  },
  {
    title: "Delivery",
    grpId: "Transportation",
  },
  {
    title: "Freight & Logistics Services",
    grpId: "Transportation",
  },
  {
    title: "Rail Bus & Taxi",
    grpId: "Transportation",
  },
  {
    title: "Shipping",
    grpId: "Transportation",
  },
  {
    title: "Transportation - General",
    grpId: "Transportation",
  },
  {
    title: "Trucking Moving & Storage",
    grpId: "Transportation",
  },
];

export const newIndustryTypes = [
  {
    id: "5567ce1f7369643b78570000",
    cleaned_name: "accounting",
    tag_name_unanalyzed_downcase: "accounting",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 159060,
    num_people: 0,
    _index_type: "tag",
    _score: 1.2280408,
    _explanation: null,
  },
  {
    id: "55718f947369642142b84a12",
    cleaned_name: "agriculture",
    tag_name_unanalyzed_downcase: "agriculture",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 1425,
    num_people: 0,
    _index_type: "tag",
    _score: 0.057856206,
    _explanation: null,
  },
  {
    id: "5567e1a87369641f6d550100",
    cleaned_name: "alternative dispute resolution",
    tag_name_unanalyzed_downcase: "alternative dispute resolution",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 4170,
    num_people: 0,
    _index_type: "tag",
    _score: 0.15136984,
    _explanation: null,
  },
  {
    id: "5567cd82736964540d0b0000",
    cleaned_name: "apparel & fashion",
    tag_name_unanalyzed_downcase: "apparel & fashion",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 122937,
    num_people: 0,
    _index_type: "tag",
    _score: 1.1236459,
    _explanation: null,
  },
  {
    id: "5567cdb77369645401080000",
    cleaned_name: "architecture & planning",
    tag_name_unanalyzed_downcase: "architecture & planning",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 120834,
    num_people: 0,
    _index_type: "tag",
    _score: 1.1167206,
    _explanation: null,
  },
  {
    id: "5567cd4d73696439d9030000",
    cleaned_name: "arts & crafts",
    tag_name_unanalyzed_downcase: "arts & crafts",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 70247,
    num_people: 0,
    _index_type: "tag",
    _score: 0.9044288,
    _explanation: null,
  },
  {
    id: "5567e0bf7369641d115f0200",
    cleaned_name: "airlines/aviation",
    tag_name_unanalyzed_downcase: "airlines/aviation",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 31674,
    num_people: 0,
    _index_type: "tag",
    _score: 0.6198652,
    _explanation: null,
  },
  {
    id: "5567e27c7369642ade490000",
    cleaned_name: "alternative medicine",
    tag_name_unanalyzed_downcase: "alternative medicine",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 16330,
    num_people: 0,
    _index_type: "tag",
    _score: 0.42045087,
    _explanation: null,
  },
  {
    id: "5567e36f73696431a4970000",
    cleaned_name: "animation",
    tag_name_unanalyzed_downcase: "animation",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 10865,
    num_people: 0,
    _index_type: "tag",
    _score: 0.31941837,
    _explanation: null,
  },
  {
    id: "5567cdf27369644cfd800000",
    cleaned_name: "automotive",
    tag_name_unanalyzed_downcase: "automotive",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 250458,
    num_people: 0,
    _index_type: "tag",
    _score: 1.4157377,
    _explanation: null,
  },
  {
    id: "5567e0dd73696416d3c20100",
    cleaned_name: "aviation & aerospace",
    tag_name_unanalyzed_downcase: "aviation & aerospace",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 26849,
    num_people: 0,
    _index_type: "tag",
    _score: 0.5664257,
    _explanation: null,
  },
  {
    id: "5567ce237369644ee5490000",
    cleaned_name: "banking",
    tag_name_unanalyzed_downcase: "banking",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 38990,
    num_people: 0,
    _index_type: "tag",
    _score: 0.6901074,
    _explanation: null,
  },
  {
    id: "5567d08e7369645dbc4b0000",
    cleaned_name: "biotechnology",
    tag_name_unanalyzed_downcase: "biotechnology",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 41577,
    num_people: 0,
    _index_type: "tag",
    _score: 0.71245605,
    _explanation: null,
  },
  {
    id: "5567e0f973696416d34e0200",
    cleaned_name: "broadcast media",
    tag_name_unanalyzed_downcase: "broadcast media",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 42098,
    num_people: 0,
    _index_type: "tag",
    _score: 0.716821,
    _explanation: null,
  },
  {
    id: "5567e1a17369641ea9d30100",
    cleaned_name: "building materials",
    tag_name_unanalyzed_downcase: "building materials",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 66996,
    num_people: 0,
    _index_type: "tag",
    _score: 0.8864682,
    _explanation: null,
  },
  {
    id: "5567e0fa73696410e4c51200",
    cleaned_name: "business supplies & equipment",
    tag_name_unanalyzed_downcase: "business supplies & equipment",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 75523,
    num_people: 0,
    _index_type: "tag",
    _score: 0.9320829,
    _explanation: null,
  },
  {
    id: "5567cdb773696439a9080000",
    cleaned_name: "capital markets",
    tag_name_unanalyzed_downcase: "capital markets",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 11397,
    num_people: 0,
    _index_type: "tag",
    _score: 0.33035287,
    _explanation: null,
  },
  {
    id: "5567e21e73696426a1030000",
    cleaned_name: "chemicals",
    tag_name_unanalyzed_downcase: "chemicals",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 49343,
    num_people: 0,
    _index_type: "tag",
    _score: 0.7733695,
    _explanation: null,
  },
  {
    id: "5567cdda7369644eed130000",
    cleaned_name: "civic & social organization",
    tag_name_unanalyzed_downcase: "civic & social organization",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 53178,
    num_people: 0,
    _index_type: "tag",
    _score: 0.80056584,
    _explanation: null,
  },
  {
    id: "5567e13a73696418756e0200",
    cleaned_name: "civil engineering",
    tag_name_unanalyzed_downcase: "civil engineering",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 65655,
    num_people: 0,
    _index_type: "tag",
    _score: 0.87883765,
    _explanation: null,
  },
  {
    id: "5567cd4e7369643b70010000",
    cleaned_name: "computer software",
    tag_name_unanalyzed_downcase: "computer software",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 305264,
    num_people: 0,
    _index_type: "tag",
    _score: 1.4986744,
    _explanation: null,
  },
  {
    id: "5567cd877369644cf94b0000",
    cleaned_name: "computer & network security",
    tag_name_unanalyzed_downcase: "computer & network security",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 30733,
    num_people: 0,
    _index_type: "tag",
    _score: 0.60994637,
    _explanation: null,
  },
  {
    id: "5567cd8b736964540d0f0000",
    cleaned_name: "computer games",
    tag_name_unanalyzed_downcase: "computer games",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 26302,
    num_people: 0,
    _index_type: "tag",
    _score: 0.55993056,
    _explanation: null,
  },
  {
    id: "5567e0d47369641233eb0600",
    cleaned_name: "computer hardware",
    tag_name_unanalyzed_downcase: "computer hardware",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 23558,
    num_people: 0,
    _index_type: "tag",
    _score: 0.52579606,
    _explanation: null,
  },
  {
    id: "5567cdbe7369643b78360000",
    cleaned_name: "computer networking",
    tag_name_unanalyzed_downcase: "computer networking",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 22516,
    num_people: 0,
    _index_type: "tag",
    _score: 0.5120971,
    _explanation: null,
  },
  {
    id: "5567e1887369641d68d40100",
    cleaned_name: "commercial real estate",
    tag_name_unanalyzed_downcase: "commercial real estate",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 26419,
    num_people: 0,
    _index_type: "tag",
    _score: 0.561328,
    _explanation: null,
  },
  {
    id: "5567cd4773696439dd350000",
    cleaned_name: "construction",
    tag_name_unanalyzed_downcase: "construction",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 531470,
    num_people: 0,
    _index_type: "tag",
    _score: 1.7335744,
    _explanation: null,
  },
  {
    id: "5567ce987369643b789e0000",
    cleaned_name: "consumer goods",
    tag_name_unanalyzed_downcase: "consumer goods",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 93184,
    num_people: 0,
    _index_type: "tag",
    _score: 1.0136124,
    _explanation: null,
  },
  {
    id: "5567d1127261697f2b1d0000",
    cleaned_name: "consumer services",
    tag_name_unanalyzed_downcase: "consumer services",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 282703,
    num_people: 0,
    _index_type: "tag",
    _score: 1.4664272,
    _explanation: null,
  },
  {
    id: "5567e1947369641ead570000",
    cleaned_name: "consumer electronics",
    tag_name_unanalyzed_downcase: "consumer electronics",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 39196,
    num_people: 0,
    _index_type: "tag",
    _score: 0.69192976,
    _explanation: null,
  },
  {
    id: "5567e1ae73696423dc040000",
    cleaned_name: "cosmetics",
    tag_name_unanalyzed_downcase: "cosmetics",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 81305,
    num_people: 0,
    _index_type: "tag",
    _score: 0.9604946,
    _explanation: null,
  },
  {
    id: "5567e8a27369646ddb0b0000",
    cleaned_name: "dairy",
    tag_name_unanalyzed_downcase: "dairy",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 5677,
    num_people: 0,
    _index_type: "tag",
    _score: 0.19526295,
    _explanation: null,
  },
  {
    id: "5567e1097369641b5f810500",
    cleaned_name: "defense & space",
    tag_name_unanalyzed_downcase: "defense & space",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 16111,
    num_people: 0,
    _index_type: "tag",
    _score: 0.4168235,
    _explanation: null,
  },
  {
    id: "5567cdbc73696439d90b0000",
    cleaned_name: "design",
    tag_name_unanalyzed_downcase: "design",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 190601,
    num_people: 0,
    _index_type: "tag",
    _score: 1.3023331,
    _explanation: null,
  },
  {
    id: "5567ce9e736964540d540000",
    cleaned_name: "education management",
    tag_name_unanalyzed_downcase: "education management",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 203840,
    num_people: 0,
    _index_type: "tag",
    _score: 1.330089,
    _explanation: null,
  },
  {
    id: "5567e19c7369641c48e70100",
    cleaned_name: "e-learning",
    tag_name_unanalyzed_downcase: "e-learning",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 52673,
    num_people: 0,
    _index_type: "tag",
    _score: 0.79708046,
    _explanation: null,
  },
  {
    id: "5567cd4c73696439c9030000",
    cleaned_name: "electrical/electronic manufacturing",
    tag_name_unanalyzed_downcase: "electrical/electronic manufacturing",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 138493,
    num_people: 0,
    _index_type: "tag",
    _score: 1.171706,
    _explanation: null,
  },
  {
    id: "5567cdd37369643b80510000",
    cleaned_name: "entertainment",
    tag_name_unanalyzed_downcase: "entertainment",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 141775,
    num_people: 0,
    _index_type: "tag",
    _score: 1.1812003,
    _explanation: null,
  },
  {
    id: "5567ce5b736964540d280000",
    cleaned_name: "environmental services",
    tag_name_unanalyzed_downcase: "environmental services",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 79858,
    num_people: 0,
    _index_type: "tag",
    _score: 0.9535567,
    _explanation: null,
  },
  {
    id: "5567cd8e7369645409450000",
    cleaned_name: "events services",
    tag_name_unanalyzed_downcase: "events services",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 154350,
    num_people: 0,
    _index_type: "tag",
    _score: 1.2157696,
    _explanation: null,
  },
  {
    id: "5567e09473696410dbf00700",
    cleaned_name: "executive office",
    tag_name_unanalyzed_downcase: "executive office",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 23868,
    num_people: 0,
    _index_type: "tag",
    _score: 0.52978957,
    _explanation: null,
  },
  {
    id: "5567ce9c7369643bc9980000",
    cleaned_name: "facilities services",
    tag_name_unanalyzed_downcase: "facilities services",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 72938,
    num_people: 0,
    _index_type: "tag",
    _score: 0.91875356,
    _explanation: null,
  },
  {
    id: "5567cd4f7369644d2d010000",
    cleaned_name: "farming",
    tag_name_unanalyzed_downcase: "farming",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 46756,
    num_people: 0,
    _index_type: "tag",
    _score: 0.75401175,
    _explanation: null,
  },
  {
    id: "5567cdd67369643e64020000",
    cleaned_name: "financial services",
    tag_name_unanalyzed_downcase: "financial services",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 285078,
    num_people: 0,
    _index_type: "tag",
    _score: 1.4699368,
    _explanation: null,
  },
  {
    id: "5567e2097369642420150000",
    cleaned_name: "fine art",
    tag_name_unanalyzed_downcase: "fine art",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 33225,
    num_people: 0,
    _index_type: "tag",
    _score: 0.635735,
    _explanation: null,
  },
  {
    id: "5567f96c7369642a22080000",
    cleaned_name: "fishery",
    tag_name_unanalyzed_downcase: "fishery",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 6008,
    num_people: 0,
    _index_type: "tag",
    _score: 0.20433708,
    _explanation: null,
  },
  {
    id: "5567ce1e7369643b806a0000",
    cleaned_name: "food & beverages",
    tag_name_unanalyzed_downcase: "food & beverages",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 195684,
    num_people: 0,
    _index_type: "tag",
    _score: 1.3132005,
    _explanation: null,
  },
  {
    id: "5567e1b3736964208b280000",
    cleaned_name: "food production",
    tag_name_unanalyzed_downcase: "food production",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 71024,
    num_people: 0,
    _index_type: "tag",
    _score: 0.9086137,
    _explanation: null,
  },
  {
    id: "5567d2ad7261697f2b1f0100",
    cleaned_name: "fund-raising",
    tag_name_unanalyzed_downcase: "fund-raising",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 13828,
    num_people: 0,
    _index_type: "tag",
    _score: 0.3770876,
    _explanation: null,
  },
  {
    id: "5567cede73696440d0040000",
    cleaned_name: "furniture",
    tag_name_unanalyzed_downcase: "furniture",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 59535,
    num_people: 0,
    _index_type: "tag",
    _score: 0.84220344,
    _explanation: null,
  },
  {
    id: "5567e0cf7369641233e50600",
    cleaned_name: "gambling & casinos",
    tag_name_unanalyzed_downcase: "gambling & casinos",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 7816,
    num_people: 0,
    _index_type: "tag",
    _score: 0.2508102,
    _explanation: null,
  },
  {
    id: "5567cd4f736964397e030000",
    cleaned_name: "glass, ceramics & concrete",
    tag_name_unanalyzed_downcase: "glass, ceramics & concrete",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 11631,
    num_people: 0,
    _index_type: "tag",
    _score: 0.3350766,
    _explanation: null,
  },
  {
    id: "5567cd527369643981050000",
    cleaned_name: "government administration",
    tag_name_unanalyzed_downcase: "government administration",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 47906,
    num_people: 0,
    _index_type: "tag",
    _score: 0.76272357,
    _explanation: null,
  },
  {
    id: "5567e29b736964256c370100",
    cleaned_name: "government relations",
    tag_name_unanalyzed_downcase: "government relations",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 13874,
    num_people: 0,
    _index_type: "tag",
    _score: 0.3779252,
    _explanation: null,
  },
  {
    id: "5567cd4d73696439d9040000",
    cleaned_name: "graphic design",
    tag_name_unanalyzed_downcase: "graphic design",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 77031,
    num_people: 0,
    _index_type: "tag",
    _score: 0.93967396,
    _explanation: null,
  },
  {
    id: "5567cddb7369644d250c0000",
    cleaned_name: "health, wellness & fitness",
    tag_name_unanalyzed_downcase: "health, wellness & fitness",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 340699,
    num_people: 0,
    _index_type: "tag",
    _score: 1.5449345,
    _explanation: null,
  },
  {
    id: "5567cd4c73696453e1300000",
    cleaned_name: "higher education",
    tag_name_unanalyzed_downcase: "higher education",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 59989,
    num_people: 0,
    _index_type: "tag",
    _score: 0.8450298,
    _explanation: null,
  },
  {
    id: "5567cdde73696439812c0000",
    cleaned_name: "hospital & health care",
    tag_name_unanalyzed_downcase: "hospital & health care",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 271764,
    num_people: 0,
    _index_type: "tag",
    _score: 1.4498855,
    _explanation: null,
  },
  {
    id: "5567ce9d7369643bc19c0000",
    cleaned_name: "hospitality",
    tag_name_unanalyzed_downcase: "hospitality",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 174263,
    num_people: 0,
    _index_type: "tag",
    _score: 1.2654381,
    _explanation: null,
  },
  {
    id: "5567e0e37369640e5ac10c00",
    cleaned_name: "human resources",
    tag_name_unanalyzed_downcase: "human resources",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 109546,
    num_people: 0,
    _index_type: "tag",
    _score: 1.077535,
    _explanation: null,
  },
  {
    id: "5567e1337369641ad2970000",
    cleaned_name: "industrial automation",
    tag_name_unanalyzed_downcase: "industrial automation",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 38677,
    num_people: 0,
    _index_type: "tag",
    _score: 0.6873238,
    _explanation: null,
  },
  {
    id: "5567ce9d7369645430c50000",
    cleaned_name: "import & export",
    tag_name_unanalyzed_downcase: "import & export",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 38876,
    num_people: 0,
    _index_type: "tag",
    _score: 0.6890957,
    _explanation: null,
  },
  {
    id: "5567d02b7369645d8b140000",
    cleaned_name: "individual & family services",
    tag_name_unanalyzed_downcase: "individual & family services",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 56276,
    num_people: 0,
    _index_type: "tag",
    _score: 0.8213563,
    _explanation: null,
  },
  {
    id: "5567e0c97369640d2b3b1600",
    cleaned_name: "information services",
    tag_name_unanalyzed_downcase: "information services",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 41322,
    num_people: 0,
    _index_type: "tag",
    _score: 0.71030354,
    _explanation: null,
  },
  {
    id: "5567cd4773696439b10b0000",
    cleaned_name: "information technology & services",
    tag_name_unanalyzed_downcase: "information technology & services",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 721810,
    num_people: 0,
    _index_type: "tag",
    _score: 1.8643984,
    _explanation: null,
  },
  {
    id: "5567cdd973696453d93f0000",
    cleaned_name: "insurance",
    tag_name_unanalyzed_downcase: "insurance",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 116915,
    num_people: 0,
    _index_type: "tag",
    _score: 1.103513,
    _explanation: null,
  },
  {
    id: "5567e3657369642f4ec90000",
    cleaned_name: "international affairs",
    tag_name_unanalyzed_downcase: "international affairs",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 9942,
    num_people: 0,
    _index_type: "tag",
    _score: 0.29976872,
    _explanation: null,
  },
  {
    id: "5567ce9c7369644eed680000",
    cleaned_name: "international trade & development",
    tag_name_unanalyzed_downcase: "international trade & development",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 39609,
    num_people: 0,
    _index_type: "tag",
    _score: 0.69556046,
    _explanation: null,
  },
  {
    id: "5567cd4d736964397e020000",
    cleaned_name: "internet",
    tag_name_unanalyzed_downcase: "internet",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 304994,
    num_people: 0,
    _index_type: "tag",
    _score: 1.4983022,
    _explanation: null,
  },
  {
    id: "5567e1ab7369641f6d660100",
    cleaned_name: "investment banking",
    tag_name_unanalyzed_downcase: "investment banking",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 14672,
    num_people: 0,
    _index_type: "tag",
    _score: 0.39220434,
    _explanation: null,
  },
  {
    id: "5567e0bc7369641d11550200",
    cleaned_name: "investment management",
    tag_name_unanalyzed_downcase: "investment management",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 46912,
    num_people: 0,
    _index_type: "tag",
    _score: 0.75520384,
    _explanation: null,
  },
  {
    id: "55680a8273696407b61f0000",
    cleaned_name: "judiciary",
    tag_name_unanalyzed_downcase: "judiciary",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 5233,
    num_people: 0,
    _index_type: "tag",
    _score: 0.18278544,
    _explanation: null,
  },
  {
    id: "5567ce1f7369644d391c0000",
    cleaned_name: "law practice",
    tag_name_unanalyzed_downcase: "law practice",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 122972,
    num_people: 0,
    _index_type: "tag",
    _score: 1.1237602,
    _explanation: null,
  },
  {
    id: "5567cdd87369643bc12f0000",
    cleaned_name: "leisure, travel & tourism",
    tag_name_unanalyzed_downcase: "leisure, travel & tourism",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 137952,
    num_people: 0,
    _index_type: "tag",
    _score: 1.1701208,
    _explanation: null,
  },
  {
    id: "5567e1797369641c48c10100",
    cleaned_name: "legislative office",
    tag_name_unanalyzed_downcase: "legislative office",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 762,
    num_people: 0,
    _index_type: "tag",
    _score: 0.031892985,
    _explanation: null,
  },
  {
    id: "556808697369647bfd420000",
    cleaned_name: "libraries",
    tag_name_unanalyzed_downcase: "libraries",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 8306,
    num_people: 0,
    _index_type: "tag",
    _score: 0.26259345,
    _explanation: null,
  },
  {
    id: "5567e0e073696408da441e00",
    cleaned_name: "law enforcement",
    tag_name_unanalyzed_downcase: "law enforcement",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 15113,
    num_people: 0,
    _index_type: "tag",
    _score: 0.3998986,
    _explanation: null,
  },
  {
    id: "5567ce2d7369644d25250000",
    cleaned_name: "legal services",
    tag_name_unanalyzed_downcase: "legal services",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 102711,
    num_people: 0,
    _index_type: "tag",
    _score: 1.0519663,
    _explanation: null,
  },
  {
    id: "5567cd4973696439b9010000",
    cleaned_name: "logistics & supply chain",
    tag_name_unanalyzed_downcase: "logistics & supply chain",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 57579,
    num_people: 0,
    _index_type: "tag",
    _score: 0.82981175,
    _explanation: null,
  },
  {
    id: "5567cda97369644cfd3e0000",
    cleaned_name: "luxury goods & jewelry",
    tag_name_unanalyzed_downcase: "luxury goods & jewelry",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 30365,
    num_people: 0,
    _index_type: "tag",
    _score: 0.60600495,
    _explanation: null,
  },
  {
    id: "5567cd4973696439d53c0000",
    cleaned_name: "machinery",
    tag_name_unanalyzed_downcase: "machinery",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 100171,
    num_people: 0,
    _index_type: "tag",
    _score: 1.0420673,
    _explanation: null,
  },
  {
    id: "5567cdd47369643dbf260000",
    cleaned_name: "management consulting",
    tag_name_unanalyzed_downcase: "management consulting",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 327912,
    num_people: 0,
    _index_type: "tag",
    _score: 1.5288036,
    _explanation: null,
  },
  {
    id: "5567cd8273696439b1240000",
    cleaned_name: "maritime",
    tag_name_unanalyzed_downcase: "maritime",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 30524,
    num_people: 0,
    _index_type: "tag",
    _score: 0.60771227,
    _explanation: null,
  },
  {
    id: "5567e1387369641ec75d0200",
    cleaned_name: "market research",
    tag_name_unanalyzed_downcase: "market research",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 22213,
    num_people: 0,
    _index_type: "tag",
    _score: 0.5080312,
    _explanation: null,
  },
  {
    id: "5567cd467369644d39040000",
    cleaned_name: "marketing & advertising",
    tag_name_unanalyzed_downcase: "marketing & advertising",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 497904,
    num_people: 0,
    _index_type: "tag",
    _score: 1.7057816,
    _explanation: null,
  },
  {
    id: "5567e1b97369641ea9690200",
    cleaned_name: "medical devices",
    tag_name_unanalyzed_downcase: "medical devices",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 54465,
    num_people: 0,
    _index_type: "tag",
    _score: 0.80932397,
    _explanation: null,
  },
  {
    id: "5567ce2773696454308f0000",
    cleaned_name: "mental health care",
    tag_name_unanalyzed_downcase: "mental health care",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 44294,
    num_people: 0,
    _index_type: "tag",
    _score: 0.7347518,
    _explanation: null,
  },
  {
    id: "5567e0ea7369640d2ba31600",
    cleaned_name: "media production",
    tag_name_unanalyzed_downcase: "media production",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 100429,
    num_people: 0,
    _index_type: "tag",
    _score: 1.0430831,
    _explanation: null,
  },
  {
    id: "5567ce2673696453d95c0000",
    cleaned_name: "mechanical or industrial engineering",
    tag_name_unanalyzed_downcase: "mechanical or industrial engineering",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 119210,
    num_people: 0,
    _index_type: "tag",
    _score: 1.111296,
    _explanation: null,
  },
  {
    id: "5567d0467369645dbc200000",
    cleaned_name: "medical practice",
    tag_name_unanalyzed_downcase: "medical practice",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 85865,
    num_people: 0,
    _index_type: "tag",
    _score: 0.98166007,
    _explanation: null,
  },
  {
    id: "5567e2c572616932bb3b0000",
    cleaned_name: "military",
    tag_name_unanalyzed_downcase: "military",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 6219,
    num_people: 0,
    _index_type: "tag",
    _score: 0.21002407,
    _explanation: null,
  },
  {
    id: "5567e3f3736964395d7a0000",
    cleaned_name: "mining & metals",
    tag_name_unanalyzed_downcase: "mining & metals",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 59215,
    num_people: 0,
    _index_type: "tag",
    _score: 0.8402002,
    _explanation: null,
  },
  {
    id: "5567cdd7736964540d130000",
    cleaned_name: "motion pictures & film",
    tag_name_unanalyzed_downcase: "motion pictures & film",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 39352,
    num_people: 0,
    _index_type: "tag",
    _score: 0.6933048,
    _explanation: null,
  },
  {
    id: "5567e15373696422aa0a0000",
    cleaned_name: "museums & institutions",
    tag_name_unanalyzed_downcase: "museums & institutions",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 16312,
    num_people: 0,
    _index_type: "tag",
    _score: 0.42015386,
    _explanation: null,
  },
  {
    id: "5567cd4f736964540d050000",
    cleaned_name: "music",
    tag_name_unanalyzed_downcase: "music",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 92813,
    num_people: 0,
    _index_type: "tag",
    _score: 1.012048,
    _explanation: null,
  },
  {
    id: "5567e7be736964110e210000",
    cleaned_name: "nanotechnology",
    tag_name_unanalyzed_downcase: "nanotechnology",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 3565,
    num_people: 0,
    _index_type: "tag",
    _score: 0.1324198,
    _explanation: null,
  },
  {
    id: "5567cd4a73696439a9010000",
    cleaned_name: "newspapers",
    tag_name_unanalyzed_downcase: "newspapers",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 16027,
    num_people: 0,
    _index_type: "tag",
    _score: 0.4154241,
    _explanation: null,
  },
  {
    id: "5567cd4773696454303a0000",
    cleaned_name: "nonprofit organization management",
    tag_name_unanalyzed_downcase: "nonprofit organization management",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 148896,
    num_people: 0,
    _index_type: "tag",
    _score: 1.201113,
    _explanation: null,
  },
  {
    id: "5567cdd97369645624020000",
    cleaned_name: "oil & energy",
    tag_name_unanalyzed_downcase: "oil & energy",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 101398,
    num_people: 0,
    _index_type: "tag",
    _score: 1.0468774,
    _explanation: null,
  },
  {
    id: "5567cdb373696439dd540000",
    cleaned_name: "online media",
    tag_name_unanalyzed_downcase: "online media",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 47701,
    num_people: 0,
    _index_type: "tag",
    _score: 0.7611833,
    _explanation: null,
  },
  {
    id: "5567d04173696457ee520000",
    cleaned_name: "outsourcing/offshoring",
    tag_name_unanalyzed_downcase: "outsourcing/offshoring",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 21441,
    num_people: 0,
    _index_type: "tag",
    _score: 0.49749634,
    _explanation: null,
  },
  {
    id: "5567e8bb7369641a658f0000",
    cleaned_name: "package/freight delivery",
    tag_name_unanalyzed_downcase: "package/freight delivery",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 10003,
    num_people: 0,
    _index_type: "tag",
    _score: 0.30109513,
    _explanation: null,
  },
  {
    id: "5567e36973696431a4480000",
    cleaned_name: "packaging & containers",
    tag_name_unanalyzed_downcase: "packaging & containers",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 24602,
    num_people: 0,
    _index_type: "tag",
    _score: 0.5391012,
    _explanation: null,
  },
  {
    id: "5567e97f7369641e57730100",
    cleaned_name: "paper & forest products",
    tag_name_unanalyzed_downcase: "paper & forest products",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 15309,
    num_people: 0,
    _index_type: "tag",
    _score: 0.40327498,
    _explanation: null,
  },
  {
    id: "5567e0af7369641ec7300000",
    cleaned_name: "performing arts",
    tag_name_unanalyzed_downcase: "performing arts",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 35169,
    num_people: 0,
    _index_type: "tag",
    _score: 0.65484047,
    _explanation: null,
  },
  {
    id: "5567e0eb73696410e4bd1200",
    cleaned_name: "pharmaceuticals",
    tag_name_unanalyzed_downcase: "pharmaceuticals",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 56909,
    num_people: 0,
    _index_type: "tag",
    _score: 0.8254845,
    _explanation: null,
  },
  {
    id: "5567ce9673696453d99f0000",
    cleaned_name: "philanthropy",
    tag_name_unanalyzed_downcase: "philanthropy",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 17586,
    num_people: 0,
    _index_type: "tag",
    _score: 0.44068873,
    _explanation: null,
  },
  {
    id: "5567cd4f7369644cfd250000",
    cleaned_name: "photography",
    tag_name_unanalyzed_downcase: "photography",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 87295,
    num_people: 0,
    _index_type: "tag",
    _score: 0.9880905,
    _explanation: null,
  },
  {
    id: "5567cdda7369644cf95d0000",
    cleaned_name: "plastics",
    tag_name_unanalyzed_downcase: "plastics",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 27718,
    num_people: 0,
    _index_type: "tag",
    _score: 0.57654864,
    _explanation: null,
  },
  {
    id: "5567e25f736964256cff0000",
    cleaned_name: "political organization",
    tag_name_unanalyzed_downcase: "political organization",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 10726,
    num_people: 0,
    _index_type: "tag",
    _score: 0.31651548,
    _explanation: null,
  },
  {
    id: "5567cdd97369645430680000",
    cleaned_name: "primary/secondary education",
    tag_name_unanalyzed_downcase: "primary/secondary education",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 44065,
    num_people: 0,
    _index_type: "tag",
    _score: 0.7329162,
    _explanation: null,
  },
  {
    id: "5567cd4d7369644d513e0000",
    cleaned_name: "printing",
    tag_name_unanalyzed_downcase: "printing",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 59956,
    num_people: 0,
    _index_type: "tag",
    _score: 0.84482497,
    _explanation: null,
  },
  {
    id: "5567cd49736964541d010000",
    cleaned_name: "professional training & coaching",
    tag_name_unanalyzed_downcase: "professional training & coaching",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 136653,
    num_people: 0,
    _index_type: "tag",
    _score: 1.166291,
    _explanation: null,
  },
  {
    id: "5567e2907369642433e60200",
    cleaned_name: "program development",
    tag_name_unanalyzed_downcase: "program development",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 18734,
    num_people: 0,
    _index_type: "tag",
    _score: 0.45839608,
    _explanation: null,
  },
  {
    id: "5567ce5973696453d9780000",
    cleaned_name: "public relations & communications",
    tag_name_unanalyzed_downcase: "public relations & communications",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 60403,
    num_people: 0,
    _index_type: "tag",
    _score: 0.84759116,
    _explanation: null,
  },
  {
    id: "5567ce5b73696439a17a0000",
    cleaned_name: "publishing",
    tag_name_unanalyzed_downcase: "publishing",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 71491,
    num_people: 0,
    _index_type: "tag",
    _score: 0.9111096,
    _explanation: null,
  },
  {
    id: "5567e28a7369642ae2500000",
    cleaned_name: "public policy",
    tag_name_unanalyzed_downcase: "public policy",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 9652,
    num_people: 0,
    _index_type: "tag",
    _score: 0.29340675,
    _explanation: null,
  },
  {
    id: "5567cd4a7369643ba9010000",
    cleaned_name: "public safety",
    tag_name_unanalyzed_downcase: "public safety",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 17468,
    num_people: 0,
    _index_type: "tag",
    _score: 0.43882704,
    _explanation: null,
  },
  {
    id: "5567e14673696416d38c0300",
    cleaned_name: "railroad manufacture",
    tag_name_unanalyzed_downcase: "railroad manufacture",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 6500,
    num_people: 0,
    _index_type: "tag",
    _score: 0.21748394,
    _explanation: null,
  },
  {
    id: "5567fd5a73696442b0f20000",
    cleaned_name: "ranching",
    tag_name_unanalyzed_downcase: "ranching",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 6361,
    num_people: 0,
    _index_type: "tag",
    _score: 0.21380983,
    _explanation: null,
  },
  {
    id: "5567e134736964214f5e0000",
    cleaned_name: "recreational facilities & services",
    tag_name_unanalyzed_downcase: "recreational facilities & services",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 36796,
    num_people: 0,
    _index_type: "tag",
    _score: 0.67020875,
    _explanation: null,
  },
  {
    id: "5567cd477369645401010000",
    cleaned_name: "real estate",
    tag_name_unanalyzed_downcase: "real estate",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 390492,
    num_people: 0,
    _index_type: "tag",
    _score: 1.6025938,
    _explanation: null,
  },
  {
    id: "5567e0f27369640e5aed0c00",
    cleaned_name: "religious institutions",
    tag_name_unanalyzed_downcase: "religious institutions",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 63938,
    num_people: 0,
    _index_type: "tag",
    _score: 0.8688677,
    _explanation: null,
  },
  {
    id: "5567cd49736964540d020000",
    cleaned_name: "renewables & environment",
    tag_name_unanalyzed_downcase: "renewables & environment",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 74529,
    num_people: 0,
    _index_type: "tag",
    _score: 0.9270057,
    _explanation: null,
  },
  {
    id: "5567e09f736964160ebb0100",
    cleaned_name: "research",
    tag_name_unanalyzed_downcase: "research",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 57071,
    num_people: 0,
    _index_type: "tag",
    _score: 0.82653475,
    _explanation: null,
  },
  {
    id: "5567e0e0736964198de70700",
    cleaned_name: "restaurants",
    tag_name_unanalyzed_downcase: "restaurants",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 210742,
    num_people: 0,
    _index_type: "tag",
    _score: 1.343885,
    _explanation: null,
  },
  {
    id: "5567ced173696450cb580000",
    cleaned_name: "retail",
    tag_name_unanalyzed_downcase: "retail",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 289296,
    num_people: 0,
    _index_type: "tag",
    _score: 1.4761009,
    _explanation: null,
  },
  {
    id: "5567e19b7369641ead740000",
    cleaned_name: "security & investigations",
    tag_name_unanalyzed_downcase: "security & investigations",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 51116,
    num_people: 0,
    _index_type: "tag",
    _score: 0.7861549,
    _explanation: null,
  },
  {
    id: "5567e0d87369640e5aa30c00",
    cleaned_name: "semiconductors",
    tag_name_unanalyzed_downcase: "semiconductors",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 11120,
    num_people: 0,
    _index_type: "tag",
    _score: 0.32469392,
    _explanation: null,
  },
  {
    id: "5568047d7369646d406c0000",
    cleaned_name: "shipbuilding",
    tag_name_unanalyzed_downcase: "shipbuilding",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 5408,
    num_people: 0,
    _index_type: "tag",
    _score: 0.18774627,
    _explanation: null,
  },
  {
    id: "5567ce227369644eed290000",
    cleaned_name: "sports",
    tag_name_unanalyzed_downcase: "sports",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 104265,
    num_people: 0,
    _index_type: "tag",
    _score: 1.0579132,
    _explanation: null,
  },
  {
    id: "5567e113736964198d5e0800",
    cleaned_name: "sporting goods",
    tag_name_unanalyzed_downcase: "sporting goods",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 33385,
    num_people: 0,
    _index_type: "tag",
    _score: 0.6373396,
    _explanation: null,
  },
  {
    id: "5567e2a97369642a553d0000",
    cleaned_name: "supermarkets",
    tag_name_unanalyzed_downcase: "supermarkets",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 5680,
    num_people: 0,
    _index_type: "tag",
    _score: 0.19534606,
    _explanation: null,
  },
  {
    id: "5567e09973696410db020800",
    cleaned_name: "staffing & recruiting",
    tag_name_unanalyzed_downcase: "staffing & recruiting",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 105366,
    num_people: 0,
    _index_type: "tag",
    _score: 1.0620779,
    _explanation: null,
  },
  {
    id: "5567e1de7369642069ea0100",
    cleaned_name: "think tanks",
    tag_name_unanalyzed_downcase: "think tanks",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 12783,
    num_people: 0,
    _index_type: "tag",
    _score: 0.3576109,
    _explanation: null,
  },
  {
    id: "55680085736964551e070000",
    cleaned_name: "tobacco",
    tag_name_unanalyzed_downcase: "tobacco",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 5608,
    num_people: 0,
    _index_type: "tag",
    _score: 0.19334725,
    _explanation: null,
  },
  {
    id: "5567cd4e7369644cf93b0000",
    cleaned_name: "transportation/trucking/railroad",
    tag_name_unanalyzed_downcase: "transportation/trucking/railroad",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 126539,
    num_people: 0,
    _index_type: "tag",
    _score: 1.1352566,
    _explanation: null,
  },
  {
    id: "5567cd4c7369644d39080000",
    cleaned_name: "telecommunications",
    tag_name_unanalyzed_downcase: "telecommunications",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 111199,
    num_people: 0,
    _index_type: "tag",
    _score: 1.0834991,
    _explanation: null,
  },
  {
    id: "5567e1327369641d91ce0300",
    cleaned_name: "textiles",
    tag_name_unanalyzed_downcase: "textiles",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 46251,
    num_people: 0,
    _index_type: "tag",
    _score: 0.75013024,
    _explanation: null,
  },
  {
    id: "5567e1097369641d91230300",
    cleaned_name: "translation & localization",
    tag_name_unanalyzed_downcase: "translation & localization",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 17749,
    num_people: 0,
    _index_type: "tag",
    _score: 0.44324732,
    _explanation: null,
  },
  {
    id: "5567e2127369642420170000",
    cleaned_name: "utilities",
    tag_name_unanalyzed_downcase: "utilities",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 34656,
    num_people: 0,
    _index_type: "tag",
    _score: 0.6498798,
    _explanation: null,
  },
  {
    id: "5567e1587369641c48370000",
    cleaned_name: "venture capital & private equity",
    tag_name_unanalyzed_downcase: "venture capital & private equity",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 28483,
    num_people: 0,
    _index_type: "tag",
    _score: 0.5852689,
    _explanation: null,
  },
  {
    id: "5567ce9673696439d5c10000",
    cleaned_name: "veterinary",
    tag_name_unanalyzed_downcase: "veterinary",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 29361,
    num_people: 0,
    _index_type: "tag",
    _score: 0.59506613,
    _explanation: null,
  },
  {
    id: "5567e127736964181e700200",
    cleaned_name: "warehousing",
    tag_name_unanalyzed_downcase: "warehousing",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 16202,
    num_people: 0,
    _index_type: "tag",
    _score: 0.41833442,
    _explanation: null,
  },
  {
    id: "5567d01e73696457ee100000",
    cleaned_name: "wholesale",
    tag_name_unanalyzed_downcase: "wholesale",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 94677,
    num_people: 0,
    _index_type: "tag",
    _score: 1.0198512,
    _explanation: null,
  },
  {
    id: "5567cd4d7369643b78100000",
    cleaned_name: "wine & spirits",
    tag_name_unanalyzed_downcase: "wine & spirits",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 28389,
    num_people: 0,
    _index_type: "tag",
    _score: 0.5842068,
    _explanation: null,
  },
  {
    id: "5567e3ca736964371b130000",
    cleaned_name: "wireless",
    tag_name_unanalyzed_downcase: "wireless",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 9966,
    num_people: 0,
    _index_type: "tag",
    _score: 0.30029106,
    _explanation: null,
  },
  {
    id: "5567cdd973696439a1370000",
    cleaned_name: "writing & editing",
    tag_name_unanalyzed_downcase: "writing & editing",
    parent_tag_id: null,
    uid: null,
    kind: "linkedin_industry",
    has_children: false,
    category: null,
    tag_category_downcase: null,
    num_organizations: 46665,
    num_people: 0,
    _index_type: "tag",
    _score: 0.7533149,
    _explanation: null,
  },
];

export const technologies = [
  { title: "Mobile Friendly", value: "0" },
  { title: "Google Front API", value: "1" },
  { title: "WordPress.org", value: "2" },
  { title: "Apache", value: "3" },
  { title: "Google Tag Manager", value: "4" },
  { title: "Nginx", value: "5" },
  { title: "Google Analytics", value: "6" },
  { title: "Outlook", value: "7" },
];

export const employeRange = [
  [1, 10],
  [11, 50],
  [51, 100],
  [101, 500],
  [501, 1000],
  [1001, 2000],
  [2001, 5000],
  [5001, 10000],
  ["10001+"],
];

export const FILTERS = [
  { name: "Location", type: "multi-select", id: "location" },
  {
    name: "Number of Employees",
    type: "multi-select",
    id: "compSize",

    options: [
      [1, 10],
      [11, 50],
      [51, 100],
      [101, 500],
      [501, 1000],
      [1001, 2000],
      [2001, 5000],
      [5001, 10000],
      [10001, 25000],
    ],
    // options: [
    //   [1, 10],
    //   [11, 20],
    //   [21, 50],
    //   [51, 100],
    //   [101, 500],
    //   [501, 1000],
    //   [1001, 2000],
    //   [2001, 5000],
    //   [5001, 10000],
    //   [10001, 1000000000],
    // ],
  },
  {
    name: "Revenue",
    type: "range-slider",
    id: "revenue",
    options: {
      range: { min: 100000, max: 50000000 },
      values: [
        [100000, 10000000],
        [10000000, 50000000], // 10000000-50000000
        [50000000, 100000000], // 50000000-100000000
        [100000000, 500000000], // 100000000-500000000
        [500000000, 1000000000], // 500000000-1000000000
        [1000000000, 5000000000], // 1000000000-5000000000
        [5000000000, 10000000000], // 5000000000<
      ],
    },
  },
  {
    name: "Funding",
    type: "range-slider",
    id: "funding",
    options: {
      range: { min: 100000, max: 50000000 },
    },
  },
  { name: "Keywords", type: "input", id: "keywords" },
  // { name: "Target Technologies", type: "input", id: "techStack" },
  { name: "Exclude Domains", type: "input", id: "blockedDomains" },
  { name: "Exclude Keywords", type: "input", id: "blockedKeywords" },
  { name: "Exclude Locations", type: "input", id: "blockedLocations" },
  { name: "allKeywords", type: "input", id: "allKeywords" },
  { name: "Company Keywords", type: "input", id: "andCompanyKeywords" },
  // {name: "Skip", type: "input", id: "skip"},
];
export const APOLLOFILTERS = [
  { name: "Location", type: "multi-select", id: "apollolocation" },
  {
    name: "Number of Employees",
    type: "multi-select",
    id: "apollocompSize",

    options: [
      [1, 10],
      [11, 50],
      [51, 100],
      [101, 500],
      [501, 1000],
      [1001, 5000],
      [5001, 10000],
      [10001, 25000],
      [25001, 100000],
      [100001, 2000000],
    ],
    // options: [
    //   [1, 10],
    //   [11, 20],
    //   [21, 50],
    //   [51, 100],
    //   [101, 500],
    //   [501, 1000],
    //   [1001, 2000],
    //   [2001, 5000],
    //   [5001, 10000],
    //   [10001, 1000000000],
    // ],
  },
  {
    name: "Revenue",
    type: "range-slider",
    id: "apollorevenue",
    options: {
      range: { min: 100000, max: 50000000 },
      values: [
        [100000, 10000000],
        [10000000, 50000000], // 10000000-50000000
        [50000000, 100000000], // 50000000-100000000
        [100000000, 500000000], // 100000000-500000000
        [500000000, 1000000000], // 500000000-1000000000
        [1000000000, 5000000000], // 1000000000-5000000000
        [5000000000, 10000000000], // 5000000000<
      ],
    },
  },
  {
    name: "Funding",
    type: "range-slider",
    id: "apollofunding",
    options: {
      range: { min: 100000, max: 50000000 },
    },
  },
  { name: "Keywords", type: "input", id: "apollokeywords" },
  // { name: "Target Technologies", type: "input", id: "techStack" },
  { name: "Exclude Domains", type: "input", id: "apolloblockedDomains" },
  { name: "Exclude Keywords", type: "input", id: "apolloblockedKeywords" },
  { name: "Exclude Locations", type: "input", id: "apolloblockedLocations" },
  // {name: "Skip", type: "input", id: "skip"},
];

// ! initialize filters here
export const initAddFilters = {
  location: [],
  revenue: [],
  funding: [],
  compSize: [],
  keywords: [],
  allKeywords: [],
  andCompanyKeywords: [],
  blockedDomains: [],
  blockedLocations: [],
  blockedIndustries: [],
  blockedKeywords: [],
  // techStack: [],
};

export const initApolloAddFilters = {
  location: [],
  revenue: [],
  funding: [],
  compSize: [],
  keywords: [],
  allKeywords: [],
  andCompanyKeywords: [],
  blockedDomains: [],
  selectedDomains: [],
  blockedLocations: [],
  blockedIndustries: [],
  blockedKeywords: [],
  zip_code: {
    code: "",
    radius: "",
  },
  // techStack: [],
};

export const initAdditionalFilters = {
  name: "",
  managementLevels: [],
  changedJob: "",
  departments: [],
  skills: [],
  schools: [],
  majors: [],
  degree: [],
  experience: "",
  companies: [],
  includePast: false,
  excludeContacts: false,
  socialLink: "",
  radius: [],
};

export const initApolloAdditionalFilters = {
  managementLevels: [],
  changedJob: "",
  departments: [],
  skills: [],
  experience: "",
  companies: [],
  socialLink: "",
};

const _1H = 100;
const _1M = 1000000;
const _1T = 1000000000000;

export const moveRange = (value, oRange, nRange) => {
  const [oldMin, oldMax] = oRange;
  const [newMin, newMax] = nRange;
  const modRange = value.map((oldValue) => {
    const oldRange = oldMax - oldMin;
    const newRange = newMax - newMin;
    const value = ((oldValue - oldMin) * newRange) / oldRange + newMin;
    return Math.round(value);
  });
  // console.log(value);
  // console.log(modRange);
  return modRange;
};

export const DESIGNATION = [
  "CEO",
  "CFO",
  "CTO",
  "CMO",
  "Sales Manager",
  "Marketing Lead",
];

export const TITLES = [
  "CMO",
  "Head Marketing",
  "Head Of Sales",
  "Sales Manager",
  "Director Of Sales",
  "BDR",
  "SDR",
  "Director Of Business Development",
];
export const YearsOfExperience = ["0-3", "3-10", "10<"];
export const companyList = [
  "world",
  "company",
  "API Companies",
  "My Companies",
];
export const educationMajor = [
  "Computer Science",
  "Business Administration",
  "Management",
  "Marketing",
  "Accounting",
];
export const degree = [
  "Bachelors",
  "Masters",
  "Associates",
  "Doctorates",
  "High School",
];
export const DEPARTMENT = [
  {
    title: "Product & Engineering",
  },

  {
    title: "Graphic Design",
    grpId: "Product & Engineering",
  },
  {
    title: "Product Design",
    grpId: "Product & Engineering",
  },
  {
    title: "Web Design",
    grpId: "Product & Engineering",
  },
  {
    title: "Data",
    grpId: "Product & Engineering",
  },
  {
    title: "Devops",
    grpId: "Product & Engineering",
  },
  {
    title: "Electrical",
    grpId: "Product & Engineering",
  },
  {
    title: "Mechanical",
    grpId: "Product & Engineering",
  },
  {
    title: "Network",
    grpId: "Product & Engineering",
  },
  {
    title: "Appointment Scheduling",
    grpId: "Product & Engineering",
  },
  {
    title: "Project Engineering",
    grpId: "Product & Engineering",
  },
  {
    title: "Quality Assurance",
    grpId: "Product & Engineering",
  },
  {
    title: "Security",
    grpId: "Product & Engineering",
  },
  {
    title: "Software",
    grpId: "Product & Engineering",
  },
  {
    title: "Systems",
    grpId: "Product & Engineering",
  },
  {
    title: "Web",
    grpId: "Product & Engineering",
  },
  { title: "Finance" },

  {
    title: "Accounting",
    grpId: "Finance",
  },
  {
    title: "Investment",
    grpId: "Finance",
  },
  {
    title: "Tax",
    grpId: "Finance",
  },
  { title: "HR" },
  {
    title: "Compensation",
    grpId: "HR",
  },
  {
    title: "Employee Development",
    grpId: "HR",
  },
  {
    title: "Recruiting",
    grpId: "HR",
  },
  { title: "Legal" },

  {
    title: "Judicial",
    grpId: "Legal",
  },
  {
    title: "Lawyer",
    grpId: "Legal",
  },
  {
    title: "Paralegal",
    grpId: "Legal",
  },
  { title: "Marketing" },

  {
    title: "Brand Marketing",
    grpId: "Marketing",
  },
  {
    title: "Content Marketing",
    grpId: "Marketing",
  },
  {
    title: "Product Marketing",
    grpId: "Marketing",
  },
  {
    title: "Events",
    grpId: "Marketing",
  },
  {
    title: "Media Relations",
    grpId: "Marketing",
  },
  {
    title: "Broadcasting",
    grpId: "Marketing",
  },
  {
    title: "Editorial",
    grpId: "Marketing",
  },
  {
    title: "Journalism",
    grpId: "Marketing",
  },
  {
    title: "Video",
    grpId: "Marketing",
  },
  {
    title: "Writing",
    grpId: "Marketing",
  },
  { title: "Health" },

  {
    title: "Dental",
    grpId: "Health",
  },
  {
    title: "Doctor",
    grpId: "Health",
  },
  {
    title: "Fitness",
    grpId: "Health",
  },
  {
    title: "Nursing",
    grpId: "Health",
  },
  {
    title: "Therapy",
    grpId: "Health",
  },
  {
    title: "Wellness",
    grpId: "Health",
  },
  { title: "Operations" },

  {
    title: "Logistics",
    grpId: "Operations",
  },
  {
    title: "Office Management",
    grpId: "Operations",
  },
  {
    title: "Product",
    grpId: "Operations",
  },
  {
    title: "Project Management",
    grpId: "Operations",
  },
  {
    title: "Customer Success",
    grpId: "Operations",
  },
  {
    title: "Support",
    grpId: "Operations",
  },
  { title: "Sales" },

  {
    title: "Accounts",
    grpId: "Sales",
  },
  {
    title: "Business Development",
    grpId: "Sales",
  },
  {
    title: "Pipeline",
    grpId: "Sales",
  },
];

export const DEPARTMENTSOPTIONS = [
  {
    title: "C suite",
    key: "c_suite",
  },
  {
    title: "Executive",
    key: "executive",
  },
  {
    title: "Finance Executive",
    key: "finance_executive",
  },
  {
    title: "Founder",
    key: "founder",
  },
  {
    title: "Human Resources Executive",
    key: "human_resources_executive",
  },
  {
    title: "Information Technology Executive",
    key: "information_technology_executive",
  },
  {
    title: "Legal Executive",
    key: "legal_executive",
  },
  {
    title: "Marketing Executive",
    key: "marketing_executive",
  },
  {
    title: "Medical & Health Executive",
    key: "medical_health_executive",
  },
  {
    title: "Operations Executive",
    key: "operations_executive",
  },
  {
    title: "Sales Leader",
    key: "sales_leader",
  },

  // product
  {
    title: "Product Development",
    key: "product_development",
  },
  {
    title: "Product Management",
    key: "product_management",
  },

  // Engg & Technical
  {
    title: "Master Engineering & Technical",
    key: "master_engineering_technical",
  },

  {
    title: "Artificial Intelligence / Machine Learning",
    key: "artificial_intelligence_machine_learning",
  },
  {
    title: "Bioengineering",
    key: "bioengineering",
  },
  {
    title: "Biometrics",
    key: "biometrics",
  },
  {
    title: "Business Intelligence",
    key: "business_intelligence",
  },
  {
    title: "Chemical Engineering",
    key: "chemical_engineering",
  },
  {
    title: "Cloud / Mobility",
    key: "cloud_mobility",
  },
  {
    title: "Data Science",
    key: "data_science",
  },
  {
    title: "DevOps",
    key: "devops",
  },
  {
    title: "Digital Transformation",
    key: "digital_transformation",
  },
  {
    title: "Emerging Technology / Innovation",
    key: "emerging_technology_innovation",
  },
  {
    title: "Engineering & Technical",
    key: "engineering_technical",
  },
  {
    title: "Industrial Engineering",
    key: "industrial_engineering",
  },
  {
    title: "Mechanic",
    key: "mechanic",
  },
  {
    title: "Mobile Development",
    key: "mobile_development",
  },
  {
    title: "Project Management",
    key: "project_management",
  },
  {
    title: "Research & Development",
    key: "research_development",
  },
  {
    title: "Scrum Master / Agile Coach",
    key: "scrum_master_agile_coach",
  },
  {
    title: "Software Development",
    key: "software_development",
  },
  {
    title: "Support / Technical Services",
    key: "support_technical_services",
  },
  {
    title: "Technician",
    key: "technician",
  },
  {
    title: "Technology Operations",
    key: "technology_operations",
  },
  {
    title: "Test / Quality Assurance",
    key: "test_quality_assurance",
  },
  {
    title: "UI / UX",
    key: "ui_ux",
  },
  {
    title: "Web Development",
    key: "web_development",
  },
  // design
  {
    title: "Design",
    key: "design",
  },

  {
    title: "All Design",
    key: "all_design",
  },
  {
    title: "Product or UI/UX Design",
    key: "product_ui_ux_design",
  },
  {
    title: "Graphic / Visual / Brand Design",
    key: "graphic_design",
  },
  //education
  {
    title: "Education",
    key: "education",
  },

  {
    title: "Teacher",
    key: "teacher",
  },
  {
    title: "Principal",
    key: "principal",
  },
  {
    title: "Superintendent",
    key: "superintendent",
  },
  {
    title: "Professor",
    key: "professor",
  },
  // finance
  {
    title: "Master Finance",
    key: "master_finance",
  },

  {
    title: "Accounting",
    key: "accounting",
  },
  {
    title: "Finance",
    key: "finance",
  },
  {
    title: "Financial Planning & Analysis",
    key: "financial_planning_analysis",
  },
  {
    title: "Financial Reporting",
    key: "financial_reporting",
  },
  {
    title: "Financial Strategy",
    key: "financial_strategy",
  },
  {
    title: "Financial Systems",
    key: "financial_systems",
  },
  {
    title: "Internal Audit & Control",
    key: "internal_audit_control",
  },
  {
    title: "Investor Relations",
    key: "investor_relations",
  },
  {
    title: "Mergers & Acquisitions",
    key: "mergers_acquisitions",
  },
  {
    title: "Real Estate Finance",
    key: "real_estate_finance",
  },
  {
    title: "Financial Risk",
    key: "financial_risk",
  },
  {
    title: "Shared Services",
    key: "shared_services",
  },
  {
    title: "Sourcing / Procurement",
    key: "sourcing_procurement",
  },
  {
    title: "Tax",
    key: "tax",
  },
  {
    title: "Treasury",
    key: "treasury",
  },
  //Human Resource

  {
    title: "Master Human Resources",
    key: "master_human_resources",
  },

  {
    title: "Compensation & Benefits",
    key: "compensation_benefits",
  },
  {
    title: "Culture, Diversity & Inclusion",
    key: "culture_diversity_inclusion",
  },
  {
    title: "Employee & Labor Relations",
    key: "employee_labor_relations",
  },
  {
    title: "Health & Safety",
    key: "health_safety",
  },
  {
    title: "Human Resource Information System",
    key: "human_resource_information_system",
  },
  {
    title: "Human Resources",
    key: "human_resources",
  },
  {
    title: "HR Business Partner",
    key: "hr_business_partner",
  },
  {
    title: "Learning & Development",
    key: "learning_development",
  },
  {
    title: "Organizational Development",
    key: "organizational_development",
  },
  {
    title: "Recruiting & Talent Acquisition",
    key: "recruiting_talent_acquisition",
  },
  {
    title: "Talent Management",
    key: "talent_management",
  },
  {
    title: "Workforce Management",
    key: "workforce_management",
  },
  {
    title: "People Operations",
    key: "people_operations",
  },
  // Information Technology

  {
    title: "Master Information Technology",
    key: "master_information_technology",
  },

  {
    title: "Application Development",
    key: "application_development",
  },
  {
    title: "Business Service Management / ITSM",
    key: "business_service_management_itsm",
  },
  {
    title: "Collaboration & Web App",
    key: "collaboration_web_app",
  },
  {
    title: "Data Center",
    key: "data_center",
  },
  {
    title: "Data Warehouse",
    key: "data_warehouse",
  },
  {
    title: "Database Administration",
    key: "database_administration",
  },
  {
    title: "eCommerce Development",
    key: "ecommerce_development",
  },
  {
    title: "Enterprise Architecture",
    key: "enterprise_architecture",
  },
  {
    title: "Help Desk / Desktop Services",
    key: "help_desk_desktop_services",
  },
  {
    title: "HR / Financial / ERP Systems",
    key: "hr_financial_erp_systems",
  },
  {
    title: "Information Security",
    key: "information_security",
  },
  {
    title: "Information Technology",
    key: "information_technology",
  },
  {
    title: "Infrastructure",
    key: "infrastructure",
  },
  {
    title: "IT Asset Management",
    key: "it_asset_management",
  },
  {
    title: "IT Audit / IT Compliance",
    key: "it_audit_it_compliance",
  },
  {
    title: "IT Operations",
    key: "it_operations",
  },
  {
    title: "IT Procurement",
    key: "it_procurement",
  },
  {
    title: "IT Strategy",
    key: "it_strategy",
  },
  {
    title: "IT Training",
    key: "it_training",
  },
  {
    title: "Networking",
    key: "networking",
  },
  {
    title: "Project & Program Management",
    key: "project_program_management",
  },
  {
    title: "Quality Assurance",
    key: "quality_assurance",
  },
  {
    title: "Retail / Store Systems",
    key: "retail_store_systems",
  },
  {
    title: "Servers",
    key: "servers",
  },
  {
    title: "Storage & Disaster Recovery",
    key: "storage_disaster_recovery",
  },
  {
    title: "Telecommunications",
    key: "telecommunications",
  },
  {
    title: "Virtualization",
    key: "virtualization",
  },
  //legal
  {
    title: "Master Legal",
    key: "master_legal",
  },

  {
    title: "Acquisitions",
    key: "acquisitions",
  },
  {
    title: "Compliance",
    key: "compliance",
  },
  {
    title: "Contracts",
    key: "contracts",
  },
  {
    title: "Corporate Secretary",
    key: "corporate_secretary",
  },
  {
    title: "eDiscovery",
    key: "ediscovery",
  },
  {
    title: "Ethics",
    key: "ethics",
  },
  {
    title: "Governance",
    key: "governance",
  },
  {
    title: "Governmental Affairs & Regulatory Law",
    key: "governmental_affairs_regulatory_law",
  },
  {
    title: "Intellectual Property & Patent",
    key: "intellectual_property_patent",
  },
  {
    title: "Labor & Employment",
    key: "labor_employment",
  },
  {
    title: "Lawyer / Attorney",
    key: "lawyer_attorney",
  },
  {
    title: "Legal",
    key: "legal",
  },
  {
    title: "Legal Counsel",
    key: "legal_counsel",
  },
  {
    title: "Legal Operations",
    key: "legal_operations",
  },
  {
    title: "Litigation",
    key: "litigation",
  },
  {
    title: "Privacy",
    key: "privacy",
  },
  //marketing
  {
    title: "Master Marketing",
    key: "master_marketing",
  },

  {
    title: "Advertising",
    key: "advertising",
  },
  {
    title: "Brand Management",
    key: "brand_management",
  },
  {
    title: "Content Marketing",
    key: "content_marketing",
  },
  {
    title: "Customer Experience",
    key: "customer_experience",
  },
  {
    title: "Customer Marketing",
    key: "customer_marketing",
  },
  {
    title: "Demand Generation",
    key: "demand_generation",
  },
  {
    title: "Digital Marketing",
    key: "digital_marketing",
  },
  {
    title: "eCommerce Marketing",
    key: "ecommerce_marketing",
  },
  {
    title: "Event Marketing",
    key: "event_marketing",
  },
  {
    title: "Field Marketing",
    key: "field_marketing",
  },
  {
    title: "Lead Generation",
    key: "lead_generation",
  },
  {
    title: "Marketing",
    key: "marketing",
  },
  {
    title: "Marketing Analytics / Insights",
    key: "marketing_analytics_insights",
  },
  {
    title: "Marketing Communications",
    key: "marketing_communications",
  },
  {
    title: "Marketing Operations",
    key: "marketing_operations",
  },
  {
    title: "Product Marketing",
    key: "product_marketing",
  },
  {
    title: "Public Relations",
    key: "public_relations",
  },
  {
    title: "Search Engine Optimization / Pay Per Click",
    key: "search_engine_optimization_pay_per_click",
  },
  {
    title: "Social Media Marketing",
    key: "social_media_marketing",
  },
  {
    title: "Strategic Communications",
    key: "strategic_communications",
  },
  {
    title: "Technical Marketing",
    key: "technical_marketing",
  },
  // health
  {
    title: "Medical & Health",
    key: "medical_health",
  },

  {
    title: "Anesthesiology",
    key: "anesthesiology",
  },
  {
    title: "Chiropractics",
    key: "chiropractics",
  },
  {
    title: "Clinical Systems",
    key: "clinical_systems",
  },
  {
    title: "Dentistry",
    key: "dentistry",
  },
  {
    title: "Dermatology",
    key: "dermatology",
  },
  {
    title: "Doctors / Physicians",
    key: "doctors_physicians",
  },
  {
    title: "Epidemiology",
    key: "epidemiology",
  },
  {
    title: "First Responder",
    key: "first_responder",
  },
  {
    title: "Infectious Disease",
    key: "infectious_disease",
  },
  {
    title: "Medical Administration",
    key: "medical_administration",
  },
  {
    title: "Medical Education & Training",
    key: "medical_education_training",
  },
  {
    title: "Medical Research",
    key: "medical_research",
  },
  {
    title: "Medicine",
    key: "medicine",
  },
  {
    title: "Neurology",
    key: "neurology",
  },
  {
    title: "Nursing",
    key: "nursing",
  },
  {
    title: "Nutrition & Dietetics",
    key: "nutrition_dietetics",
  },
  {
    title: "Obstetrics / Gynecology",
    key: "obstetrics_gynecology",
  },
  {
    title: "Oncology",
    key: "oncology",
  },
  {
    title: "Opthalmology",
    key: "opthalmology",
  },
  {
    title: "Optometry",
    key: "optometry",
  },
  {
    title: "Orthopedics",
    key: "orthopedics",
  },
  {
    title: "Pathology",
    key: "pathology",
  },
  {
    title: "Pediatrics",
    key: "pediatrics",
  },
  {
    title: "Pharmacy",
    key: "pharmacy",
  },
  {
    title: "Physical Therapy",
    key: "physical_therapy",
  },
  {
    title: "Psychiatry",
    key: "psychiatry",
  },
  {
    title: "Psychology",
    key: "psychology",
  },
  {
    title: "Public Health",
    key: "public_health",
  },
  {
    title: "Radiology",
    key: "radiology",
  },
  {
    title: "Social Work",
    key: "social_work",
  },
  // operations
  {
    title: "Master Operations",
    key: "master_operations",
  },

  {
    title: "Call Center",
    key: "call_center",
  },
  {
    title: "Construction",
    key: "construction",
  },
  {
    title: "Corporate Strategy",
    key: "corporate_strategy",
  },
  {
    title: "Customer Service / Support",
    key: "customer_service_support",
  },
  {
    title: "Enterprise Resource Planning",
    key: "enterprise_resource_planning",
  },
  {
    title: "Facilities Management",
    key: "facilities_management",
  },
  {
    title: "Leasing",
    key: "leasing",
  },
  {
    title: "Logistics",
    key: "logistics",
  },
  {
    title: "Office Operations",
    key: "office_operations",
  },
  {
    title: "Operations",
    key: "operations",
  },
  {
    title: "Physical Security",
    key: "physical_security",
  },
  {
    title: "Project Development",
    key: "project_development",
  },
  {
    title: "Quality Management",
    key: "quality_management",
  },
  {
    title: "Real Estate",
    key: "real_estate",
  },
  {
    title: "Safety",
    key: "safety",
  },
  {
    title: "Store Operations",
    key: "store_operations",
  },
  {
    title: "Supply Chain",
    key: "supply_chain",
  },
  //

  {
    title: "Master Sales",
    key: "master_sales",
  },

  {
    title: "Account Management",
    key: "account_management",
  },
  {
    title: "Business Development",
    key: "business_development",
  },
  {
    title: "Channel Sales",
    key: "channel_sales",
  },
  {
    title: "Customer Retention & Development",
    key: "customer_retention_development",
  },
  {
    title: "Customer Success",
    key: "customer_success",
  },
  {
    title: "Field / Outside Sales",
    key: "field_outside_sales",
  },
  {
    title: "Inside Sales",
    key: "inside_sales",
  },
  {
    title: "Partnerships",
    key: "partnerships",
  },
  {
    title: "Revenue Operations",
    key: "revenue_operations",
  },
  {
    title: "Sales",
    key: "sales",
  },
  {
    title: "Sales Enablement",
    key: "sales_enablement",
  },
  {
    title: "Sales Engineering",
    key: "sales_engineering",
  },
  {
    title: "Sales Operations",
    key: "sales_operations",
  },
  {
    title: "Sales Training",
    key: "sales_training",
  },
  // consulting
  {
    title: "Consulting",
    key: "consulting",
  },
];

/* export const DEPARTMENTSOPTIONS = [
  {
    title: "C suite",
    key:"c_suite"
  },
  {
    title: "Product Management",
    key:"product_management"
  },
  {
    title: "Master Engineering Technical",
    key:"master_engineering_technical"
  },
  {
    title: "Design",
    key:"design"
  },
  {
    title: "Education",
    key:"education"
  },
  {
    title: "Master Finance",
    key:"master_finance"
  },
  {
    title: "Master Human Resources",
    key:"master_human_resources"
  },
  {
    title: "Master Information Technology",
    key:"master_information_technology"
  },
  {
    title: "Master Legal",
    key:"master_legal"
  },
  {
    title: "Marketing",
    key:"master_marketing"
  },
  {
    title: "Medical Health",
    key:"medical_health"
  },
  {
    title: "Master Operations",
    key:"master_operations"
  },
  {
    title: "Master Sales",
    key:"master_sales"
  },
  {
    title: "Consulting",
    key:"consulting"
  },
]; */

// export const DEPARTMENTSOPTIONS = [
//   {
//     title: "Ad Servers",
//   },
//   {
//     title: "Executive",
//     grpId: "Ad Servers",
//   },
//   {
//     title: "Finance Executive",
//     grpId: "Ad Servers",
//   },
//   {
//     title: "Founder",
//     grpId: "Ad Servers",
//   },
//   {
//     title: "Human Resource Executive",
//     grpId: "Ad Servers",
//   },
//   {
//     title: "Information Technology Executive",
//     grpId: "Ad Servers",
//   },
//   {
//     title: "Legal Executive",
//     grpId: "Ad Servers",
//   },
//   {
//     title: "Marketing Executive",
//     grpId: "Ad Servers",
//   },
//   {
//     title: "Medical & Health Executive",
//     grpId: "Ad Servers",
//   },
//   {
//     title: "Operations Executive",
//     grpId: "Ad Servers",
//   },
//   {
//     title: "Sales Leader",
//     grpId: "Ad Servers",
//   },
//   { title: "Product" },
//   {
//     title: "Product Development",
//     grpId: "Product",
//   },
//   {
//     title: "Product Management",
//     grpId: "Product",
//   },
//   { title: "Engineering & Technical" },
//   {
//     title: "Artifical Intelligence / Machine Learning",
//     grpId: "Engineering & Technical",
//   },
//   {
//     title: "Bioengineering",
//     grpId: "Engineering & Technical",
//   },
//   {
//     title: "Biometrics",
//     grpId: "Engineering & Technical",
//   },
//   {
//     title: "Business Intelligence",
//     grpId: "Engineering & Technical",
//   },
//   {
//     title: "Chemical Engineering",
//     grpId: "Engineering & Technical",
//   },
//   {
//     title: "Cloud / Mobolity",
//     grpId: "Engineering & Technical",
//   },
//   {
//     title: "Data Science",
//     grpId: "Engineering & Technical",
//   },
//   {
//     title: "DevOps",
//     grpId: "Engineering & Technical",
//   },
//   {
//     title: "Digital Transformation",
//     grpId: "Engineering & Technical",
//   },
//   {
//     title: "Emerging Technology / Innovation",
//     grpId: "Engineering & Technical",
//   },
//   // {
//   //   title: "Engineering & Technical",
//   //   grpId: "Engineering & Technical",
//   // },
//   {
//     title: "Industrial Engineering",
//     grpId: "Engineering & Technical",
//   },
//   {
//     title: "Mechanic",
//     grpId: "Engineering & Technical",
//   },
//   {
//     title: "Mobile Development",
//     grpId: "Engineering & Technical",
//   },
//   {
//     title: "Project Management",
//     grpId: "Engineering & Technical",
//   },
//   {
//     title: "Research & Development",
//     grpId: "Engineering & Technical",
//   },
//   {
//     title: "Scrum Master / Agile Coach",
//     grpId: "Engineering & Technical",
//   },
//   {
//     title: "Software Development",
//     grpId: "Engineering & Technical",
//   },
//   {
//     title: "Support / Technical Services",
//     grpId: "Engineering & Technical",
//   },
//   {
//     title: "Technician",
//     grpId: "Engineering & Technical",
//   },
//   {
//     title: "Technology Operations",
//     grpId: "Engineering & Technical",
//   },
//   {
//     title: "Test / Quality Assurance",
//     grpId: "Engineering & Technical",
//   },
//   {
//     title: "UI / UX",
//     grpId: "Engineering & Technical",
//   },
//   {
//     title: "Web Development",
//     grpId: "Engineering & Technical",
//   },
//   { title: "Design" },

//   {
//     title: "All Design",
//     grpId: "Design",
//   },
//   {
//     title: "Product or UI/UX Design",
//     grpId: "Design",
//   },
//   {
//     title: "Graphic/Visual/Brand Design",
//     grpId: "Design",
//   },
//   { title: "Education" },

//   {
//     title: "Teacher",
//     grpId: "Education",
//   },
//   {
//     title: "Principal",
//     grpId: "Education",
//   },
//   {
//     title: "Superintendent",
//     grpId: "Education",
//   },
//   {
//     title: "Professor",
//     grpId: "Education",
//   },
//   { title: "Finance" },

//   {
//     title: "Accounting",
//     grpId: "Finance",
//   },
//   // {
//   //   title: "Finance",
//   //   grpId: "Finance",
//   // },
//   {
//     title: "Financial Planning & Analysis",
//     grpId: "Finance",
//   },
//   {
//     title: "Financial Reporting",
//     grpId: "Finance",
//   },
//   {
//     title: "Financial Strategy",
//     grpId: "Finance",
//   },
//   {
//     title: "Financial Systems",
//     grpId: "Finance",
//   },
//   {
//     title: "Internal Audit & Control",
//     grpId: "Finance",
//   },
//   {
//     title: "Investor Relations",
//     grpId: "Finance",
//   },
//   {
//     title: "Mergers & Acquisitions",
//     grpId: "Finance",
//   },
//   {
//     title: "Real Estate Finance",
//     grpId: "Finance",
//   },
//   {
//     title: "Financial Risk",
//     grpId: "Finance",
//   },
//   {
//     title: "Shared Services",
//     grpId: "Finance",
//   },
//   {
//     title: "Sourcing/ Procurement",
//     grpId: "Finance",
//   },
//   {
//     title: "Tax",
//     grpId: "Finance",
//   },
//   {
//     title: "Treasury",
//     grpId: "Finance",
//   },
//   { title: "Human Reasource" },
//   {
//     title: "Compensation & Benifits",
//     grpId: "Human Reasource",
//   },
//   {
//     title: "Culture,Diversity and Inclusion ",
//     grpId: "Human Reasource",
//   },
//   {
//     title: "Employee & Safety",
//     grpId: "Human Reasource",
//   },
//   {
//     title: "Human Reasource Information System",
//     grpId: "Human Reasource",
//   },
//   // {
//   //   title: "Human Reasource",
//   //   grpId: "Human Reasource",
//   // },
//   {
//     title: "HR Business Partner",
//     grpId: "Human Reasource",
//   },
//   {
//     title: "Learning & Development",
//     grpId: "Human Reasource",
//   },
//   {
//     title: "Organizational Development",
//     grpId: "Human Reasource",
//   },
//   {
//     title: "Recruting & Talent Acquisition",
//     grpId: "Human Reasource",
//   },
//   {
//     title: "People Operations",
//     grpId: "Human Reasource",
//   },
//   { title: "Information Technology" },

//   {
//     title: "Application Development",
//     grpId: "Information Technology",
//   },
//   {
//     title: "Busuness Service Management / ITSM",
//     grpId: "Information Technology",
//   },
//   {
//     title: "Collabration & Web App",
//     grpId: "Information Technology",
//   },
//   {
//     title: "Data Center",
//     grpId: "Information Technology",
//   },
//   {
//     title: "Data Warehouse",
//     grpId: "Information Technology",
//   },
//   {
//     title: "Database Administration",
//     grpId: "Information Technology",
//   },
//   {
//     title: "eCommerce Development",
//     grpId: "Information Technology",
//   },
//   {
//     title: "Enterprise Architecture",
//     grpId: "Information Technology",
//   },
//   {
//     title: "Help Desk / Desktop Services",
//     grpId: "Information Technology",
//   },
//   {
//     title: "HR / Financial / ERP Systems",
//     grpId: "Information Technology",
//   },
//   {
//     title: "Information Security",
//     grpId: "Information Technology",
//   },
//   // {
//   //   title: "Information Technology",
//   //   grpId: "Information Technology",
//   // },
//   {
//     title: "Infrastructure",
//     grpId: "Information Technology",
//   },
//   {
//     title: "IT Asset Management",
//     grpId: "Information Technology",
//   },
//   {
//     title: "IT Audit / IT Compilance",
//     grpId: "Information Technology",
//   },
//   {
//     title: "IT Operations",
//     grpId: "Information Technology",
//   },
//   {
//     title: "IT Procurement",
//     grpId: "Information Technology",
//   },
//   {
//     title: "IT Strategy",
//     grpId: "Information Technology",
//   },
//   {
//     title: "IT Training",
//     grpId: "Information Technology",
//   },
//   {
//     title: "Networking",
//     grpId: "Information Technology",
//   },
//   {
//     title: "Project & Program Management",
//     grpId: "Information Technology",
//   },
//   {
//     title: "Quality Assurance",
//     grpId: "Information Technology",
//   },
//   {
//     title: "Retail / Store Systems",
//     grpId: "Information Technology",
//   },
//   {
//     title: "Servers",
//     grpId: "Information Technology",
//   },
//   {
//     title: "Storage & Diaster Recovery",
//     grpId: "Information Technology",
//   },
//   {
//     title: "Telecommunications",
//     grpId: "Information Technology",
//   },
//   {
//     title: "Virtualization",
//     grpId: "Information Technology",
//   },
//   { title: "Legal" },

//   {
//     title: "Acquisitions",
//     grpId: "Legal",
//   },
//   {
//     title: "Compliance",
//     grpId: "Legal",
//   },
//   {
//     title: "Contracts",
//     grpId: "Legal",
//   },
//   {
//     title: "Corporate Secretary",
//     grpId: "Legal",
//   },
//   {
//     title: "eDiscovery",
//     grpId: "Legal",
//   },
//   {
//     title: "Ethics",
//     grpId: "Legal",
//   },
//   {
//     title: "Governance",
//     grpId: "Legal",
//   },
//   {
//     title: "Governmental Affairs & Regulatory Law",
//     grpId: "Legal",
//   },
//   {
//     title: "Intellectual Property & Patent",
//     grpId: "Legal",
//   },
//   {
//     title: "Labor & Employment",
//     grpId: "Legal",
//   },
//   {
//     title: "Lawyer / Attorney",
//     grpId: "Legal",
//   },
//   // {
//   //   title: "Legal",
//   //   grpId: "Legal",
//   // },
//   {
//     title: "Legal Counsel",
//     grpId: "Legal",
//   },
//   {
//     title: "Legal Operations",
//     grpId: "Legal",
//   },
//   {
//     title: "Litigation",
//     grpId: "Legal",
//   },
//   {
//     title: "Privacy",
//     grpId: "Legal",
//   },
//   { title: "Marketing" },
//   {
//     title: "Advertising",
//     grpId: "Marketing",
//   },
//   {
//     title: "Brand Marketing",
//     grpId: "Marketing",
//   },
//   {
//     title: "Content Marketing",
//     grpId: "Marketing",
//   },
//   {
//     title: "Coustmer Experience",
//     grpId: "Marketing",
//   },
//   {
//     title: "Coustmer Marketing",
//     grpId: "Marketing",
//   },
//   {
//     title: "Demand Generation",
//     grpId: "Marketing",
//   },
//   {
//     title: "Digital Marketing",
//     grpId: "Marketing",
//   },
//   {
//     title: "eCommerce Marketing",
//     grpId: "Marketing",
//   },
//   {
//     title: "Event Marketing",
//     grpId: "Marketing",
//   },
//   {
//     title: "Field Marketing",
//     grpId: "Marketing",
//   },
//   {
//     title: "Lead Genaration",
//     grpId: "Marketing",
//   },
//   // {
//   //   title: "Marketing",
//   //   grpId: "Marketing",
//   // },
//   {
//     title: "Marketing Analytics / Insights",
//     grpId: "Marketing",
//   },
//   {
//     title: "Marketing Communications",
//     grpId: "Marketing",
//   },
//   {
//     title: "Marketing Operations",
//     grpId: "Marketing",
//   },
//   {
//     title: "Product Marketing",
//     grpId: "Marketing",
//   },
//   {
//     title: "Publics Realtions",
//     grpId: "Marketing",
//   },
//   {
//     title: "Search Engine Optimization / Pay Per Click",
//     grpId: "Marketing",
//   },
//   {
//     title: "Social Media Marketing",
//     grpId: "Marketing",
//   },
//   {
//     title: "Statergic Communications",
//     grpId: "Marketing",
//   },
//   {
//     title: "Technical Marketing",
//     grpId: "Marketing",
//   },
//   { title: "Medical & Health" },

//   {
//     title: "Anesthesiology",
//     grpId: "Medical & Health",
//   },
//   {
//     title: "Chiropractics",
//     grpId: "Medical & Health",
//   },
//   {
//     title: "Clinical Systems",
//     grpId: "Medical & Health",
//   },
//   {
//     title: "Dentistry",
//     grpId: "Medical & Health",
//   },
//   {
//     title: "Dermatology",
//     grpId: "Medical & Health",
//   },
//   {
//     title: "Doctors / Physicians",
//     grpId: "Medical & Health",
//   },
//   {
//     title: "Epidemiology",
//     grpId: "Medical & Health",
//   },
//   {
//     title: "Chiropractics",
//     grpId: "Medical & Health",
//   },
//   {
//     title: "First Responder",
//     grpId: "Medical & Health",
//   },
//   {
//     title: "Infectious diease",
//     grpId: "Medical & Health",
//   },
//   {
//     title: "Medical Administration",
//     grpId: "Medical & Health",
//   },
//   {
//     title: "Medical Education & Training",
//     grpId: "Medical & Health",
//   },
//   {
//     title: "Medical Research",
//     grpId: "Medical & Health",
//   },
//   {
//     title: "Medicine",
//     grpId: "Medical & Health",
//   },
//   {
//     title: "Neurology",
//     grpId: "Medical & Health",
//   },
//   {
//     title: "Nursing",
//     grpId: "Medical & Health",
//   },
//   {
//     title: "Nursing & Dietetics",
//     grpId: "Medical & Health",
//   },
//   {
//     title: "Obstetrics / Gynecology",
//     grpId: "Medical & Health",
//   },
//   {
//     title: "Oncology",
//     grpId: "Medical & Health",
//   },
//   {
//     title: "Opthalomology",
//     grpId: "Medical & Health",
//   },
//   {
//     title: "Optometry",
//     grpId: "Medical & Health",
//   },
//   {
//     title: "Orthpedics",
//     grpId: "Medical & Health",
//   },
//   {
//     title: "Pathology",
//     grpId: "Medical & Health",
//   },
//   {
//     title: "Pediatrics",
//     grpId: "Medical & Health",
//   },
//   {
//     title: "Pharmacy",
//     grpId: "Medical & Health",
//   },
//   {
//     title: "Physical Therapy",
//     grpId: "Medical & Health",
//   },
//   {
//     title: "Psychiatry",
//     grpId: "Medical & Health",
//   },
//   {
//     title: "Psycology",
//     grpId: "Medical & Health",
//   },
//   {
//     title: "Public Health",
//     grpId: "Medical & Health",
//   },
//   {
//     title: "Radiology",
//     grpId: "Medical & Health",
//   },
//   {
//     title: "Social Work",
//     grpId: "Medical & Health",
//   },
//   { title: "Operations" },
//   {
//     title: "Call Center",
//     grpId: "Operations",
//   },
//   {
//     title: "Consruction",
//     grpId: "Operations",
//   },
//   {
//     title: "Corporate Strategy",
//     grpId: "Operations",
//   },
//   {
//     title: "Customer Service / Support",
//     grpId: "Operations",
//   },
//   {
//     title: "Enterprise Reasource Planning",
//     grpId: "Operations",
//   },
//   {
//     title: "Facilities Management",
//     grpId: "Operations",
//   },
//   {
//     title: "Leasing",
//     grpId: "Operations",
//   },
//   {
//     title: "Logistics",
//     grpId: "Operations",
//   },
//   {
//     title: "Office Operations",
//     grpId: "Operations",
//   },
//   // {
//   //   title: "Operations",
//   //   grpId: "Operations",
//   // },
//   {
//     title: "Physical Security",
//     grpId: "Operations",
//   },
//   {
//     title: "Project Development",
//     grpId: "Operations",
//   },
//   {
//     title: "Quality Management",
//     grpId: "Operations",
//   },
//   {
//     title: "Real Estate",
//     grpId: "Operations",
//   },
//   {
//     title: "Safety",
//     grpId: "Operations",
//   },
//   {
//     title: "Store Operations",
//     grpId: "Operations",
//   },
//   {
//     title: "Supply Chain",
//     grpId: "Operations",
//   },
//   { title: "Sales" },

//   {
//     title: "Account Management",
//     grpId: "Sales",
//   },
//   {
//     title: "Business Development",
//     grpId: "Sales",
//   },
//   {
//     title: "Channel Sales",
//     grpId: "Sales",
//   },
//   {
//     title: "Customer Retention & Development",
//     grpId: "Sales",
//   },
//   {
//     title: "Customer Success",
//     grpId: "Sales",
//   },
//   {
//     title: "Field / Outside Sales",
//     grpId: "Sales",
//   },
//   {
//     title: "Inside Sales",
//     grpId: "Sales",
//   },
//   {
//     title: "Partnerships",
//     grpId: "Sales",
//   },
//   {
//     title: "Revenue Operations",
//     grpId: "Sales",
//   },
//   // {
//   //   title: "Sales",
//   //   grpId: "Sales",
//   // },
//   {
//     title: "Sales Enablement",
//     grpId: "Sales",
//   },
//   {
//     title: "Sales Engineering",
//     grpId: "Sales",
//   },
//   {
//     title: "Sales Operations",
//     grpId: "Sales",
//   },
//   {
//     title: "Sales Training",
//     grpId: "Sales",
//   },
//   { title: "Consulting" },

//   {
//     title: "Consultant",
//     grpId: "Consulting",
//   },
// ];

export const EMPLOYEES = [
  [1, 10],
  [11, 20],
  [21, 50],
  [51, 100],
  [101, 200],
  [201, 500],
  [501, 1000],
  [1001, 2000],
  [2001, 5000],
  [5001, 10000],
  // ["10,001+"]
];

export const MANAGEMENT_LEVELS = [
  "Founder/Owner",
  "C-Level",
  "VP",
  "Director",
  "Manager",
  "Individual Contributor",
  "Entry",
  "Intern",
  "Unpaid",
];

export const MANAGEMENT_OPTIONS = [
  { title: "Founder", key: "founder" },
  { title: "Owner", key: "owner" },
  { title: "C suite", key: "c_suite" },
  { title: "Partner", key: "partner" },
  { title: "Vp", key: "vp" },
  { title: "Head", key: "head" },
  { title: "Director", key: "director" },
  { title: "Manager", key: "manager" },
  { title: "Senior", key: "senior" },
  { title: "Entry", key: "entry" },
  { title: "Intern", key: "intern" },
  // "Founder",
  // "Owner",
  // "C suite",
  // "Partner",
  // "Vp",
  // "Head",
  // "Director",
  // "Manager",
  // "Senior",
  //"Individual Contributor",
  // "Entry",
  // "Intern",
  // Unpaid
];

export const FUNDING = [
  { title: "Seed", value: "0" },
  { title: "Angel", value: "1" },
  { title: "Venture (Round not Specified)", value: "10" },
  { title: "Series A", value: "2" },
  { title: "Series B", value: "3" },
  { title: "Series C", value: "4" },
  { title: "Series D", value: "5" },
  { title: "Series E", value: "6" },
  { title: "Series F", value: "7" },
  { title: "Debt Financing", value: "13" },
  { title: "Equity Crowdfunding", value: "14" },
  { title: "Convertible Note", value: "15" },
  { title: "Private Equity", value: "11" },
  { title: "Other", value: "12" },
];
// export const CHANGED_JOBS_WITHIN = [
//   "Last 3 Months",
//   "Last 6 Months",
//   "Last Year",
//   "Last 2 Years",
//   "Last 3 Years",
// ];
export const CHANGED_JOBS_WITHIN = [
  "0-90",
  "0-180",
  "0-360",
  "0-720",
  "0-1080",
];

export const SIC_CODES = [
  1000, 1040, 1090, 1220, 1381, 1382, 1389, 1400, 1623, 1731, 2011, 2013, 2030,
  2080, 2086, 2100, 2300, 2400, 2600, 2741, 2750, 2810, 2834, 2836, 2870, 3100,
  3140, 3317, 3334, 3523, 3530, 3532, 3537, 3560, 3572, 3575, 3576, 3577, 3600,
  3620, 3630, 3634, 3669, 3674, 3690, 3713, 3714, 3721, 3730, 3751, 3812, 3821,
  3944, 3949, 3990, 4210, 4213, 4220, 4400, 4412, 4512, 4522, 4581, 4610, 4700,
  4731, 4832, 4833, 4841, 4900, 4923, 4950, 5000, 5010, 5020, 5047, 5094, 5099,
  5180, 5200, 5311, 5331, 5399, 5400, 5531, 5700, 5734, 5912, 5960, 5990, 6162,
  6189, 6199, 6200, 6282, 6321, 6331, 6399, 6411, 6500, 6798, 7011, 7200, 7310,
  7311, 7330, 7361, 7370, 7371, 7372, 7373, 7374, 7380, 7389, 7500, 7600, 7900,
  8000, 8060, 8090, 8111, 8200, 8300, 8600, 8700, 8711, 8731, 8734, 8741, 8742,
  8744, 8900,
];

export const NAICS_CODE = [
  11,
  111,
  1111,
  11111,
  111110,
  11112,
  111120,
  11113,
  111130,
  11114,
  111140,
  11115,
  111150,
  11116,
  111160,
  11119,
  111191,
  111199,
  1112,
  11121,
  111211,
  111219,
  1113,
  11131,
  111310,
  11132,
  111320,
  11133,
  111331,
  111332,
  111333,
  111334,
  111335,
  111336,
  111339,
  1114,
  11141,
  111411,
  111419,
  11142,
  111421,
  111422,
  1119,
  11191,
  111910,
  11192,
  111920,
  11193,
  111930,
  11194,
  111940,
  11199,
  111991,
  111992,
  111998,
  112,
  1121,
  11211,
  112111,
  112112,
  11212,
  112120,
  11213,
  112130,
  1122,
  11221,
  112210,
  1123,
  11231,
  112310,
  11232,
  112320,
  11233,
  112330,
  11234,
  112340,
  11239,
  112390,
  1124,
  11241,
  112410,
  11242,
  112420,
  1125,
  11251,
  112511,
  112512,
  112519,
  1129,
  11291,
  112910,
  11292,
  112920,
  11293,
  112930,
  11299,
  112990,
  113,
  1131,
  11311,
  113110,
  1132,
  11321,
  113210,
  1133,
  11331,
  113310,
  114,
  1141,
  11411,
  114111,
  114112,
  114119,
  1142,
  11421,
  114210,
  115,
  1151,
  11511,
  115111,
  115112,
  115113,
  115114,
  115115,
  115116,
  1152,
  11521,
  115210,
  1153,
  11531,
  115310,
  21,
  211,
  2111,
  21112,
  211120,
  21113,
  211130,
  212,
  2121,
  21211,
  212114,
  212115,
  2122,
  21221,
  212210,
  21222,
  212220,
  21223,
  212230,
  21229,
  212290,
  2123,
  21231,
  212311,
  212312,
  212313,
  212319,
  21232,
  212321,
  212322,
  212323,
  21239,
  212390,
  213,
  2131,
  21311,
  213111,
  213112,
  213113,
  213114,
  213115,
  22,
  221,
  2211,
  22111,
  221111,
  221112,
  221113,
  221114,
  221115,
  221116,
  221117,
  221118,
  22112,
  221121,
  221122,
  2212,
  22121,
  221210,
  2213,
  22131,
  221310,
  22132,
  221320,
  22133,
  221330,
  23,
  236,
  2361,
  23611,
  236115,
  236116,
  236117,
  236118,
  2362,
  23621,
  236210,
  23622,
  236220,
  237,
  2371,
  23711,
  237110,
  23712,
  237120,
  23713,
  237130,
  2372,
  23721,
  237210,
  2373,
  23731,
  237310,
  2379,
  23799,
  237990,
  238,
  2381,
  23811,
  238110,
  23812,
  238120,
  23813,
  238130,
  23814,
  238140,
  23815,
  238150,
  23816,
  238160,
  23817,
  238170,
  23819,
  238190,
  2382,
  23821,
  238210,
  23822,
  238220,
  23829,
  238290,
  2383,
  23831,
  238310,
  23832,
  238320,
  23833,
  238330,
  23834,
  238340,
  23835,
  238350,
  23839,
  238390,
  2389,
  23891,
  238910,
  23899,
  238990,
  31 - 33,
  311,
  3111,
  31111,
  311111,
  311119,
  3112,
  31121,
  311211,
  311212,
  311213,
  31122,
  311221,
  311224,
  311225,
  31123,
  311230,
  3113,
  31131,
  311313,
  311314,
  31134,
  311340,
  31135,
  311351,
  311352,
  3114,
  31141,
  311411,
  311412,
  31142,
  311421,
  311422,
  311423,
  3115,
  31151,
  311511,
  311512,
  311513,
  311514,
  31152,
  311520,
  3116,
  31161,
  311611,
  311612,
  311613,
  311615,
  3117,
  31171,
  311710,
  3118,
  31181,
  311811,
  311812,
  311813,
  31182,
  311821,
  311824,
  31183,
  311830,
  3119,
  31191,
  311911,
  311919,
  31192,
  311920,
  31193,
  311930,
  31194,
  311941,
  311942,
  31199,
  311991,
  311999,
  312,
  3121,
  31211,
  312111,
  312112,
  312113,
  31212,
  312120,
  31213,
  312130,
  31214,
  312140,
  3122,
  31223,
  312230,
  313,
  3131,
  31311,
  313110,
  3132,
  31321,
  313210,
  31322,
  313220,
  31323,
  313230,
  31324,
  313240,
  3133,
  31331,
  313310,
  31332,
  313320,
  314,
  3141,
  31411,
  314110,
  31412,
  314120,
  3149,
  31491,
  314910,
  31499,
  314994,
  314999,
  315,
  3151,
  31512,
  315120,
  3152,
  31521,
  315210,
  31525,
  315250,
  3159,
  31599,
  315990,
  316,
  3161,
  31611,
  316110,
  3162,
  31621,
  316210,
  3169,
  31699,
  316990,
  321,
  3211,
  32111,
  321113,
  321114,
  3212,
  32121,
  321211,
  321212,
  321215,
  321219,
  3219,
  32191,
  321911,
  321912,
  321918,
  32192,
  321920,
  32199,
  321991,
  321992,
  321999,
  322,
  3221,
  32211,
  322110,
  32212,
  322120,
  32213,
  322130,
  3222,
  32221,
  322211,
  322212,
  322219,
  32222,
  322220,
  32223,
  322230,
  32229,
  322291,
  322299,
  323,
  3231,
  32311,
  323111,
  323113,
  323117,
  32312,
  323120,
  324,
  3241,
  32411,
  324110,
  32412,
  324121,
  324122,
  32419,
  324191,
  324199,
  325,
  3251,
  32511,
  325110,
  32512,
  325120,
  32513,
  325130,
  32518,
  325180,
  32519,
  325193,
  325194,
  325199,
  3252,
  32521,
  325211,
  325212,
  32522,
  325220,
  3253,
  32531,
  325311,
  325312,
  325314,
  325315,
  32532,
  325320,
  3254,
  32541,
  325411,
  325412,
  325413,
  325414,
  3255,
  32551,
  325510,
  32552,
  325520,
  3256,
  32561,
  325611,
  325612,
  325613,
  32562,
  325620,
  3259,
  32591,
  325910,
  32592,
  325920,
  32599,
  325991,
  325992,
  325998,
  326,
  3261,
  32611,
  326111,
  326112,
  326113,
  32612,
  326121,
  326122,
  32613,
  326130,
  32614,
  326140,
  32615,
  326150,
  32616,
  326160,
  32619,
  326191,
  326199,
  3262,
  32621,
  326211,
  326212,
  32622,
  326220,
  32629,
  326291,
  326299,
  327,
  3271,
  32711,
  327110,
  32712,
  327120,
  3272,
  32721,
  327211,
  327212,
  327213,
  327215,
  3273,
  32731,
  327310,
  32732,
  327320,
  32733,
  327331,
  327332,
  32739,
  327390,
  3274,
  32741,
  327410,
  32742,
  327420,
  3279,
  32791,
  327910,
  32799,
  327991,
  327992,
  327993,
  327999,
  331,
  3311,
  33111,
  331110,
  3312,
  33121,
  331210,
  33122,
  331221,
  331222,
  3313,
  33131,
  331313,
  331314,
  331315,
  331318,
  3314,
  33141,
  331410,
  33142,
  331420,
  33149,
  331491,
  331492,
  3315,
  33151,
  331511,
  331512,
  331513,
  33152,
  331523,
  331524,
  331529,
  332,
  3321,
  33211,
  332111,
  332112,
  332114,
  332117,
  332119,
  3322,
  33221,
  332215,
  332216,
  3323,
  33231,
  332311,
  332312,
  332313,
  33232,
  332321,
  332322,
  332323,
  3324,
  33241,
  332410,
  33242,
  332420,
  33243,
  332431,
  332439,
  3325,
  33251,
  332510,
  3326,
  33261,
  332613,
  332618,
  3327,
  33271,
  332710,
  33272,
  332721,
  332722,
  3328,
  33281,
  332811,
  332812,
  332813,
  3329,
  33291,
  332911,
  332912,
  332913,
  332919,
  33299,
  332991,
  332992,
  332993,
  332994,
  332996,
  332999,
  333,
  3331,
  33311,
  333111,
  333112,
  33312,
  333120,
  33313,
  333131,
  333132,
  3332,
  33324,
  333241,
  333242,
  333243,
  333248,
  3333,
  33331,
  333310,
  3334,
  33341,
  333413,
  333414,
  333415,
  3335,
  33351,
  333511,
  333514,
  333515,
  333517,
  333519,
  3336,
  33361,
  333611,
  333612,
  333613,
  333618,
  3339,
  33391,
  333912,
  333914,
  33392,
  333921,
  333922,
  333923,
  333924,
  33399,
  333991,
  333992,
  333993,
  333994,
  333995,
  333996,
  333998,
  334,
  3341,
  33411,
  334111,
  334112,
  334118,
  3342,
  33421,
  334210,
  33422,
  334220,
  33429,
  334290,
  3343,
  33431,
  334310,
  3344,
  33441,
  334412,
  334413,
  334416,
  334417,
  334418,
  334419,
  3345,
  33451,
  334510,
  334511,
  334512,
  334513,
  334514,
  334515,
  334516,
  334517,
  334519,
  3346,
  33461,
  334610,
  335,
  3351,
  33513,
  335131,
  335132,
  335139,
  3352,
  33521,
  335210,
  33522,
  335220,
  3353,
  33531,
  335311,
  335312,
  335313,
  335314,
  3359,
  33591,
  335910,
  33592,
  335921,
  335929,
  33593,
  335931,
  335932,
  33599,
  335991,
  335999,
  336,
  3361,
  33611,
  336110,
  33612,
  336120,
  3362,
  33621,
  336211,
  336212,
  336213,
  336214,
  3363,
  33631,
  336310,
  33632,
  336320,
  33633,
  336330,
  33634,
  336340,
  33635,
  336350,
  33636,
  336360,
  33637,
  336370,
  33639,
  336390,
  3364,
  33641,
  336411,
  336412,
  336413,
  336414,
  336415,
  336419,
  3365,
  33651,
  336510,
  3366,
  33661,
  336611,
  336612,
  3369,
  33699,
  336991,
  336992,
  336999,
  337,
  3371,
  33711,
  337110,
  33712,
  337121,
  337122,
  337126,
  337127,
  3372,
  33721,
  337211,
  337212,
  337214,
  337215,
  3379,
  33791,
  337910,
  33792,
  337920,
  339,
  3391,
  33911,
  339112,
  339113,
  339114,
  339115,
  339116,
  3399,
  33991,
  339910,
  33992,
  339920,
  33993,
  339930,
  33994,
  339940,
  33995,
  339950,
  33999,
  339991,
  339992,
  339993,
  339994,
  339995,
  339999,
  42,
  423,
  4231,
  42311,
  423110,
  42312,
  423120,
  42313,
  423130,
  42314,
  423140,
  4232,
  42321,
  423210,
  42322,
  423220,
  4233,
  42331,
  423310,
  42332,
  423320,
  42333,
  423330,
  42339,
  423390,
  4234,
  42341,
  423410,
  42342,
  423420,
  42343,
  423430,
  42344,
  423440,
  42345,
  423450,
  42346,
  423460,
  42349,
  423490,
  4235,
  42351,
  423510,
  42352,
  423520,
  4236,
  42361,
  423610,
  42362,
  423620,
  42369,
  423690,
  4237,
  42371,
  423710,
  42372,
  423720,
  42373,
  423730,
  42374,
  423740,
  4238,
  42381,
  423810,
  42382,
  423820,
  42383,
  423830,
  42384,
  423840,
  42385,
  423850,
  42386,
  423860,
  4239,
  42391,
  423910,
  42392,
  423920,
  42393,
  423930,
  42394,
  423940,
  42399,
  423990,
  424,
  4241,
  42411,
  424110,
  42412,
  424120,
  42413,
  424130,
  4242,
  42421,
  424210,
  4243,
  42431,
  424310,
  42434,
  424340,
  42435,
  424350,
  4244,
  42441,
  424410,
  42442,
  424420,
  42443,
  424430,
  42444,
  424440,
  42445,
  424450,
  42446,
  424460,
  42447,
  424470,
  42448,
  424480,
  42449,
  424490,
  4245,
  42451,
  424510,
  42452,
  424520,
  42459,
  424590,
  4246,
  42461,
  424610,
  42469,
  424690,
  4247,
  42471,
  424710,
  42472,
  424720,
  4248,
  42481,
  424810,
  42482,
  424820,
  4249,
  42491,
  424910,
  42492,
  424920,
  42493,
  424930,
  42494,
  424940,
  42495,
  424950,
  42499,
  424990,
  425,
  4251,
  42512,
  425120,
  44 - 45,
  441,
  4411,
  44111,
  441110,
  44112,
  441120,
  4412,
  44121,
  441210,
  44122,
  441222,
  441227,
  4413,
  44133,
  441330,
  44134,
  441340,
  444,
  4441,
  44411,
  444110,
  44412,
  444120,
  44414,
  444140,
  44418,
  444180,
  4442,
  44423,
  444230,
  44424,
  444240,
  445,
  4451,
  44511,
  445110,
  44513,
  445131,
  445132,
  4452,
  44523,
  445230,
  44524,
  445240,
  44525,
  445250,
  44529,
  445291,
  445292,
  445298,
  4453,
  44532,
  445320,
  449,
  4491,
  44911,
  449110,
  44912,
  449121,
  449122,
  449129,
  4492,
  44921,
  449210,
  455,
  4551,
  45511,
  455110,
  4552,
  45521,
  455211,
  455219,
  456,
  4561,
  45611,
  456110,
  45612,
  456120,
  45613,
  456130,
  45619,
  456191,
  456199,
  457,
  4571,
  45711,
  457110,
  45712,
  457120,
  4572,
  45721,
  457210,
  458,
  4581,
  45811,
  458110,
  4582,
  45821,
  458210,
  4583,
  45831,
  458310,
  45832,
  458320,
  459,
  4591,
  45911,
  459110,
  45912,
  459120,
  45913,
  459130,
  45914,
  459140,
  4592,
  45921,
  459210,
  4593,
  45931,
  459310,
  4594,
  45941,
  459410,
  45942,
  459420,
  4595,
  45951,
  459510,
  4599,
  45991,
  459910,
  45992,
  459920,
  45993,
  459930,
  45999,
  459991,
  459999,
  48 - 49,
  481,
  4811,
  48111,
  481111,
  481112,
  4812,
  48121,
  481211,
  481212,
  481219,
  482,
  4821,
  48211,
  482111,
  482112,
  483,
  4831,
  48311,
  483111,
  483112,
  483113,
  483114,
  4832,
  48321,
  483211,
  483212,
  484,
  4841,
  48411,
  484110,
  48412,
  484121,
  484122,
  4842,
  48421,
  484210,
  48422,
  484220,
  48423,
  484230,
  485,
  4851,
  48511,
  485111,
  485112,
  485113,
  485119,
  4852,
  48521,
  485210,
  4853,
  48531,
  485310,
  48532,
  485320,
  4854,
  48541,
  485410,
  4855,
  48551,
  485510,
  4859,
  48599,
  485991,
  485999,
  486,
  4861,
  48611,
  486110,
  4862,
  48621,
  486210,
  4869,
  48691,
  486910,
  48699,
  486990,
  487,
  4871,
  48711,
  487110,
  4872,
  48721,
  487210,
  4879,
  48799,
  487990,
  488,
  4881,
  48811,
  488111,
  488119,
  48819,
  488190,
  4882,
  48821,
  488210,
  4883,
  48831,
  488310,
  48832,
  488320,
  48833,
  488330,
  48839,
  488390,
  4884,
  48841,
  488410,
  48849,
  488490,
  4885,
  48851,
  488510,
  4889,
  48899,
  488991,
  488999,
  491,
  4911,
  49111,
  491110,
  492,
  4921,
  49211,
  492110,
  4922,
  49221,
  492210,
  493,
  4931,
  49311,
  493110,
  49312,
  493120,
  49313,
  493130,
  49319,
  493190,
  51,
  512,
  5121,
  51211,
  512110,
  51212,
  512120,
  51213,
  512131,
  512132,
  51219,
  512191,
  512199,
  5122,
  51223,
  512230,
  51224,
  512240,
  51225,
  512250,
  51229,
  512290,
  513,
  5131,
  51311,
  513110,
  51312,
  513120,
  51313,
  513130,
  51314,
  513140,
  51319,
  513191,
  513199,
  5132,
  51321,
  513210,
  516,
  5161,
  51611,
  516110,
  51612,
  516120,
  5162,
  51621,
  516210,
  517,
  5171,
  51711,
  517111,
  517112,
  51712,
  517121,
  517122,
  5174,
  51741,
  517410,
  5178,
  51781,
  517810,
  518,
  5182,
  51821,
  518210,
  519,
  5192,
  51921,
  519210,
  51929,
  519290,
  52,
  521,
  5211,
  52111,
  521110,
  522,
  5221,
  52211,
  522110,
  52213,
  522130,
  52218,
  522180,
  5222,
  52221,
  522210,
  52222,
  522220,
  52229,
  522291,
  522292,
  522299,
  5223,
  52231,
  522310,
  52232,
  522320,
  52239,
  522390,
  523,
  5231,
  52315,
  523150,
  52316,
  523160,
  5232,
  52321,
  523210,
  5239,
  52391,
  523910,
  52394,
  523940,
  52399,
  523991,
  523999,
  524,
  5241,
  52411,
  524113,
  524114,
  52412,
  524126,
  524127,
  524128,
  52413,
  524130,
  5242,
  52421,
  524210,
  52429,
  524291,
  524292,
  524298,
  525,
  5251,
  52511,
  525110,
  52512,
  525120,
  52519,
  525190,
  5259,
  52591,
  525910,
  52592,
  525920,
  52599,
  525990,
  53,
  531,
  5311,
  53111,
  531110,
  53112,
  531120,
  53113,
  531130,
  53119,
  531190,
  5312,
  53121,
  531210,
  5313,
  53131,
  531311,
  531312,
  53132,
  531320,
  53139,
  531390,
  532,
  5321,
  53211,
  532111,
  532112,
  53212,
  532120,
  5322,
  53221,
  532210,
  53228,
  532281,
  532282,
  532283,
  532284,
  532289,
  5323,
  53231,
  532310,
  5324,
  53241,
  532411,
  532412,
  53242,
  532420,
  53249,
  532490,
  533,
  5331,
  53311,
  533110,
  54,
  541,
  5411,
  54111,
  541110,
  54112,
  541120,
  54119,
  541191,
  541199,
  5412,
  54121,
  541211,
  541213,
  541214,
  541219,
  5413,
  54131,
  541310,
  54132,
  541320,
  54133,
  541330,
  54134,
  541340,
  54135,
  541350,
  54136,
  541360,
  54137,
  541370,
  54138,
  541380,
  5414,
  54141,
  541410,
  54142,
  541420,
  54143,
  541430,
  54149,
  541490,
  5415,
  54151,
  541511,
  541512,
  541513,
  541519,
  5416,
  54161,
  541611,
  541612,
  541613,
  541614,
  541618,
  54162,
  541620,
  54169,
  541690,
  5417,
  54171,
  541713,
  541714,
  541715,
  54172,
  541720,
  5418,
  54181,
  541810,
  54182,
  541820,
  54183,
  541830,
  54184,
  541840,
  54185,
  541850,
  54186,
  541860,
  54187,
  541870,
  54189,
  541890,
  5419,
  54191,
  541910,
  54192,
  541921,
  541922,
  54193,
  541930,
  54194,
  541940,
  54199,
  541990,
  55,
  551,
  5511,
  55111,
  551111,
  551112,
  551114,
  56,
  561,
  5611,
  56111,
  561110,
  5612,
  56121,
  561210,
  5613,
  56131,
  561311,
  561312,
  56132,
  561320,
  56133,
  561330,
  5614,
  56141,
  561410,
  56142,
  561421,
  561422,
  56143,
  561431,
  561439,
  56144,
  561440,
  56145,
  561450,
  56149,
  561491,
  561492,
  561499,
  5615,
  56151,
  561510,
  56152,
  561520,
  56159,
  561591,
  561599,
  5616,
  56161,
  561611,
  561612,
  561613,
  56162,
  561621,
  561622,
  5617,
  56171,
  561710,
  56172,
  561720,
  56173,
  561730,
  56174,
  561740,
  56179,
  561790,
  5619,
  56191,
  561910,
  56192,
  561920,
  56199,
  561990,
  562,
  5621,
  56211,
  562111,
  562112,
  562119,
  5622,
  56221,
  562211,
  562212,
  562213,
  562219,
  5629,
  56291,
  562910,
  56292,
  562920,
  56299,
  562991,
  562998,
  61,
  611,
  6111,
  61111,
  611110,
  6112,
  61121,
  611210,
  6113,
  61131,
  611310,
  6114,
  61141,
  611410,
  61142,
  611420,
  61143,
  611430,
  6115,
  61151,
  611511,
  611512,
  611513,
  611519,
  6116,
  61161,
  611610,
  61162,
  611620,
  61163,
  611630,
  61169,
  611691,
  611692,
  611699,
  6117,
  61171,
  611710,
  62,
  621,
  6211,
  62111,
  621111,
  621112,
  6212,
  62121,
  621210,
  6213,
  62131,
  621310,
  62132,
  621320,
  62133,
  621330,
  62134,
  621340,
  62139,
  621391,
  621399,
  6214,
  62141,
  621410,
  62142,
  621420,
  62149,
  621491,
  621492,
  621493,
  621498,
  6215,
  62151,
  621511,
  621512,
  6216,
  62161,
  621610,
  6219,
  62191,
  621910,
  62199,
  621991,
  621999,
  622,
  6221,
  62211,
  622110,
  6222,
  62221,
  622210,
  6223,
  62231,
  622310,
  623,
  6231,
  62311,
  623110,
  6232,
  62321,
  623210,
  62322,
  623220,
  6233,
  62331,
  623311,
  623312,
  6239,
  62399,
  623990,
  624,
  6241,
  62411,
  624110,
  62412,
  624120,
  62419,
  624190,
  6242,
  62421,
  624210,
  62422,
  624221,
  624229,
  62423,
  624230,
  6243,
  62431,
  624310,
  6244,
  62441,
  624410,
  71,
  711,
  7111,
  71111,
  711110,
  71112,
  711120,
  71113,
  711130,
  71119,
  711190,
  7112,
  71121,
  711211,
  711212,
  711219,
  7113,
  71131,
  711310,
  71132,
  711320,
  7114,
  71141,
  711410,
  7115,
  71151,
  711510,
  712,
  7121,
  71211,
  712110,
  71212,
  712120,
  71213,
  712130,
  71219,
  712190,
  713,
  7131,
  71311,
  713110,
  71312,
  713120,
  7132,
  71321,
  713210,
  71329,
  713290,
  7139,
  71391,
  713910,
  71392,
  713920,
  71393,
  713930,
  71394,
  713940,
  71395,
  713950,
  71399,
  713990,
  72,
  721,
  7211,
  72111,
  721110,
  72112,
  721120,
  72119,
  721191,
  721199,
  7212,
  72121,
  721211,
  721214,
  7213,
  72131,
  721310,
  722,
  7223,
  72231,
  722310,
  72232,
  722320,
  72233,
  722330,
  7224,
  72241,
  722410,
  7225,
  72251,
  722511,
  722513,
  722514,
  722515,
  81,
  811,
  8111,
  81111,
  811111,
  811114,
  81112,
  811121,
  811122,
  81119,
  811191,
  811192,
  811198,
  8112,
  81121,
  811210,
  8113,
  81131,
  811310,
  8114,
  81141,
  811411,
  811412,
  81142,
  811420,
  81143,
  811430,
  81149,
  811490,
  812,
  8121,
  81211,
  812111,
  812112,
  812113,
  81219,
  812191,
  812199,
  8122,
  81221,
  812210,
  81222,
  812220,
  8123,
  81231,
  812310,
  81232,
  812320,
  81233,
  812331,
  812332,
  8129,
  81291,
  812910,
  81292,
  812921,
  812922,
  81293,
  812930,
  81299,
  812990,
  813,
  8131,
  81311,
  813110,
  8132,
  81321,
  813211,
  813212,
  813219,
  8133,
  81331,
  813311,
  813312,
  813319,
  8134,
  81341,
  813410,
  8139,
  81391,
  813910,
  81392,
  813920,
  81393,
  813930,
  81394,
  813940,
  81399,
  813990,
  814,
  8141,
  81411,
  814110,
  92,
  921,
  9211,
  92111,
  921110,
  92112,
  921120,
  92113,
  921130,
  92114,
  921140,
  92115,
  921150,
  92119,
  921190,
  922,
  9221,
  92211,
  922110,
  92212,
  922120,
  92213,
  922130,
  92214,
  922140,
  92215,
  922150,
  92216,
  922160,
  92219,
  922190,
  923,
  9231,
  92311,
  923110,
  92312,
  923120,
  92313,
  923130,
  92314,
  923140,
  924,
  9241,
  92411,
  924110,
  92412,
  924120,
  925,
  9251,
  92511,
  925110,
  92512,
  925120,
  926,
  9261,
  92611,
  926110,
  92612,
  926120,
  92613,
  926130,
  92614,
  926140,
  92615,
  926150,
  927,
  9271,
  92711,
  927110,
  928,
  9281,
  92811,
  928110,
  92812,
  928120,
];

// const getFilters = {
//   revenue: (value) => {
//     const _val = _1M + (value / 100) * (_1T - _1M);
//     return [_val, `${parseInt(_val / _1M)} M`];
//   },
//   compSize: (value) => {
//     const _val = _1H + (value / 100) * (_1M - _1H);
//     return [_val, `${parseInt(_val)}`];
//   },
// };

// module.exports = {
//   INDUSTRY_OPTIONS,
//   FILTERS,
//   initAddFilters,
//   moveRange,
//   // getFilters,
//   // nums: { _1H, _1M, _1T },
// };

// campaign dropdowns step 3
// email structure
export const PURPOSE = [
  "Product/Service Pitch",
  "Event Promotion",
  "Ask for Referral",
  "custom",
];
export const CREATIVENESS = ["Low", "Standard", "High", "custom"];
export const TONE = [
  "Business Professional",
  "Business Casual",
  "Conversational",
  "Friendly",
  "Casual",
  "Persuasive",
  "Authoritative",
  "custom",
];
export const LENGTH = ["Short (30)", "Standard (60)","Long (90)", "custom"];
export const LANGUAGE = [
  "Afrikaans",
  "Amharic",
  "Arabic",
  "Assamese",
  "Azerbaijani",
  "Belarusian",
  "Bulgarian",
  "Bengali",
  "Breton",
  "Bosnian",
  "Catalan",
  "Czech",
  "Welsh",
  "Danish",
  "German",
  "Greek",
  "English (US)",
  "English (UK)",
  "Esperanto",
  "Spanish",
  "Estonian",
  "Basque",
  "Persian",
  "Finnish",
  "French",
  "Western Frisian",
  "Irish",
  "Scottish Gaelic",
  "Galician",
  "Gujarati",
  "Hausa",
  "Hebrew",
  "Hindi",
  "Croatian",
  "Hungarian",
  "Armenian",
  "Indonesian",
  "Italian",
  "Japanese",
  "Javanese",
  "Georgian",
  "Kazakh",
  "Khmer",
  "Kannada",
  "Korean",
  "Kurdish (Kurmanji)",
  "Kyrgyz",
  "Latin",
  "Lao",
  "Lithuanian",
  "Latvian",
  "Malagasy",
  "Macedonian",
  "Malayalam",
  "Mongolian",
  "Marathi",
  "Malay",
  "Burmese",
  "Nepali",
  "Dutch",
  "Norwegian (Bokmal)",
  "Odia",
  "Punjabi",
  "Polish",
  "Pashto",
  "Portuguese (Brazil)",
  "Portuguese (Portugal)",
  "Romanian",
  "Russian",
  "Sanskrit",
  "Sindhi",
  "Sinhala",
  "Slovak",
  "Slovenian",
  "Somali",
  "Albanian",
  "Serbian",
  "Sundanese",
  "Swedish",
  "Swahili",
  "Tamil",
  "Telugu",
  "Thai",
  "Filipino",
  "Turkish",
  "Uyghur",
  "Ukrainian",
  "Urdu",
  "Uzbek",
  "Vietnamese",
  "Xhosa",
  "Yiddish",
  "Chinese (Simplified)",
  "Chinese (Traditional)",
  "Zulu",
];

// email content
export const OPENING = [
  "Relevant Statistic",
  "Open with a question",
  "Educate",
  "Storytelling",
  "Problem, Solution, Results",
  "Testimonials",
  "Don't include this",
  "custom",
];

export const CONNECTING = [
  "Synergies",
  "Opportunities",
  "Value Add",
  "Case Studies",
  "Testimonials",
  "Don't include this",
  "custom",
];

export const companyOptionData = [
  { key: "includeCompanyDescription", label: "Company Description" },
  { key: "includeIndustry", label: "Industry" },
  { key: "includeFunding", label: "Funding" },
  { key: "includeYearFounded", label: "Year Founded" },
  { key: "includeRevenue", label: "Revenue" },
  { key: "includeNumberOfEmployees", label: "Number of Employees" },
  { key: "includeSicCodes", label: "SIC Codes" },
];

export const prospectOption = [
  { key: "includeLocation", label: "Location" },
  { key: "includeSkills", label: "Skills" },
  { key: "includeEducation", label: "Education" },
  { key: "includeJobHistory", label: "Job History" },
];

export const BODY = [
  "Standard Description",
  "Showcase Success",
  "High Level Overview",
  "Key Benefits",
  "Don't include this",
  "custom",
];

// redirect link placement
export const START_OF_CTA = [
  "Schedule a demo",
  "Schedule a chat",
  "Schedule a meeting",
  "Book a meeting",
  "Book your spot",
  "Book a slot",
  "Visit our site",
  "Sign up",
  "Use this link",
];
export const END_OF_CTA = [
  "this link.",
  "by clicking here.",
  "sign up here.",
  "visit our site.",
];

export const EMAIL_SUBJECT_TEMPLATES = [
  "[SENDER_COMPANY]: Meeting Proposal",
  "Let's Connect: [SENDER_COMPANY]",
  "Discuss Opportunities with [SENDER_COMPANY]",
  "[USER_COMPANY] + [PROSPECT_COMPANY]: Potential Collaboration?",
  "Strategic Chat: [SENDER_COMPANY] & You",
  "Meeting Invitation: [SENDER_COMPANY]",
  "Collaborate with [SENDER_COMPANY]",
  "Quick Chat: [SENDER_COMPANY] Insights",
  "Explore with [SENDER_COMPANY]",
];
export const EMAIL_SUBJECT_VARIABLES = [
  "[SENDER_COMPANY]",
  "[USER_COMPANY]",
  "[PROSPECT_COMPANY]",
  "[PROSPECT_FIRST_NAME]",
];
export const EMAIL_CUSTOM_VARIABLES = [
  "[SENDER_FIRST_NAME]",
  "[SENDER_TITLE]",
  "[SENDER_COMPANY]",
  "[SENDER_LOCATION]",
  "[SENDER_COMPANY_DESCRIPTION]",
  "[SENDER_PRODUCT_SERVICE_DESCRIPTION]",
  "[SENDER_SUCCESS_STORIES]",
  "[PROSPECT_FIRST_NAME]",
  "[PROSPECT_TITLE]",
  "[PROSPECT_COMPANY]",
  "[PROSPECT_LOCATION]",
  "[PROSPECT_COMPANY_DESCRIPTION]",
  "[PROSPECT_COMPANY_INDUSTRY]",
  "[PROSPECT_COMPANY_FUNDING]",
  "[PROSPECT_COMPANY_REVENUE]",
  "[PROSPECT_COMPANY_NUMBER_EMPLOYEES]",
];

export const TECHNOLOGYOPTIONS = [
  {
    title: "Ad Servers",
  },
  {
    key: "24-7_media",
    title: "24-7 Media",
    grpId: "Ad Servers",
  },
  {
    key: "adform",
    title: "Adform",
    grpId: "Ad Servers",
  },
  {
    key: "altitude_arena",
    title: "Altitude Arena",
    grpId: "Ad Servers",
  },
  {
    key: "atlas_by_facebook",
    title: "Atlas By Facebook",
    grpId: "Ad Servers",
  },
  {
    key: "doubleclick",
    title: "Doubleclick",
    grpId: "Ad Servers",
  },
  {
    key: "e-planning",
    title: "E-Planning",
    grpId: "Ad Servers",
  },
  {
    key: "emediate",
    title: "Emediate",
    grpId: "Ad Servers",
  },
  {
    key: "epom",
    title: "Epom",
    grpId: "Ad Servers",
  },
  {
    key: "freewheel",
    title: "Freewheel",
    grpId: "Ad Servers",
  },
  {
    key: "microad",
    title: "Microad",
    grpId: "Ad Servers",
  },
  {
    key: "sizmek_mediamind",
    title: "Sizmek Mediamind",
    grpId: "Ad Servers",
  },
  {
    key: "smart_adserver",
    title: "Smart Adserver",
    grpId: "Ad Servers",
  },
  {
    key: "switch_concepts",
    title: "Switch Concepts",
    grpId: "Ad Servers",
  },
  {
    key: "weborama",
    title: "Weborama",
    grpId: "Ad Servers",
  },
  { title: "Advertising Networks" },
  {
    key: "3xchange",
    title: "3xchange",
    grpId: "Advertising Networks",
  },
  {
    key: "41ads",
    title: "41ads",
    grpId: "Advertising Networks",
  },
  {
    key: "ad_dynamo",
    title: "Ad Dynamo",
    grpId: "Advertising Networks",
  },
  {
    key: "adara",
    title: "Adara",
    grpId: "Advertising Networks",
  },
  {
    key: "adconion",
    title: "Adconion",
    grpId: "Advertising Networks",
  },
  {
    key: "adgoal",
    title: "Adgoal",
    grpId: "Advertising Networks",
  },
  {
    key: "adition_technologies_-_advertisers",
    title: "Adition Technologies - Advertisers",
    grpId: "Advertising Networks",
  },
  {
    key: "adparlor",
    title: "Adparlor",
    grpId: "Advertising Networks",
  },
  {
    key: "baidu_ads",
    title: "Baidu Ads",
    grpId: "Advertising Networks",
  },
  {
    key: "bidtellect",
    title: "Bidtellect",
    grpId: "Advertising Networks",
  },
  {
    key: "bing_ads",
    title: "Bing Ads",
    grpId: "Advertising Networks",
  },
  {
    key: "blogher",
    title: "Blogher",
    grpId: "Advertising Networks",
  },
  {
    key: "boo-box",
    title: "Boo-Box",
    grpId: "Advertising Networks",
  },
  {
    key: "carbon",
    title: "Carbon",
    grpId: "Advertising Networks",
  },
  {
    key: "chitika",
    title: "Chitika",
    grpId: "Advertising Networks",
  },
  {
    key: "clicksor",
    title: "Clicksor",
    grpId: "Advertising Networks",
  },
  {
    key: "complex_media_network",
    title: "Complex Media Network",
    grpId: "Advertising Networks",
  },
  {
    key: "conversant_valueclick",
    title: "Conversant Valueclick",
    grpId: "Advertising Networks",
  },
  {
    key: "cpmstar",
    title: "Cpmstar",
    grpId: "Advertising Networks",
  },
  {
    key: "cpx_interactive",
    title: "Cpx Interactive",
    grpId: "Advertising Networks",
  },
  {
    key: "cross_pixel_media",
    title: "Cross Pixel Media",
    grpId: "Advertising Networks",
  },
  {
    key: "datonics",
    title: "Datonics",
    grpId: "Advertising Networks",
  },
  {
    key: "doubleclick_conversion",
    title: "Doubleclick Conversion",
    grpId: "Advertising Networks",
  },
  {
    key: "dstillery",
    title: "Dstillery",
    grpId: "Advertising Networks",
  },
  {
    key: "ezakus",
    title: "Ezakus",
    grpId: "Advertising Networks",
  },
  {
    key: "federatedmedia",
    title: "Federatedmedia",
    grpId: "Advertising Networks",
  },
  {
    key: "flashtalking",
    title: "Flashtalking",
    grpId: "Advertising Networks",
  },
  {
    key: "flite",
    title: "Flite",
    grpId: "Advertising Networks",
  },
  {
    key: "fluid_ads",
    title: "Fluid Ads",
    grpId: "Advertising Networks",
  },
  {
    key: "forensiq",
    title: "Forensiq",
    grpId: "Advertising Networks",
  },
  {
    key: "glam_media",
    title: "Glam Media",
    grpId: "Advertising Networks",
  },
  {
    key: "google_adsense_for_domains",
    title: "Google Adsense For Domains",
    grpId: "Advertising Networks",
  },
  {
    key: "google_adwords_conversion",
    title: "Google Adwords Conversion",
    grpId: "Advertising Networks",
  },
  {
    key: "gorilla_nation",
    title: "Gorilla Nation",
    grpId: "Advertising Networks",
  },
  {
    key: "gumgum",
    title: "Gumgum",
    grpId: "Advertising Networks",
  },
  {
    key: "hasoffers",
    title: "Hasoffers",
    grpId: "Advertising Networks",
  },
  {
    key: "hi-media_performance",
    title: "Hi-Media Performance",
    grpId: "Advertising Networks",
  },
  {
    key: "industrybrains",
    title: "Industrybrains",
    grpId: "Advertising Networks",
  },
  {
    key: "infolinks",
    title: "Infolinks",
    grpId: "Advertising Networks",
  },
  {
    key: "integral_ad_science",
    title: "Integral Ad Science",
    grpId: "Advertising Networks",
  },
  {
    key: "intent_media",
    title: "Intent Media",
    grpId: "Advertising Networks",
  },
  {
    key: "interclick_genome_by_yahoo",
    title: "Interclick Genome By Yahoo",
    grpId: "Advertising Networks",
  },
  {
    key: "kiosked",
    title: "Kiosked",
    grpId: "Advertising Networks",
  },
  {
    key: "kontera",
    title: "Kontera",
    grpId: "Advertising Networks",
  },
  {
    key: "lifestreet_media",
    title: "Lifestreet Media",
    grpId: "Advertising Networks",
  },
  {
    key: "ligatus",
    title: "Ligatus",
    grpId: "Advertising Networks",
  },
  {
    key: "linkedin_display_ads__formerly_bizo",
    title: "Linkedin Display Ads  Formerly Bizo",
    grpId: "Advertising Networks",
  },
  {
    key: "magnetvideo",
    title: "Magnetvideo",
    grpId: "Advertising Networks",
  },
  {
    key: "marchex",
    title: "Marchex",
    grpId: "Advertising Networks",
  },
  {
    key: "medialets",
    title: "Medialets",
    grpId: "Advertising Networks",
  },
  {
    key: "moat",
    title: "Moat",
    grpId: "Advertising Networks",
  },
  {
    key: "onscroll",
    title: "Onscroll",
    grpId: "Advertising Networks",
  },
  {
    key: "owneriq",
    title: "Owneriq",
    grpId: "Advertising Networks",
  },
  {
    key: "proclivitymedia",
    title: "Proclivitymedia",
    grpId: "Advertising Networks",
  },
  {
    key: "quartic",
    title: "Quartic",
    grpId: "Advertising Networks",
  },
  {
    key: "reactx",
    title: "Reactx",
    grpId: "Advertising Networks",
  },
  {
    key: "redvertisement",
    title: "Redvertisement",
    grpId: "Advertising Networks",
  },
  {
    key: "respond",
    title: "Respond",
    grpId: "Advertising Networks",
  },
  {
    key: "right_media_yahoo_ads",
    title: "Right Media Yahoo Ads",
    grpId: "Advertising Networks",
  },
  {
    key: "say_media",
    title: "Say Media",
    grpId: "Advertising Networks",
  },
  {
    key: "scupio",
    title: "Scupio",
    grpId: "Advertising Networks",
  },
  {
    key: "sitescout",
    title: "Sitescout",
    grpId: "Advertising Networks",
  },
  {
    key: "specific_media",
    title: "Specific Media",
    grpId: "Advertising Networks",
  },
  {
    key: "technorati",
    title: "Technorati",
    grpId: "Advertising Networks",
  },
  {
    key: "tradedoubler",
    title: "Tradedoubler",
    grpId: "Advertising Networks",
  },
  {
    key: "tribalfusion",
    title: "Tribalfusion",
    grpId: "Advertising Networks",
  },
  {
    key: "triplelift",
    title: "Triplelift",
    grpId: "Advertising Networks",
  },
  {
    key: "twitter_advertising",
    title: "Twitter Advertising",
    grpId: "Advertising Networks",
  },
  {
    key: "undertone",
    title: "Undertone",
    grpId: "Advertising Networks",
  },
  {
    key: "valueclick_mediaplex",
    title: "Valueclick Mediaplex",
    grpId: "Advertising Networks",
  },
  {
    key: "vibrant_media",
    title: "Vibrant Media",
    grpId: "Advertising Networks",
  },
  {
    key: "viewable_media_visable_measures",
    title: "Viewable Media Visable Measures",
    grpId: "Advertising Networks",
  },
  {
    key: "webspectator",
    title: "Webspectator",
    grpId: "Advertising Networks",
  },
  {
    key: "wider_planet",
    title: "Wider Planet",
    grpId: "Advertising Networks",
  },
  {
    key: "yahoo_ad_manager",
    title: "Yahoo Ad Manager",
    grpId: "Advertising Networks",
  },
  {
    key: "yieldbot",
    title: "Yieldbot",
    grpId: "Advertising Networks",
  },
  {
    key: "znaptag",
    title: "Znaptag",
    grpId: "Advertising Networks",
  },
  {
    key: "basis",
    title: "Basis",
    grpId: "Advertising Networks",
  },
  {
    key: "centro",
    title: "Centro",
    grpId: "Advertising Networks",
  },
  { title: "Affiliate Advertising Network" },

  {
    key: "adbay",
    title: "Adbay",
    grpId: "Affiliate Advertising Network",
  },
  {
    key: "adpilot",
    title: "Adpilot",
    grpId: "Affiliate Advertising Network",
  },
  {
    key: "affiliate_window",
    title: "Affiliate Window",
    grpId: "Affiliate Advertising Network",
  },
  {
    key: "affilinet",
    title: "Affilinet",
    grpId: "Affiliate Advertising Network",
  },
  {
    key: "amazon_associates",
    title: "Amazon Associates",
    grpId: "Affiliate Advertising Network",
  },
  {
    key: "avantlink",
    title: "Avantlink",
    grpId: "Affiliate Advertising Network",
  },
  {
    key: "commission_junction",
    title: "Commission Junction",
    grpId: "Affiliate Advertising Network",
  },
  {
    key: "contentclick",
    title: "Contentclick",
    grpId: "Affiliate Advertising Network",
  },
  {
    key: "deqwas",
    title: "Deqwas",
    grpId: "Affiliate Advertising Network",
  },
  {
    key: "impact_radius",
    title: "Impact Radius",
    grpId: "Affiliate Advertising Network",
  },
  {
    key: "linkshare",
    title: "Linkshare",
    grpId: "Affiliate Advertising Network",
  },
  {
    key: "opt-intelligence",
    title: "Opt-Intelligence",
    grpId: "Affiliate Advertising Network",
  },
  {
    key: "postpresso",
    title: "Postpresso",
    grpId: "Affiliate Advertising Network",
  },
  {
    key: "rewardstyle",
    title: "Rewardstyle",
    grpId: "Affiliate Advertising Network",
  },
  {
    key: "shareasale",
    title: "Shareasale",
    grpId: "Affiliate Advertising Network",
  },
  {
    key: "skimlinks",
    title: "Skimlinks",
    grpId: "Affiliate Advertising Network",
  },
  {
    key: "vantage_media",
    title: "Vantage Media",
    grpId: "Affiliate Advertising Network",
  },
  {
    key: "viglink",
    title: "Viglink",
    grpId: "Affiliate Advertising Network",
  },
  {
    key: "webgains",
    title: "Webgains",
    grpId: "Affiliate Advertising Network",
  },
  {
    key: "yieldkit",
    title: "Yieldkit",
    grpId: "Affiliate Advertising Network",
  },
  {
    key: "zanox",
    title: "Zanox",
    grpId: "Affiliate Advertising Network",
  },

  { title: "Analytics and Tracking" },

  {
    key: "admetrics",
    title: "Admetrics",
    grpId: "Analytics and Tracking",
  },
  {
    key: "adometry_google",
    title: "Adometry Google",
    grpId: "Analytics and Tracking",
  },
  {
    key: "amplitude",
    title: "Amplitude",
    grpId: "Analytics and Tracking",
  },
  {
    key: "at_internet",
    title: "At Internet",
    grpId: "Analytics and Tracking",
  },
  {
    key: "avantmetrics",
    title: "Avantmetrics",
    grpId: "Analytics and Tracking",
  },
  {
    key: "c3_metrics",
    title: "C3 Metrics",
    grpId: "Analytics and Tracking",
  },
  {
    key: "chartbeat",
    title: "Chartbeat",
    grpId: "Analytics and Tracking",
  },
  {
    key: "clearsaleing_ebay_enterprise",
    title: "Clearsaleing Ebay Enterprise",
    grpId: "Analytics and Tracking",
  },
  {
    key: "clickdensity",
    title: "Clickdensity",
    grpId: "Analytics and Tracking",
  },
  {
    key: "clicky",
    title: "Clicky",
    grpId: "Analytics and Tracking",
  },
  {
    key: "compete",
    title: "Compete",
    grpId: "Analytics and Tracking",
  },
  {
    key: "comscore",
    title: "Comscore",
    grpId: "Analytics and Tracking",
  },
  {
    key: "convertro_aol",
    title: "Convertro Aol",
    grpId: "Analytics and Tracking",
  },
  {
    key: "coremetrics",
    title: "Coremetrics",
    grpId: "Analytics and Tracking",
  },
  {
    key: "crazyegg",
    title: "Crazyegg",
    grpId: "Analytics and Tracking",
  },
  {
    key: "dc_storm",
    title: "Dc Storm",
    grpId: "Analytics and Tracking",
  },
  {
    key: "decibel_insight",
    title: "Decibel Insight",
    grpId: "Analytics and Tracking",
  },
  {
    key: "demandbase",
    title: "Demandbase",
    grpId: "Analytics and Tracking",
  },
  {
    key: "demandware_analytics",
    title: "Demandware Analytics",
    grpId: "Analytics and Tracking",
  },
  {
    key: "deviceatlas",
    title: "Deviceatlas",
    grpId: "Analytics and Tracking",
  },
  {
    key: "drawbridge",
    title: "Drawbridge",
    grpId: "Analytics and Tracking",
  },
  {
    key: "eaglestats",
    title: "Eaglestats",
    grpId: "Analytics and Tracking",
  },
  {
    key: "econda",
    title: "Econda",
    grpId: "Analytics and Tracking",
  },
  {
    key: "effective_measure",
    title: "Effective Measure",
    grpId: "Analytics and Tracking",
  },
  {
    key: "etracker",
    title: "Etracker",
    grpId: "Analytics and Tracking",
  },
  {
    key: "extreme_tracker",
    title: "Extreme Tracker",
    grpId: "Analytics and Tracking",
  },
  {
    key: "facebook_conversion_tracking",
    title: "Facebook Conversion Tracking",
    grpId: "Analytics and Tracking",
  },
  {
    key: "gauges",
    title: "Gauges",
    grpId: "Analytics and Tracking",
  },
  {
    key: "gosquared",
    title: "Gosquared",
    grpId: "Analytics and Tracking",
  },
  {
    key: "heapanalytics",
    title: "Heapanalytics",
    grpId: "Analytics and Tracking",
  },
  {
    key: "histats",
    title: "Histats",
    grpId: "Analytics and Tracking",
  },
  {
    key: "hitslink",
    title: "Hitslink",
    grpId: "Analytics and Tracking",
  },
  {
    key: "hotjar",
    title: "Hotjar",
    grpId: "Analytics and Tracking",
  },
  {
    key: "iapps",
    title: "Iapps",
    grpId: "Analytics and Tracking",
  },
  {
    key: "improvely",
    title: "Improvely",
    grpId: "Analytics and Tracking",
  },
  {
    key: "insightexpress_flex",
    title: "Insightexpress Flex",
    grpId: "Analytics and Tracking",
  },
  {
    key: "inspectlet",
    title: "Inspectlet",
    grpId: "Analytics and Tracking",
  },
  {
    key: "keen_io",
    title: "Keen Io",
    grpId: "Analytics and Tracking",
  },
  {
    key: "kissmetrics",
    title: "Kissmetrics",
    grpId: "Analytics and Tracking",
  },
  {
    key: "korrelate",
    title: "Korrelate",
    grpId: "Analytics and Tracking",
  },
  {
    key: "kya",
    title: "Kya",
    grpId: "Analytics and Tracking",
  },
  {
    key: "lucky_orange",
    title: "Lucky Orange",
    grpId: "Analytics and Tracking",
  },
  {
    key: "meetrics",
    title: "Meetrics",
    grpId: "Analytics and Tracking",
  },
  {
    key: "mint",
    title: "Mint",
    grpId: "Analytics and Tracking",
  },
  {
    key: "mixpanel",
    title: "Mixpanel",
    grpId: "Analytics and Tracking",
  },
  {
    key: "motigo",
    title: "Motigo",
    grpId: "Analytics and Tracking",
  },
  {
    key: "mouseflow",
    title: "Mouseflow",
    grpId: "Analytics and Tracking",
  },
  {
    key: "nielsen_netratings",
    title: "Nielsen Netratings",
    grpId: "Analytics and Tracking",
  },
  {
    key: "omniture_adobe",
    title: "Omniture Adobe",
    grpId: "Analytics and Tracking",
  },
  {
    key: "opentracker",
    title: "Opentracker",
    grpId: "Analytics and Tracking",
  },
  {
    key: "pathful",
    title: "Pathful",
    grpId: "Analytics and Tracking",
  },
  {
    key: "performable",
    title: "Performable",
    grpId: "Analytics and Tracking",
  },
  {
    key: "phg",
    title: "Phg",
    grpId: "Analytics and Tracking",
  },
  {
    key: "piano_media",
    title: "Piano Media",
    grpId: "Analytics and Tracking",
  },
  {
    key: "piwik",
    title: "Piwik",
    grpId: "Analytics and Tracking",
  },
  {
    key: "pmetrics",
    title: "Pmetrics",
    grpId: "Analytics and Tracking",
  },
  {
    key: "prudsys",
    title: "Prudsys",
    grpId: "Analytics and Tracking",
  },
  {
    key: "quantcast",
    title: "Quantcast",
    grpId: "Analytics and Tracking",
  },
  {
    key: "radarstats",
    title: "Radarstats",
    grpId: "Analytics and Tracking",
  },
  {
    key: "realtracker",
    title: "Realtracker",
    grpId: "Analytics and Tracking",
  },
  {
    key: "reinvigorate",
    title: "Reinvigorate",
    grpId: "Analytics and Tracking",
  },
  {
    key: "segment_io",
    title: "Segment Io",
    grpId: "Analytics and Tracking",
  },
  {
    key: "sessioncam",
    title: "Sessioncam",
    grpId: "Analytics and Tracking",
  },
  {
    key: "shinystat",
    title: "Shinystat",
    grpId: "Analytics and Tracking",
  },
  {
    key: "shop2market",
    title: "Shop2market",
    grpId: "Analytics and Tracking",
  },
  {
    key: "sitemeter",
    title: "Sitemeter",
    grpId: "Analytics and Tracking",
  },
  {
    key: "snoobi",
    title: "Snoobi",
    grpId: "Analytics and Tracking",
  },
  {
    key: "sophus_3_emetrics",
    title: "Sophus 3 Emetrics",
    grpId: "Analytics and Tracking",
  },
  {
    key: "spingmetrics",
    title: "Spingmetrics",
    grpId: "Analytics and Tracking",
  },
  {
    key: "spinnakr",
    title: "Spinnakr",
    grpId: "Analytics and Tracking",
  },
  {
    key: "statcounter",
    title: "Statcounter",
    grpId: "Analytics and Tracking",
  },
  {
    key: "gauges",
    title: "Gauges",
    grpId: "Analytics and Tracking",
  },
  {
    key: "superstats",
    title: "Superstats",
    grpId: "Analytics and Tracking",
  },
  {
    key: "taboola_newsroom",
    title: "Taboola Newsroom",
    grpId: "Analytics and Tracking",
  },
  {
    key: "tapad",
    title: "Tapad",
    grpId: "Analytics and Tracking",
  },
  {
    key: "tealeaf",
    title: "Tealeaf",
    grpId: "Analytics and Tracking",
  },
  {
    key: "topspot",
    title: "Topspot",
    grpId: "Analytics and Tracking",
  },
  {
    key: "totango",
    title: "Totango",
    grpId: "Analytics and Tracking",
  },
  {
    key: "trak_io",
    title: "Trak Io",
    grpId: "Analytics and Tracking",
  },
  {
    key: "umbel",
    title: "Umbel",
    grpId: "Analytics and Tracking",
  },
  {
    key: "usercycle",
    title: "Usercycle",
    grpId: "Analytics and Tracking",
  },
  {
    key: "visiblemeasures",
    title: "Visiblemeasures",
    grpId: "Analytics and Tracking",
  },
  {
    key: "visual_iq",
    title: "Visual Iq",
    grpId: "Analytics and Tracking",
  },
  {
    key: "w3counter",
    title: "W3counter",
    grpId: "Analytics and Tracking",
  },
  {
    key: "walkme",
    title: "Walkme",
    grpId: "Analytics and Tracking",
  },
  {
    key: "webtraxs",
    title: "Webtraxs",
    grpId: "Analytics and Tracking",
  },
  {
    key: "webtrekk",
    title: "Webtrekk",
    grpId: "Analytics and Tracking",
  },
  {
    key: "webtrends",
    title: "Webtrends",
    grpId: "Analytics and Tracking",
  },
  {
    key: "whos_amung_us",
    title: "Whos Amung Us",
    grpId: "Analytics and Tracking",
  },
  {
    key: "woopra",
    title: "Woopra",
    grpId: "Analytics and Tracking",
  },
  {
    key: "wowanalytics",
    title: "Wowanalytics",
    grpId: "Analytics and Tracking",
  },
  {
    key: "xiti",
    title: "Xiti",
    grpId: "Analytics and Tracking",
  },
  {
    key: "yahoo_analytics",
    title: "Yahoo Analytics",
    grpId: "Analytics and Tracking",
  },
  {
    key: "yandex_metrika",
    title: "Yandex Metrika",
    grpId: "Analytics and Tracking",
  },
  {
    key: "zift_solutions",
    title: "Zift Solutions",
    grpId: "Analytics and Tracking",
  },
  {
    key: "audioeye",
    title: "Audioeye",
    grpId: "Analytics and Tracking",
  },
  {
    key: "barracuda",
    title: "Barracuda",
    grpId: "Analytics and Tracking",
  },
  {
    key: "patientpop",
    title: "Patientpop",
    grpId: "Analytics and Tracking",
  },
  {
    key: "amazon_cloudwatch",
    title: "Amazon Cloudwatch",
    grpId: "Analytics and Tracking",
  },
  {
    key: "braze",
    title: "Braze",
    grpId: "Analytics and Tracking",
  },
  {
    key: "splunk",
    title: "Splunk",
    grpId: "Analytics and Tracking",
  },
  {
    key: "4patientcare",
    title: "4patientcare",
    grpId: "Appointment Scheduling",
  },
  { title: "Appointment Scheduling" },

  {
    key: "appointmentplus",
    title: "Appointmentplus",
    grpId: "Appointment Scheduling",
  },
  {
    key: "avimark_rapport",
    title: "Avimark Rapport",
    grpId: "Appointment Scheduling",
  },
  {
    key: "ecentral",
    title: "Ecentral",
    grpId: "Appointment Scheduling",
  },
  {
    key: "mindbody",
    title: "Mindbody",
    grpId: "Appointment Scheduling",
  },
  {
    key: "schedulicity",
    title: "Schedulicity",
    grpId: "Appointment Scheduling",
  },
  {
    key: "skedge_me",
    title: "Skedge Me",
    grpId: "Appointment Scheduling",
  },
  {
    key: "solutionreach",
    title: "Solutionreach",
    grpId: "Appointment Scheduling",
  },
  {
    key: "speedrfp",
    title: "Speedrfp",
    grpId: "Appointment Scheduling",
  },
  {
    key: "stx_software",
    title: "Stx Software",
    grpId: "Appointment Scheduling",
  },
  {
    key: "timetrade",
    title: "Timetrade",
    grpId: "Appointment Scheduling",
  },
  {
    key: "tula",
    title: "Tula",
    grpId: "Appointment Scheduling",
  },
  {
    key: "vagaro",
    title: "Vagaro",
    grpId: "Appointment Scheduling",
  },
  {
    key: "wodify",
    title: "Wodify",
    grpId: "Appointment Scheduling",
  },
  {
    key: "youcanbook_me",
    title: "Youcanbook Me",
    grpId: "Appointment Scheduling",
  },
  {
    key: "zen_planner",
    title: "Zen Planner",
    grpId: "Appointment Scheduling",
  },
  { title: "CMS" },

  {
    key: "adobe_cq",
    title: "Adobe Cq",
    grpId: "CMS",
  },
  {
    key: "adobe_coldfusion",
    title: "Adobe Coldfusion",
    grpId: "CMS",
  },
  {
    key: "advanced_solutions_international_asi",
    title: "Advanced Solutions International Asi",
    grpId: "CMS",
  },
  {
    key: "agility",
    title: "Agility",
    grpId: "CMS",
  },
  {
    key: "atex",
    title: "Atex",
    grpId: "CMS",
  },
  {
    key: "auctori",
    title: "Auctori",
    grpId: "CMS",
  },
  {
    key: "auto_vitals",
    title: "Auto Vitals",
    grpId: "CMS",
  },
  {
    key: "basekit",
    title: "Basekit",
    grpId: "CMS",
  },
  {
    key: "bitrix",
    title: "Bitrix",
    grpId: "CMS",
  },
  {
    key: "blogger",
    title: "Blogger",
    grpId: "CMS",
  },
  {
    key: "buddypress",
    title: "Buddypress",
    grpId: "CMS",
  },
  {
    key: "buuteeq",
    title: "Buuteeq",
    grpId: "CMS",
  },
  {
    key: "catylist_sitelink",
    title: "Catylist Sitelink",
    grpId: "CMS",
  },
  {
    key: "civic_plus",
    title: "Civic Plus",
    grpId: "CMS",
  },
  {
    key: "clickability",
    title: "Clickability",
    grpId: "CMS",
  },
  {
    key: "clickmotive",
    title: "Clickmotive",
    grpId: "CMS",
  },
  {
    key: "cm4all",
    title: "Cm4all",
    grpId: "CMS",
  },
  {
    key: "cobalt",
    title: "Cobalt",
    grpId: "CMS",
  },
  {
    key: "convio",
    title: "Convio",
    grpId: "CMS",
  },
  {
    key: "crownpeak",
    title: "Crownpeak",
    grpId: "CMS",
  },
  {
    key: "dealer_com",
    title: "Dealer Com",
    grpId: "CMS",
  },
  {
    key: "dealeron",
    title: "Dealeron",
    grpId: "CMS",
  },
  {
    key: "dotnetnuke",
    title: "Dotnetnuke",
    grpId: "CMS",
  },
  {
    key: "driverside",
    title: "Driverside",
    grpId: "CMS",
  },
  {
    key: "drupal",
    title: "Drupal",
    grpId: "CMS",
  },
  {
    key: "ektron",
    title: "Ektron",
    grpId: "CMS",
  },
  {
    key: "episerver",
    title: "Episerver",
    grpId: "CMS",
  },
  {
    key: "jazel",
    title: "Jazel",
    grpId: "CMS",
  },
  {
    key: "jimdo",
    title: "Jimdo",
    grpId: "CMS",
  },
  {
    key: "joomla",
    title: "Joomla",
    grpId: "CMS",
  },
  {
    key: "kentico",
    title: "Kentico",
    grpId: "CMS",
  },
  {
    key: "kirra",
    title: "Kirra",
    grpId: "CMS",
  },
  {
    key: "kukui",
    title: "Kukui",
    grpId: "CMS",
  },
  {
    key: "liferay",
    title: "Liferay",
    grpId: "CMS",
  },
  {
    key: "listinglab",
    title: "Listinglab",
    grpId: "CMS",
  },
  {
    key: "loopnet",
    title: "Loopnet",
    grpId: "CMS",
  },
  {
    key: "magnolia",
    title: "Magnolia",
    grpId: "CMS",
  },
  {
    key: "marqui",
    title: "Marqui",
    grpId: "CMS",
  },
  {
    key: "microsoft_frontpage",
    title: "Microsoft Frontpage",
    grpId: "CMS",
  },
  {
    key: "mono_solutions",
    title: "Mono Solutions",
    grpId: "CMS",
  },
  {
    key: "mycase",
    title: "Mycase",
    grpId: "CMS",
  },
  {
    key: "nationbuilder",
    title: "Nationbuilder",
    grpId: "CMS",
  },
  {
    key: "netsuite",
    title: "Netsuite",
    grpId: "CMS",
  },
  {
    key: "oracle_webcenter",
    title: "Oracle Webcenter",
    grpId: "CMS",
  },
  {
    key: "outsystems",
    title: "Outsystems",
    grpId: "CMS",
  },
  {
    key: "pantheon",
    title: "Pantheon",
    grpId: "CMS",
  },
  {
    key: "paperthin",
    title: "Paperthin",
    grpId: "CMS",
  },
  {
    key: "placester",
    title: "Placester",
    grpId: "CMS",
  },
  {
    key: "sharepoint",
    title: "Sharepoint",
    grpId: "CMS",
  },
  {
    key: "sitecore",
    title: "Sitecore",
    grpId: "CMS",
  },
  {
    key: "squarespace",
    title: "Squarespace",
    grpId: "CMS",
  },
  {
    key: "strikingly",
    title: "Strikingly",
    grpId: "CMS",
  },
  {
    key: "tumblr",
    title: "Tumblr",
    grpId: "CMS",
  },
  {
    key: "typo3",
    title: "Typo3",
    grpId: "CMS",
  },
  {
    key: "vbulletin",
    title: "Vbulletin",
    grpId: "CMS",
  },
  {
    key: "webcube",
    title: "Webcube",
    grpId: "CMS",
  },
  {
    key: "weboffice",
    title: "Weboffice",
    grpId: "CMS",
  },
  {
    key: "webydo",
    title: "Webydo",
    grpId: "CMS",
  },
  {
    key: "weebly",
    title: "Weebly",
    grpId: "CMS",
  },
  {
    key: "whipple_hill",
    title: "Whipple Hill",
    grpId: "CMS",
  },
  {
    key: "wix",
    title: "Wix",
    grpId: "CMS",
  },
  {
    key: "wordpress_com",
    title: "Wordpress Com",
    grpId: "CMS",
  },
  {
    key: "wordpress_org",
    title: "Wordpress Org",
    grpId: "CMS",
  },
  {
    key: "wordpress_vip",
    title: "Wordpress Vip",
    grpId: "CMS",
  },
  {
    key: "wp_engine",
    title: "Wp Engine",
    grpId: "CMS",
  },
  {
    key: "yext",
    title: "Yext",
    grpId: "CMS",
  },
  {
    key: "yola",
    title: "Yola",
    grpId: "CMS",
  },
  {
    key: "yourmembership",
    title: "Yourmembership",
    grpId: "CMS",
  },
  {
    key: "sage-intacct",
    title: "Sage-Intacct",
    grpId: "CMS",
  },
  {
    key: "atlassian_bitbucket",
    title: "Atlassian Bitbucket",
    grpId: "CMS",
  },
  {
    key: "gitlab",
    title: "Gitlab",
    grpId: "CMS",
  },
  {
    key: "imatrix",
    title: "Imatrix",
    grpId: "CMS",
  },
  {
    key: "microsoft_sharepoint",
    title: "Microsoft Sharepoint",
    grpId: "CMS",
  },
  {
    key: "visual_basic",
    title: "Visual Basic",
    grpId: "CMS",
  },
  {
    key: "lokki",
    title: "Lokki",
    grpId: "CMS",
  },
  {
    key: "gorgias",
    title: "Gorgias",
    grpId: "CMS",
  },
  {
    key: "five9",
    title: "Five9",
    grpId: "CMS",
  },
  { title: "CSS and JavaScript Libraries" },

  {
    key: "amcharts_js_library",
    title: "Amcharts Js Library",
    grpId: "CSS and JavaScript Libraries",
  },
  {
    key: "bootstrap_framework",
    title: "Bootstrap Framework",
    grpId: "CSS and JavaScript Libraries",
  },
  {
    key: "bootstrap_framework_v3_1_1",
    title: "Bootstrap Framework V3 1 1",
    grpId: "CSS and JavaScript Libraries",
  },
  {
    key: "bootstrap_framework_v3_2_0",
    title: "Bootstrap Framework V3 2 0",
    grpId: "CSS and JavaScript Libraries",
  },
  {
    key: "ember_js_library",
    title: "Ember Js Library",
    grpId: "CSS and JavaScript Libraries",
  },
  {
    key: "fusioncharts_js_library",
    title: "Fusioncharts Js Library",
    grpId: "CSS and JavaScript Libraries",
  },
  {
    key: "highcharts_js_library",
    title: "Highcharts Js Library",
    grpId: "CSS and JavaScript Libraries",
  },
  {
    key: "jquery_1_11_1",
    title: "Jquery 1 11 1",
    grpId: "CSS and JavaScript Libraries",
  },
  {
    key: "jquery_2_1_1",
    title: "Jquery 2 1 1",
    grpId: "CSS and JavaScript Libraries",
  },
  {
    key: "react",
    title: "React",
    grpId: "CSS and JavaScript Libraries",
  },
  {
    key: "signalr",
    title: "Signalr",
    grpId: "CSS and JavaScript Libraries",
  },
  { title: "Call Tracking" },

  {
    key: "callrail",
    title: "Callrail",
    grpId: "Call Tracking",
  },
  {
    key: "century_interactive",
    title: "Century Interactive",
    grpId: "Call Tracking",
  },
  {
    key: "freespee",
    title: "Freespee",
    grpId: "Call Tracking",
  },
  {
    key: "ifbyphone",
    title: "Ifbyphone",
    grpId: "Call Tracking",
  },
  {
    key: "infinity_tracking",
    title: "Infinity Tracking",
    grpId: "Call Tracking",
  },
  {
    key: "logmycalls",
    title: "Logmycalls",
    grpId: "Call Tracking",
  },
  {
    key: "responsetap",
    title: "Responsetap",
    grpId: "Call Tracking",
  },
  {
    key: "vox_analytics",
    title: "Vox Analytics",
    grpId: "Call Tracking",
  },
  { title: "Captcha" },

  {
    key: "keycaptcha",
    title: "Keycaptcha",
    grpId: "Captcha",
  },
  {
    key: "nlp_catpcha",
    title: "Nlp Catpcha",
    grpId: "Captcha",
  },
  {
    key: "minteye_/_adscaptcha",
    title: "Minteye / Adscaptcha",
    grpId: "Captcha",
  },
  {
    key: "nucaptcha",
    title: "Nucaptcha",
    grpId: "Captcha",
  },
  {
    key: "recaptcha",
    title: "Recaptcha",
    grpId: "Captcha",
  },
  {
    key: "solvemedia",
    title: "Solvemedia",
    grpId: "Captcha",
  },
  {
    key: "sweetcaptcha",
    title: "Sweetcaptcha",
    grpId: "Captcha",
  },
  { title: "Chats" },

  { key: "activa", grpId: "Chats", title: "Activa" },
  { key: "activengage", grpId: "Chats", title: "Activengage" },
  { key: "activetalk", grpId: "Chats", title: "Activetalk" },
  { key: "apex_chat", grpId: "Chats", title: "Apex Chat" },
  { key: "bevyup", grpId: "Chats", title: "Bevyup" },
  { key: "boldchat", grpId: "Chats", title: "Boldchat" },
  { key: "carchat24", grpId: "Chats", title: "Carchat24" },
  { key: "cbox", grpId: "Chats", title: "Cbox" },
  { key: "clientconnexion", grpId: "Chats", title: "Clientconnexion" },
  { key: "cloudim", grpId: "Chats", title: "Cloudim" },
  { key: "cobrowser_chat", grpId: "Chats", title: "Cobrowser Chat" },
  { key: "comm100", grpId: "Chats", title: "Comm100" },
  { key: "contactatonce", grpId: "Chats", title: "Contactatonce" },
  { key: "egain", grpId: "Chats", title: "Egain" },
  { key: "elead_chat", grpId: "Chats", title: "Elead Chat" },
  { key: "gaglers", grpId: "Chats", title: "Gaglers" },
  { key: "forddirect_chat", grpId: "Chats", title: "Forddirect Chat" },
  { key: "gotalk", grpId: "Chats", title: "Gotalk" },
  { key: "gubagoo", grpId: "Chats", title: "Gubagoo" },
  { key: "helponclick", grpId: "Chats", title: "Helponclick" },
  { key: "iadvize", grpId: "Chats", title: "Iadvize" },
  { key: "instantservice", grpId: "Chats", title: "Instantservice" },
  { key: "jivosite", grpId: "Chats", title: "Jivosite" },
  { key: "livechat", grpId: "Chats", title: "Livechat" },
  {
    key: "liveperson_monitor",
    grpId: "Chats",
    title: "Liveperson Monitor",
  },
  { key: "livetex", grpId: "Chats", title: "Livetex" },
  { key: "livezilla", grpId: "Chats", title: "Livezilla" },
  { key: "marva", grpId: "Chats", title: "Marva" },
  { key: "moxie", grpId: "Chats", title: "Moxie" },
  {
    key: "netop_live_guide",
    grpId: "Chats",
    title: "Netop Live Guide",
  },
  { key: "netroxsc", grpId: "Chats", title: "Netroxsc" },
  { key: "olark", grpId: "Chats", title: "Olark" },
  { key: "onicon", grpId: "Chats", title: "Onicon" },
  { key: "opanel", grpId: "Chats", title: "Opanel" },
  {
    key: "oracle_live_help",
    grpId: "Chats",
    title: "Oracle Live Help",
  },
  { key: "p3chat", grpId: "Chats", title: "P3chat" },
  { key: "providesupport", grpId: "Chats", title: "Providesupport" },
  { key: "purpleview", grpId: "Chats", title: "Purpleview" },
  {
    key: "realperson_chat_optimise-it",
    grpId: "Chats",
    title: "Realperson Chat Optimise-It",
  },
  { key: "redhelper", grpId: "Chats", title: "Redhelper" },
  { key: "robin", grpId: "Chats", title: "Robin" },
  {
    key: "salesforce_live_agent",
    grpId: "Chats",
    title: "Salesforce Live Agent",
  },
  { key: "salesmove", grpId: "Chats", title: "Salesmove" },
  { key: "siteheart", grpId: "Chats", title: "Siteheart" },
  { key: "sitehelp", grpId: "Chats", title: "Sitehelp" },
  {
    key: "slack_subdomains_only",
    grpId: "Chats",
    title: "Slack Subdomains Only",
  },
  { key: "smartsupp", grpId: "Chats", title: "Smartsupp" },
  { key: "snapengage", grpId: "Chats", title: "Snapengage" },
  { key: "touchcommerce", grpId: "Chats", title: "Touchcommerce" },
  { key: "unblu", grpId: "Chats", title: "Unblu" },
  { key: "userlike", grpId: "Chats", title: "Userlike" },
  { key: "vee24", grpId: "Chats", title: "Vee24" },
  { key: "velaro", grpId: "Chats", title: "Velaro" },
  { key: "vivocha", grpId: "Chats", title: "Vivocha" },
  {
    key: "volusion_livechat",
    grpId: "Chats",
    title: "Volusion Livechat",
  },
  { key: "webgreeter", grpId: "Chats", title: "Webgreeter" },
  { key: "websitealive", grpId: "Chats", title: "Websitealive" },
  { key: "whoson", grpId: "Chats", title: "Whoson" },
  { key: "zoho_livedesk", grpId: "Chats", title: "Zoho Livedesk" },
  { key: "zopim", grpId: "Chats", title: "Zopim" },
  { title: "Cloud Services" },

  { key: "cloudinary", grpId: "Cloud Services", title: "Cloudinary" },
  { key: "dropbox", grpId: "Cloud Services", title: "Dropbox" },
  { key: "servint", grpId: "Cloud Services", title: "ServInt" },

  { title: "Comments" },

  { key: "disqus", grpId: "Comments", title: "Disqus" },
  {
    key: "facebook_comments",
    grpId: "Comments",
    title: "Facebook Comments",
  },
  { key: "gigya_comments", grpId: "Comments", title: "Gigya Comments" },
  { key: "intense_debate", grpId: "Comments", title: "Intense Debate" },
  {
    key: "invision_power_ip_board",
    grpId: "Comments",
    title: "Invision Power (IP Board)",
  },
  { key: "sezwho", grpId: "Comments", title: "Sezwho" },
  { key: "vanilla_forums", grpId: "Comments", title: "Vanilla Forums" },
  { key: "viafoura", grpId: "Comments", title: "Viafoura" },
  { title: "Content Delivery Networks" },

  {
    key: "akamai",
    grpId: "Content Delivery Networks",
    title: "Akamai",
  },
  {
    key: "amazon_s3",
    grpId: "Content Delivery Networks",
    title: "Amazon S3",
  },
  {
    key: "amplience",
    grpId: "Content Delivery Networks",
    title: "Amplience",
  },
  {
    key: "ananke_cdn",
    grpId: "Content Delivery Networks",
    title: "Ananke Cdn",
  },
  { key: "azure", grpId: "Content Delivery Networks", title: "Azure" },
  {
    key: "bitgravity",
    grpId: "Content Delivery Networks",
    title: "Bitgravity",
  },
  {
    key: "bitrix_cdn",
    grpId: "Content Delivery Networks",
    title: "Bitrix Cdn",
  },
  { key: "cdn77", grpId: "Content Delivery Networks", title: "Cdn77" },
  {
    key: "cdnetworks",
    grpId: "Content Delivery Networks",
    title: "Cdnetworks",
  },
  {
    key: "cdnsun",
    grpId: "Content Delivery Networks",
    title: "Cdnsun",
  },
  {
    key: "cdnvideo",
    grpId: "Content Delivery Networks",
    title: "Cdnvideo",
  },
  {
    key: "cedexis",
    grpId: "Content Delivery Networks",
    title: "Cedexis",
  },
  {
    key: "china_net_center",
    grpId: "Content Delivery Networks",
    title: "China Net Center",
  },
  {
    key: "chinacache",
    grpId: "Content Delivery Networks",
    title: "Chinacache",
  },
  {
    key: "cloudflare_cdn",
    grpId: "Content Delivery Networks",
    title: "Cloudflare Cdn",
  },
  {
    key: "contentful",
    grpId: "Content Delivery Networks",
    title: "Contentful",
  },
  {
    key: "distrify",
    grpId: "Content Delivery Networks",
    title: "Distrify",
  },
  {
    key: "distroscale",
    grpId: "Content Delivery Networks",
    title: "Distroscale",
  },
  {
    key: "edgecast",
    grpId: "Content Delivery Networks",
    title: "Edgecast",
  },
  {
    key: "fastly_cdn",
    grpId: "Content Delivery Networks",
    title: "Fastly Cdn",
  },
  {
    key: "google_cloud_storage",
    grpId: "Content Delivery Networks",
    title: "Google Cloud Storage",
  },
  {
    key: "highwinds",
    grpId: "Content Delivery Networks",
    title: "Highwinds",
  },
  { key: "ielo", grpId: "Content Delivery Networks", title: "Ielo" },
  {
    key: "instart_logic",
    grpId: "Content Delivery Networks",
    title: "Instart Logic",
  },
  {
    key: "internap",
    grpId: "Content Delivery Networks",
    title: "Internap",
  },
  {
    key: "internap_cdn",
    grpId: "Content Delivery Networks",
    title: "Internap Cdn",
  },
  {
    key: "isprime",
    grpId: "Content Delivery Networks",
    title: "Isprime",
  },
  {
    key: "level_3",
    grpId: "Content Delivery Networks",
    title: "Level 3",
  },
  {
    key: "maxcdn",
    grpId: "Content Delivery Networks",
    title: "Maxcdn",
  },
  {
    key: "ngenix",
    grpId: "Content Delivery Networks",
    title: "Ngenix",
  },
  { key: "onapp", grpId: "Content Delivery Networks", title: "Onapp" },
  {
    key: "panther",
    grpId: "Content Delivery Networks",
    title: "Panther",
  },
  {
    key: "pubnub",
    grpId: "Content Delivery Networks",
    title: "Pubnub",
  },
  {
    key: "pusher",
    grpId: "Content Delivery Networks",
    title: "Pusher",
  },
  {
    key: "rackspace_cdn",
    grpId: "Content Delivery Networks",
    title: "Rackspace Cdn",
  },
  {
    key: "reflected_networks",
    grpId: "Content Delivery Networks",
    title: "Reflected Networks",
  },
  {
    key: "scaleengine",
    grpId: "Content Delivery Networks",
    title: "Scaleengine",
  },
  {
    key: "skypark_cdn",
    grpId: "Content Delivery Networks",
    title: "Skypark Cdn",
  },
  {
    key: "swiftcdn",
    grpId: "Content Delivery Networks",
    title: "Swiftcdn",
  },
  {
    key: "taobao",
    grpId: "Content Delivery Networks",
    title: "Taobao",
  },
  {
    key: "tencent",
    grpId: "Content Delivery Networks",
    title: "Tencent",
  },
  {
    key: "turbobytes",
    grpId: "Content Delivery Networks",
    title: "Turbobytes",
  },
  { key: "vhx", grpId: "Content Delivery Networks", title: "Vhx" },
  {
    key: "xinnet",
    grpId: "Content Delivery Networks",
    title: "Xinnet",
  },
  { key: "yekra", grpId: "Content Delivery Networks", title: "Yekra" },
  {
    key: "yottaa",
    grpId: "Content Delivery Networks",
    title: "Yottaa",
  },
  { title: "Content Syndication Networks" },

  {
    key: "adblade_advertisement",
    grpId: "Content Syndication Networks",
    title: "Adblade Advertisement",
  },
  {
    key: "content_ad",
    grpId: "Content Syndication Networks",
    title: "Content Ad",
  },
  {
    key: "dianomi",
    grpId: "Content Syndication Networks",
    title: "Dianomi",
  },
  {
    key: "flix_media",
    grpId: "Content Syndication Networks",
    title: "Flix Media",
  },
  {
    key: "gravity",
    grpId: "Content Syndication Networks",
    title: "Gravity",
  },
  {
    key: "instinctive_ads",
    grpId: "Content Syndication Networks",
    title: "Instinctive Ads",
  },
  {
    key: "linksmart",
    grpId: "Content Syndication Networks",
    title: "Linksmart",
  },
  {
    key: "native_ads",
    grpId: "Content Syndication Networks",
    title: "Native Ads",
  },
  {
    key: "newscred",
    grpId: "Content Syndication Networks",
    title: "Newscred",
  },
  {
    key: "nster",
    grpId: "Content Syndication Networks",
    title: "Nster",
  },
  {
    key: "ntent",
    grpId: "Content Syndication Networks",
    title: "Ntent",
  },
  {
    key: "outbrain",
    grpId: "Content Syndication Networks",
    title: "Outbrain",
  },
  {
    key: "outbrain_-_advertisers",
    grpId: "Content Syndication Networks",
    title: "Outbrain - Advertisers",
  },
  {
    key: "perfect_market_taboola",
    grpId: "Content Syndication Networks",
    title: "Perfect Market Taboola",
  },
  {
    key: "plista",
    grpId: "Content Syndication Networks",
    title: "Plista",
  },
  {
    key: "realgravity",
    grpId: "Content Syndication Networks",
    title: "Realgravity",
  },
  {
    key: "shareaholic_content_amplification",
    grpId: "Content Syndication Networks",
    title: "Shareaholic Content Amplification",
  },
  {
    key: "simplereach",
    grpId: "Content Syndication Networks",
    title: "Simplereach",
  },
  {
    key: "taboola",
    grpId: "Content Syndication Networks",
    title: "Taboola",
  },
  {
    key: "vidible",
    grpId: "Content Syndication Networks",
    title: "Vidible",
  },
  {
    key: "webcollage",
    grpId: "Content Syndication Networks",
    title: "Webcollage",
  },
  {
    key: "yahoo_recommends",
    grpId: "Content Syndication Networks",
    title: "Yahoo Recommends",
  },
  {
    key: "zemanta",
    grpId: "Content Syndication Networks",
    title: "Zemanta",
  },
  {
    key: "zergnet",
    grpId: "Content Syndication Networks",
    title: "Zergnet",
  },

  { title: "Customer Relationship Management" },

  {
    key: "athenahealth",
    grpId: "Customer Relationship Management",
    title: "Athenahealth",
  },
  {
    key: "base_crm",
    grpId: "Customer Relationship Management",
    title: "Base Crm",
  },
  {
    key: "blackbaud",
    grpId: "Customer Relationship Management",
    title: "Blackbaud",
  },
  {
    key: "gingr",
    grpId: "Customer Relationship Management",
    title: "Gingr",
  },
  {
    key: "infusionsoft",
    grpId: "Customer Relationship Management",
    title: "Infusionsoft",
  },
  {
    key: "insightly",
    grpId: "Customer Relationship Management",
    title: "Insightly",
  },
  {
    key: "jive",
    grpId: "Customer Relationship Management",
    title: "Jive",
  },
  {
    key: "lithium_technologies",
    grpId: "Customer Relationship Management",
    title: "Lithium Technologies",
  },
  {
    key: "nimble_ams",
    grpId: "Customer Relationship Management",
    title: "Nimble Ams",
  },
  {
    key: "ontraport",
    grpId: "Customer Relationship Management",
    title: "Ontraport",
  },
  {
    key: "sage_crm",
    grpId: "Customer Relationship Management",
    title: "Sage Crm",
  },
  {
    key: "salesforce",
    grpId: "Customer Relationship Management",
    title: "Salesforce",
  },
  {
    key: "sugar_crm",
    grpId: "Customer Relationship Management",
    title: "Sugar Crm",
  },
  {
    key: "telligent",
    grpId: "Customer Relationship Management",
    title: "Telligent",
  },
  {
    key: "zoho_crm",
    grpId: "Customer Relationship Management",
    title: "Zoho Crm",
  },
  { title: "Customer Reviews" },

  {
    key: "ausgezeichnet",
    grpId: "Customer Reviews",
    title: "Ausgezeichnet",
  },
  {
    key: "avis_verifies",
    grpId: "Customer Reviews",
    title: "Avis Verifies",
  },
  {
    key: "bazaarvoice",
    grpId: "Customer Reviews",
    title: "Bazaarvoice",
  },
  { key: "bizrate", grpId: "Customer Reviews", title: "Bizrate" },
  {
    key: "demandforce_reviews_widget",
    grpId: "Customer Reviews",
    title: "Demandforce Reviews Widget",
  },
  { key: "ekomi", grpId: "Customer Reviews", title: "Ekomi" },
  { key: "feefo", grpId: "Customer Reviews", title: "Feefo" },
  { key: "fia-net", grpId: "Customer Reviews", title: "Fia-Net" },
  { key: "foxrate", grpId: "Customer Reviews", title: "Foxrate" },
  {
    key: "guild_quality",
    grpId: "Customer Reviews",
    title: "Guild Quality",
  },
  { key: "kiyoh_nl", grpId: "Customer Reviews", title: "Kiyoh Nl" },
  {
    key: "powerreviews",
    grpId: "Customer Reviews",
    title: "Powerreviews",
  },
  {
    key: "pricegrabber",
    grpId: "Customer Reviews",
    title: "Pricegrabber",
  },
  { key: "reevoo", grpId: "Customer Reviews", title: "Reevoo" },
  {
    key: "resellerratings",
    grpId: "Customer Reviews",
    title: "Resellerratings",
  },
  {
    key: "review_buzz",
    grpId: "Customer Reviews",
    title: "Review Buzz",
  },
  {
    key: "reviewcentre",
    grpId: "Customer Reviews",
    title: "Reviewcentre",
  },
  {
    key: "reviews_co_uk",
    grpId: "Customer Reviews",
    title: "Reviews Co Uk",
  },
  {
    key: "shopauskunft",
    grpId: "Customer Reviews",
    title: "Shopauskunft",
  },
  {
    key: "shopper_approved",
    grpId: "Customer Reviews",
    title: "Shopper Approved",
  },
  { key: "shopvote", grpId: "Customer Reviews", title: "Shopvote" },
  {
    key: "the_feedback_company",
    grpId: "Customer Reviews",
    title: "The Feedback Company",
  },
  {
    key: "trustedshops",
    grpId: "Customer Reviews",
    title: "Trustedshops",
  },
  { key: "trustpilot", grpId: "Customer Reviews", title: "Trustpilot" },
  { title: "Data Management Platform" },

  { key: "acxiom", grpId: "Data Management Platform", title: "Acxiom" },
  {
    key: "audiencescience",
    grpId: "Data Management Platform",
    title: "Audiencescience",
  },
  {
    key: "blueconic",
    grpId: "Data Management Platform",
    title: "Blueconic",
  },
  {
    key: "bluekai",
    grpId: "Data Management Platform",
    title: "Bluekai",
  },
  { key: "brilig", grpId: "Data Management Platform", title: "Brilig" },
  {
    key: "crowd_science",
    grpId: "Data Management Platform",
    title: "Crowd Science",
  },
  {
    key: "datalogix",
    grpId: "Data Management Platform",
    title: "Datalogix",
  },
  {
    key: "enreach",
    grpId: "Data Management Platform",
    title: "Enreach",
  },
  { key: "flxone", grpId: "Data Management Platform", title: "Flxone" },
  { key: "krux", grpId: "Data Management Platform", title: "Krux" },
  {
    key: "liveramp",
    grpId: "Data Management Platform",
    title: "Liveramp",
  },
  {
    key: "liveramp_match_partners",
    grpId: "Data Management Platform",
    title: "Liveramp Match Partners",
  },
  { key: "lotame", grpId: "Data Management Platform", title: "Lotame" },
  { key: "navegg", grpId: "Data Management Platform", title: "Navegg" },
  {
    key: "nielsen_display_ads_formerly_exelate",
    grpId: "Data Management Platform",
    title: "Nielsen Display Ads Formerly Exelate",
  },
  { key: "nuggad", grpId: "Data Management Platform", title: "Nuggad" },
  { key: "sojern", grpId: "Data Management Platform", title: "Sojern" },
  {
    key: "tailtarget",
    grpId: "Data Management Platform",
    title: "Tailtarget",
  },

  { title: "Demand Side Platforms" },

  {
    key: "33across",
    title: "33across",
    grpId: "Demand Side Platforms",
  },
  {
    key: "appnexus",
    title: "Appnexus",
    grpId: "Demand Side Platforms",
  },
  {
    key: "freakout",
    title: "Freakout",
    grpId: "Demand Side Platforms",
  },
  {
    key: "genesismedia",
    title: "Genesismedia",
    grpId: "Demand Side Platforms",
  },
  {
    key: "gdm_digital",
    title: "Gdm Digital",
    grpId: "Demand Side Platforms",
  },
  {
    key: "mediamath",
    title: "Mediamath",
    grpId: "Demand Side Platforms",
  },
  {
    key: "rocketfuel",
    title: "Rocketfuel",
    grpId: "Demand Side Platforms",
  },
  {
    key: "simpli_fi",
    title: "Simpli Fi",
    grpId: "Demand Side Platforms",
  },
  {
    key: "the_trade_desk",
    title: "The Trade Desk",
    grpId: "Demand Side Platforms",
  },
  {
    key: "tradelab",
    title: "Tradelab",
    grpId: "Demand Side Platforms",
  },
  { key: "triggit", title: "Triggit", grpId: "Demand Side Platforms" },
  { key: "turn", title: "Turn", grpId: "Demand Side Platforms" },
  {
    key: "yahoo_ad_manager_plus",
    title: "Yahoo Ad Manager Plus",
    grpId: "Demand Side Platforms",
  },
  {
    title: "Domain Name Services",
  },
  {
    title: "123-reg DNS",
    grpId: "Domain Name Services",
    key: "123-reg_dns",
  },
  {
    title: "Akamai DNS",
    grpId: "Domain Name Services",
    key: "akamai_dns",
  },
  {
    title: "AT&T DNS",
    grpId: "Domain Name Services",
    key: "at&t_dns",
  },
  {
    title: "Binero",
    grpId: "Domain Name Services",
    key: "binero",
  },
  {
    title: "Cloudflare DNS",
    grpId: "Domain Name Services",
    key: "cloudflare_dns",
  },
  {
    title: "Cotendo",
    grpId: "Domain Name Services",
    key: "cotendo",
  },
  {
    title: "CSC Corporate Domains",
    grpId: "Domain Name Services",
    key: "csc_corporate_domains",
  },
  {
    title: "DNS2Go",
    grpId: "Domain Name Services",
    key: "dns2go",
  },
  {
    title: "DNS.com",
    grpId: "Domain Name Services",
    key: "dns_com",
  },
  {
    title: "DNS Made Easy",
    grpId: "Domain Name Services",
    key: "dns_made_easy",
  },
  {
    title: "DNSimple",
    grpId: "Domain Name Services",
    key: "dnsimple",
  },
  {
    title: "DNSMax",
    grpId: "Domain Name Services",
    key: "dnsmax",
  },
  {
    title: "DNSPark",
    grpId: "Domain Name Services",
    key: "dnspark",
  },
  {
    title: "DNSPod",
    grpId: "Domain Name Services",
    key: "dnspod",
  },
  {
    title: "DtDNS",
    grpId: "Domain Name Services",
    key: "dtdns",
  },
  {
    title: "Dyn Managed DNS",
    grpId: "Domain Name Services",
    key: "dyn_managed_dns",
  },
  {
    title: "DynIP",
    grpId: "Domain Name Services",
    key: "dynip",
  },
  {
    title: "EasyDNS",
    grpId: "Domain Name Services",
    key: "easydns",
  },
  {
    title: "EdgeDirector",
    grpId: "Domain Name Services",
    key: "edgedirector",
  },
  {
    title: "Enom",
    grpId: "Domain Name Services",
    key: "enom",
  },
  {
    title: "Fasthosts",
    grpId: "Domain Name Services",
    key: "fasthosts",
  },
  {
    title: "Frobbit",
    grpId: "Domain Name Services",
    key: "frobbit",
  },
  {
    title: "Google Cloud DNS",
    grpId: "Domain Name Services",
    key: "google_cloud_dns",
  },
  {
    title: "Infoblox",
    grpId: "Domain Name Services",
    key: "infoblox",
  },
  {
    title: "Internap DNS",
    grpId: "Domain Name Services",
    key: "internap_dns",
  },
  {
    title: "LoadDNS",
    grpId: "Domain Name Services",
    key: "loaddns",
  },
  {
    title: "Melbourne IT",
    grpId: "Domain Name Services",
    key: "melbourne_it",
  },
  {
    title: "Netnames Cedel",
    grpId: "Domain Name Services",
    key: "netnames_cedel",
  },
  {
    title: "Netnames Speednames",
    grpId: "Domain Name Services",
    key: "netnames_speednames",
  },
  {
    title: "Netriplex",
    grpId: "Domain Name Services",
    key: "netriplex",
  },
  {
    title: "NMU AB",
    grpId: "Domain Name Services",
    key: "nmu_ab",
  },
  {
    title: "Nominum",
    grpId: "Domain Name Services",
    key: "nominum",
  },
  {
    title: "NSOne",
    grpId: "Domain Name Services",
    key: "nsone",
  },
  {
    title: "pointHQ",
    grpId: "Domain Name Services",
    key: "pointhq",
  },
  {
    title: "Ports IT",
    grpId: "Domain Name Services",
    key: "ports_it",
  },
  {
    title: "Prolexic DNS",
    grpId: "Domain Name Services",
    key: "prolexic_dns",
  },
  {
    title: "RackSpace DNS",
    grpId: "Domain Name Services",
    key: "rackspace_dns",
  },
  {
    title: "Return Path",
    grpId: "Domain Name Services",
    key: "return_path",
  },
  {
    title: "Riktad AB",
    grpId: "Domain Name Services",
    key: "riktad_ab",
  },
  {
    title: "Route 53",
    grpId: "Domain Name Services",
    key: "route_53",
  },
  {
    title: "Sprint",
    grpId: "Domain Name Services",
    key: "sprint",
  },
  {
    title: "Svenska domaner",
    grpId: "Domain Name Services",
    key: "svenska_domaner",
  },
  {
    title: "TDC",
    grpId: "Domain Name Services",
    key: "tdc",
  },
  {
    title: "UltraDns",
    grpId: "Domain Name Services",
    key: "ultradns",
  },
  {
    title: "Verisign",
    grpId: "Domain Name Services",
    key: "verisign",
  },
  {
    title: "WorldwideDNS",
    grpId: "Domain Name Services",
    key: "worldwidedns",
  },
  {
    title: "Zerigo",
    grpId: "Domain Name Services",
    key: "zerigo",
  },
  {
    title: "ZoneEdit",
    grpId: "Domain Name Services",
    key: "zoneedit",
  },
  {
    title: "Campaign Monitor",
    grpId: "Domain Name Services",
    key: "campaign_monitor",
  },
  {
    title: "E-commerce Platforms",
  },
  {
    title: "1shoppingcart",
    grpId: "E-commerce Platforms",
    key: "1shoppingcart",
  },
  {
    title: "3D cart",
    grpId: "E-commerce Platforms",
    key: "3d_cart",
  },
  {
    title: "Amazon Webstore",
    grpId: "E-commerce Platforms",
    key: "amazon_webstore",
  },
  {
    title: "AmeriCommerce",
    grpId: "E-commerce Platforms",
    key: "americommerce",
  },
  {
    title: "Ascentium Commerce Server",
    grpId: "E-commerce Platforms",
    key: "ascentium_commerce_server",
  },
  {
    title: "AspDotNetStorefront",
    grpId: "E-commerce Platforms",
    key: "aspdotnetstorefront",
  },
  {
    title: "ATG Commerce",
    grpId: "E-commerce Platforms",
    key: "atg_commerce",
  },
  {
    title: "BigCartel",
    grpId: "E-commerce Platforms",
    key: "bigcartel",
  },
  {
    title: "BigCommerce",
    grpId: "E-commerce Platforms",
    key: "bigcommerce",
  },
  {
    title: "BorderFree",
    grpId: "E-commerce Platforms",
    key: "borderfree",
  },
  {
    title: "Boxever",
    grpId: "E-commerce Platforms",
    key: "boxever",
  },
  {
    title: "Branding Brand",
    grpId: "E-commerce Platforms",
    key: "branding_brand",
  },
  {
    title: "Cleverbridge",
    grpId: "E-commerce Platforms",
    key: "cleverbridge",
  },
  {
    title: "Coinbase",
    grpId: "E-commerce Platforms",
    key: "coinbase",
  },
  {
    title: "CoreCommerce",
    grpId: "E-commerce Platforms",
    key: "corecommerce",
  },
  {
    title: "CS Cart",
    grpId: "E-commerce Platforms",
    key: "cs_cart",
  },
  {
    title: "CubeCart",
    grpId: "E-commerce Platforms",
    key: "cubecart",
  },
  {
    title: "Demandware",
    grpId: "E-commerce Platforms",
    key: "demandware",
  },
  {
    title: "Ecwid",
    grpId: "E-commerce Platforms",
    key: "ecwid",
  },
  {
    title: "EKM Powershop",
    grpId: "E-commerce Platforms",
    key: "ekm_powershop",
  },
  {
    title: "Epages",
    grpId: "E-commerce Platforms",
    key: "epages",
  },
  {
    title: "Frosmo",
    grpId: "E-commerce Platforms",
    key: "frosmo",
  },
  {
    title: "GoECart",
    grpId: "E-commerce Platforms",
    key: "goecart",
  },
  {
    title: "GSI (Ebay Enterprise)",
    grpId: "E-commerce Platforms",
    key: "gsi_ebay_enterprise",
  },
  {
    title: "Hybris (SAP)",
    grpId: "E-commerce Platforms",
    key: "hybris_sap",
  },
  {
    title: "IBM Websphere",
    grpId: "E-commerce Platforms",
    key: "ibm_websphere",
  },
  {
    title: "InSales",
    grpId: "E-commerce Platforms",
    key: "insales",
  },
  {
    title: "Intershop",
    grpId: "E-commerce Platforms",
    key: "intershop",
  },
  {
    title: "JTL-Shop 3",
    grpId: "E-commerce Platforms",
    key: "jtl-shop_3",
  },
  {
    title: "Kalio",
    grpId: "E-commerce Platforms",
    key: "kalio",
  },
  {
    title: "KonaKart",
    grpId: "E-commerce Platforms",
    key: "konakart",
  },
  {
    title: "Mad Mobile",
    grpId: "E-commerce Platforms",
    key: "mad_mobile",
  },
  {
    title: "Magento",
    grpId: "E-commerce Platforms",
    key: "magento",
  },
  {
    title: "Magento Enterprise",
    grpId: "E-commerce Platforms",
    key: "magento_enterprise",
  },
  {
    title: "Marketlive",
    grpId: "E-commerce Platforms",
    key: "marketlive",
  },
  {
    title: "Miva Merchant",
    grpId: "E-commerce Platforms",
    key: "miva_merchant",
  },
  {
    title: "Mobify",
    grpId: "E-commerce Platforms",
    key: "mobify",
  },
  {
    title: "Moovweb",
    grpId: "E-commerce Platforms",
    key: "moovweb",
  },
  {
    title: "Mozu",
    grpId: "E-commerce Platforms",
    key: "mozu",
  },
  {
    title: "MyCommerce",
    grpId: "E-commerce Platforms",
    key: "mycommerce",
  },
  {
    title: "NetSuite E-commerce",
    grpId: "E-commerce Platforms",
    key: "netsuite_e-commerce",
  },
  {
    title: "OpenCart",
    grpId: "E-commerce Platforms",
    key: "opencart",
  },
  {
    title: "osCommerce",
    grpId: "E-commerce Platforms",
    key: "oscommerce",
  },
  {
    title: "Oxid",
    grpId: "E-commerce Platforms",
    key: "oxid",
  },
  {
    title: "PINNACLE CART",
    grpId: "E-commerce Platforms",
    key: "pinnacle_cart",
  },
  {
    title: "Pixafy",
    grpId: "E-commerce Platforms",
    key: "pixafy",
  },
  {
    title: "Premium Web Cart",
    grpId: "E-commerce Platforms",
    key: "premium_web_cart",
  },
  {
    title: "Prom.ua",
    grpId: "E-commerce Platforms",
    key: "prom_ua",
  },
  {
    title: "SalonBiz / SpaBiz",
    grpId: "E-commerce Platforms",
    key: "salonbiz_/_spabiz",
  },
  {
    title: "SEOshop",
    grpId: "E-commerce Platforms",
    key: "seoshop",
  },
  {
    title: "Shopify",
    grpId: "E-commerce Platforms",
    key: "shopify",
  },
  {
    title: "Shopp",
    grpId: "E-commerce Platforms",
    key: "shopp",
  },
  {
    title: "ShopVisible",
    grpId: "E-commerce Platforms",
    key: "shopvisible",
  },
  {
    title: "Shopware",
    grpId: "E-commerce Platforms",
    key: "shopware",
  },
  {
    title: "Spree",
    grpId: "E-commerce Platforms",
    key: "spree",
  },
  {
    title: "Squarespace ECommerce",
    grpId: "E-commerce Platforms",
    key: "squarespace_ecommerce",
  },
  {
    title: "Tictail",
    grpId: "E-commerce Platforms",
    key: "tictail",
  },
  {
    title: "UltraCart",
    grpId: "E-commerce Platforms",
    key: "ultracart",
  },
  {
    title: "UniteU",
    grpId: "E-commerce Platforms",
    key: "uniteu",
  },
  {
    title: "Upshot Commerce",
    grpId: "E-commerce Platforms",
    key: "upshot_commerce",
  },
  {
    title: "Usablenet",
    grpId: "E-commerce Platforms",
    key: "usablenet",
  },
  {
    title: "Venda",
    grpId: "E-commerce Platforms",
    key: "venda",
  },
  {
    title: "Virtuemart",
    grpId: "E-commerce Platforms",
    key: "virtuemart",
  },
  {
    title: "Visualsoft",
    grpId: "E-commerce Platforms",
    key: "visualsoft",
  },
  {
    title: "Volusion",
    grpId: "E-commerce Platforms",
    key: "volusion",
  },
  {
    title: "Vtex",
    grpId: "E-commerce Platforms",
    key: "vtex",
  },
  {
    title: "WebLinc",
    grpId: "E-commerce Platforms",
    key: "weblinc",
  },
  {
    title: "Woo Commerce",
    grpId: "E-commerce Platforms",
    key: "woo_commerce",
  },
  {
    title: "WP eCommerce",
    grpId: "E-commerce Platforms",
    key: "wp_ecommerce",
  },
  {
    title: "X-Cart",
    grpId: "E-commerce Platforms",
    key: "x-cart",
  },
  {
    title: "Xt-commerce",
    grpId: "E-commerce Platforms",
    key: "xt-commerce",
  },
  {
    title: "Yahoo Store",
    grpId: "E-commerce Platforms",
    key: "yahoo_store",
  },
  {
    title: "Zoovy",
    grpId: "E-commerce Platforms",
    key: "zoovy",
  },
  {
    title: "Magento 1.7",
    grpId: "E-commerce Platforms",
    key: "magento_17",
  },
  {
    title: "Magento 1.8",
    grpId: "E-commerce Platforms",
    key: "magento_18",
  },
  {
    title: "Magento 1.9",
    grpId: "E-commerce Platforms",
    key: "magento_19",
  },
  {
    title: "Magento 2.0",
    grpId: "E-commerce Platforms",
    key: "magento_20",
  },
  {
    title: "Magento 2 Community",
    grpId: "E-commerce Platforms",
    key: "magento_2_community",
  },
  {
    title: "Magento 2 Enterprise",
    grpId: "E-commerce Platforms",
    key: "magento_2_enterprise",
  },
  {
    title: "Shopify Plus",
    grpId: "E-commerce Platforms",
    key: "shopify_plus",
  },
  {
    title: "Shopify Product Reviews",
    grpId: "E-commerce Platforms",
    key: "shopify_product_reviews",
  },
  {
    title: "PrestaShop",
    grpId: "E-commerce Platforms",
    key: "prestashop",
  },
  {
    title: "E-commerce Tools",
  },
  {
    title: "Barilliance",
    grpId: "E-commerce Tools",
    key: "barilliance",
  },
  {
    title: "Candid",
    grpId: "E-commerce Tools",
    key: "candid",
  },
  {
    title: "Celerant",
    grpId: "E-commerce Tools",
    key: "celerant",
  },
  {
    title: "Channel Advisor",
    grpId: "E-commerce Tools",
    key: "channel_advisor",
  },
  {
    title: "Channel Intelligence by Google",
    grpId: "E-commerce Tools",
    key: "channel_intelligence_by_google",
  },
  {
    title: "ChannelAdvisor",
    grpId: "E-commerce Tools",
    key: "channeladvisor",
  },
  {
    title: "Etsy",
    grpId: "E-commerce Tools",
    key: "etsy",
  },
  {
    title: "FanPlayr",
    grpId: "E-commerce Tools",
    key: "fanplayr",
  },
  {
    title: "Forewards",
    grpId: "E-commerce Tools",
    key: "forewards",
  },
  {
    title: "Google Analytics Ecommerce Tracking",
    grpId: "E-commerce Tools",
    key: "google_analytics_ecommerce_tracking",
  },
  {
    title: "Google Trusted Stores",
    grpId: "E-commerce Tools",
    key: "google_trusted_stores",
  },
  {
    title: "Hook MyVisit",
    grpId: "E-commerce Tools",
    key: "hook_myvisit",
  },
  {
    title: "HookLogic",
    grpId: "E-commerce Tools",
    key: "hooklogic",
  },
  {
    title: "Insparq",
    grpId: "E-commerce Tools",
    key: "insparq",
  },
  {
    title: "Jirafe",
    grpId: "E-commerce Tools",
    key: "jirafe",
  },
  {
    title: "JustUno",
    grpId: "E-commerce Tools",
    key: "justuno",
  },
  {
    title: "Lengow",
    grpId: "E-commerce Tools",
    key: "lengow",
  },
  {
    title: "Lexity",
    grpId: "E-commerce Tools",
    key: "lexity",
  },
  {
    title: "Mercent",
    grpId: "E-commerce Tools",
    key: "mercent",
  },
  {
    title: "Nextopia",
    grpId: "E-commerce Tools",
    key: "nextopia",
  },
  {
    title: "Nosto",
    grpId: "E-commerce Tools",
    key: "nosto",
  },
  {
    title: "OneStop",
    grpId: "E-commerce Tools",
    key: "onestop",
  },
  {
    title: "Reflektion",
    grpId: "E-commerce Tools",
    key: "reflektion",
  },
  {
    title: "Scene7",
    grpId: "E-commerce Tools",
    key: "scene7",
  },
  {
    title: "SearchSpring",
    grpId: "E-commerce Tools",
    key: "searchspring",
  },
  {
    title: "Site Vibes",
    grpId: "E-commerce Tools",
    key: "site_vibes",
  },
  {
    title: "SociableLabs",
    grpId: "E-commerce Tools",
    key: "sociablelabs",
  },
  {
    title: "Symphony Commerce",
    grpId: "E-commerce Tools",
    key: "symphony_commerce",
  },
  {
    title: "TheFind",
    grpId: "E-commerce Tools",
    key: "thefind",
  },
  {
    title: "WebEngage",
    grpId: "E-commerce Tools",
    key: "webengage",
  },
  {
    title: "Where2getit",
    grpId: "E-commerce Tools",
    key: "where2getit",
  },
  {
    title: "Yotpo",
    grpId: "E-commerce Tools",
    key: "yotpo",
  },
  {
    title: "Quickbooks Commerce",
    grpId: "E-commerce Tools",
    key: "quickbooks_commerce",
  },
  {
    title: "eBay Store",
    grpId: "E-commerce Tools",
    key: "ebay_store",
  },
  {
    title: "Education Management Software",
  },
  {
    title: "AAHA Accredited",
    grpId: "Education Management Software",
    key: "aaha_accredited",
  },
  {
    title: "Absorb",
    grpId: "Education Management Software",
    key: "absorb",
  },
  {
    title: "Active Network (Active Educate)",
    grpId: "Education Management Software",
    key: "active_network_active_educate",
  },
  {
    title: "Aeries",
    grpId: "Education Management Software",
    key: "aeries",
  },
  {
    title: "ANGEL LMS",
    grpId: "Education Management Software",
    key: "angel_lms",
  },
  {
    title: "Aspen",
    grpId: "Education Management Software",
    key: "aspen",
  },
  {
    title: "Blackboard LMS",
    grpId: "Education Management Software",
    key: "blackboard_lms",
  },
  {
    title: "Canvas by Instructure",
    grpId: "Education Management Software",
    key: "canvas_by_instructure",
  },
  {
    title: "Capita-SIMS",
    grpId: "Education Management Software",
    key: "capita-sims",
  },
  {
    title: "Champions Way",
    grpId: "Education Management Software",
    key: "champions_way",
  },
  {
    title: "Compliance Wire",
    grpId: "Education Management Software",
    key: "compliance_wire",
  },
  {
    title: "CourseSites by Blackboard",
    grpId: "Education Management Software",
    key: "coursesites_by_blackboard",
  },
  {
    title: "Desire2Learn / Brightspace",
    grpId: "Education Management Software",
    key: "desire2learn_/_brightspace",
  },
  {
    title: "Docebo",
    grpId: "Education Management Software",
    key: "docebo",
  },
  {
    title: "Echo 360",
    grpId: "Education Management Software",
    key: "echo_360",
  },
  {
    title: "Edupoint",
    grpId: "Education Management Software",
    key: "edupoint",
  },
  {
    title: "Follett Aspen",
    grpId: "Education Management Software",
    key: "follett_aspen",
  },
  {
    title: "Infinite Campus",
    grpId: "Education Management Software",
    key: "infinite_campus",
  },
  {
    title: "Its Learning LMS",
    grpId: "Education Management Software",
    key: "its_learning_lms",
  },
  {
    title: "Litmos",
    grpId: "Education Management Software",
    key: "litmos",
  },
  {
    title: "Moodle",
    grpId: "Education Management Software",
    key: "moodle",
  },
  {
    title: "MyBigCampus",
    grpId: "Education Management Software",
    key: "mybigcampus",
  },
  {
    title: "Rediker (Plus Portals)",
    grpId: "Education Management Software",
    key: "rediker_plus_portals",
  },
  {
    title: "REnWeb",
    grpId: "Education Management Software",
    key: "renweb",
  },
  {
    title: "Schoology",
    grpId: "Education Management Software",
    key: "schoology",
  },
  {
    title: "Senior Systems",
    grpId: "Education Management Software",
    key: "senior_systems",
  },
  {
    title: "StudyWiz",
    grpId: "Education Management Software",
    key: "studywiz",
  },
  {
    title: "SunGard K12 eSchool Plus",
    grpId: "Education Management Software",
    key: "sungard_k12_eschool_plus",
  },
  {
    title: "Tegrity",
    grpId: "Education Management Software",
    key: "tegrity",
  },
  {
    title: "Tyler Technologies",
    grpId: "Education Management Software",
    key: "tyler_technologies",
  },
  {
    title: "Veracross",
    grpId: "Education Management Software",
    key: "veracross",
  },
  {
    title: "Email Delivery",
  },
  {
    title: "Amazon SES",
    grpId: "Email Delivery",
    key: "amazon_ses",
  },
  {
    title: "BriteVerify",
    grpId: "Email Delivery",
    key: "briteverify",
  },
  {
    title: "CritSend",
    grpId: "Email Delivery",
    key: "critsend",
  },
  {
    title: "DynECT",
    grpId: "Email Delivery",
    key: "dynect",
  },
  {
    title: "ElasticEmail",
    grpId: "Email Delivery",
    key: "elasticemail",
  },
  {
    title: "Mailchimp Mandrill",
    grpId: "Email Delivery",
    key: "mailchimp_mandrill",
  },
  {
    title: "MailJet",
    grpId: "Email Delivery",
    key: "mailjet",
  },
  {
    title: "MessageBus",
    grpId: "Email Delivery",
    key: "messagebus",
  },
  {
    title: "MySMTP",
    grpId: "Email Delivery",
    key: "mysmtp",
  },
  {
    title: "Oracle SPF",
    grpId: "Email Delivery",
    key: "oracle_spf",
  },
  {
    title: "PostageApp",
    grpId: "Email Delivery",
    key: "postageapp",
  },
  {
    title: "Postmark",
    grpId: "Email Delivery",
    key: "postmark",
  },
  {
    title: "Rackspace MailGun",
    grpId: "Email Delivery",
    key: "rackspace_mailgun",
  },
  {
    title: "Sendgrid",
    grpId: "Email Delivery",
    key: "sendgrid",
  },
  {
    title: "SMTP.com",
    grpId: "Email Delivery",
    key: "smtp_com",
  },
  {
    title: "SocketLabs",
    grpId: "Email Delivery",
    key: "socketlabs",
  },
  {
    title: "SparkPost",
    grpId: "Email Delivery",
    key: "sparkpost",
  },
  {
    title: "TurboSMTP",
    grpId: "Email Delivery",
    key: "turbosmtp",
  },
  {
    title: "Email Marketing",
  },
  {
    title: "Adestra",
    grpId: "Email Marketing",
    key: "adestra",
  },
  {
    title: "AgilOne",
    grpId: "Email Marketing",
    key: "agilone",
  },
  {
    title: "AWeber",
    grpId: "Email Marketing",
    key: "aweber",
  },
  {
    title: "Biz Wizard",
    grpId: "Email Marketing",
    key: "biz_wizard",
  },
  {
    title: "Bluehornet",
    grpId: "Email Marketing",
    key: "bluehornet",
  },
  {
    title: "Boomtrain",
    grpId: "Email Marketing",
    key: "boomtrain",
  },
  {
    title: "Bronto",
    grpId: "Email Marketing",
    key: "bronto",
  },
  {
    title: "CakeMail",
    grpId: "Email Marketing",
    key: "cakemail",
  },
  {
    title: "Campaigner",
    grpId: "Email Marketing",
    key: "campaigner",
  },
  {
    title: "CampaignMonitor",
    grpId: "Email Marketing",
    key: "campaignmonitor",
  },
  {
    title: "Carma Mail (Compost Marketing)",
    grpId: "Email Marketing",
    key: "carma_mail_compost_marketing",
  },
  {
    title: "CheetahMail",
    grpId: "Email Marketing",
    key: "cheetahmail",
  },
  {
    title: "Communicator",
    grpId: "Email Marketing",
    key: "communicator",
  },
  {
    title: "Constant Contact",
    grpId: "Email Marketing",
    key: "constant_contact",
  },
  {
    title: "Customer.IO",
    grpId: "Email Marketing",
    key: "customer_io",
  },
  {
    title: "Delivra",
    grpId: "Email Marketing",
    key: "delivra",
  },
  {
    title: "dotMailer",
    grpId: "Email Marketing",
    key: "dotmailer",
  },
  {
    title: "ECircle (Teradata)",
    grpId: "Email Marketing",
    key: "ecircle_teradata",
  },
  {
    title: "Email Attitude (1000mercis)",
    grpId: "Email Marketing",
    key: "email_attitude_1000mercis",
  },
  {
    title: "Email Center Pro",
    grpId: "Email Marketing",
    key: "email_center_pro",
  },
  {
    title: "EmailDirect",
    grpId: "Email Marketing",
    key: "emaildirect",
  },
  {
    title: "Emarsys",
    grpId: "Email Marketing",
    key: "emarsys",
  },
  {
    title: "Ematch",
    grpId: "Email Marketing",
    key: "ematch",
  },
  {
    title: "Emma",
    grpId: "Email Marketing",
    key: "emma",
  },
  {
    title: "Eperflex / ividence",
    grpId: "Email Marketing",
    key: "eperflex_/_ividence",
  },
  {
    title: "ExactTarget",
    grpId: "Email Marketing",
    key: "exacttarget",
  },
  {
    title: "Fishbowl",
    grpId: "Email Marketing",
    key: "fishbowl",
  },
  {
    title: "Get-a-newsletter",
    grpId: "Email Marketing",
    key: "get-a-newsletter",
  },
  {
    title: "GetResponse",
    grpId: "Email Marketing",
    key: "getresponse",
  },
  {
    title: "Graphicmail",
    grpId: "Email Marketing",
    key: "graphicmail",
  },
  {
    title: "iContact",
    grpId: "Email Marketing",
    key: "icontact",
  },
  {
    title: "Klaviyo",
    grpId: "Email Marketing",
    key: "klaviyo",
  },
  {
    title: "Listrak",
    grpId: "Email Marketing",
    key: "listrak",
  },
  {
    title: "Liveclicker",
    grpId: "Email Marketing",
    key: "liveclicker",
  },
  {
    title: "Lyris",
    grpId: "Email Marketing",
    key: "lyris",
  },
  {
    title: "Mad Mimi",
    grpId: "Email Marketing",
    key: "mad_mimi",
  },
  {
    title: "MailChimp",
    grpId: "Email Marketing",
    key: "mailchimp",
  },
  {
    title: "Maildirect.se",
    grpId: "Email Marketing",
    key: "maildirect_se",
  },
  {
    title: "MailUp",
    grpId: "Email Marketing",
    key: "mailup",
  },
  {
    title: "Maropost",
    grpId: "Email Marketing",
    key: "maropost",
  },
  {
    title: "MessageGears",
    grpId: "Email Marketing",
    key: "messagegears",
  },
  {
    title: "Mojn",
    grpId: "Email Marketing",
    key: "mojn",
  },
  {
    title: "MultiNet",
    grpId: "Email Marketing",
    key: "multinet",
  },
  {
    title: "Nudgespot",
    grpId: "Email Marketing",
    key: "nudgespot",
  },
  {
    title: "Paloma",
    grpId: "Email Marketing",
    key: "paloma",
  },
  {
    title: "Predictive Response",
    grpId: "Email Marketing",
    key: "predictive_response",
  },
  {
    title: "Pure360",
    grpId: "Email Marketing",
    key: "pure360",
  },
  {
    title: "Remintrex",
    grpId: "Email Marketing",
    key: "remintrex",
  },
  {
    title: "Responsys",
    grpId: "Email Marketing",
    key: "responsys",
  },
  {
    title: "Sare",
    grpId: "Email Marketing",
    key: "sare",
  },
  {
    title: "SendInBlue",
    grpId: "Email Marketing",
    key: "sendinblue",
  },
  {
    title: "Silverpop",
    grpId: "Email Marketing",
    key: "silverpop",
  },
  {
    title: "SmarterRemarketer",
    grpId: "Email Marketing",
    key: "smarterremarketer",
  },
  {
    title: "SmartFocus",
    grpId: "Email Marketing",
    key: "smartfocus",
  },
  {
    title: "Stream Send",
    grpId: "Email Marketing",
    key: "stream_send",
  },
  {
    title: "Tedemis",
    grpId: "Email Marketing",
    key: "tedemis",
  },
  {
    title: "TriggerMail",
    grpId: "Email Marketing",
    key: "triggermail",
  },
  {
    title: "Trumpia",
    grpId: "Email Marketing",
    key: "trumpia",
  },
  {
    title: "Vero",
    grpId: "Email Marketing",
    key: "vero",
  },
  {
    title: "Vision6",
    grpId: "Email Marketing",
    key: "vision6",
  },
  {
    title: "WhatCounts / Publicaster",
    grpId: "Email Marketing",
    key: "whatcounts_/_publicaster",
  },
  {
    title: "YesMail",
    grpId: "Email Marketing",
    key: "yesmail",
  },
  {
    title: "Email Providers",
  },
  {
    title: "1&1 Email Provider",
    grpId: "Email Providers",
    key: "1&1_email_provider",
  },
  {
    title: "Atmail",
    grpId: "Email Providers",
    key: "atmail",
  },
  {
    title: "Ceryx",
    grpId: "Email Providers",
    key: "ceryx",
  },
  {
    title: "Frontbridge",
    grpId: "Email Providers",
    key: "frontbridge",
  },
  {
    title: "Gmail",
    grpId: "Email Providers",
    key: "gmail",
  },
  {
    title: "GMX",
    grpId: "Email Providers",
    key: "gmx",
  },
  {
    title: "Horde",
    grpId: "Email Providers",
    key: "horde",
  },
  {
    title: "Hotmail",
    grpId: "Email Providers",
    key: "hotmail",
  },
  {
    title: "Hushmail",
    grpId: "Email Providers",
    key: "hushmail",
  },
  {
    title: "Inbox.com",
    grpId: "Email Providers",
    key: "inbox_com",
  },
  {
    title: "IntY MailDefender",
    grpId: "Email Providers",
    key: "inty_maildefender",
  },
  {
    title: "Lavabit",
    grpId: "Email Providers",
    key: "lavabit",
  },
  {
    title: "Microsoft Email Providers",
    grpId: "Email Providers",
    key: "microsoft_email_providers",
  },
  {
    title: "Mimecast",
    grpId: "Email Providers",
    key: "mimecast",
  },
  {
    title: "MX Logic",
    grpId: "Email Providers",
    key: "mx_logic",
  },
  {
    title: "Oracle Email Server",
    grpId: "Email Providers",
    key: "oracle_email_server",
  },
  {
    title: "Outlook",
    grpId: "Email Providers",
    key: "outlook",
  },
  {
    title: "Postini",
    grpId: "Email Providers",
    key: "postini",
  },
  {
    title: "Rackspace Email",
    grpId: "Email Providers",
    key: "rackspace_email",
  },
  {
    title: "Roundcube Email",
    grpId: "Email Providers",
    key: "roundcube_email",
  },
  {
    title: "ServiceNow",
    grpId: "Email Providers",
    key: "service_now",
  },
  {
    title: "SquirrelMail",
    grpId: "Email Providers",
    key: "squirrelmail",
  },
  {
    title: "Yahoo Mail",
    grpId: "Email Providers",
    key: "yahoo_mail",
  },
  {
    title: "Zimbra",
    grpId: "Email Providers",
    key: "zimbra",
  },
  {
    title: "Zoho Email",
    grpId: "Email Providers",
    key: "zoho_email",
  },
  {
    title: "Event Management",
  },
  {
    title: "ActiveNetwork",
    grpId: "Event Management",
    key: "activenetwork",
  },
  {
    title: "BusyConf",
    grpId: "Event Management",
    key: "busyconf",
  },
  {
    title: "Centium Software",
    grpId: "Event Management",
    key: "centium_software",
  },
  {
    title: "Certain Software",
    grpId: "Event Management",
    key: "certain_software",
  },
  {
    title: "CompuSystems",
    grpId: "Event Management",
    key: "compusystems",
  },
  {
    title: "Cvent",
    grpId: "Event Management",
    key: "cvent",
  },
  {
    title: "ePly Events",
    grpId: "Event Management",
    key: "eply_events",
  },
  {
    title: "etouches",
    grpId: "Event Management",
    key: "etouches",
  },
  {
    title: "Eventbrite",
    grpId: "Event Management",
    key: "eventbrite",
  },
  {
    title: "EventsAir by Centium",
    grpId: "Event Management",
    key: "eventsair_by_centium",
  },
  {
    title: "Experient",
    grpId: "Event Management",
    key: "experient",
  },
  {
    title: "Expotracker",
    grpId: "Event Management",
    key: "expotracker",
  },
  {
    title: "Info Salons",
    grpId: "Event Management",
    key: "info_salons",
  },
  {
    title: "RegOnline",
    grpId: "Event Management",
    key: "regonline",
  },
  {
    title: "Regpacks",
    grpId: "Event Management",
    key: "regpacks",
  },
  {
    title: "SignUp4",
    grpId: "Event Management",
    key: "signup4",
  },
  {
    title: "Ungerboeck Software",
    grpId: "Event Management",
    key: "ungerboeck_software",
  },
  {
    title: "Fonts",
  },
  {
    title: "Adobe Edge",
    grpId: "Fonts",
    key: "adobe_edge",
  },
  {
    title: "Cufon",
    grpId: "Fonts",
    key: "cufon",
  },
  {
    title: "FontDeck",
    grpId: "Fonts",
    key: "fontdeck",
  },
  {
    title: "Fonts.com",
    grpId: "Fonts",
    key: "fonts_com",
  },
  {
    title: "Google Font API",
    grpId: "Fonts",
    key: "google_font_api",
  },
  {
    title: "Typekit",
    grpId: "Fonts",
    key: "typekit",
  },
  {
    title: "Food Ordering Services",
  },
  {
    title: "1001menus",
    grpId: "Food Ordering Services",
    key: "1001menus",
  },
  {
    title: "BeyondMenu",
    grpId: "Food Ordering Services",
    key: "beyondmenu",
  },
  {
    title: "Brygid",
    grpId: "Food Ordering Services",
    key: "brygid",
  },
  {
    title: "Caviar",
    grpId: "Food Ordering Services",
    key: "caviar",
  },
  {
    title: "DoorDash",
    grpId: "Food Ordering Services",
    key: "doordash",
  },
  {
    title: "GrubHub",
    grpId: "Food Ordering Services",
    key: "grubhub",
  },
  {
    title: "MyPizza.com",
    grpId: "Food Ordering Services",
    key: "mypizza_com",
  },
  {
    title: "OrderAhead",
    grpId: "Food Ordering Services",
    key: "orderahead",
  },
  {
    title: "OrderingOnline",
    grpId: "Food Ordering Services",
    key: "orderingonline",
  },
  {
    title: "Seamless Web",
    grpId: "Food Ordering Services",
    key: "seamless_web",
  },
  {
    title: "Frameworks and Programming Languages",
  },
  {
    title: "AngularJS",
    grpId: "Frameworks and Programming Languages",
    key: "angularjs",
  },
  {
    title: "AngularJS 1.2.20",
    grpId: "Frameworks and Programming Languages",
    key: "angularjs_1_2_20",
  },
  {
    title: "AngularJS 1.3.0 Beta",
    grpId: "Frameworks and Programming Languages",
    key: "angularjs_1_3_0_beta",
  },
  {
    title: "Apache Coyote",
    grpId: "Frameworks and Programming Languages",
    key: "apache_coyote",
  },
  {
    title: "Apache Coyote v1.1",
    grpId: "Frameworks and Programming Languages",
    key: "apache_coyote_v1_1",
  },
  {
    title: "ASP.NET",
    grpId: "Frameworks and Programming Languages",
    key: "asp_net",
  },
  {
    title: "Django",
    grpId: "Frameworks and Programming Languages",
    key: "django",
  },
  {
    title: "Express JS Framework",
    grpId: "Frameworks and Programming Languages",
    key: "express_js_framework",
  },
  {
    title: "PHP",
    grpId: "Frameworks and Programming Languages",
    key: "php",
  },
  {
    title: "PHP 5.3",
    grpId: "Frameworks and Programming Languages",
    key: "php_5_3",
  },
  {
    title: "PrototypeJS",
    grpId: "Frameworks and Programming Languages",
    key: "prototypejs",
  },
  {
    title: "Ruby On Rails",
    grpId: "Frameworks and Programming Languages",
    key: "ruby_on_rails",
  },
  {
    title: "Flutter",
    grpId: "Frameworks and Programming Languages",
    key: "flutter",
  },
  {
    title: "Python",
    grpId: "Frameworks and Programming Languages",
    key: "python",
  },
  {
    title: "Hosted Forms",
  },
  {
    title: "123ContactForm",
    grpId: "Hosted Forms",
    key: "123contactform",
  },
  {
    title: "Confirmit",
    grpId: "Hosted Forms",
    key: "confirmit",
  },
  {
    title: "Formassembly",
    grpId: "Hosted Forms",
    key: "formassembly",
  },
  {
    title: "Forms on the Fly",
    grpId: "Hosted Forms",
    key: "forms_on_the_fly",
  },
  {
    title: "Formsite",
    grpId: "Hosted Forms",
    key: "formsite",
  },
  {
    title: "Formspree",
    grpId: "Hosted Forms",
    key: "formspree",
  },
  {
    title: "Formstack",
    grpId: "Hosted Forms",
    key: "formstack",
  },
  {
    title: "Gravity Forms",
    grpId: "Hosted Forms",
    key: "gravity_forms",
  },
  {
    title: "Jotform",
    grpId: "Hosted Forms",
    key: "jotform",
  },
  {
    title: "Podio",
    grpId: "Hosted Forms",
    key: "podio",
  },
  {
    title: "Reformed",
    grpId: "Hosted Forms",
    key: "reformed",
  },
  {
    title: "Scholarselect",
    grpId: "Hosted Forms",
    key: "scholarselect",
  },
  {
    title: "Wufoo",
    grpId: "Hosted Forms",
    key: "wufoo",
  },
  {
    title: "Hosting",
  },
  {
    title: "1&1 Hosting",
    grpId: "Hosting",
    key: "1&1_hosting",
  },
  {
    title: "3t Systems",
    grpId: "Hosting",
    key: "3t_systems",
  },
  {
    title: "6 Degrees Hosting",
    grpId: "Hosting",
    key: "6_degrees_hosting",
  },
  {
    title: "A Small Orange",
    grpId: "Hosting",
    key: "a_small_orange",
  },
  {
    title: "AboveNet",
    grpId: "Hosting",
    key: "abovenet",
  },
  {
    title: "Adapt Hosting",
    grpId: "Hosting",
    key: "adapt_hosting",
  },
  {
    title: "Affinity Internet (Hostway)",
    grpId: "Hosting",
    key: "affinity_internet_hostway",
  },
  {
    title: "Amazon AWS",
    grpId: "Hosting",
    key: "amazon_aws",
  },
  {
    title: "American Technology Services",
    grpId: "Hosting",
    key: "american_technology_services",
  },
  {
    title: "Ananke Hosting",
    grpId: "Hosting",
    key: "ananke_hosting",
  },
  {
    title: "Black Lotus",
    grpId: "Hosting",
    key: "black_lotus",
  },
  {
    title: "BlackMesh",
    grpId: "Hosting",
    key: "blackmesh",
  },
  {
    title: "Blue Host",
    grpId: "Hosting",
    key: "blue_host",
  },
  {
    title: "Carpathia Hosting",
    grpId: "Hosting",
    key: "carpathia_hosting",
  },
  {
    title: "Catalyst",
    grpId: "Hosting",
    key: "catalyst",
  },
  {
    title: "CenturyLink",
    grpId: "Hosting",
    key: "centurylink",
  },
  {
    title: "Cirrus Tech",
    grpId: "Hosting",
    key: "cirrus_tech",
  },
  {
    title: "Claranet Hosting",
    grpId: "Hosting",
    key: "claranet_hosting",
  },
  {
    title: "CloudFlare Hosting",
    grpId: "Hosting",
    key: "cloudflare_hosting",
  },
  {
    title: "Cogent",
    grpId: "Hosting",
    key: "cogent",
  },
  {
    title: "Colt",
    grpId: "Hosting",
    key: "colt",
  },
  {
    title: "Comcast",
    grpId: "Hosting",
    key: "comcast",
  },
  {
    title: "Connectria",
    grpId: "Hosting",
    key: "connectria",
  },
  {
    title: "Crucial",
    grpId: "Hosting",
    key: "crucial",
  },
  {
    title: "Datapipe",
    grpId: "Hosting",
    key: "datapipe",
  },
  {
    title: "Dbs International",
    grpId: "Hosting",
    key: "dbs_international",
  },
  {
    title: "DigitalOcean",
    grpId: "Hosting",
    key: "digitalocean",
  },
  {
    title: "Dimension Data",
    grpId: "Hosting",
    key: "dimension_data",
  },
  {
    title: "DreamHost Hosting",
    grpId: "Hosting",
    key: "dreamhost_hosting",
  },
  {
    title: "Edgewebhosting",
    grpId: "Hosting",
    key: "edgewebhosting",
  },
  {
    title: "Endurance International Group",
    grpId: "Hosting",
    key: "endurance_international_group",
  },
  {
    title: "Equinix",
    grpId: "Hosting",
    key: "equinix",
  },
  {
    title: "Expedient Data Centers",
    grpId: "Hosting",
    key: "expedient_data_centers",
  },
  {
    title: "Fastly Hosting",
    grpId: "Hosting",
    key: "fastly_hosting",
  },
  {
    title: "Firehost",
    grpId: "Hosting",
    key: "firehost",
  },
  {
    title: "Fujitsu",
    grpId: "Hosting",
    key: "fujitsu",
  },
  {
    title: "GoDaddy Hosting",
    grpId: "Hosting",
    key: "godaddy_hosting",
  },
  {
    title: "GoGrid",
    grpId: "Hosting",
    key: "gogrid",
  },
  {
    title: "Google Cloud Hosting",
    grpId: "Hosting",
    key: "google_cloud_hosting",
  },
  {
    title: "Google Sites",
    grpId: "Hosting",
    key: "google_sites",
  },
  {
    title: "Heroku",
    grpId: "Hosting",
    key: "heroku",
  },
  {
    title: "Hetzner",
    grpId: "Hosting",
    key: "hetzner",
  },
  {
    title: "HostDime",
    grpId: "Hosting",
    key: "hostdime",
  },
  {
    title: "Hostgator",
    grpId: "Hosting",
    key: "hostgator",
  },
  {
    title: "Hosting.com",
    grpId: "Hosting",
    key: "hosting_com",
  },
  {
    title: "Hostinger",
    grpId: "Hosting",
    key: "hostinger",
  },
  {
    title: "HostPapa",
    grpId: "Hosting",
    key: "hostpapa",
  },
  {
    title: "Hostway",
    grpId: "Hosting",
    key: "hostway",
  },
  {
    title: "iland",
    grpId: "Hosting",
    key: "iland",
  },
  {
    title: "InfoRelay",
    grpId: "Hosting",
    key: "inforelay",
  },
  {
    title: "Internap Network",
    grpId: "Hosting",
    key: "internap_network",
  },
  {
    title: "IT.net",
    grpId: "Hosting",
    key: "it_net",
  },
  {
    title: "Joyent",
    grpId: "Hosting",
    key: "joyent",
  },
  {
    title: "Latisys",
    grpId: "Hosting",
    key: "latisys",
  },
  {
    title: "Layered Tech",
    grpId: "Hosting",
    key: "layered_tech",
  },
  {
    title: "LeaseWeb",
    grpId: "Hosting",
    key: "leaseweb",
  },
  {
    title: "Level 3 Hosting",
    grpId: "Hosting",
    key: "level_3_hosting",
  },
  {
    title: "Linknow Media",
    grpId: "Hosting",
    key: "linknow_media",
  },
  {
    title: "Linode",
    grpId: "Hosting",
    key: "linode",
  },
  {
    title: "Liquidweb",
    grpId: "Hosting",
    key: "liquidweb",
  },
  {
    title: "Logicworks",
    grpId: "Hosting",
    key: "logicworks",
  },
  {
    title: "MaxMind",
    grpId: "Hosting",
    key: "maxmind",
  },
  {
    title: "Media Temple",
    grpId: "Hosting",
    key: "media_temple",
  },
  {
    title: "Microsoft Azure Hosting",
    grpId: "Hosting",
    key: "microsoft",
  },
  {
    title: "Navisite",
    grpId: "Hosting",
    key: "navisite",
  },
  {
    title: "NephoScale",
    grpId: "Hosting",
    key: "nephoscale",
  },
  {
    title: "NeuStar",
    grpId: "Hosting",
    key: "neustar",
  },
  {
    title: "Nexcess",
    grpId: "Hosting",
    key: "nexcess",
  },
  {
    title: "Online Tech",
    grpId: "Hosting",
    key: "online_tech",
  },
  {
    title: "OnRamp Hosting",
    grpId: "Hosting",
    key: "onramp_hosting",
  },
  {
    title: "ORCS Web",
    grpId: "Hosting",
    key: "orcs_web",
  },
  {
    title: "OVH",
    grpId: "Hosting",
    key: "ovh",
  },
  {
    title: "OVH Hosting",
    grpId: "Hosting",
    key: "ovh_hosting",
  },

  {
    title: "Peak 10",
    grpId: "Hosting",
    key: "peak_10",
  },
  {
    title: "Peer 1",
    grpId: "Hosting",
    key: "peer_1",
  },
  {
    title: "Philadelphia Technology Park",
    grpId: "Hosting",
    key: "philadelphia_technology_park",
  },
  {
    title: "Prolexic",
    grpId: "Hosting",
    key: "prolexic",
  },
  {
    title: "Pulsant Hosting",
    grpId: "Hosting",
    key: "pulsant_hosting",
  },
  {
    title: "Rackspace",
    grpId: "Hosting",
    key: "rackspace",
  },
  {
    title: "Savvis",
    grpId: "Hosting",
    key: "savvis",
  },
  {
    title: "Selectel Hosting",
    grpId: "Hosting",
    key: "selectel_hosting",
  },
  {
    title: "ServerBeach",
    grpId: "Hosting",
    key: "serverbeach",
  },
  {
    title: "SimpleHelix",
    grpId: "Hosting",
    key: "simplehelix",
  },
  {
    title: "SingleHop",
    grpId: "Hosting",
    key: "singlehop",
  },
  {
    title: "Sleek Networks",
    grpId: "Hosting",
    key: "sleek_networks",
  },
  {
    title: "SoftLayer",
    grpId: "Hosting",
    key: "softlayer",
  },
  {
    title: "Strato",
    grpId: "Hosting",
    key: "strato",
  },
  {
    title: "Telepacific",
    grpId: "Hosting",
    key: "telepacific",
  },
  {
    title: "Terremark",
    grpId: "Hosting",
    key: "terremark",
  },
  {
    title: "Thinkgrid",
    grpId: "Hosting",
    key: "thinkgrid",
  },
  {
    title: "UK Fast Hosting",
    grpId: "Hosting",
    key: "uk_fast_hosting",
  },
  {
    title: "Unified Layer",
    grpId: "Hosting",
    key: "unified_layer",
  },
  {
    title: "Verio",
    grpId: "Hosting",
    key: "verio",
  },
  {
    title: "Verio Hosting (NTT Comm)",
    grpId: "Hosting",
    key: "verio_hosting_ntt_comm",
  },
  {
    title: "Verizon",
    grpId: "Hosting",
    key: "verizon",
  },
  {
    title: "Web.com",
    grpId: "Hosting",
    key: "web_com",
  },
  {
    title: "WHMCS",
    grpId: "Hosting",
    key: "whmcs",
  },
  {
    title: "Xand",
    grpId: "Hosting",
    key: "xand",
  },
  {
    title: "XO Communications",
    grpId: "Hosting",
    key: "xo_communications",
  },
  {
    title: "Xtium",
    grpId: "Hosting",
    key: "xtium",
  },
  {
    title: "XXL webhosting",
    grpId: "Hosting",
    key: "xxl_webhosting",
  },
  {
    title: "Zerolag",
    grpId: "Hosting",
    key: "zerolag",
  },
  {
    title: "SolarWinds",
    grpId: "Hosting",
    key: "solarwinds",
  },
  {
    title: "GitHub Hosting",
    grpId: "Hosting",
    key: "github_hosting",
  },
  {
    title: "Lead Generation Software",
  },
  {
    title: "ActiveConversion",
    grpId: "Lead Generation Software",
    key: "activeconversion",
  },
  {
    title: "Captora",
    grpId: "HostingLead Generation Software",
    key: "captora",
  },
  {
    title: "Enecto",
    grpId: "Lead Generation Software",
    key: "enecto",
  },
  {
    title: "Insightera",
    grpId: "Lead Generation Software",
    key: "insightera",
  },
  {
    title: "Intelligent Demand",
    grpId: "Lead Generation Software",
    key: "intelligent_demand",
  },
  {
    title: "Kunversion",
    grpId: "Lead Generation Software",
    key: "kunversion",
  },
  {
    title: "LeadExplorer",
    grpId: "Lead Generation Software",
    key: "leadexplorer",
  },
  {
    title: "LeadForensics",
    grpId: "Lead Generation Software",
    key: "leadforensics",
  },
  {
    title: "Leadlander",
    grpId: "Lead Generation Software",
    key: "leadlander",
  },
  {
    title: "Leads by Web.com",
    grpId: "Lead Generation SoftwareLead Generation Software",
    key: "leads_by_web_com",
  },
  {
    title: "Market Leader",
    grpId: "Lead Generation Software",
    key: "market_leader",
  },
  {
    title: "Mentad",
    grpId: "Lead Generation Software",
    key: "mentad",
  },
  {
    title: "ReachForce",
    grpId: "Lead Generation Software",
    key: "reachforce",
  },
  {
    title: "Rooster",
    grpId: "Lead Generation Software",
    key: "rooster",
  },
  {
    title: "Survicate",
    grpId: "Lead Generation Software",
    key: "survicate",
  },
  {
    title: "TigerLead",
    grpId: "Lead Generation Software",
    key: "tigerlead",
  },
  {
    title: "VisiStat",
    grpId: "Lead Generation Software",
    key: "visistat",
  },
  {
    title: "VisitorTrack",
    grpId: "Lead Generation Software",
    key: "visitortrack",
  },
  {
    title: "Visual Visitor",
    grpId: "Lead Generation Software",
    key: "visual_visitor",
  },
  {
    title: "Zurple",
    grpId: "Lead Generation Software",
    key: "zurple",
  },
  {
    title: "Live Streaming Platforms",
  },
  {
    title: "316networks",
    grpId: "Live Streaming Platforms",
    key: "316networks",
  },
  {
    title: "Conviva",
    grpId: "Live Streaming Platforms",
    key: "conviva",
  },
  {
    title: "Dacast",
    grpId: "Live Streaming Platforms",
    key: "dacast",
  },
  {
    title: "Neulion",
    grpId: "Live Streaming Platforms",
    key: "neulion",
  },
  {
    title: "NicePeopleAtWork",
    grpId: "Live Streaming Platforms",
    key: "nicepeopleatwork",
  },
  {
    title: "Panopto",
    grpId: "Live Streaming Platforms",
    key: "panopto",
  },
  {
    title: "Uplynk",
    grpId: "Live Streaming Platforms",
    key: "uplynk",
  },
  {
    title: "Ustream",
    grpId: "Live Streaming Platforms",
    key: "ustream",
  },
  {
    title: "Load Balancers",
  },
  {
    title: "Amazon Elastic Load Balancer",
    grpId: "Load Balancers",
    key: "amazon_elastic_load_balancer",
  },
  {
    title: "Apache",
    grpId: "Load Balancers",
    key: "apache",
  },
  {
    title: "BT Web Server",
    grpId: "Load Balancers",
    key: "bt_web_server",
  },
  {
    title: "Citrix NetScaler",
    grpId: "Load Balancers",
    key: "citrix_netscaler",
  },
  {
    title: "F5 BIG-IP",
    grpId: "Load Balancers",
    key: "f5_big-ip",
  },
  {
    title: "Google Frontend (Webserver)",
    grpId: "Load Balancers",
    key: "google_frontend_webserver",
  },
  {
    title: "Gunicorn",
    grpId: "Load Balancers",
    key: "gunicorn",
  },
  {
    title: "IBM HTTP Server",
    grpId: "Load Balancers",
    key: "ibm_http_server",
  },
  {
    title: "ISA Web Publishing Load Balancer",
    grpId: "Load Balancers",
    key: "isa_web_publishing_load_balancer",
  },
  {
    title: "JetNEXUS",
    grpId: "Load Balancers",
    key: "jetnexus",
  },
  {
    title: "Jetty Server",
    grpId: "Load Balancers",
    key: "jetty_server",
  },
  {
    title: "Microsoft-IIS",
    grpId: "Load Balancers",
    key: "microsoft-iis",
  },
  {
    title: "Netcache",
    grpId: "Load Balancers",
    key: "netcache",
  },
  {
    title: "Nginx",
    grpId: "Load Balancers",
    key: "nginx",
  },
  {
    title: "Oracle Application Server",
    grpId: "Load Balancers",
    key: "oracle_application_server",
  },
  {
    title: "Oracle iPlanet Web Server",
    grpId: "Load Balancers",
    key: "oracle_iplanet_web_server",
  },
  {
    title: "Phusion Passenger",
    grpId: "Load Balancers",
    key: "phusion_passenger",
  },
  {
    title: "QRATOR",
    grpId: "Load Balancers",
    key: "qrator",
  },
  {
    title: "Ubuntu",
    grpId: "Load Balancers",
    key: "ubuntu",
  },
  {
    title: "Varnish",
    grpId: "Load Balancers",
    key: "varnish",
  },
  {
    title: "Wily",
    grpId: "Load Balancers",
    key: "wily",
  },
  {
    title: "Zeus",
    grpId: "Load Balancers",
    key: "zeus",
  },
  {
    title: "Loyalty and Gamification",
  },
  {
    title: "Badgeville",
    grpId: "Loyalty and Gamification",
    key: "badgeville",
  },
  {
    title: "BellyCard",
    grpId: "Loyalty and Gamification",
    key: "bellycard",
  },
  {
    title: "Bigdoor",
    grpId: "Loyalty and Gamification",
    key: "bigdoor",
  },
  {
    title: "Bunchball",
    grpId: "Loyalty and Gamification",
    key: "bunchball",
  },
  {
    title: "CrowdTwist",
    grpId: "Loyalty and Gamification",
    key: "crowdtwist",
  },
  {
    title: "Eprize",
    grpId: "Loyalty and Gamification",
    key: "eprize",
  },
  {
    title: "Loyalty Lab",
    grpId: "Loyalty and Gamification",
    key: "loyalty_lab",
  },
  {
    title: "Perkville",
    grpId: "Loyalty and Gamification",
    key: "perkville",
  },
  {
    title: "Punchtab",
    grpId: "Loyalty and Gamification",
    key: "punchtab",
  },
  {
    title: "S Loyalty",
    grpId: "Loyalty and Gamification",
    key: "s_loyalty",
  },
  {
    title: "Social Annex",
    grpId: "Loyalty and Gamification",
    key: "social_annex",
  },
  {
    title: "Marketing Automation",
  },
  {
    title: "Act-On",
    grpId: "Marketing Automation",
    key: "act-on",
  },
  {
    title: "Active Campaign",
    grpId: "Marketing Automation",
    key: "active_campaign",
  },
  {
    title: "Autopilot",
    grpId: "Marketing Automation",
    key: "autopilot",
  },
  {
    title: "Balihoo",
    grpId: "Marketing Automation",
    key: "balihoo",
  },
  {
    title: "BounceExchange",
    grpId: "Marketing Automation",
    key: "bounceexchange",
  },
  {
    title: "ClickDimensions",
    grpId: "Marketing Automation",
    key: "clickdimensions",
  },
  {
    title: "CoreMotives",
    grpId: "Marketing Automation",
    key: "coremotives",
  },
  {
    title: "Demandforce",
    grpId: "Marketing Automation",
    key: "demandforce",
  },
  {
    title: "Drip",
    grpId: "Marketing Automation",
    key: "drip",
  },
  {
    title: "Eloqua",
    grpId: "Marketing Automation",
    key: "eloqua",
  },
  {
    title: "eTrigue",
    grpId: "Marketing Automation",
    key: "etrigue",
  },
  {
    title: "Exit Intel",
    grpId: "Marketing Automation",
    key: "exit_intel",
  },
  {
    title: "Genius",
    grpId: "Marketing Automation",
    key: "genius",
  },
  {
    title: "Genoo",
    grpId: "Marketing Automation",
    key: "genoo",
  },
  {
    title: "Go Digital Marketing",
    grpId: "Marketing Automation",
    key: "go_digital_marketing",
  },
  {
    title: "Greenrope",
    grpId: "Marketing Automation",
    key: "greenrope",
  },
  {
    title: "Hatchbuck",
    grpId: "Marketing Automation",
    key: "hatchbuck",
  },
  {
    title: "Hubspot",
    grpId: "Marketing Automation",
    key: "hubspot",
  },
  {
    title: "Intive Referrals",
    grpId: "Marketing Automation",
    key: "intive_referrals",
  },
  {
    title: "iPresso",
    grpId: "Marketing Automation",
    key: "ipresso",
  },
  {
    title: "JumpDemand / ActiveDemand",
    grpId: "Marketing Automation",
    key: "jumpdemand_/_activedemand",
  },
  {
    title: "Kahuna",
    grpId: "Marketing Automation",
    key: "kahuna",
  },
  {
    title: "Kapost",
    grpId: "Marketing Automation",
    key: "kapost",
  },
  {
    title: "KnowledgeMarketing",
    grpId: "Marketing Automation",
    key: "knowledgemarketing",
  },
  {
    title: "LeadFormix",
    grpId: "Marketing Automation",
    key: "leadformix",
  },
  {
    title: "LeadLiaison",
    grpId: "Marketing Automation",
    key: "leadliaison",
  },
  {
    title: "Leadsius",
    grpId: "Marketing Automation",
    key: "leadsius",
  },
  {
    title: "LeadSquared",
    grpId: "Marketing Automation",
    key: "leadsquared",
  },
  {
    title: "LoopFuse",
    grpId: "Marketing Automation",
    key: "loopfuse",
  },
  {
    title: "MakesBridge",
    grpId: "Marketing Automation",
    key: "makesbridge",
  },
  {
    title: "Manticore",
    grpId: "Marketing Automation",
    key: "manticore",
  },
  {
    title: "Marketingmatic",
    grpId: "Marketing Automation",
    key: "marketingmatic",
  },
  {
    title: "Marketo",
    grpId: "Marketing Automation",
    key: "marketo",
  },
  {
    title: "Net-Results",
    grpId: "Marketing Automation",
    key: "net-results",
  },
  {
    title: "Optify",
    grpId: "Marketing Automation",
    key: "optify",
  },
  {
    title: "Orange Soda",
    grpId: "Marketing Automation",
    key: "orange_soda",
  },
  {
    title: "Otto",
    grpId: "Marketing Automation",
    key: "otto",
  },
  {
    title: "Pardot",
    grpId: "Marketing Automation",
    key: "pardot",
  },
  {
    title: "ProspectEye",
    grpId: "Marketing Automation",
    key: "prospecteye",
  },
  {
    title: "Reach Local",
    grpId: "Marketing Automation",
    key: "reach_local",
  },
  {
    title: "RedEye",
    grpId: "Marketing Automation",
    key: "redeye",
  },
  {
    title: "SalesFUSION",
    grpId: "Marketing Automation",
    key: "salesfusion",
  },
  {
    title: "SALESmanago",
    grpId: "Marketing Automation",
    key: "salesmanago",
  },
  {
    title: "Selligent",
    grpId: "Marketing Automation",
    key: "selligent",
  },
  {
    title: "SharpSpring",
    grpId: "Marketing Automation",
    key: "sharpspring",
  },
  {
    title: "SnapApp",
    grpId: "Marketing Automation",
    key: "snapapp",
  },
  {
    title: "UberFlip",
    grpId: "Marketing Automation",
    key: "uberflip",
  },
  {
    title: "Unica",
    grpId: "Marketing Automation",
    key: "unica",
  },
  {
    title: "Yodle",
    grpId: "Marketing Automation",
    key: "yodle",
  },
  {
    title: "Online Testing Platforms",
  },
  {
    title: "AB Tasty",
    grpId: "Online Testing Platforms",
    key: "ab_tasty",
  },
  {
    title: "Adobe TestAndTarget",
    grpId: "Online Testing Platforms",
    key: "adobe_testandtarget",
  },
  {
    title: "ClickFunnels",
    grpId: "Online Testing Platforms",
    key: "clickfunnels",
  },
  {
    title: "DynamicYield",
    grpId: "Online Testing Platforms",
    key: "dynamicyield",
  },
  {
    title: "Evergage",
    grpId: "Online Testing Platforms",
    key: "evergage",
  },
  {
    title: "Google Website Optimizer",
    grpId: "Online Testing Platforms",
    key: "google_website_optimizer",
  },
  {
    title: "Hiconversion",
    grpId: "Online Testing Platforms",
    key: "hiconversion",
  },
  {
    title: "LeadPages",
    grpId: "Online Testing Platforms",
    key: "leadpages",
  },
  {
    title: "Litmus",
    grpId: "Online Testing Platforms",
    key: "litmus",
  },
  {
    title: "Maxymiser",
    grpId: "Online Testing Platforms",
    key: "maxymiser",
  },
  {
    title: "Monetate",
    grpId: "Online Testing Platforms",
    key: "monetate",
  },
  {
    title: "Optimizely",
    grpId: "Online Testing Platforms",
    key: "optimizely",
  },
  {
    title: "Optimost",
    grpId: "Online Testing Platforms",
    key: "optimost",
  },
  {
    title: "Qubit Deliver",
    grpId: "Online Testing Platforms",
    key: "qubit_deliver",
  },
  {
    title: "Sitespect",
    grpId: "Online Testing Platforms",
    key: "sitespect",
  },
  {
    title: "Unbounce",
    grpId: "Online Testing Platforms",
    key: "unbounce",
  },
  {
    title: "UserZoom",
    grpId: "Online Testing Platforms",
    key: "userzoom",
  },
  {
    title: "Visual Website Optimizer",
    grpId: "Online Testing Platforms",
    key: "visual_website_optimizer",
  },
  {
    title: ",Webtrends Optimize",
    grpId: "Online Testing Platforms",
    key: "webtrends_optimize",
  },
  {
    title: "Online Video Platforms",
  },
  {
    title: "Bits on the Run",
    grpId: "Online Video Platforms",
    key: "bits_on_the_run",
  },
  {
    title: "BlipTV",
    grpId: "Online Video Platforms",
    key: "bliptv",
  },
  {
    title: "Brightcove",
    grpId: "Online Video Platforms",
    key: "brightcove",
  },
  {
    title: "Clipstream",
    grpId: "Online Video Platforms",
    key: "clipstream",
  },
  {
    title: "DailyMotion",
    grpId: "Online Video Platforms",
    key: "dailymotion",
  },
  {
    title: "Eagle Platform (Online Video Platform)",
    grpId: "Online Video Platforms",
    key: "eagle_platform_online_video_platform",
  },
  {
    title: "Flickr Video",
    grpId: "Online Video Platforms",
    key: "flickr_video",
  },
  {
    title: "Flowplayer",
    grpId: "Online Video Platforms",
    key: "flowplayer",
  },
  {
    title: "Flowplayer Commercial",
    grpId: "Online Video Platforms",
    key: "flowplayer_commercial",
  },
  {
    title: "Fordela",
    grpId: "Online Video Platforms",
    key: "fordela",
  },
  {
    title: "jPlayer",
    grpId: "Online Video Platforms",
    key: "jplayer",
  },
  {
    title: "JW Player",
    grpId: "Online Video Platforms",
    key: "jw_player",
  },
  {
    title: "Kaltura",
    grpId: "Online Video Platforms",
    key: "kaltura",
  },
  {
    title: "Kewego",
    grpId: "Online Video Platforms",
    key: "kewego",
  },
  {
    title: "Kit Digital",
    grpId: "Online Video Platforms",
    key: "kit_digital",
  },
  {
    title: "Liverail",
    grpId: "Online Video Platforms",
    key: "liverail",
  },
  {
    title: "Mediasilo",
    grpId: "Online Video Platforms",
    key: "mediasilo",
  },
  {
    title: "MovingImage24",
    grpId: "Online Video Platforms",
    key: "movingimage24",
  },
  {
    title: "Ooyala",
    grpId: "Online Video Platforms",
    key: "ooyala",
  },
  {
    title: "Playwire",
    grpId: "Online Video Platforms",
    key: "playwire",
  },
  {
    title: "Quadia",
    grpId: "Online Video Platforms",
    key: "quadia",
  },
  {
    title: "Simian",
    grpId: "Online Video Platforms",
    key: "simian",
  },
  {
    title: "Springboard Video",
    grpId: "Online Video Platforms",
    key: "springboard_video",
  },
  {
    title: "SublimeVideo",
    grpId: "Online Video Platforms",
    key: "sublimevideo",
  },
  {
    title: "ThePlatform",
    grpId: "Online Video Platforms",
    key: "theplatform",
  },
  {
    title: "Tvigle",
    grpId: "Online Video Platforms",
    key: "tvigle",
  },
  {
    title: "Twistage",
    grpId: "Online Video Platforms",
    key: "twistage",
  },
  {
    title: "Vid.ly",
    grpId: "Online Video Platforms",
    key: "vid_ly",
  },
  {
    title: "Vidcaster",
    grpId: "Online Video Platforms",
    key: "vidcaster",
  },
  {
    title: "Viddler",
    grpId: "Online Video Platforms",
    key: "viddler",
  },
  {
    title: "VideoJS",
    grpId: "Online Video Platforms",
    key: "videojs",
  },
  {
    title: "Vidyard",
    grpId: "Online Video Platforms",
    key: "vidyard",
  },
  {
    title: "Vimeo",
    grpId: "Online Video Platforms",
    key: "vimeo",
  },
  {
    title: "Vzaar",
    grpId: "Online Video Platforms",
    key: "vzaar",
  },
  {
    title: "Wiredrive",
    grpId: "Online Video Platforms",
    key: "wiredrive",
  },
  {
    title: "Wirewax",
    grpId: "Online Video Platforms",
    key: "wirewax",
  },
  {
    title: "Wistia",
    grpId: "Online Video Platforms",
    key: "wistia",
  },
  {
    title: "YouTube",
    grpId: "Online Video Platforms",
    key: "youtube",
  },
  {
    title: "Zaption",
    grpId: "Online Video Platforms",
    key: "zaption",
  },
  {
    title: "Other",
  },
  {
    title: "5th Finger",
    grpId: "Other",
    key: "5th_finger",
  },
  {
    title: "A2Z Inc",
    grpId: "Other",
    key: "a2z_inc",
  },
  {
    title: "Ad Unit 160 x 600",
    grpId: "Other",
    key: "ad_unit_160_x_600",
  },
  {
    title: "Ad Unit 300 x 250",
    grpId: "Other",
    key: "ad_unit_300_x_250",
  },
  {
    title: "Ad Unit 300 x 600",
    grpId: "Other",
    key: "ad_unit_300_x_600",
  },
  {
    title: "Ad Unit 320 x 50",
    grpId: "Other",
    key: "ad_unit_320_x_50",
  },
  {
    title: "Ad Unit 728 x 90",
    grpId: "Other",
    key: "ad_unit_728_x_90",
  },
  {
    title: "ADEBiS",
    grpId: "Other",
    key: "adebis",
  },
  {
    title: "Adloox",
    grpId: "Other",
    key: "adloox",
  },
  {
    title: "ADX Studio",
    grpId: "Other",
    key: "adx_studio",
  },
  {
    title: "ALEX",
    grpId: "Other",
    key: "alex",
  },
  {
    title: "Altas Relaunched",
    grpId: "Other",
    key: "altas_relaunched",
  },
  {
    title: "Amadeus",
    grpId: "Other",
    key: "amadeus",
  },
  {
    title: "Americaneagle",
    grpId: "Other",
    key: "americaneagle",
  },
  {
    title: "Atlas Legacy",
    grpId: "Other",
    key: "atlas_legacy",
  },
  {
    title: "Avvo",
    grpId: "Other",
    key: "avvo",
  },
  {
    title: "Bayshore Solutions",
    grpId: "Other",
    key: "bayshore_solutions",
  },
  {
    title: "Bing Maps",
    grpId: "Other",
    key: "bing_maps",
  },
  {
    title: "Box.net",
    grpId: "Other",
    key: "box_net",
  },
  {
    title: "BulletinMarketing",
    grpId: "Other",
    key: "bulletinmarketing",
  },
  {
    title: "CallTrackingMetrics",
    grpId: "Other",
    key: "calltrackingmetrics",
  },
  {
    title: "Sequence Monitor SPF",
    grpId: "Other",
    key: "campaign_monitor_spf",
  },
  {
    title: "CareerBuilder",
    grpId: "Other",
    key: "careerbuilder",
  },
  {
    title: "Cision",
    grpId: "Other",
    key: "cision",
  },
  {
    title: "Coreg-Feed",
    grpId: "Other",
    key: "coreg-feed",
  },
  {
    title: "CoStar Connect",
    grpId: "Other",
    key: "costar_connect",
  },
  {
    title: "cXense",
    grpId: "Other",
    key: "cxense",
  },
  {
    title: "Deluxe",
    grpId: "Other",
    key: "deluxe",
  },
  {
    title: "Delve",
    grpId: "Other",
    key: "delve",
  },
  {
    title: "DexMedia",
    grpId: "Other",
    key: "dexmedia",
  },
  {
    title: "Diverse Solutions",
    grpId: "Other",
    key: "diverse_solutions",
  },
  {
    title: "DocuSign",
    grpId: "Other",
    key: "docusign",
  },
  {
    title: "DoubleVerify",
    grpId: "Other",
    key: "doubleverify",
  },
  {
    title: "DudaMobile",
    grpId: "Other",
    key: "dudamobile",
  },
  {
    title: "Easyling",
    grpId: "Other",
    key: "easyling",
  },
  {
    title: "Echo",
    grpId: "Other",
    key: "echo",
  },
  {
    title: "eNeighborhoods (Homes.com)",
    grpId: "Other",
    key: "eneighborhoods_homes_com",
  },
  {
    title: "ExactOnline",
    grpId: "Other",
    key: "exactonline",
  },
  {
    title: "Experian",
    grpId: "Other",
    key: "experian",
  },
  {
    title: "FBWCA-AR",
    grpId: "Other",
    key: "fbwca-ar",
  },
  {
    title: "Find Law",
    grpId: "Other",
    key: "find_law",
  },
  {
    title: "Flickr API",
    grpId: "Other",
    key: "flickr_api",
  },
  {
    title: "Flickr Badge",
    grpId: "Other",
    key: "fflickr_badge",
  },
  {
    title: "Flickr Slideshow",
    grpId: "Other",
    key: "flickr_slideshow",
  },
  {
    title: "Fry",
    grpId: "Other",
    key: "fry",
  },
  {
    title: "FullStory",
    grpId: "Other",
    key: "fullstory",
  },
  {
    title: "Gettyimages",
    grpId: "Other",
    key: "gettyimages",
  },
  {
    title: "Gigya Login",
    grpId: "Other",
    key: "gigya_login",
  },
  {
    title: "Gigya Registration",
    grpId: "Other",
    key: "gigya_registration",
  },
  {
    title: "Gigya Registration as a Service",
    grpId: "Other",
    key: "gigya_registration_as_a_service",
  },
  {
    title: "Google Apps",
    grpId: "Other",
    key: "google_apps ",
  },
  {
    title: "Google Checkout",
    grpId: "Other",
    key: "google_checkout",
  },
  {
    title: "Google Maps",
    grpId: "Other",
    key: "google_maps",
  },
  {
    title: "Google Maps (Non Paid Users)",
    grpId: "Other",
    key: "google_maps_non_paid_users",
  },
  {
    title: "Google Maps (Paid Users)",
    grpId: "Other",
    key: "google_maps_paid_users",
  },
  {
    title: "Google Places",
    grpId: "Other",
    key: "google_places",
  },
  {
    title: "greyscale - black",
    grpId: "Other",
    key: "greyscale_-_black",
  },
  {
    title: "greyscale - grey",
    grpId: "Other",
    key: "greyscale_-_grey",
  },
  {
    title: "greyscale - white",
    grpId: "Other",
    key: "greyscale_-_white",
  },
  {
    title: "Homeadvisor",
    grpId: "Other",
    key: "homeadvisor",
  },
  {
    title: "iHomeFinder",
    grpId: "Other",
    key: "ihomefinder",
  },
  {
    title: "Indeed",
    grpId: "Other",
    key: "indeed",
  },
  {
    title: "Influitive",
    grpId: "Other",
    key: "influitive",
  },
  {
    title: "IntelliAd",
    grpId: "Other",
    key: "intelliad",
  },
  {
    title: "InTouch",
    grpId: "Other",
    key: "intouch",
  },
  {
    title: "Lighthouse 360",
    grpId: "Other",
    key: "lighthouse_360",
  },
  {
    title: "Linkedin Widget",
    grpId: "Other",
    key: "linkedin_widget",
  },
  {
    title: "Loggly",
    grpId: "Other",
    key: "loggly",
  },
  {
    title: "MailChimp SPF",
    grpId: "Other",
    key: "mailchimp_spf",
  },
  {
    title: "Map Quest",
    grpId: "Other",
    key: "map_quest",
  },
  {
    title: "MegaLeads",
    grpId: "Other",
    key: "megaleads",
  },
  {
    title: "Mobile Friendly",
    grpId: "Other",
    key: "mobile_friendly",
  },
  {
    title: "MotionPoint",
    grpId: "Other",
    key: "motionpoint",
  },
  {
    title: "Mulesoft",
    grpId: "Other",
    key: "mulesoft",
  },
  {
    title: "nRelate",
    grpId: "Other",
    key: "nrelate",
  },
  {
    title: "Microsoft Office 365",
    grpId: "Other",
    key: "office_365",
  },
  {
    title: "Okta (sub-domains only)",
    grpId: "Other",
    key: "okta_sub-domains_only",
  },
  {
    title: "Onswipe",
    grpId: "Other",
    key: "onswipe",
  },
  {
    title: "OpenID",
    grpId: "Other",
    key: "openid",
  },
  {
    title: "Paczkomaty",
    grpId: "Other",
    key: "paczkomaty",
  },
  {
    title: "Parityrate",
    grpId: "Other",
    key: "parityrate",
  },
  {
    title: "Pet Exec",
    grpId: "Other",
    key: "pet_exec",
  },
  {
    title: "PostAffiliatePro",
    grpId: "Other",
    key: "postaffiliatepro",
  },
  {
    title: "PowerSchool",
    grpId: "Other",
    key: "powerschool",
  },
  {
    title: "Predictry",
    grpId: "Other",
    key: "predictry",
  },
  {
    title: "Purecars",
    grpId: "Other",
    key: "purecars",
  },
  {
    title: "QuoteValet",
    grpId: "Other",
    key: "quotevalet",
  },
  {
    title: "RevenueWell (patientconnect365)",
    grpId: "Other",
    key: "revenuewell_patientconnect365",
  },
  {
    title: "Service2 Client",
    grpId: "Other",
    key: "service2_client",
  },
  {
    title: "Shopsense",
    grpId: "Other",
    key: "shopsense",
  },
  {
    title: "Shutterstock",
    grpId: "Other",
    key: "shutterstock",
  },
  {
    title: "SignNow",
    grpId: "Other",
    key: "signnow",
  },
  {
    title: "Smartling",
    grpId: "Other",
    key: "smartling",
  },
  {
    title: "SoundCloud",
    grpId: "Other",
    key: "soundcloud",
  },
  {
    title: "Steepto",
    grpId: "Other",
    key: "steepto",
  },
  {
    title: "Transifex",
    grpId: "Other",
    key: "transifex",
  },
  {
    title: "Twilio",
    grpId: "Other",
    key: "twilio",
  },
  {
    title: "Unity 3D Web Player",
    grpId: "Other",
    key: "unity_3d_web_player",
  },
  {
    title: "Unreal Engine",
    grpId: "Other",
    key: "unreal_engine",
  },
  {
    title: "VetScene",
    grpId: "Other",
    key: "vetscene",
  },
  {
    title: "Vizergy",
    grpId: "Other",
    key: "vizergy",
  },
  {
    title: "VMIX",
    grpId: "Other",
    key: "vmix",
  },
  {
    title: "Websystem2/3",
    grpId: "Other",
    key: "websystem2/3",
  },
  {
    title: "Wix TINT",
    grpId: "Other",
    key: "wix_tint",
  },
  {
    title: "Wolfnet",
    grpId: "Other",
    key: "wolfnet",
  },
  {
    title: "Xceligent",
    grpId: "Other",
    key: "xceligent",
  },
  {
    title: "Xenforo",
    grpId: "Other",
    key: "xenforo",
  },
  {
    title: "Xero",
    grpId: "Other",
    key: "xero",
  },
  {
    title: "Yelp",
    grpId: "Other",
    key: "yelp",
  },
  {
    title: "Zencoder",
    grpId: "Other",
    key: "zencoder",
  },
  {
    title: "Thomson Reuters Legal Solutions",
    grpId: "Other",
    key: "thomson_reuters_legal_solutions",
  },
  {
    title: "Black Line",
    grpId: "Other",
    key: "black_line",
  },
  {
    title: "Mapbox",
    grpId: "Other",
    key: "mapbox",
  },
  {
    title: "TikTok",
    grpId: "Other",
    key: "tiktok",
  },
  {
    title: "Payments",
  },
  {
    title: "Adyen",
    grpId: "Payments",
    key: "adyen",
  },
  {
    title: "Maxymiser",
    grpId: "Payments",
    key: "amazon_payments",
  },
  {
    title: "Amazon Payments",
    grpId: "Payments",
    key: "amazon_payments",
  },
  {
    title: "Apple Pay",
    grpId: "Payments",
    key: "apple_pay",
  },
  {
    title: "Authorize.NET",
    grpId: "Payments",
    key: "authorize_net",
  },
  {
    title: "Avangate",
    grpId: "Payments",
    key: "avangate",
  },
  {
    title: "Balanced",
    grpId: "Payments",
    key: "balanced",
  },
  {
    title: "BillMeLater",
    grpId: "Payments",
    key: "billmelater",
  },
  {
    title: "BitPay",
    grpId: "Payments",
    key: "bitpay",
  },
  {
    title: "BlueSnap",
    grpId: "Payments",
    key: "bluesnap",
  },
  {
    title: "Braintree",
    grpId: "Payments",
    key: "braintree",
  },
  {
    title: "CCBill",
    grpId: "Payments",
    key: "ccbill",
  },
  {
    title: "Digital River",
    grpId: "Payments",
    key: "digital_river",
  },
  {
    title: "Dwolla",
    grpId: "Payments",
    key: "dwolla",
  },
  {
    title: "FastSpring",
    grpId: "Payments",
    key: "fastspring",
  },
  {
    title: "Flattr",
    grpId: "Payments",
    key: "flattr",
  },
  {
    title: "Freshbooks",
    grpId: "Payments",
    key: "freshbooks",
  },
  {
    title: "Google Wallet",
    grpId: "Payments",
    key: "google_wallet",
  },
  {
    title: "Ingenico (Ogone)",
    grpId: "Payments",
    key: "ingenico_ogone",
  },
  {
    title: "Klarna",
    grpId: "Payments",
    key: "klarna",
  },
  {
    title: "MasterPass",
    grpId: "Payments",
    key: "masterpass",
  },
  {
    title: "Paychex",
    grpId: "Payments",
    key: "paychex",
  },
  {
    title: "Paylocity",
    grpId: "Payments",
    key: "paylocity",
  },
  {
    title: "Paypal",
    grpId: "Payments",
    key: "paypal",
  },
  {
    title: "PaySimple",
    grpId: "Payments",
    key: "paysimple",
  },
  {
    title: "Quickbooks Online",
    grpId: "Payments",
    key: "quickbooks_online",
  },
  {
    title: "Recurly",
    grpId: "Payments",
    key: "recurly",
  },
  {
    title: "Recurly JS",
    grpId: "Payments",
    key: "recurly_js",
  },
  {
    title: "Square, Inc.",
    grpId: "Payments",
    key: "square,_inc_",
  },
  {
    title: "Stripe",
    grpId: "Payments",
    key: "stripe",
  },
  {
    title: "TrialPay",
    grpId: "Payments",
    key: "trialpay",
  },
  {
    title: "Visa Checkout",
    grpId: "Payments",
    key: "visa_checkout",
  },
  {
    title: "WePay",
    grpId: "Payments",
    key: "wepay",
  },
  {
    title: "World Pay",
    grpId: "Payments",
    key: "world_pay",
  },
  {
    title: "Zombaio",
    grpId: "Payments",
    key: "zombaio",
  },
  {
    title: "Zuora",
    grpId: "Payments",
    key: "zuora",
  },
  {
    title: "2Checkout",
    grpId: "Payments",
    key: "2checkout",
  },
  {
    title: "Personalization",
  },
  {
    title: "Amadesa",
    grpId: "Personalization",
    key: "amadesa",
  },
  {
    title: "Baynote",
    grpId: "Personalization",
    key: "baynote",
  },
  {
    title: "Certona",
    grpId: "Personalization",
    key: "certona",
  },
  {
    title: "Commerce Sciences",
    grpId: "Personalization",
    key: "commerce_sciences",
  },
  {
    title: "CrowdIgnite",
    grpId: "Personalization",
    key: "crowdignite",
  },
  {
    title: "iGoDigital",
    grpId: "Personalization",
    key: "igodigital",
  },
  {
    title: "Lead Converter",
    grpId: "Personalization",
    key: "lead_converter",
  },
  {
    title: "Monoloop",
    grpId: "Personalization",
    key: "monoloop",
  },
  {
    title: "MyBuys",
    grpId: "Personalization",
    key: "mybuys",
  },
  {
    title: "Peerius",
    grpId: "Personalization",
    key: "peerius",
  },
  {
    title: "Personyze",
    grpId: "Personalization",
    key: "personyze",
  },
  {
    title: "RichRelevance",
    grpId: "Personalization",
    key: "richrelevance",
  },
  {
    title: "Sailthru",
    grpId: "Personalization",
    key: "sailthru",
  },
  {
    title: "SeeWhy",
    grpId: "Personalization",
    key: "seewhy",
  },
  {
    title: "Strands Retail",
    grpId: "Personalization",
    key: "strands_retail",
  },
  {
    title: "Vertical Acuity",
    grpId: "Personalization",
    key: "vertical_acuity",
  },
  {
    title: "Publisher Advertising Tools",
  },
  {
    title: "Adition Technologies - Publishers",
    grpId: "Publisher Advertising Tools",
    key: "adition_technologies_-_publishers",
  },
  {
    title: "AdsNative",
    grpId: "Publisher Advertising Tools",
    key: "adsnative",
  },
  {
    title: "AdSupply",
    grpId: "Publisher Advertising Tools",
    key: "adsupply",
  },
  {
    title: "AudienceFuel",
    grpId: "Publisher Advertising Tools",
    key: "audiencefuel",
  },
  {
    title: "BuySellAds",
    grpId: "Publisher Advertising Tools",
    key: "buysellads",
  },
  {
    title: "Contently",
    grpId: "Publisher Advertising Tools",
    key: "contently",
  },
  {
    title: "Criteo Publisher Marketplace",
    grpId: "Publisher Advertising Tools",
    key: "criteo_publisher_marketplace",
  },
  {
    title: "DTScout",
    grpId: "Publisher Advertising Tools",
    key: "dtscout",
  },
  {
    title: "Hexagram Exchange",
    grpId: "Publisher Advertising Tools",
    key: "hexagram_exchange",
  },
  {
    title: "Komoona",
    grpId: "Publisher Advertising Tools",
    key: "komoona",
  },
  {
    title: "Luminate",
    grpId: "Publisher Advertising Tools",
    key: "luminate",
  },
  {
    title: "MadAdsMedia",
    grpId: "Publisher Advertising Tools",
    key: "madadsmedia",
  },
  {
    title: "Media.net",
    grpId: "Publisher Advertising Tools",
    key: "media_net",
  },
  {
    title: "Nativo",
    grpId: "Publisher Advertising Tools",
    key: "nativo",
  },
  {
    title: "NetSeer",
    grpId: "Publisher Advertising Tools",
    key: "netseer",
  },
  {
    title: "Open AdStream (Appnexus)",
    grpId: "Publisher Advertising Tools",
    key: "open_adstream_appnexus",
  },
  {
    title: "OpenX - Exchange",
    grpId: "Publisher Advertising Tools",
    key: "openx_-_exchange",
  },
  {
    title: "Parse.ly",
    grpId: "Publisher Advertising Tools",
    key: "parse_ly",
  },
  {
    title: "Polar (Media Voice)",
    grpId: "Publisher Advertising Tools",
    key: "polar",
  },
  {
    title: "Scout Analytics",
    grpId: "Publisher Advertising Tools",
    key: "scout_analytics",
  },
  {
    title: "Sharethrough",
    grpId: "Publisher Advertising Tools",
    key: "sharethrough",
  },
  {
    title: "Sovrn",
    grpId: "Publisher Advertising Tools",
    key: "sovrn",
  },
  {
    title: "Switch Ads",
    grpId: "Publisher Advertising Tools",
    key: "switch_ads",
  },
  {
    title: "Thoughtleadr",
    grpId: "Publisher Advertising Tools",
    key: "thoughtleadr",
  },
  {
    title: "Tynt",
    grpId: "Publisher Advertising Tools",
    key: "tynt",
  },
  {
    title: "Recruitment",
  },
  {
    title: "AcquireTM",
    grpId: "Recruitment",
    key: "acquiretm",
  },
  {
    title: "ADP Applicant Tracking System",
    grpId: "Recruitment",
    key: "adp_applicant_tracking_system",
  },
  {
    title: "Applicant Pro",
    grpId: "Recruitment",
    key: "applicant_pro",
  },
  {
    title: "Ascendify",
    grpId: "Recruitment",
    key: "ascendify",
  },
  {
    title: "ATS OnDemand",
    grpId: "Recruitment",
    key: "ats_ondemand",
  },
  {
    title: "Avature",
    grpId: "Recruitment",
    key: "avature",
  },
  {
    title: "Avionte",
    grpId: "Recruitment",
    key: "avionte",
  },
  {
    title: "BambooHR",
    grpId: "Recruitment",
    key: "bamboohr",
  },
  {
    title: "Bond Adapt",
    grpId: "Recruitment",
    key: "bond_adapt",
  },
  {
    title: "Breezy HR (formerly NimbleHR)",
    grpId: "Recruitment",
    key: "breezy_hr_formerly_nimblehr",
  },
  {
    title: "Catsone",
    grpId: "Recruitment",
    key: "catsone",
  },
  {
    title: "Compas (MyCompas)",
    grpId: "Recruitment",
    key: "compas_mycompas",
  },
  {
    title: "Cornerstone On Demand",
    grpId: "Recruitment",
    key: "cornerstone_on_demand",
  },
  {
    title: "Crelate",
    grpId: "Recruitment",
    key: "crelate",
  },
  {
    title: "eRecruit",
    grpId: "Recruitment",
    key: "erecruit",
  },
  {
    title: "Employease",
    grpId: "Recruitment",
    key: "employease",
  },
  {
    title: "Findly",
    grpId: "Recruitment",
    key: "findly",
  },
  {
    title: "Gethired",
    grpId: "Recruitment",
    key: "gethired",
  },
  {
    title: "Gild",
    grpId: "Recruitment",
    key: "gild",
  },
  {
    title: "Greenhouse.io",
    grpId: "Recruitment",
    key: "greenhouse_io",
  },
  {
    title: "HealthcareSource",
    grpId: "Recruitment",
    key: "healthcaresource",
  },
  {
    title: "HireBridge",
    grpId: "Recruitment",
    key: "hirebridge",
  },
  {
    title: "HR Logix",
    grpId: "Recruitment",
    key: "hr_logix",
  },
  {
    title: "HRMDirect",
    grpId: "Recruitment",
    key: "hrmdirect",
  },
  {
    title: "HRSmart",
    grpId: "Recruitment",
    key: "hrsmart",
  },
  {
    title: "Hyrell",
    grpId: "Recruitment",
    key: "hyrell",
  },
  {
    title: "iCIMS",
    grpId: "Recruitment",
    key: "icims",
  },
  {
    title: "Indeed Sponsored Ads",
    grpId: "Recruitment",
    key: "indeed_sponsored_ads",
  },
  {
    title: "Infor (PeopleAnswers)",
    grpId: "Recruitment",
    key: "infor_peopleanswers",
  },
  {
    title: "Interviewstream",
    grpId: "Recruitment",
    key: "interviewstream",
  },
  {
    title: "JobAdder",
    grpId: "Recruitment",
    key: "jobadder",
  },
  {
    title: "JobApp",
    grpId: "Recruitment",
    key: "jobapp",
  },
  {
    title: "JobDiva",
    grpId: "Recruitment",
    key: "jobdiva",
  },
  {
    title: "Jobscore",
    grpId: "Recruitment",
    key: "jobscore",
  },
  {
    title: "Jobvite",
    grpId: "Recruitment",
    key: "jobvite",
  },
  {
    title: "Kenexa",
    grpId: "Recruitment",
    key: "kenexa",
  },
  {
    title: "Kwantek",
    grpId: "Recruitment",
    key: "kwantek",
  },
  {
    title: "Lever",
    grpId: "Recruitment",
    key: "lever",
  },
  {
    title: "Luceo",
    grpId: "Recruitment",
    key: "luceo",
  },
  {
    title: "Lumesse",
    grpId: "Recruitment",
    key: "lumesse",
  },
  {
    title: "myStaffingPro",
    grpId: "Recruitment",
    key: "mystaffingpro",
  },
  {
    title: "myTalentLink",
    grpId: "Recruitment",
    key: "mytalentlink",
  },
  {
    title: "Newton Software",
    grpId: "Recruitment",
    key: "newton_software",
  },
  {
    title: "PC Recruiter",
    grpId: "Recruitment",
    key: "pc_recruiter",
  },
  {
    title: "People Matter",
    grpId: "Recruitment",
    key: "people_matter",
  },
  {
    title: "PeopleFluent",
    grpId: "Recruitment",
    key: "peoplefluent",
  },
  {
    title: "Resumator",
    grpId: "Recruitment",
    key: "resumator",
  },
  {
    title: "Sendouts",
    grpId: "Recruitment",
    key: "sendouts",
  },
  {
    title: "SilkRoad",
    grpId: "Recruitment",
    key: "silkroad",
  },
  {
    title: "SmartRecruiters",
    grpId: "Recruitment",
    key: "smartrecruiters",
  },
  {
    title: "SmashFly",
    grpId: "Recruitment",
    key: "smashfly",
  },
  {
    title: "SuccessFactors (SAP)",
    grpId: "Recruitment",
    key: "successfactors_sap",
  },
  {
    title: "TalentEd",
    grpId: "Recruitment",
    key: "talented",
  },
  {
    title: "Taleo",
    grpId: "Recruitment",
    key: "taleo",
  },
  {
    title: "TMP Worldwide",
    grpId: "Recruitment",
    key: "tmp_worldwide",
  },
  {
    title: "TrackerRMS",
    grpId: "Recruitment",
    key: "trackerrms",
  },
  {
    title: "UltiPro",
    grpId: "Recruitment",
    key: "ultipro",
  },
  {
    title: "Umantis",
    grpId: "Recruitment",
    key: "umantis",
  },
  {
    title: "Winocular",
    grpId: "Recruitment",
    key: "winocular",
  },
  {
    title: "Workable",
    grpId: "Recruitment",
    key: "workable",
  },
  {
    title: "Workday Recruit",
    grpId: "Recruitment",
    key: "workday_recruit",
  },
  {
    title: "ZipRecruiter",
    grpId: "Recruitment",
    key: "ziprecruiter",
  },
  {
    title: "Zoho Recruit",
    grpId: "Recruitment",
    key: "zoho_recruit",
  },
  {
    title: "Reservation Software",
  },
  {
    title: "Availpro",
    grpId: "Reservation Software",
    key: "availpro",
  },
  {
    title: "BookaTable",
    grpId: "Reservation Software",
    key: "bookatable",
  },
  {
    title: "Bookenda",
    grpId: "Reservation Software",
    key: "bookenda",
  },
  {
    title: "Booker Software",
    grpId: "Reservation Software",
    key: "booker_software",
  },
  {
    title: "Demandforce Scheduler Widget",
    grpId: "Reservation Software",
    key: "demandforce_scheduler_widget",
  },
  {
    title: "Ebisol",
    grpId: "Reservation Software",
    key: "ebisol",
  },
  {
    title: "Eveve",
    grpId: "Reservation Software",
    key: "eveve",
  },
  {
    title: "FastBooking",
    grpId: "Reservation Software",
    key: "fastbooking",
  },
  {
    title: "Hotel Spider",
    grpId: "Reservation Software",
    key: "hotel_spider",
  },
  {
    title: "iHotelier (TravelClick)",
    grpId: "Reservation Software",
    key: "ihotelier_travelclick",
  },
  {
    title: "Livebookings",
    grpId: "Reservation Software",
    key: "livebookings",
  },
  {
    title: "NexTable",
    grpId: "Reservation Software",
    key: "nextable",
  },
  {
    title: "Office 24",
    grpId: "Reservation Software",
    key: "office_24",
  },
  {
    title: "Opentable",
    grpId: "Reservation Software",
    key: "opentable",
  },
  {
    title: "Opentable Widget",
    grpId: "Reservation Software",
    key: "opentable_widget",
  },
  {
    title: "Quandoo",
    grpId: "Reservation Software",
    key: "quandoo",
  },
  {
    title: "ResDiary",
    grpId: "Reservation Software",
    key: "resdiary",
  },
  {
    title: "Reservit",
    grpId: "Reservation Software",
    key: "reservit",
  },
  {
    title: "RestaurantConnect",
    grpId: "Reservation Software",
    key: "restaurantconnect",
  },
  {
    title: "Restorando",
    grpId: "Reservation Software",
    key: "restorando",
  },
  {
    title: "SeatMe",
    grpId: "Reservation Software",
    key: "seatme",
  },
  {
    title: "Simplebooking",
    grpId: "Reservation Software",
    key: "simplebooking",
  },
  {
    title: "SynXis (Sabre Hospitality)",
    grpId: "Reservation Software",
    key: "synxis_sabre_hospitality",
  },
  {
    title: "Table 37",
    grpId: "Reservation Software",
    key: "table_37",
  },
  {
    title: "TableCheck (Vesper)",
    grpId: "Reservation Software",
    key: "tablecheck_vesper",
  },
  {
    title: "Retargeting",
  },
  {
    title: "Adcrowd",
    grpId: "Retargeting",
    key: "adcrowd",
  },
  {
    title: "AdExtent",
    grpId: "Retargeting",
    key: "adextent",
  },
  {
    title: "AdRoll",
    grpId: "Retargeting",
    key: "adroll",
  },
  {
    title: "Buysight (Advertising.com)",
    grpId: "Retargeting",
    key: "buysight_advertising_com",
  },
  {
    title: "Captify",
    grpId: "Retargeting",
    key: "captify",
  },
  {
    title: "Chango",
    grpId: "Retargeting",
    key: "chango",
  },
  {
    title: "Criteo",
    grpId: "Retargeting",
    key: "criteo",
  },
  {
    title: "Dotomi",
    grpId: "Retargeting",
    key: "dotomi",
  },
  {
    title: "Facebook Custom Audiences",
    grpId: "Retargeting",
    key: "facebook_web_custom_audiences",
  },
  {
    title: "Fetchback",
    grpId: "Retargeting",
    key: "fetchback",
  },
  {
    title: "Google Dynamic Remarketing",
    grpId: "Retargeting",
    key: "google_dynamic_remarketing",
  },
  {
    title: "Google Remarketing",
    grpId: "Retargeting",
    key: "google_remarketing",
  },
  {
    title: "Heais by Adnologies",
    grpId: "Retargeting",
    key: "heais_by_adnologies",
  },
  {
    title: "Kupona",
    grpId: "Retargeting",
    key: "kupona",
  },
  {
    title: "Leadback",
    grpId: "Retargeting",
    key: "leadback",
  },
  {
    title: "Magnetic",
    grpId: "Retargeting",
    key: "magnetic",
  },
  {
    title: "mainADV",
    grpId: "Retargeting",
    key: "mainadv",
  },
  {
    title: "mediaFORGE",
    grpId: "Retargeting",
    key: "mediaforge",
  },
  {
    title: "Merchenta",
    grpId: "Retargeting",
    key: "merchenta",
  },
  {
    title: "Meteora",
    grpId: "Retargeting",
    key: "meteora",
  },
  {
    title: "MyThings",
    grpId: "Retargeting",
    key: "mythings",
  },
  {
    title: "NextPerf",
    grpId: "Retargeting",
    key: "nextperf",
  },
  {
    title: "Perfect Audience",
    grpId: "Retargeting",
    key: "perfect_audience",
  },
  {
    title: "Realtime Targeting",
    grpId: "Retargeting",
    key: "realtime_targeting",
  },
  {
    title: "ReTargeter",
    grpId: "Retargeting",
    key: "retargeter",
  },
  {
    title: "RTB House",
    grpId: "Retargeting",
    key: "rtb_house",
  },
  {
    title: "Sociomantic",
    grpId: "Retargeting",
    key: "sociomantic",
  },
  {
    title: "SteelHouse",
    grpId: "Retargeting",
    key: "steelhouse",
  },
  {
    title: "Struq",
    grpId: "Retargeting",
    key: "struq",
  },
  {
    title: "Tacoda",
    grpId: "Retargeting",
    key: "tacoda",
  },
  {
    title: "Target Performance",
    grpId: "Retargeting",
    key: "target_performance",
  },
  {
    title: "Tellapart",
    grpId: "Retargeting",
    key: "tellapart",
  },
  {
    title: "Ve Interactive",
    grpId: "Retargeting",
    key: "ve_interactive",
  },
  {
    title: "Veruta (MyBuys)",
    grpId: "Retargeting",
    key: "veruta_mybuys",
  },
  {
    title: "Vizury",
    grpId: "Retargeting",
    key: "vizury",
  },
  {
    title: "webtraffic",
    grpId: "Retargeting",
    key: "webtraffic",
  },
  {
    title: "Xplosion",
    grpId: "Retargeting",
    key: "xplosion",
  },
  {
    title: "Xplosion Interactive",
    grpId: "Retargeting",
    key: "xplosion_interactive",
  },
  {
    title: "Yahoo Retargeting",
    grpId: "Retargeting",
    key: "yahoo_retargeting_japan",
  },
  {
    title: "Yieldr",
    grpId: "Retargeting",
    key: "yieldr",
  },
  {
    title: "SSL Certificates",
  },
  {
    title: "GeoTrust",
    grpId: "SSL Certificates",
    key: "geotrust",
  },
  {
    title: "GoDaddy",
    grpId: "SSL Certificates",
    key: "godaddy_hosting",
  },
  {
    title: "OpenSSL",
    grpId: "SSL Certificates",
    key: "openssl",
  },
  {
    title: "Search Appliances",
  },
  {
    title: "Bing",
    grpId: "Search Appliances",
    key: "bing",
  },
  {
    title: "Elasticsearch",
    grpId: "Search Appliances",
    key: "elasticsearch",
  },
  {
    title: "Endeca",
    grpId: "Search Appliances",
    key: "endeca",
  },
  {
    title: "Google AFS",
    grpId: "Search Appliances",
    key: "google_afs",
  },
  {
    title: "Google Custom Search",
    grpId: "Search Appliances",
    key: "google_async",
  },
  {
    title: "Google GSA",
    grpId: "Search Appliances",
    key: "google_gsa",
  },
  {
    title: "SLI Site Champion",
    grpId: "Search Appliances",
    key: "sli_site_champion",
  },
  {
    title: "SLI Systems",
    grpId: "Search Appliances",
    key: "sli_systems",
  },
  {
    title: "Swiftype",
    grpId: "Search Appliances",
    key: "swiftype",
  },
  {
    title: "Search Marketing",
  },
  {
    title: "Acquisio",
    grpId: "Search Marketing",
    key: "acquisio",
  },
  {
    title: "Adobe Media Optimizer",
    grpId: "Search Marketing",
    key: "adobe_media_optimizer",
  },
  {
    title: "Bizible",
    grpId: "Search Marketing",
    key: "bizible",
  },
  {
    title: "BloomReach",
    grpId: "Search Marketing",
    key: "bloomreach",
  },
  {
    title: "Brand Rep",
    grpId: "Search Marketing",
    key: "brand_rep",
  },
  {
    title: "BrightEdge",
    grpId: "Search Marketing",
    key: "brightedge",
  },
  {
    title: "Cint",
    grpId: "Search Marketing",
    key: "cint",
  },
  {
    title: "HitTail",
    grpId: "Search Marketing",
    key: "hittail",
  },
  {
    title: "IgnitionOne",
    grpId: "Search Marketing",
    key: "ignitionone",
  },
  {
    title: "Kenshoo",
    grpId: "Search Marketing",
    key: "kenshoo",
  },
  {
    title: "Marin",
    grpId: "Search Marketing",
    key: "marin",
  },
  {
    title: "SearchDex",
    grpId: "Search Marketing",
    key: "searchdex",
  },
  {
    title: "SearchMetrics",
    grpId: "Search Marketing",
    key: "searchmetrics",
  },
  {
    title: "Wishpond",
    grpId: "Search Marketing",
    key: "wishpond",
  },
  {
    title: "Wordstream",
    grpId: "Search Marketing",
    key: "wordstream",
  },
  {
    title: "Yandex.Direct",
    grpId: "Search Marketing",
    key: "yandex_direct",
  },
  {
    title: "YourAmigo",
    grpId: "Search Marketing",
    key: "youramigo",
  },
  {
    title: "Adobe Marketing Cloud",
    grpId: "Search Marketing",
    key: "adobe_marketing_cloud",
  },
  {
    title: "Security",
  },
  {
    title: "BBB seal",
    grpId: "Security",
    key: "bbb_seal",
  },
  {
    title: "BuySafe",
    grpId: "Security",
    key: "buysafe",
  },
  {
    title: "Evidon Seal",
    grpId: "Security",
    key: "evidon_seal",
  },
  {
    title: "GeoTrust Verification",
    grpId: "Security",
    key: "geotrust_verification",
  },
  {
    title: "GoDaddy Verified",
    grpId: "Security",
    key: "godaddy_verified",
  },
  {
    title: "HONESTe Online",
    grpId: "Security",
    key: "honeste_online",
  },
  {
    title: "McAfee",
    grpId: "Security",
    key: "mcafee",
  },
  {
    title: "Network Solutions Seal",
    grpId: "Security",
    key: "network_solutions_seal",
  },
  {
    title: "Sift Science",
    grpId: "Security",
    key: "sift_science",
  },
  {
    title: "Sitelock",
    grpId: "Security",
    key: "sitelock",
  },
  {
    title: "Starfield",
    grpId: "Security",
    key: "starfield",
  },
  {
    title: "Thawte Seal",
    grpId: "Security",
    key: "thawte_seal",
  },
  {
    title: "Truste",
    grpId: "Security",
    key: "truste",
  },
  {
    title: "TrustGuard",
    grpId: "Security",
    key: "trustguard",
  },
  {
    title: "TrustKeeper (TrustWave)",
    grpId: "Security",
    key: "trustkeeper_trustwave",
  },
  {
    title: "Trustwave Seal",
    grpId: "Security",
    key: "trustwave_seal",
  },
  {
    title: "User Trust (Comodo)",
    grpId: "Security",
    key: "user_trust_comodo",
  },
  {
    title: "Verisign Seal",
    grpId: "Security",
    key: "verisign_seal",
  },
  {
    title: "Site and Cart Abandonment",
  },
  {
    title: "Abandonaid",
    grpId: "Site and Cart Abandonment",
    key: "abandonaid",
  },
  {
    title: "CartStack",
    grpId: "Site and Cart Abandonment",
    key: "cartstack",
  },
  {
    title: "Exitmonitor",
    grpId: "Site and Cart Abandonment",
    key: "exitmonitor",
  },
  {
    title: "Granify",
    grpId: "Site and Cart Abandonment",
    key: "granify",
  },
  {
    title: "OptiLead",
    grpId: "Site and Cart Abandonment",
    key: "optilead",
  },
  {
    title: "Optimonk",
    grpId: "Site and Cart Abandonment",
    key: "optimonk",
  },
  {
    title: "PicReel",
    grpId: "Site and Cart Abandonment",
    key: "picreel",
  },
  {
    title: "Rejoiner",
    grpId: "Site and Cart Abandonment",
    key: "rejoiner",
  },
  {
    title: "SaleCycle",
    grpId: "Site and Cart Abandonment",
    key: "salecycle",
  },
  {
    title: "Spring Metrics",
    grpId: "Site and Cart Abandonment",
    key: "spring_metrics",
  },
  {
    title: "Upsellit",
    grpId: "Site and Cart Abandonment",
    key: "upsellit",
  },
  {
    title: "Yieldify",
    grpId: "Site and Cart Abandonment",
    key: "yieldify",
  },
  {
    title: "Small Business Marketing Software",
  },
  {
    title: "PetWise Marketing",
    grpId: "Small Business Marketing Software",
    key: "petwise_marketing",
  },
  {
    title: "VetShout",
    grpId: "Small Business Marketing Software",
    key: "vetshout",
  },
  {
    title: "Social Login",
  },
  {
    title: "CoffeeBean",
    grpId: "Social Login",
    key: "coffeebean",
  },
  {
    title: "Dito",
    grpId: "Social Login",
    key: "dito",
  },
  {
    title: "Facebook Login (Connect)",
    grpId: "Social Login",
    key: "facebook_login",
  },
  {
    title: "Gigya",
    grpId: "Social Login",
    key: "gigya",
  },
  {
    title: "Google Plus",
    grpId: "Social Login",
    key: "google_plus_login",
  },
  {
    title: "Janrain",
    grpId: "Social Login",
    key: "janrain",
  },
  {
    title: "Linkedin Login",
    grpId: "Social Login",
    key: "linkedin_login",
  },
  {
    title: "LoginRadius",
    grpId: "Social Login",
    key: "loginradius",
  },
  {
    title: "OneAll",
    grpId: "Social Login",
    key: "oneall",
  },
  {
    title: "ShopSocially",
    grpId: "Social Login",
    key: "shopsocially",
  },
  {
    title: "SocialPlus",
    grpId: "Social Login",
    key: "socialplus",
  },
  {
    title: "Twitter Widgets",
    grpId: "Social Login",
    key: "twitter_login_widget",
  },
  {
    title: "Social Media Marketing",
  },
  {
    title: "Agropulse",
    grpId: "Social Media Marketing",
    key: "agropulse",
  },
  {
    title: "Ambassador",
    grpId: "Social Media Marketing",
    key: "ambassador",
  },
  {
    title: "BuddyMedia",
    grpId: "Social Media Marketing",
    key: "buddymedia",
  },
  {
    title: "Chute",
    grpId: "Social Media Marketing",
    key: "chute",
  },
  {
    title: "CoverItLive",
    grpId: "Social Media Marketing",
    key: "coveritlive",
  },
  {
    title: "Curalate",
    grpId: "Social Media Marketing",
    key: "curalate",
  },
  {
    title: "Curebit",
    grpId: "Social Media Marketing",
    key: "curebit",
  },
  {
    title: "Extole",
    grpId: "Social Media Marketing",
    key: "extole",
  },
  {
    title: "FeedMagnet",
    grpId: "Social Media Marketing",
    key: "feedmagnet",
  },
  {
    title: "Friendbuy",
    grpId: "Social Media Marketing",
    key: "friendbuy",
  },
  {
    title: "Gleam",
    grpId: "Social Media Marketing",
    key: "gleam",
  },
  {
    title: "Iconosquare",
    grpId: "Social Media Marketing",
    key: "iconosquare",
  },
  {
    title: "Madwire",
    grpId: "Social Media Marketing",
    key: "madwire",
  },
  {
    title: "MassRelevance (Spredfast)",
    grpId: "Social Media Marketing",
    key: "massrelevance_spredfast",
  },
  {
    title: "Nanigans",
    grpId: "Social Media Marketing",
    key: "nanigans",
  },
  {
    title: "Netbase",
    grpId: "Social Media Marketing",
    key: "netbase",
  },
  {
    title: "Offerpop",
    grpId: "Social Media Marketing",
    key: "offerpop",
  },
  {
    title: "Olapic",
    grpId: "Social Media Marketing",
    key: "olapic",
  },
  {
    title: "Pixlee",
    grpId: "Social Media Marketing",
    key: "pixlee",
  },
  {
    title: "Postano",
    grpId: "Social Media Marketing",
    key: "postano",
  },
  {
    title: "Ready Pulse",
    grpId: "Social Media Marketing",
    key: "ready_pulse",
  },
  {
    title: "Readypulse",
    grpId: "Social Media Marketing",
    key: "readypulse",
  },
  {
    title: "RebelMouse",
    grpId: "Social Media Marketing",
    key: "rebelmouse",
  },
  {
    title: "ReferralCandy",
    grpId: "Social Media Marketing",
    key: "referralcandy",
  },
  {
    title: "ScribbleLive",
    grpId: "Social Media Marketing",
    key: "scribblelive",
  },
  {
    title: "Seesmic",
    grpId: "Social Media Marketing",
    key: "seesmic",
  },
  {
    title: "Smartify",
    grpId: "Social Media Marketing",
    key: "smartify",
  },
  {
    title: "SocialBro",
    grpId: "Social Media Marketing",
    key: "socialbro",
  },
  {
    title: "SocialTwist",
    grpId: "Social Media Marketing",
    key: "socialtwist",
  },
  {
    title: "Stackla",
    grpId: "Social Media Marketing",
    key: "stackla",
  },
  {
    title: "ThisMoment",
    grpId: "Social Media Marketing",
    key: "thismoment",
  },
  {
    title: "Tint",
    grpId: "Social Media Marketing",
    key: "tint",
  },
  {
    title: "Traackr",
    grpId: "Social Media Marketing",
    key: "traackr",
  },
  {
    title: "TradableBits",
    grpId: "Social Media Marketing",
    key: "tradablebits",
  },
  {
    title: "VideoBloom",
    grpId: "Social Media Marketing",
    key: "videobloom",
  },
  {
    title: "Videogenie",
    grpId: "Social Media Marketing",
    key: "videogenie",
  },
  {
    title: "ViralNinjas",
    grpId: "Social Media Marketing",
    key: "viralninjas",
  },
  {
    title: "Wayin",
    grpId: "Social Media Marketing",
    key: "wayin",
  },
  {
    title: "Social Sharing",
  },
  {
    title: "AddShoppers",
    grpId: "Social Sharing",
    key: "addshoppers",
  },
  {
    title: "AddThis",
    grpId: "Social Sharing",
    key: "addthis",
  },
  {
    title: "Facebook Like Button",
    grpId: "Social Sharing",
    key: "facebook_like_button",
  },
  {
    title: "Facebook Social Plugins",
    grpId: "Social Sharing",
    key: "facebook_share_button",
  },
  {
    title: "Gigya Share",
    grpId: "Social Sharing",
    key: "gigya_share",
  },
  {
    title: "Hupso",
    grpId: "Social Sharing",
    key: "hupso",
  },
  {
    title: "Instagram",
    grpId: "Social Sharing",
    key: "instagram",
  },
  {
    title: "Janrain Social Share",
    grpId: "Social Sharing",
    key: "janrain_social_share",
  },
  {
    title: "ShareThis",
    grpId: "Social Sharing",
    key: "sharethis",
  },
  {
    title: "Twitter Sharing",
    grpId: "Social Sharing",
    key: "twitter_sharing",
  },
  {
    title: "Supply Side Platforms",
  },
  {
    title: "PubMatic",
    grpId: "Supply Side Platforms",
    key: "pubmatic",
  },
  {
    title: "Rubicon Project",
    grpId: "Supply Side Platforms",
    key: "rubicon_project",
  },
  {
    title: "Sonobi",
    grpId: "Supply Side Platforms",
    key: "sonobi",
  },
  {
    title: "Zedo",
    grpId: "Supply Side Platforms",
    key: "zedo",
  },
  {
    title: "Support and Feedback",
  },
  {
    title: "Autotask",
    grpId: "Support and Feedback",
    key: "autotask",
  },
  {
    title: "BrightPearl",
    grpId: "Support and Feedback",
    key: "brightpearl",
  },
  {
    title: "CentraStage RMM",
    grpId: "Support and Feedback",
    key: "centrastage_rmm",
  },
  {
    title: "ClickDesk",
    grpId: "Support and Feedback",
    key: "clickdesk",
  },
  {
    title: "ConnectWise",
    grpId: "Support and Feedback",
    key: "connectwise",
  },
  {
    title: "Desk.com",
    grpId: "Support and Feedback",
    key: "desk_com",
  },
  {
    title: "drchrono",
    grpId: "Support and Feedback",
    key: "drchrono",
  },
  {
    title: "Enalyzer",
    grpId: "Support and Feedback",
    key: "enalyzer",
  },
  {
    title: "ePet Health",
    grpId: "Support and Feedback",
    key: "epet_health",
  },
  {
    title: "Estara",
    grpId: "Support and Feedback",
    key: "estara",
  },
  {
    title: "Ethnio",
    grpId: "Support and Feedback",
    key: "ethnio",
  },
  {
    title: "Feedbackify",
    grpId: "Support and Feedback",
    key: "feedbackify",
  },
  {
    title: "ForeSee",
    grpId: "Support and Feedback",
    key: "foresee",
  },
  {
    title: "Freshdesk",
    grpId: "Support and Feedback",
    key: "freshdesk",
  },
  {
    title: "GetFeedback",
    grpId: "Support and Feedback",
    key: "getfeedback",
  },
  {
    title: "GetSatisfaction",
    grpId: "Support and Feedback",
    key: "getsatisfaction",
  },
  {
    title: "Groove",
    grpId: "Support and Feedback",
    key: "groove",
  },
  {
    title: "Helpscout",
    grpId: "Support and Feedback",
    key: "helpscout",
  },
  {
    title: "InMoment",
    grpId: "Support and Feedback",
    key: "inmoment",
  },
  {
    title: "Intercom",
    grpId: "Support and Feedback",
    key: "intercom",
  },
  {
    title: "iPerceptions",
    grpId: "Support and Feedback",
    key: "iperceptions",
  },
  {
    title: "Kampyle",
    grpId: "Support and Feedback",
    key: "kampyle",
  },
  {
    title: "KarmaSoft",
    grpId: "Support and Feedback",
    key: "karmasoft",
  },
  {
    title: "Kayako",
    grpId: "Support and Feedback",
    key: "kayako",
  },
  {
    title: "LogMeIn - Rescue",
    grpId: "Support and Feedback",
    key: "logmein_-_rescue",
  },
  {
    title: "MemberClicks",
    grpId: "Support and Feedback",
    key: "memberclicks",
  },
  {
    title: "mHelpDesk",
    grpId: "Support and Feedback",
    key: "mhelpdesk",
  },
  {
    title: "Needle",
    grpId: "Support and Feedback",
    key: "needle",
  },
  {
    title: "OpinionLab",
    grpId: "Support and Feedback",
    key: "opinionlab",
  },
  {
    title: "Parature",
    grpId: "Support and Feedback",
    key: "parature",
  },
  {
    title: "PerfectMind",
    grpId: "Support and Feedback",
    key: "perfectmind",
  },
  {
    title: "Qualaroo",
    grpId: "Support and Feedback",
    key: "qualaroo",
  },
  {
    title: "Qualtrics",
    grpId: "Support and Feedback",
    key: "qualtrics_intercept",
  },
  {
    title: "Questback",
    grpId: "Support and Feedback",
    key: "questback",
  },
  {
    title: "RightNow",
    grpId: "Support and Feedback",
    key: "rightnow",
  },
  {
    title: "RightSignature",
    grpId: "Support and Feedback",
    key: "rightsignature",
  },
  {
    title: "Rosy Salon Software",
    grpId: "Support and Feedback",
    key: "rosy_salon_software",
  },
  {
    title: "SOAP Vault",
    grpId: "Support and Feedback",
    key: "soap_vault",
  },
  {
    title: "Spigit (Mindjet)",
    grpId: "Support and Feedback",
    key: "spigit_mindjet",
  },
  {
    title: "SurveyGizmo",
    grpId: "Support and Feedback",
    key: "surveygizmo",
  },
  {
    title: "SurveyMonkey",
    grpId: "Support and Feedback",
    key: "surveymonkey",
  },
  {
    title: "Surveypal",
    grpId: "Support and Feedback",
    key: "surveypal",
  },
  {
    title: "TurnTo",
    grpId: "Support and Feedback",
    key: "turnto",
  },
  {
    title: "Userecho",
    grpId: "Support and Feedback",
    key: "userecho",
  },
  {
    title: "UserMood",
    grpId: "Support and Feedback",
    key: "usermood",
  },
  {
    title: "UserNeeds",
    grpId: "Support and Feedback",
    key: "userneeds",
  },
  {
    title: "UserReport",
    grpId: "Support and Feedback",
    key: "userreport",
  },
  {
    title: "UserVoice",
    grpId: "Support and Feedback",
    key: "uservoice",
  },
  {
    title: "Webropol",
    grpId: "Support and Feedback",
    key: "webropol",
  },
  {
    title: "Zendesk",
    grpId: "Support and Feedback",
    key: "zendesk",
  },
  {
    title: "Tag Management",
  },
  {
    title: "Adobe Tag Management",
    grpId: "Tag Management",
    key: "adobe_tag_management",
  },
  {
    title: "Datalicious SuperTag",
    grpId: "Tag Management",
    key: "datalicious_supertag",
  },
  {
    title: "DoubleClick Floodlight",
    grpId: "Tag Management",
    key: "doubleclick_floodlight",
  },
  {
    title: "Ensighten",
    grpId: "Tag Management",
    key: "ensighten",
  },
  {
    title: "Exactag",
    grpId: "Tag Management",
    key: "exactag",
  },
  {
    title: "Google Tag Manager",
    grpId: "Tag Management",
    key: "google_tag_manager  ",
  },
  {
    title: "Qubit OpenTag",
    grpId: "Tag Management",
    key: "qubit_opentag",
  },
  {
    title: "SearchDiscovery Satellite",
    grpId: "Tag Management",
    key: "searchdiscovery_satellite",
  },
  {
    title: "Signal by BrightTag",
    grpId: "Tag Management",
    key: "signal_by_brighttag",
  },
  {
    title: "Tag Commander",
    grpId: "Tag Management",
    key: "tag_commander",
  },
  {
    title: "TagMan",
    grpId: "Tag Management",
    key: "tagman",
  },
  {
    title: "Tealium",
    grpId: "Tag Management",
    key: "tealium",
  },
  {
    title: "UberTags (X+1)",
    grpId: "Tag Management",
    key: "ubertags_x+1",
  },
  {
    title: "Yahoo Tag Manager",
    grpId: "Tag Management",
    key: "yahoo_tag_manager",
  },
  {
    title: "Video Advertising Networks",
  },
  {
    title: "AdapTV",
    grpId: "Video Advertising Networks",
    key: "adaptv",
  },
  {
    title: "Animoto",
    grpId: "Video Advertising Networks",
    key: "animoto",
  },
  {
    title: "Brightroll",
    grpId: "Video Advertising Networks",
    key: "brightroll",
  },
  {
    title: "Coull",
    grpId: "Video Advertising Networks",
    key: "coull",
  },
  {
    title: "eBuzzing",
    grpId: "Video Advertising Networks",
    key: "ebuzzing",
  },
  {
    title: "Idomoo",
    grpId: "Video Advertising Networks",
    key: "idomoo",
  },
  {
    title: "Invodo",
    grpId: "Video Advertising Networks",
    key: "invodo",
  },
  {
    title: "Mediabong",
    grpId: "Video Advertising Networks",
    key: "mediabong",
  },
  {
    title: "Mixpo",
    grpId: "Video Advertising Networks",
    key: "mixpo",
  },
  {
    title: "NDN",
    grpId: "Video Advertising Networks",
    key: "ndn",
  },
  {
    title: "PointRoll",
    grpId: "Video Advertising Networks",
    key: "pointroll",
  },
  {
    title: "Selectable Media",
    grpId: "Video Advertising Networks",
    key: "selectable_media",
  },
  {
    title: "Smartclip",
    grpId: "Video Advertising Networks",
    key: "smartclip",
  },
  {
    title: "SpotXchange",
    grpId: "Video Advertising Networks",
    key: "spotxchange",
  },
  {
    title: "Teads",
    grpId: "Video Advertising Networks",
    key: "teads",
  },
  {
    title: "Treepodia",
    grpId: "Video Advertising Networks",
    key: "treepodia",
  },
  {
    title: "Tremor Video",
    grpId: "Video Advertising Networks",
    key: "tremor_video",
  },
  {
    title: "TubeMogul",
    grpId: "Video Advertising Networks",
    key: "tubemogul",
  },
  {
    title: "Unicorn Media",
    grpId: "Video Advertising Networks",
    key: "unicorn_media",
  },
  {
    title: "Unruly Media",
    grpId: "Video Advertising Networks",
    key: "unruly_media",
  },
  {
    title: "Videology",
    grpId: "Video Advertising Networks",
    key: "videology",
  },
  {
    title: "Videoplaza",
    grpId: "Video Advertising Networks",
    key: "videoplaza",
  },
  {
    title: "Virool",
    grpId: "Video Advertising Networks",
    key: "virool",
  },
  {
    title: "YuMe",
    grpId: "Video Advertising Networks",
    key: "yume",
  },
  {
    title: "Web Accelerators",
  },
  {
    title: "CloudFlare",
    grpId: "Web Accelerators",
    key: "cloudflare_dns",
  },
  {
    title: "Digital Fulcrum GhostWriter",
    grpId: "Web Accelerators",
    key: "digital_fulcrum_ghostwriter",
  },
  {
    title: "Incapsula",
    grpId: "Web Accelerators",
    key: "incapsula",
  },
  {
    title: "mod_page_speed",
    grpId: "Web Accelerators",
    key: "mod_page_speed",
  },
  {
    title: "Page Speed Service",
    grpId: "Web Accelerators",
    key: "page_speed_service",
  },
  {
    title: "StrangeLoop",
    grpId: "Web Accelerators",
    key: "strangeloop",
  },
  {
    title: "Webo",
    grpId: "Web Accelerators",
    key: "strangeloop",
  },
  {
    title: "Web Performance Monitoring",
  },
  {
    title: "Akamai RUM",
    grpId: "Web Performance Monitoring",
    key: "akamai_rum",
  },
  {
    title: "AlertSite by Smartbear",
    grpId: "Web Performance Monitoring",
    key: "alertsite_by_smartbear",
  },
  {
    title: "AppDynamics",
    grpId: "Web Performance Monitoring",
    key: "appdynamics",
  },
  {
    title: "Boomerang JS library",
    grpId: "Web Performance Monitoring",
    key: "boomerang_js_library",
  },
  {
    title: "Bucky",
    grpId: "Web Performance Monitoring",
    key: "bucky",
  },
  {
    title: "Catchpoint",
    grpId: "Web Performance Monitoring",
    key: "catchpoint",
  },
  {
    title: "Cedexis Radar",
    grpId: "Web Performance Monitoring",
    key: "cedexis_radar",
  },
  {
    title: "dynaTrace",
    grpId: "Web Performance Monitoring",
    key: "dynatrace",
  },
  {
    title: "Gomez",
    grpId: "Web Performance Monitoring",
    key: "gomez",
  },
  {
    title: "Google Speed Analytics",
    grpId: "Web Performance Monitoring",
    key: "google_speed_analytics",
  },
  {
    title: "Keynote",
    grpId: "Web Performance Monitoring",
    key: "keynote",
  },
  {
    title: "Lognormal",
    grpId: "Web Performance Monitoring",
    key: "lognormal",
  },
  {
    title: "New Relic",
    grpId: "Web Performance Monitoring",
    key: "new_relic",
  },
  {
    title: "Pingdom",
    grpId: "Web Performance Monitoring",
    key: "pingdom",
  },
  {
    title: "R17",
    grpId: "Web Performance Monitoring",
    key: "r17",
  },
  {
    title: "Ruxit",
    grpId: "Web Performance Monitoring",
    key: "ruxit",
  },
  {
    title: "SOASTA",
    grpId: "Web Performance Monitoring",
    key: "soasta",
  },
  {
    title: "ThousandEyes",
    grpId: "Web Performance Monitoring",
    key: "thousandeyes",
  },
  {
    title: "Torbit Insight",
    grpId: "Web Performance Monitoring",
    key: "torbit_insight",
  },
  {
    title: "Datadog",
    grpId: "Web Performance Monitoring",
    key: "datadog",
  },
  {
    title: "StatusPage IO",
    grpId: "Web Performance Monitoring",
    key: "statuspage",
  },
  {
    title: "Web Presentation Tools",
  },
  {
    title: "Adobe Connect",
    grpId: "Web Presentation Tools",
    key: "adobe_connect",
  },
  {
    title: "Brainshark",
    grpId: "Web Presentation Tools",
    key: "brainshark",
  },
  {
    title: "Brighttalk",
    grpId: "Web Presentation Tools",
    key: "brighttalk",
  },
  {
    title: "Fuze",
    grpId: "Web Presentation Tools",
    key: "fuze",
  },
  {
    title: "GoToMeeting",
    grpId: "Web Presentation Tools",
    key: "gotomeeting",
  },
  {
    title: "GoToWebinar",
    grpId: "Web Presentation Tools",
    key: "gotowebinar",
  },
  {
    title: "Knowledge Vision",
    grpId: "Web Presentation Tools",
    key: "knowledgevision",
  },
  {
    title: "MediaPlatform",
    grpId: "Web Presentation Tools",
    key: "mediaplatform",
  },
  {
    title: "ON24",
    grpId: "Web Presentation Tools",
    key: "on24",
  },
  {
    title: "Personify",
    grpId: "Web Presentation Tools",
    key: "personify",
  },
  {
    title: "ReadyTalk",
    grpId: "Web Presentation Tools",
    key: "readytalk",
  },
  {
    title: "Webex",
    grpId: "Web Presentation Tools",
    key: "webex",
  },
  {
    title: "Widgets",
  },
  {
    title: "Amazon Widgets",
    grpId: "Widgets",
    key: "amazon_widgets",
  },
  {
    title: "Angies List",
    grpId: "Widgets",
    key: "angies_list",
  },
  {
    title: "Aviary",
    grpId: "Widgets",
    key: "aviary",
  },
  {
    title: "Bing Accredited Professional Tag",
    grpId: "Widgets",
    key: "bing_accredited_professional_tag",
  },
  {
    title: "BlueKnow",
    grpId: "Widgets",
    key: "blueknow",
  },
  {
    title: "BugHerd",
    grpId: "Widgets",
    key: "bugherd",
  },
  {
    title: "CustomerLobby",
    grpId: "Widgets",
    key: "customerlobby",
  },
  {
    title: "Errorception",
    grpId: "Widgets",
    key: "errorception",
  },
  {
    title: "Facebook Widget",
    grpId: "Widgets",
    key: "facebook_widget",
  },
  {
    title: "Ghostery (formerly Evidon)",
    grpId: "Widgets",
    key: "ghostery_formerly_evidon",
  },
  {
    title: "Google Play",
    grpId: "Widgets",
    key: "google_play",
  },
  {
    title: "Google translate API",
    grpId: "Widgets",
    key: "google_translate_api  ",
  },
  {
    title: "Google translate widget",
    grpId: "Widgets",
    key: "google_translate_widget",
  },
  {
    title: "Hellobar",
    grpId: "Widgets",
    key: "hellobar",
  },
  {
    title: "iTunes",
    grpId: "Widgets",
    key: "itunes",
  },
  {
    title: "Kelly Blue Book Widget",
    grpId: "Widgets",
    key: "kelly_blue_book_widget",
  },
  {
    title: "Multilingual",
    grpId: "Widgets",
    key: "multilingual",
  },
  {
    title: "PollDaddy",
    grpId: "Widgets",
    key: "polldaddy",
  },
  {
    title: "Pubexchange",
    grpId: "Widgets",
    key: "pubexchange",
  },
  {
    title: "Rateabiz",
    grpId: "Widgets",
    key: "rateabiz",
  },
  {
    title: "Searchify",
    grpId: "Widgets",
    key: "searchify",
  },
  {
    title: "Skype Widget",
    grpId: "Widgets",
    key: "skype_widget",
  },
  {
    title: "Slimbox",
    grpId: "Widgets",
    key: "slimbox",
  },
  {
    title: "Snapwidget",
    grpId: "Widgets",
    key: "snapwidget",
  },
  {
    title: "SumoMe",
    grpId: "Widgets",
    key: "sumome",
  },
  {
    title: "SureCritic",
    grpId: "Widgets",
    key: "surecritic",
  },
  {
    title: "TripTease (Price Check)",
    grpId: "Widgets",
    key: "triptease_price_check",
  },
  {
    title: "eBay eCommerce for Wix",
    grpId: "Widgets",
    key: "ebay_ecommerce_for_wix",
  },
  {
    title: "Reamaze",
    grpId: "Widgets",
    key: "reamaze",
  },
  {
    title: "RingCentral",
    grpId: "Widgets",
    key: "ringcentral",
  },
];

export const SPAM_WORDS_OLD = [
  {
    word: "Access",
    category: "Urgency",
  },
  {
    word: "Access now",
    category: "Urgency",
  },
  {
    word: "Act",
    category: "Urgency",
  },
  {
    word: "Act immediately",
    category: "Urgency",
  },
  {
    word: "Act now",
    category: "Urgency",
  },
  {
    word: "Act now!",
    category: "Urgency",
  },
  {
    word: "Action",
    category: "Urgency",
  },
  {
    word: "Action required",
    category: "Urgency",
  },
  {
    word: "Apply here",
    category: "Urgency",
  },
  {
    word: "Apply now",
    category: "Urgency",
  },
  {
    word: "Apply now!",
    category: "Urgency",
  },
  {
    word: "Apply online",
    category: "Urgency",
  },
  {
    word: "Become a member",
    category: "Urgency",
  },
  {
    word: "Before it's too late",
    category: "Urgency",
  },
  {
    word: "Being a member",
    category: "Urgency",
  },
  {
    word: "Buy",
    category: "Urgency",
  },
  {
    word: "Buy direct",
    category: "Urgency",
  },
  {
    word: "Buy now",
    category: "Urgency",
  },
  {
    word: "Buy today",
    category: "Urgency",
  },
  {
    word: "Call",
    category: "Urgency",
  },
  {
    word: "Call free",
    category: "Urgency",
  },
  {
    word: "Call free/now",
    category: "Urgency",
  },
  {
    word: "Call me",
    category: "Urgency",
  },
  {
    word: "Call now",
    category: "Urgency",
  },
  {
    word: "Call now!",
    category: "Urgency",
  },
  {
    word: "Can we have a minute of your time?",
    category: "Urgency",
  },
  {
    word: "Cancel now",
    category: "Urgency",
  },
  {
    word: "Cancellation required",
    category: "Urgency",
  },
  {
    word: "Claim now",
    category: "Urgency",
  },
  {
    word: "Click",
    category: "Urgency",
  },
  {
    word: "Click below",
    category: "Urgency",
  },
  {
    word: "Click here",
    category: "Urgency",
  },
  {
    word: "Click me to download",
    category: "Urgency",
  },
  {
    word: "Click now",
    category: "Urgency",
  },
  {
    word: "Click this link",
    category: "Urgency",
  },
  {
    word: "Click to get",
    category: "Urgency",
  },
  {
    word: "Click to remove",
    category: "Urgency",
  },
  {
    word: "Contact us immediately",
    category: "Urgency",
  },
  {
    word: "Deal ending soon",
    category: "Urgency",
  },
  {
    word: "Do it now",
    category: "Urgency",
  },
  {
    word: "Do it today",
    category: "Urgency",
  },
  {
    word: "Don't delete",
    category: "Urgency",
  },
  {
    word: "Don't hesitate",
    category: "Urgency",
  },
  {
    word: "Don't waste time",
    category: "Urgency",
  },
  {
    word: "Don’t delete",
    category: "Urgency",
  },
  {
    word: "Exclusive deal",
    category: "Urgency",
  },
  {
    word: "Expire",
    category: "Urgency",
  },
  {
    word: "Expires today",
    category: "Urgency",
  },
  {
    word: "Final call",
    category: "Urgency",
  },
  {
    word: "For instant access",
    category: "Urgency",
  },
  {
    word: "For Only",
    category: "Urgency",
  },
  {
    word: "For you",
    category: "Urgency",
  },
  {
    word: "Friday before [holiday]",
    category: "Urgency",
  },
  {
    word: "Get it away",
    category: "Urgency",
  },
  {
    word: "Get it now",
    category: "Urgency",
  },
  {
    word: "Get now",
    category: "Urgency",
  },
  {
    word: "Get paid",
    category: "Urgency",
  },
  {
    word: "Get started",
    category: "Urgency",
  },
  {
    word: "Get started now",
    category: "Urgency",
  },
  {
    word: "Great offer",
    category: "Urgency",
  },
  {
    word: "Hurry up",
    category: "Urgency",
  },
  {
    word: "Immediately",
    category: "Urgency",
  },
  {
    word: "Info you requested",
    category: "Urgency",
  },
  {
    word: "Information you requested",
    category: "Urgency",
  },
  {
    word: "Instant",
    category: "Urgency",
  },
  {
    word: "Limited time",
    category: "Urgency",
  },
  {
    word: "New customers only",
    category: "Urgency",
  },
  {
    word: "Now",
    category: "Urgency",
  },
  {
    word: "Now only",
    category: "Urgency",
  },
  {
    word: "Offer expires",
    category: "Urgency",
  },
  {
    word: "Once in lifetime",
    category: "Urgency",
  },
  {
    word: "Only",
    category: "Urgency",
  },
  {
    word: "Order now",
    category: "Urgency",
  },
  {
    word: "Order today",
    category: "Urgency",
  },
  {
    word: "Please read",
    category: "Urgency",
  },
  {
    word: "Purchase now",
    category: "Urgency",
  },
  {
    word: "Sign up free",
    category: "Urgency",
  },
  {
    word: "Sign up free today",
    category: "Urgency",
  },
  {
    word: "Supplies are limited",
    category: "Urgency",
  },
  {
    word: "Take action",
    category: "Urgency",
  },
  {
    word: "Take action now",
    category: "Urgency",
  },
  {
    word: "This won’t last",
    category: "Urgency",
  },
  {
    word: "Time limited",
    category: "Urgency",
  },
  {
    word: "Today",
    category: "Urgency",
  },
  {
    word: "Top urgent",
    category: "Urgency",
  },
  {
    word: "Trial",
    category: "Urgency",
  },
  {
    word: "Urgent",
    category: "Urgency",
  },
  {
    word: "What are you waiting for?",
    category: "Urgency",
  },
  {
    word: "While supplies last",
    category: "Urgency",
  },
  {
    word: "You are a winner",
    category: "Urgency",
  },
  {
    word: "0 down",
    category: "Shady",
  },
  {
    word: "All",
    category: "Shady",
  },
  {
    word: "All natural",
    category: "Shady",
  },
  {
    word: "All natural/new",
    category: "Shady",
  },
  {
    word: "All new",
    category: "Shady",
  },
  {
    word: "All-natural",
    category: "Shady",
  },
  {
    word: "All-new",
    category: "Shady",
  },
  {
    word: "Allowance",
    category: "Shady",
  },
  {
    word: "As seen on",
    category: "Shady",
  },
  {
    word: "As seen on Oprah",
    category: "Shady",
  },
  {
    word: "At no cost",
    category: "Shady",
  },
  {
    word: "Auto email removal",
    category: "Shady",
  },
  {
    word: "Avoice bankruptcy",
    category: "Shady",
  },
  {
    word: "Avoid",
    category: "Shady",
  },
  {
    word: "Beneficial offer",
    category: "Shady",
  },
  {
    word: "Beneficiary",
    category: "Shady",
  },
  {
    word: "Bill 1618",
    category: "Shady",
  },
  {
    word: "Brand new pager",
    category: "Shady",
  },
  {
    word: "Bulk email",
    category: "Shady",
  },
  {
    word: "Buying judgements",
    category: "Shady",
  },
  {
    word: "Buying judgments",
    category: "Shady",
  },
  {
    word: "Cable converter",
    category: "Shady",
  },
  {
    word: "Calling creditors",
    category: "Shady",
  },
  {
    word: "Can you help us?",
    category: "Shady",
  },
  {
    word: "Cancel at any time",
    category: "Shady",
  },
  {
    word: "Cannot be combined",
    category: "Shady",
  },
  {
    word: "Celebrity",
    category: "Shady",
  },
  {
    word: "Cell phone cancer scam",
    category: "Shady",
  },
  {
    word: "Certified",
    category: "Shady",
  },
  {
    word: "Chance",
    category: "Shady",
  },
  {
    word: "Cheap",
    category: "Shady",
  },
  {
    word: "Cheap meds",
    category: "Shady",
  },
  {
    word: "Cialis",
    category: "Shady",
  },
  {
    word: "Claims",
    category: "Shady",
  },
  {
    word: "Claims not to be selling anything",
    category: "Shady",
  },
  {
    word: "Claims to be in accordance with some spam law",
    category: "Shady",
  },
  {
    word: "Claims to be legal",
    category: "Shady",
  },
  {
    word: "Clearance",
    category: "Shady",
  },
  {
    word: "Collect",
    category: "Shady",
  },
  {
    word: "Collect child support",
    category: "Shady",
  },
  {
    word: "Compare",
    category: "Shady",
  },
  {
    word: "Compare now",
    category: "Shady",
  },
  {
    word: "Compare online",
    category: "Shady",
  },
  {
    word: "Compare rates",
    category: "Shady",
  },
  {
    word: "Compete for your business",
    category: "Shady",
  },
  {
    word: "Confidentiality",
    category: "Shady",
  },
  {
    word: "Congratulations",
    category: "Shady",
  },
  {
    word: "Consolidate debt and credit",
    category: "Shady",
  },
  {
    word: "Consolidate your debt",
    category: "Shady",
  },
  {
    word: "Copy accurately",
    category: "Shady",
  },
  {
    word: "Copy DVDs",
    category: "Shady",
  },
  {
    word: "COVID",
    category: "Shady",
  },
  {
    word: "Cures",
    category: "Shady",
  },
  {
    word: "Cures baldness",
    category: "Shady",
  },
  {
    word: "Diagnostic",
    category: "Shady",
  },
  {
    word: "DIAGNOSTICS",
    category: "Shady",
  },
  {
    word: "Diet",
    category: "Shady",
  },
  {
    word: "Dig up dirt on friends",
    category: "Shady",
  },
  {
    word: "Direct email",
    category: "Shady",
  },
  {
    word: "Direct marketing",
    category: "Shady",
  },
  {
    word: "Eliminate debt",
    category: "Shady",
  },
  {
    word: "Explode your business",
    category: "Shady",
  },
  {
    word: "Fast viagra delivery",
    category: "Shady",
  },
  {
    word: "Finance",
    category: "Shady",
  },
  {
    word: "Financial",
    category: "Shady",
  },
  {
    word: "Financial advice",
    category: "Shady",
  },
  {
    word: "Financial independence",
    category: "Shady",
  },
  {
    word: "Financially independent",
    category: "Shady",
  },
  {
    word: "For new customers only",
    category: "Shady",
  },
  {
    word: "Foreclosure",
    category: "Shady",
  },
  {
    word: "Free",
    category: "Shady",
  },
  {
    word: "Free access/money/gift",
    category: "Shady",
  },
  {
    word: "Free bonus",
    category: "Shady",
  },
  {
    word: "Free cell phone",
    category: "Shady",
  },
  {
    word: "Free DVD",
    category: "Shady",
  },
  {
    word: "Free grant money",
    category: "Shady",
  },
  {
    word: "Free information",
    category: "Shady",
  },
  {
    word: "Free installation",
    category: "Shady",
  },
  {
    word: "Free Instant",
    category: "Shady",
  },
  {
    word: "Free iPhone",
    category: "Shady",
  },
  {
    word: "Free laptop",
    category: "Shady",
  },
  {
    word: "Free leads",
    category: "Shady",
  },
  {
    word: "Free Macbook",
    category: "Shady",
  },
  {
    word: "Free offer",
    category: "Shady",
  },
  {
    word: "Free priority mail",
    category: "Shady",
  },
  {
    word: "Free sample",
    category: "Shady",
  },
  {
    word: "Free website",
    category: "Shady",
  },
  {
    word: "Free!",
    category: "Shady",
  },
  {
    word: "Get",
    category: "Shady",
  },
  {
    word: "Gift card",
    category: "Shady",
  },
  {
    word: "Gift certificate",
    category: "Shady",
  },
  {
    word: "Gift included",
    category: "Shady",
  },
  {
    word: "Give it away",
    category: "Shady",
  },
  {
    word: "Giving away",
    category: "Shady",
  },
  {
    word: "Giving it away",
    category: "Shady",
  },
  {
    word: "Gold",
    category: "Shady",
  },
  {
    word: "Great",
    category: "Shady",
  },
  {
    word: "Great deal",
    category: "Shady",
  },
  {
    word: "Greetings of the day",
    category: "Shady",
  },
  {
    word: "Growth hormone",
    category: "Shady",
  },
  {
    word: "Guarantee",
    category: "Shady",
  },
  {
    word: "Guaranteed deposit",
    category: "Shady",
  },
  {
    word: "Guaranteed income",
    category: "Shady",
  },
  {
    word: "Guaranteed payment",
    category: "Shady",
  },
  {
    word: "Have you been turned down?",
    category: "Shady",
  },
  {
    word: "Hello (with no name included)",
    category: "Shady",
  },
  {
    word: "Hidden charges",
    category: "Shady",
  },
  {
    word: "Hidden costs",
    category: "Shady",
  },
  {
    word: "Hidden fees",
    category: "Shady",
  },
  {
    word: "High score",
    category: "Shady",
  },
  {
    word: "Home based business",
    category: "Shady",
  },
  {
    word: "Home mortgage",
    category: "Shady",
  },
  {
    word: "Human",
    category: "Shady",
  },
  {
    word: "Human growth hormone",
    category: "Shady",
  },
  {
    word: "If only it were that easy",
    category: "Shady",
  },
  {
    word: "Important information",
    category: "Shady",
  },
  {
    word: "Important notification",
    category: "Shady",
  },
  {
    word: "Instant weight loss",
    category: "Shady",
  },
  {
    word: "Insurance Lose weight",
    category: "Shady",
  },
  {
    word: "Internet marketing",
    category: "Shady",
  },
  {
    word: "Investment decision",
    category: "Shady",
  },
  {
    word: "Invoice",
    category: "Shady",
  },
  {
    word: "It’s effective",
    category: "Shady",
  },
  {
    word: "Job alert",
    category: "Shady",
  },
  {
    word: "Junk",
    category: "Shady",
  },
  {
    word: "Lambo",
    category: "Shady",
  },
  {
    word: "Laser printer",
    category: "Shady",
  },
  {
    word: "Last Day",
    category: "Shady",
  },
  {
    word: "Legal",
    category: "Shady",
  },
  {
    word: "Legal notice",
    category: "Shady",
  },
  {
    word: "Life",
    category: "Shady",
  },
  {
    word: "Life insurance",
    category: "Shady",
  },
  {
    word: "Lifetime access",
    category: "Shady",
  },
  {
    word: "Lifetime deal",
    category: "Shady",
  },
  {
    word: "Limited",
    category: "Shady",
  },
  {
    word: "Limited amount",
    category: "Shady",
  },
  {
    word: "Limited number",
    category: "Shady",
  },
  {
    word: "Limited offer",
    category: "Shady",
  },
  {
    word: "Limited supply",
    category: "Shady",
  },
  {
    word: "Limited time offer",
    category: "Shady",
  },
  {
    word: "Limited time only",
    category: "Shady",
  },
  {
    word: "Loan",
    category: "Shady",
  },
  {
    word: "Long distance phone number",
    category: "Shady",
  },
  {
    word: "Long distance phone offer",
    category: "Shady",
  },
  {
    word: "Lose weight",
    category: "Shady",
  },
  {
    word: "Lose weight fast",
    category: "Shady",
  },
  {
    word: "Lose weight spam",
    category: "Shady",
  },
  {
    word: "Lottery",
    category: "Shady",
  },
  {
    word: "Lower interest rate",
    category: "Shady",
  },
  {
    word: "Lower interest rates",
    category: "Shady",
  },
  {
    word: "Lower monthly payment",
    category: "Shady",
  },
  {
    word: "Lower your mortgage rate",
    category: "Shady",
  },
  {
    word: "Lowest insurance rates",
    category: "Shady",
  },
  {
    word: "Lowest interest rate",
    category: "Shady",
  },
  {
    word: "Lowest rate",
    category: "Shady",
  },
  {
    word: "Lowest rates",
    category: "Shady",
  },
  {
    word: "Luxury",
    category: "Shady",
  },
  {
    word: "Luxury car",
    category: "Shady",
  },
  {
    word: "Mail in order form",
    category: "Shady",
  },
  {
    word: "Main in order form",
    category: "Shady",
  },
  {
    word: "Mark this as not junk",
    category: "Shady",
  },
  {
    word: "Mass email",
    category: "Shady",
  },
  {
    word: "Medical",
    category: "Shady",
  },
  {
    word: "Medicine",
    category: "Shady",
  },
  {
    word: "Meet girls",
    category: "Shady",
  },
  {
    word: "Meet me",
    category: "Shady",
  },
  {
    word: "Meet singles",
    category: "Shady",
  },
  {
    word: "Meet women",
    category: "Shady",
  },
  {
    word: "Member",
    category: "Shady",
  },
  {
    word: "Member stuff",
    category: "Shady",
  },
  {
    word: "Message contains disclaimer",
    category: "Shady",
  },
  {
    word: "Message from",
    category: "Shady",
  },
  {
    word: "Millionaire",
    category: "Shady",
  },
  {
    word: "Millions",
    category: "Shady",
  },
  {
    word: "MLM",
    category: "Shady",
  },
  {
    word: "Multi-level marketing",
    category: "Shady",
  },
  {
    word: "Name",
    category: "Shady",
  },
  {
    word: "Near you",
    category: "Shady",
  },
  {
    word: "Never before",
    category: "Shady",
  },
  {
    word: "New",
    category: "Shady",
  },
  {
    word: "New domain extensions",
    category: "Shady",
  },
  {
    word: "Nigerian",
    category: "Shady",
  },
  {
    word: "No age restrictions",
    category: "Shady",
  },
  {
    word: "No catch",
    category: "Shady",
  },
  {
    word: "No claim forms",
    category: "Shady",
  },
  {
    word: "No cost",
    category: "Shady",
  },
  {
    word: "No credit check",
    category: "Shady",
  },
  {
    word: "No credit experience",
    category: "Shady",
  },
  {
    word: "No deposit required",
    category: "Shady",
  },
  {
    word: "No disappointment",
    category: "Shady",
  },
  {
    word: "No experience",
    category: "Shady",
  },
  {
    word: "No fees",
    category: "Shady",
  },
  {
    word: "No gimmick",
    category: "Shady",
  },
  {
    word: "No hidden",
    category: "Shady",
  },
  {
    word: "No hidden costs",
    category: "Shady",
  },
  {
    word: "No hidden fees",
    category: "Shady",
  },
  {
    word: "No hidden сosts",
    category: "Shady",
  },
  {
    word: "No interest",
    category: "Shady",
  },
  {
    word: "No interests",
    category: "Shady",
  },
  {
    word: "No inventory",
    category: "Shady",
  },
  {
    word: "No investment",
    category: "Shady",
  },
  {
    word: "No investment required",
    category: "Shady",
  },
  {
    word: "No medical exams",
    category: "Shady",
  },
  {
    word: "No middleman",
    category: "Shady",
  },
  {
    word: "No obligation",
    category: "Shady",
  },
  {
    word: "No payment required",
    category: "Shady",
  },
  {
    word: "No purchase necessary",
    category: "Shady",
  },
  {
    word: "No questions asked",
    category: "Shady",
  },
  {
    word: "No selling",
    category: "Shady",
  },
  {
    word: "No strings attached",
    category: "Shady",
  },
  {
    word: "No-obligation",
    category: "Shady",
  },
  {
    word: "Nominated bank account",
    category: "Shady",
  },
  {
    word: "Not intended",
    category: "Shady",
  },
  {
    word: "Not junk",
    category: "Shady",
  },
  {
    word: "Not scam",
    category: "Shady",
  },
  {
    word: "Not spam",
    category: "Shady",
  },
  {
    word: "Notspam",
    category: "Shady",
  },
  {
    word: "Number 1",
    category: "Shady",
  },
  {
    word: "Obligation",
    category: "Shady",
  },
  {
    word: "Off",
    category: "Shady",
  },
  {
    word: "Off everything",
    category: "Shady",
  },
  {
    word: "Off shore",
    category: "Shady",
  },
  {
    word: "Offer extended",
    category: "Shady",
  },
  {
    word: "Offers",
    category: "Shady",
  },
  {
    word: "Offshore",
    category: "Shady",
  },
  {
    word: "One hundred percent",
    category: "Shady",
  },
  {
    word: "One-time",
    category: "Shady",
  },
  {
    word: "Online biz opportunity",
    category: "Shady",
  },
  {
    word: "Online degree",
    category: "Shady",
  },
  {
    word: "Online income",
    category: "Shady",
  },
  {
    word: "Online job",
    category: "Shady",
  },
  {
    word: "Open",
    category: "Shady",
  },
  {
    word: "Opportunity",
    category: "Shady",
  },
  {
    word: "Opt-in",
    category: "Shady",
  },
  {
    word: "Order",
    category: "Shady",
  },
  {
    word: "Order shipped by",
    category: "Shady",
  },
  {
    word: "Order status",
    category: "Shady",
  },
  {
    word: "Orders shipped by",
    category: "Shady",
  },
  {
    word: "Orders shipped by shopper",
    category: "Shady",
  },
  {
    word: "Outstanding value",
    category: "Shady",
  },
  {
    word: "Outstanding values",
    category: "Shady",
  },
  {
    word: "Password",
    category: "Shady",
  },
  {
    word: "Passwords",
    category: "Shady",
  },
  {
    word: "Pay your bills",
    category: "Shady",
  },
  {
    word: "Per day/per week/per year",
    category: "Shady",
  },
  {
    word: "Per month",
    category: "Shady",
  },
  {
    word: "Perfect",
    category: "Shady",
  },
  {
    word: "Performance",
    category: "Shady",
  },
  {
    word: "Phone",
    category: "Shady",
  },
  {
    word: "Please",
    category: "Shady",
  },
  {
    word: "Please open",
    category: "Shady",
  },
  {
    word: "Presently",
    category: "Shady",
  },
  {
    word: "Print form signature",
    category: "Shady",
  },
  {
    word: "Print from signature",
    category: "Shady",
  },
  {
    word: "Print out and fax",
    category: "Shady",
  },
  {
    word: "Priority mail",
    category: "Shady",
  },
  {
    word: "Privately owned funds",
    category: "Shady",
  },
  {
    word: "Prizes",
    category: "Shady",
  },
  {
    word: "Problem with shipping",
    category: "Shady",
  },
  {
    word: "Problem with your order",
    category: "Shady",
  },
  {
    word: "Produced and sent out",
    category: "Shady",
  },
  {
    word: "Profit",
    category: "Shady",
  },
  {
    word: "Promise you",
    category: "Shady",
  },
  {
    word: "Purchase",
    category: "Shady",
  },
  {
    word: "Pure Profits",
    category: "Shady",
  },
  {
    word: "Quotes",
    category: "Shady",
  },
  {
    word: "Rate",
    category: "Shady",
  },
  {
    word: "Real thing",
    category: "Shady",
  },
  {
    word: "Rebate",
    category: "Shady",
  },
  {
    word: "Reduce debt",
    category: "Shady",
  },
  {
    word: "Refinance home",
    category: "Shady",
  },
  {
    word: "Refinanced home",
    category: "Shady",
  },
  {
    word: "Refund",
    category: "Shady",
  },
  {
    word: "Regarding",
    category: "Shady",
  },
  {
    word: "Removal instructions",
    category: "Shady",
  },
  {
    word: "Removes",
    category: "Shady",
  },
  {
    word: "Removes wrinkles",
    category: "Shady",
  },
  {
    word: "Replica watches",
    category: "Shady",
  },
  {
    word: "Request",
    category: "Shady",
  },
  {
    word: "Request now",
    category: "Shady",
  },
  {
    word: "Request today",
    category: "Shady",
  },
  {
    word: "Requires initial investment",
    category: "Shady",
  },
  {
    word: "Requires investment",
    category: "Shady",
  },
  {
    word: "Reverses aging",
    category: "Shady",
  },
  {
    word: "Risk free",
    category: "Shady",
  },
  {
    word: "Rolex",
    category: "Shady",
  },
  {
    word: "Round the world",
    category: "Shady",
  },
  {
    word: "S 1618",
    category: "Shady",
  },
  {
    word: "Safeguard notice",
    category: "Shady",
  },
  {
    word: "Sale",
    category: "Shady",
  },
  {
    word: "Sales",
    category: "Shady",
  },
  {
    word: "Save",
    category: "Shady",
  },
  {
    word: "Save $, Save €",
    category: "Shady",
  },
  {
    word: "Save big",
    category: "Shady",
  },
  {
    word: "Save big month",
    category: "Shady",
  },
  {
    word: "Save money",
    category: "Shady",
  },
  {
    word: "Save now",
    category: "Shady",
  },
  {
    word: "Score with babes",
    category: "Shady",
  },
  {
    word: "Search engine optimisation",
    category: "Shady",
  },
  {
    word: "Section 301",
    category: "Shady",
  },
  {
    word: "See for yourself",
    category: "Shady",
  },
  {
    word: "Seen on",
    category: "Shady",
  },
  {
    word: "Serious",
    category: "Shady",
  },
  {
    word: "Serious case",
    category: "Shady",
  },
  {
    word: "Serious offer",
    category: "Shady",
  },
  {
    word: "Serious only",
    category: "Shady",
  },
  {
    word: "Sex",
    category: "Shady",
  },
  {
    word: "Shop now",
    category: "Shady",
  },
  {
    word: "Shopper",
    category: "Shady",
  },
  {
    word: "Shopping spree",
    category: "Shady",
  },
  {
    word: "Snoring",
    category: "Shady",
  },
  {
    word: "Social security number",
    category: "Shady",
  },
  {
    word: "Soon",
    category: "Shady",
  },
  {
    word: "Spam",
    category: "Shady",
  },
  {
    word: "Spam free",
    category: "Shady",
  },
  {
    word: "Special deal",
    category: "Shady",
  },
  {
    word: "Special discount",
    category: "Shady",
  },
  {
    word: "Special for you",
    category: "Shady",
  },
  {
    word: "Special offer",
    category: "Shady",
  },
  {
    word: "Stainless steel",
    category: "Shady",
  },
  {
    word: "Stock alert",
    category: "Shady",
  },
  {
    word: "Stock disclaimer statement",
    category: "Shady",
  },
  {
    word: "Stock pick",
    category: "Shady",
  },
  {
    word: "Stocks/stock pick/stock alert",
    category: "Shady",
  },
  {
    word: "Stop calling me",
    category: "Shady",
  },
  {
    word: "Stop emailing me",
    category: "Shady",
  },
  {
    word: "Stop further distribution",
    category: "Shady",
  },
  {
    word: "Stop snoring",
    category: "Shady",
  },
  {
    word: "Strong buy",
    category: "Shady",
  },
  {
    word: "Stuff on sale",
    category: "Shady",
  },
  {
    word: "Subject to",
    category: "Shady",
  },
  {
    word: "Subject to cash",
    category: "Shady",
  },
  {
    word: "Subscribe",
    category: "Shady",
  },
  {
    word: "Subscribe for free",
    category: "Shady",
  },
  {
    word: "Subscribe now",
    category: "Shady",
  },
  {
    word: "Super promo",
    category: "Shady",
  },
  {
    word: "Supplies",
    category: "Shady",
  },
  {
    word: "Tack action now",
    category: "Shady",
  },
  {
    word: "Talks about hidden charges",
    category: "Shady",
  },
  {
    word: "Talks about prizes",
    category: "Shady",
  },
  {
    word: "Tells you it’s an ad",
    category: "Shady",
  },
  {
    word: "Terms",
    category: "Shady",
  },
  {
    word: "The best rates",
    category: "Shady",
  },
  {
    word: "The email asks for a credit card",
    category: "Shady",
  },
  {
    word: "The following form",
    category: "Shady",
  },
  {
    word: "They make a claim or claims that they're in accordance with spam law",
    category: "Shady",
  },
  {
    word: "They try to keep your money no refund",
    category: "Shady",
  },
  {
    word: "They’re just giving it away",
    category: "Shady",
  },
  {
    word: "This isn't junk",
    category: "Shady",
  },
  {
    word: "This isn't spam",
    category: "Shady",
  },
  {
    word: "This isn’t a scam",
    category: "Shady",
  },
  {
    word: "This isn’t junk",
    category: "Shady",
  },
  {
    word: "This isn’t spam",
    category: "Shady",
  },
  {
    word: "Timeshare",
    category: "Shady",
  },
  {
    word: "Timeshare offers",
    category: "Shady",
  },
  {
    word: "Traffic",
    category: "Shady",
  },
  {
    word: "Trial unlimited",
    category: "Shady",
  },
  {
    word: "U.S. dollars",
    category: "Shady",
  },
  {
    word: "Undisclosed",
    category: "Shady",
  },
  {
    word: "Undisclosed recipient",
    category: "Shady",
  },
  {
    word: "University diplomas",
    category: "Shady",
  },
  {
    word: "Unsecured credit",
    category: "Shady",
  },
  {
    word: "Unsecured debt",
    category: "Shady",
  },
  {
    word: "Unsolicited",
    category: "Shady",
  },
  {
    word: "Unsubscribe",
    category: "Shady",
  },
  {
    word: "Urgent response",
    category: "Shady",
  },
  {
    word: "US dollars / Euros",
    category: "Shady",
  },
  {
    word: "Vacation",
    category: "Shady",
  },
  {
    word: "Vacation offers",
    category: "Shady",
  },
  {
    word: "Valium",
    category: "Shady",
  },
  {
    word: "Viagra",
    category: "Shady",
  },
  {
    word: "Vicodin",
    category: "Shady",
  },
  {
    word: "VIP",
    category: "Shady",
  },
  {
    word: "Visit our website",
    category: "Shady",
  },
  {
    word: "Wants credit card",
    category: "Shady",
  },
  {
    word: "Warranty expired",
    category: "Shady",
  },
  {
    word: "We hate spam",
    category: "Shady",
  },
  {
    word: "We honor all",
    category: "Shady",
  },
  {
    word: "Website visitors",
    category: "Shady",
  },
  {
    word: "Weekend getaway",
    category: "Shady",
  },
  {
    word: "Weight loss",
    category: "Shady",
  },
  {
    word: "What’s keeping you?",
    category: "Shady",
  },
  {
    word: "While available",
    category: "Shady",
  },
  {
    word: "While in stock",
    category: "Shady",
  },
  {
    word: "While stocks last",
    category: "Shady",
  },
  {
    word: "While you sleep",
    category: "Shady",
  },
  {
    word: "Who really wins?",
    category: "Shady",
  },
  {
    word: "Win",
    category: "Shady",
  },
  {
    word: "Winner",
    category: "Shady",
  },
  {
    word: "Winning",
    category: "Shady",
  },
  {
    word: "Won",
    category: "Shady",
  },
  {
    word: "Xanax",
    category: "Shady",
  },
  {
    word: "XXX",
    category: "Shady",
  },
  {
    word: "You have been chosen",
    category: "Shady",
  },
  {
    word: "You have been selected",
    category: "Shady",
  },
  {
    word: "Your chance",
    category: "Shady",
  },
  {
    word: "Your status",
    category: "Shady",
  },
  {
    word: "Zero chance",
    category: "Shady",
  },
  {
    word: "Zero percent",
    category: "Shady",
  },
  {
    word: "Zero risk",
    category: "Shady",
  },
  {
    word: "#1",
    category: "Overpromise",
  },
  {
    word: "%",
    category: "Overpromise",
  },
  {
    word: "% free",
    category: "Overpromise",
  },
  {
    word: "% Satisfied",
    category: "Overpromise",
  },
  {
    word: "0%",
    category: "Overpromise",
  },
  {
    word: "0% risk",
    category: "Overpromise",
  },
  {
    word: "100%",
    category: "Overpromise",
  },
  {
    word: "100% free",
    category: "Overpromise",
  },
  {
    word: "100% more",
    category: "Overpromise",
  },
  {
    word: "100% off",
    category: "Overpromise",
  },
  {
    word: "100% satisfied",
    category: "Overpromise",
  },
  {
    word: "99.90%",
    category: "Overpromise",
  },
  {
    word: "99%",
    category: "Overpromise",
  },
  {
    word: "Access for free",
    category: "Overpromise",
  },
  {
    word: "Additional income",
    category: "Overpromise",
  },
  {
    word: "Amazed",
    category: "Overpromise",
  },
  {
    word: "Amazing",
    category: "Overpromise",
  },
  {
    word: "Amazing offer",
    category: "Overpromise",
  },
  {
    word: "Amazing stuff",
    category: "Overpromise",
  },
  {
    word: "Be amazed",
    category: "Overpromise",
  },
  {
    word: "Be surprised",
    category: "Overpromise",
  },
  {
    word: "Be your own boss",
    category: "Overpromise",
  },
  {
    word: "Believe me",
    category: "Overpromise",
  },
  {
    word: "Best bargain",
    category: "Overpromise",
  },
  {
    word: "Best deal",
    category: "Overpromise",
  },
  {
    word: "Best offer",
    category: "Overpromise",
  },
  {
    word: "Best price",
    category: "Overpromise",
  },
  {
    word: "Best rates",
    category: "Overpromise",
  },
  {
    word: "Big bucks",
    category: "Overpromise",
  },
  {
    word: "Bonus",
    category: "Overpromise",
  },
  {
    word: "Boss",
    category: "Overpromise",
  },
  {
    word: "Can’t live without",
    category: "Overpromise",
  },
  {
    word: "Cancel",
    category: "Overpromise",
  },
  {
    word: "Consolidate debt",
    category: "Overpromise",
  },
  {
    word: "Double your cash",
    category: "Overpromise",
  },
  {
    word: "Double your income",
    category: "Overpromise",
  },
  {
    word: "Drastically reduced",
    category: "Overpromise",
  },
  {
    word: "Earn extra cash",
    category: "Overpromise",
  },
  {
    word: "Earn money",
    category: "Overpromise",
  },
  {
    word: "Eliminate bad credit",
    category: "Overpromise",
  },
  {
    word: "Expect to earn",
    category: "Overpromise",
  },
  {
    word: "Extra",
    category: "Overpromise",
  },
  {
    word: "Extra cash",
    category: "Overpromise",
  },
  {
    word: "Extra income",
    category: "Overpromise",
  },
  {
    word: "Fantastic",
    category: "Overpromise",
  },
  {
    word: "Fantastic deal",
    category: "Overpromise",
  },
  {
    word: "Fantastic offer",
    category: "Overpromise",
  },
  {
    word: "FAST",
    category: "Overpromise",
  },
  {
    word: "Fast cash",
    category: "Overpromise",
  },
  {
    word: "Financial freedom",
    category: "Overpromise",
  },
  {
    word: "Free access",
    category: "Overpromise",
  },
  {
    word: "Free consultation",
    category: "Overpromise",
  },
  {
    word: "Free gift",
    category: "Overpromise",
  },
  {
    word: "Free hosting",
    category: "Overpromise",
  },
  {
    word: "Free info",
    category: "Overpromise",
  },
  {
    word: "Free investment",
    category: "Overpromise",
  },
  {
    word: "Free membership",
    category: "Overpromise",
  },
  {
    word: "Free money",
    category: "Overpromise",
  },
  {
    word: "Free preview",
    category: "Overpromise",
  },
  {
    word: "Free quote",
    category: "Overpromise",
  },
  {
    word: "Free trial",
    category: "Overpromise",
  },
  {
    word: "Full refund",
    category: "Overpromise",
  },
  {
    word: "Get out of debt",
    category: "Overpromise",
  },
  {
    word: "Giveaway",
    category: "Overpromise",
  },
  {
    word: "Guaranteed",
    category: "Overpromise",
  },
  {
    word: "Increase sales",
    category: "Overpromise",
  },
  {
    word: "Increase traffic",
    category: "Overpromise",
  },
  {
    word: "Incredible deal",
    category: "Overpromise",
  },
  {
    word: "Join billions",
    category: "Overpromise",
  },
  {
    word: "Join millions",
    category: "Overpromise",
  },
  {
    word: "Join millions of Americans",
    category: "Overpromise",
  },
  {
    word: "Join thousands",
    category: "Overpromise",
  },
  {
    word: "Lower rates",
    category: "Overpromise",
  },
  {
    word: "Lowest price",
    category: "Overpromise",
  },
  {
    word: "Make money",
    category: "Overpromise",
  },
  {
    word: "Million",
    category: "Overpromise",
  },
  {
    word: "Million dollars",
    category: "Overpromise",
  },
  {
    word: "Miracle",
    category: "Overpromise",
  },
  {
    word: "Money back",
    category: "Overpromise",
  },
  {
    word: "Month trial offer",
    category: "Overpromise",
  },
  {
    word: "More Internet Traffic",
    category: "Overpromise",
  },
  {
    word: "Number one",
    category: "Overpromise",
  },
  {
    word: "Once in a lifetime",
    category: "Overpromise",
  },
  {
    word: "One hundred percent guaranteed",
    category: "Overpromise",
  },
  {
    word: "One time",
    category: "Overpromise",
  },
  {
    word: "Pennies a day",
    category: "Overpromise",
  },
  {
    word: "Potential earnings",
    category: "Overpromise",
  },
  {
    word: "Prize",
    category: "Overpromise",
  },
  {
    word: "Promise",
    category: "Overpromise",
  },
  {
    word: "Pure profit",
    category: "Overpromise",
  },
  {
    word: "Risk-free",
    category: "Overpromise",
  },
  {
    word: "Satisfaction guaranteed",
    category: "Overpromise",
  },
  {
    word: "Save big money",
    category: "Overpromise",
  },
  {
    word: "Save up to",
    category: "Overpromise",
  },
  {
    word: "Special promotion",
    category: "Overpromise",
  },
  {
    word: "The best",
    category: "Overpromise",
  },
  {
    word: "Thousands",
    category: "Overpromise",
  },
  {
    word: "Unbeatable offer",
    category: "Overpromise",
  },
  {
    word: "Unbelievable",
    category: "Overpromise",
  },
  {
    word: "Unlimited",
    category: "Overpromise",
  },
  {
    word: "Unlimited trial",
    category: "Overpromise",
  },
  {
    word: "Wonderful",
    category: "Overpromise",
  },
  {
    word: "You will not believe your eyes",
    category: "Overpromise",
  },
  {
    word: "$$$",
    category: "Money",
  },
  {
    word: "€€€",
    category: "Money",
  },
  {
    word: "£££",
    category: "Money",
  },
  {
    word: "50% off",
    category: "Money",
  },
  {
    word: "A few bob",
    category: "Money",
  },
  {
    word: "Accept cash cards",
    category: "Money",
  },
  {
    word: "Accept credit cards",
    category: "Money",
  },
  {
    word: "Affordable",
    category: "Money",
  },
  {
    word: "Affordable deal",
    category: "Money",
  },
  {
    word: "Avoid bankruptcy",
    category: "Money",
  },
  {
    word: "Bad credit",
    category: "Money",
  },
  {
    word: "Bank",
    category: "Money",
  },
  {
    word: "Bankruptcy",
    category: "Money",
  },
  {
    word: "Bargain",
    category: "Money",
  },
  {
    word: "Billing",
    category: "Money",
  },
  {
    word: "Billing address",
    category: "Money",
  },
  {
    word: "Billion",
    category: "Money",
  },
  {
    word: "Billion dollars",
    category: "Money",
  },
  {
    word: "Billionaire",
    category: "Money",
  },
  {
    word: "Card accepted",
    category: "Money",
  },
  {
    word: "Cards accepted",
    category: "Money",
  },
  {
    word: "Cash",
    category: "Money",
  },
  {
    word: "Cash bonus",
    category: "Money",
  },
  {
    word: "Cash out",
    category: "Money",
  },
  {
    word: "Cash-out",
    category: "Money",
  },
  {
    word: "Cashcashcash",
    category: "Money",
  },
  {
    word: "Casino",
    category: "Money",
  },
  {
    word: "Cents on the dollar",
    category: "Money",
  },
  {
    word: "Check",
    category: "Money",
  },
  {
    word: "Check or money order",
    category: "Money",
  },
  {
    word: "Claim your discount",
    category: "Money",
  },
  {
    word: "Cost",
    category: "Money",
  },
  {
    word: "Costs",
    category: "Money",
  },
  {
    word: "Credit",
    category: "Money",
  },
  {
    word: "Credit bureaus",
    category: "Money",
  },
  {
    word: "Credit card",
    category: "Money",
  },
  {
    word: "Credit card offers",
    category: "Money",
  },
  {
    word: "Credit or Debit",
    category: "Money",
  },
  {
    word: "Deal",
    category: "Money",
  },
  {
    word: "Debt",
    category: "Money",
  },
  {
    word: "Discount",
    category: "Money",
  },
  {
    word: "Dollars",
    category: "Money",
  },
  {
    word: "Double your",
    category: "Money",
  },
  {
    word: "Double your wealth",
    category: "Money",
  },
  {
    word: "Earn",
    category: "Money",
  },
  {
    word: "Earn $",
    category: "Money",
  },
  {
    word: "Earn cash",
    category: "Money",
  },
  {
    word: "Earn extra income",
    category: "Money",
  },
  {
    word: "Earn from home",
    category: "Money",
  },
  {
    word: "Earn monthly",
    category: "Money",
  },
  {
    word: "Earn per month",
    category: "Money",
  },
  {
    word: "Earn per week",
    category: "Money",
  },
  {
    word: "Earn your degree",
    category: "Money",
  },
  {
    word: "Easy income",
    category: "Money",
  },
  {
    word: "Easy terms",
    category: "Money",
  },
  {
    word: "F r e e",
    category: "Money",
  },
  {
    word: "For free",
    category: "Money",
  },
  {
    word: "For just $",
    category: "Money",
  },
  {
    word: "For just $ (amount)",
    category: "Money",
  },
  {
    word: "For just $xxx",
    category: "Money",
  },
  {
    word: "Get Money",
    category: "Money",
  },
  {
    word: "Get your money",
    category: "Money",
  },
  {
    word: "Hidden assets",
    category: "Money",
  },
  {
    word: "Huge discount",
    category: "Money",
  },
  {
    word: "Income",
    category: "Money",
  },
  {
    word: "Income from home",
    category: "Money",
  },
  {
    word: "Increase revenue",
    category: "Money",
  },
  {
    word: "Increase sales/traffic",
    category: "Money",
  },
  {
    word: "Increase your chances",
    category: "Money",
  },
  {
    word: "Initial investment",
    category: "Money",
  },
  {
    word: "Instant earnings",
    category: "Money",
  },
  {
    word: "Instant income",
    category: "Money",
  },
  {
    word: "Insurance",
    category: "Money",
  },
  {
    word: "Investment",
    category: "Money",
  },
  {
    word: "Investment advice",
    category: "Money",
  },
  {
    word: "Lifetime",
    category: "Money",
  },
  {
    word: "Loans",
    category: "Money",
  },
  {
    word: "Make $",
    category: "Money",
  },
  {
    word: "Money",
    category: "Money",
  },
  {
    word: "Money making",
    category: "Money",
  },
  {
    word: "Money-back guarantee",
    category: "Money",
  },
  {
    word: "Money-making",
    category: "Money",
  },
  {
    word: "Monthly payment",
    category: "Money",
  },
  {
    word: "Mortgage",
    category: "Money",
  },
  {
    word: "Mortgage rates",
    category: "Money",
  },
  {
    word: "Offer",
    category: "Money",
  },
  {
    word: "One hundred percent free",
    category: "Money",
  },
  {
    word: "Only $",
    category: "Money",
  },
  {
    word: "Price",
    category: "Money",
  },
  {
    word: "Price protection",
    category: "Money",
  },
  {
    word: "Prices",
    category: "Money",
  },
  {
    word: "Profits",
    category: "Money",
  },
  {
    word: "Quote",
    category: "Money",
  },
  {
    word: "Rates",
    category: "Money",
  },
  {
    word: "Refinance",
    category: "Money",
  },
  {
    word: "Save $",
    category: "Money",
  },
  {
    word: "Serious cash",
    category: "Money",
  },
  {
    word: "Subject to credit",
    category: "Money",
  },
  {
    word: "US dollars",
    category: "Money",
  },
  {
    word: "Why pay more?",
    category: "Money",
  },
  {
    word: "Your income",
    category: "Money",
  },
  {
    word: "Acceptance",
    category: "Unnatural",
  },
  {
    word: "Accordingly",
    category: "Unnatural",
  },
  {
    word: "Account-based marketing (ABM)",
    category: "Unnatural",
  },
  {
    word: "Accounts",
    category: "Unnatural",
  },
  {
    word: "Addresses",
    category: "Unnatural",
  },
  {
    word: "Addresses on CD",
    category: "Unnatural",
  },
  {
    word: "Beverage",
    category: "Unnatural",
  },
  {
    word: "Confidentiality on all orders",
    category: "Unnatural",
  },
  {
    word: "Confidentially on all orders",
    category: "Unnatural",
  },
  {
    word: "Content marketing",
    category: "Unnatural",
  },
  {
    word: "Dear [email address]",
    category: "Unnatural",
  },
  {
    word: "Dear [email/friend/somebody]",
    category: "Unnatural",
  },
  {
    word: "Dear [first name]",
    category: "Unnatural",
  },
  {
    word: "Dear [wrong name]",
    category: "Unnatural",
  },
  {
    word: "Digital marketing",
    category: "Unnatural",
  },
  {
    word: "Dormant",
    category: "Unnatural",
  },
  {
    word: "Email extractor",
    category: "Unnatural",
  },
  {
    word: "Email harvest",
    category: "Unnatural",
  },
  {
    word: "Email marketing",
    category: "Unnatural",
  },
  {
    word: "Extract email",
    category: "Unnatural",
  },
  {
    word: "Form",
    category: "Unnatural",
  },
  {
    word: "Freedom",
    category: "Unnatural",
  },
  {
    word: "Friend",
    category: "Unnatural",
  },
  {
    word: "Here",
    category: "Unnatural",
  },
  {
    word: "Hidden",
    category: "Unnatural",
  },
  {
    word: "Home",
    category: "Unnatural",
  },
  {
    word: "Home based",
    category: "Unnatural",
  },
  {
    word: "Home employment",
    category: "Unnatural",
  },
  {
    word: "Home-based",
    category: "Unnatural",
  },
  {
    word: "Home-based business",
    category: "Unnatural",
  },
  {
    word: "Homebased business",
    category: "Unnatural",
  },
  {
    word: "If you no longer wish to receive",
    category: "Unnatural",
  },
  {
    word: "Important information regarding",
    category: "Unnatural",
  },
  {
    word: "In accordance with laws",
    category: "Unnatural",
  },
  {
    word: "Increase your sales",
    category: "Unnatural",
  },
  {
    word: "Internet market",
    category: "Unnatural",
  },
  {
    word: "Leave",
    category: "Unnatural",
  },
  {
    word: "Lose",
    category: "Unnatural",
  },
  {
    word: "Maintained",
    category: "Unnatural",
  },
  {
    word: "Marketing",
    category: "Unnatural",
  },
  {
    word: "Marketing solution",
    category: "Unnatural",
  },
  {
    word: "Marketing solutions",
    category: "Unnatural",
  },
  {
    word: "Medium",
    category: "Unnatural",
  },
  {
    word: "Message contains",
    category: "Unnatural",
  },
  {
    word: "Multi level marketing",
    category: "Unnatural",
  },
  {
    word: "Never",
    category: "Unnatural",
  },
  {
    word: "One time mailing",
    category: "Unnatural",
  },
  {
    word: "Online marketing",
    category: "Unnatural",
  },
  {
    word: "Online pharmacy",
    category: "Unnatural",
  },
  {
    word: "Opt in",
    category: "Unnatural",
  },
  {
    word: "Per day",
    category: "Unnatural",
  },
  {
    word: "Per week",
    category: "Unnatural",
  },
  {
    word: "Pre-approved",
    category: "Unnatural",
  },
  {
    word: "Problem",
    category: "Unnatural",
  },
  {
    word: "Removal",
    category: "Unnatural",
  },
  {
    word: "Remove",
    category: "Unnatural",
  },
  {
    word: "Reserves the right",
    category: "Unnatural",
  },
  {
    word: "Reverses",
    category: "Unnatural",
  },
  {
    word: "Sample",
    category: "Unnatural",
  },
  {
    word: "Satisfaction",
    category: "Unnatural",
  },
  {
    word: "Score",
    category: "Unnatural",
  },
  {
    word: "Search engine",
    category: "Unnatural",
  },
  {
    word: "Search engine listings",
    category: "Unnatural",
  },
  {
    word: "Search engines",
    category: "Unnatural",
  },
  {
    word: "Sent in compliance",
    category: "Unnatural",
  },
  {
    word: "Solution",
    category: "Unnatural",
  },
  {
    word: "Stop",
    category: "Unnatural",
  },
  {
    word: "Success",
    category: "Unnatural",
  },
  {
    word: "Teen",
    category: "Unnatural",
  },
  {
    word: "Terms and conditions",
    category: "Unnatural",
  },
  {
    word: "Warranty",
    category: "Unnatural",
  },
  {
    word: "Web traffic",
    category: "Unnatural",
  },
  {
    word: "Wife",
    category: "Unnatural",
  },
  {
    word: "Work at home",
    category: "Unnatural",
  },
  {
    word: "Work from home",
    category: "Unnatural",
  },
];

const spamArray = [
  { highlight: /\baccess\b/gi, keyword: "Access", category: "urgency" },
  { highlight: /\baccess now\b/gi, keyword: "Access now", category: "urgency" },
  { highlight: /\bact\b/gi, keyword: "Act", category: "urgency" },
  {
    highlight: /\bact immediately\b/gi,
    keyword: "Act immediately",
    category: "urgency",
  },
  { highlight: /\bact now\b/gi, keyword: "Act now", category: "urgency" },
  { highlight: /\bact now!\b/gi, keyword: "Act now!", category: "urgency" },
  { highlight: /\baction\b/gi, keyword: "Action", category: "urgency" },
  {
    highlight: /\baction required\b/gi,
    keyword: "Action required",
    category: "urgency",
  },
  { highlight: /\bapply here\b/gi, keyword: "Apply here", category: "urgency" },
  { highlight: /\bapply now\b/gi, keyword: "Apply now", category: "urgency" },
  { highlight: /\bapply now!\b/gi, keyword: "Apply now!", category: "urgency" },
  {
    highlight: /\bapply online\b/gi,
    keyword: "Apply online",
    category: "urgency",
  },
  // { highlight: /\basap\b/gi, keyword: "ASAP", category: "urgency" },
  {
    highlight: /\bbecome a member\b/gi,
    keyword: "Become a member",
    category: "urgency",
  },
  {
    highlight: /\bbefore it's too late\b/gi,
    keyword: "Before it's too late",
    category: "urgency",
  },
  {
    highlight: /\bbeing a member\b/gi,
    keyword: "Being a member",
    category: "urgency",
  },
  { highlight: /\bbuy\b/gi, keyword: "Buy", category: "urgency" },
  { highlight: /\bbuy direct\b/gi, keyword: "Buy direct", category: "urgency" },
  { highlight: /\bbuy now\b/gi, keyword: "Buy now", category: "urgency" },
  { highlight: /\bbuy today\b/gi, keyword: "Buy today", category: "urgency" },
  { highlight: /\bcall\b/gi, keyword: "Call", category: "urgency" },
  { highlight: /\bcall free\b/gi, keyword: "Call free", category: "urgency" },
  {
    highlight: /\bcall (free|now)\b/gi,
    keyword: "Call free/now",
    category: "urgency",
  },
  { highlight: /\bcall me\b/gi, keyword: "Call me", category: "urgency" },
  { highlight: /\bcall now\b/gi, keyword: "Call now", category: "urgency" },
  { highlight: /\bcall now!\b/gi, keyword: "Call now!", category: "urgency" },
  {
    highlight: /\bcan we have a minute of your time\?\b/gi,
    keyword: "Can we have a minute of your time?",
    category: "urgency",
  },
  { highlight: /\bcancel now\b/gi, keyword: "Cancel now", category: "urgency" },
  {
    highlight: /\bcancellation required\b/gi,
    keyword: "Cancellation required",
    category: "urgency",
  },
  { highlight: /\bclaim now\b/gi, keyword: "Claim now", category: "urgency" },
  { highlight: /\bclick\b/gi, keyword: "Click", category: "urgency" },
  {
    highlight: /\bclick below\b/gi,
    keyword: "Click below",
    category: "urgency",
  },
  { highlight: /\bclick here\b/gi, keyword: "Click here", category: "urgency" },
  {
    highlight: /\bclick me to download\b/gi,
    keyword: "Click me to download",
    category: "urgency",
  },
  { highlight: /\bclick now\b/gi, keyword: "Click now", category: "urgency" },
  {
    highlight: /\bclick this link\b/gi,
    keyword: "Click this link",
    category: "urgency",
  },
  {
    highlight: /\bclick to get\b/gi,
    keyword: "Click to get",
    category: "urgency",
  },
  {
    highlight: /\bclick to remove\b/gi,
    keyword: "Click to remove",
    category: "urgency",
  },
  {
    highlight: /\bcontact us immediately\b/gi,
    keyword: "Contact us immediately",
    category: "urgency",
  },
  {
    highlight: /\bdeal ending soon\b/gi,
    keyword: "Deal ending soon",
    category: "urgency",
  },
  { highlight: /\bdo it now\b/gi, keyword: "Do it now", category: "urgency" },
  {
    highlight: /\bdo it today\b/gi,
    keyword: "Do it today",
    category: "urgency",
  },
  {
    highlight: /\bdon't delete\b/gi,
    keyword: "Don't delete",
    category: "urgency",
  },
  {
    highlight: /\bdon't hesitate\b/gi,
    keyword: "Don't hesitate",
    category: "urgency",
  },
  {
    highlight: /\bdon't waste time\b/gi,
    keyword: "Don't waste time",
    category: "urgency",
  },
  {
    highlight: /\bdon’t delete\b/gi,
    keyword: "Don’t delete",
    category: "urgency",
  },
  {
    highlight: /\bexclusive deal\b/gi,
    keyword: "Exclusive deal",
    category: "urgency",
  },
  { highlight: /\bexpire\b/gi, keyword: "Expire", category: "urgency" },
  {
    highlight: /\bexpires today\b/gi,
    keyword: "Expires today",
    category: "urgency",
  },
  { highlight: /\bfinal call\b/gi, keyword: "Final call", category: "urgency" },
  {
    highlight: /\bfor instant access\b/gi,
    keyword: "For instant access",
    category: "urgency",
  },
  { highlight: /\bfor only\b/gi, keyword: "For Only", category: "urgency" },
  {
    highlight: /\bspecial for you\b/gi,
    keyword: "Special for you",
    category: "shady",
  },
  { highlight: /\bfor you\b/gi, keyword: "For you", category: "urgency" },
  {
    highlight: /\bfriday before [holiday]\b/gi,
    keyword: "Friday before [holiday]",
    category: "urgency",
  },
  {
    highlight: /\bget it away\b/gi,
    keyword: "Get it away",
    category: "urgency",
  },
  { highlight: /\bget it now\b/gi, keyword: "Get it now", category: "urgency" },
  { highlight: /\bget now\b/gi, keyword: "Get now", category: "urgency" },
  { highlight: /\bget paid\b/gi, keyword: "Get paid", category: "urgency" },
  {
    highlight: /\bget started\b/gi,
    keyword: "Get started",
    category: "urgency",
  },
  {
    highlight: /\bget started now\b/gi,
    keyword: "Get started now",
    category: "urgency",
  },
  {
    highlight: /\bgreat offer\b/gi,
    keyword: "Great offer",
    category: "urgency",
  },
  { highlight: /\bhurry up\b/gi, keyword: "Hurry up", category: "urgency" },
  {
    highlight: /\bimmediately\b/gi,
    keyword: "Immediately",
    category: "urgency",
  },
  {
    highlight: /\binfo you requested\b/gi,
    keyword: "Info you requested",
    category: "urgency",
  },
  {
    highlight: /\binformation you requested\b/gi,
    keyword: "Information you requested",
    category: "urgency",
  },
  { highlight: /\binstant\b/gi, keyword: "Instant", category: "urgency" },
  {
    highlight: /\blimited time offer\b/gi,
    keyword: "Limited time offer",
    category: "shady",
  },
  {
    highlight: /\blimited time only\b/gi,
    keyword: "Limited time only",
    category: "shady",
  },
  {
    highlight: /\blimited time\b/gi,
    keyword: "Limited time",
    category: "urgency",
  },
  {
    highlight: /\bfor new customers only\b/gi,
    keyword: "For new customers only",
    category: "shady",
  },
  {
    highlight: /\bnew customers only\b/gi,
    keyword: "New customers only",
    category: "urgency",
  },
  { highlight: /\bnow\b/gi, keyword: "Now", category: "urgency" },
  { highlight: /\bnow only\b/gi, keyword: "Now only", category: "urgency" },
  {
    highlight: /\boffer expires\b/gi,
    keyword: "Offer expires",
    category: "urgency",
  },
  {
    highlight: /\bonce in lifetime\b/gi,
    keyword: "Once in lifetime",
    category: "urgency",
  },
  { highlight: /\bonly\b/gi, keyword: "Only", category: "urgency" },
  { highlight: /\border now\b/gi, keyword: "Order now", category: "urgency" },
  {
    highlight: /\border today\b/gi,
    keyword: "Order today",
    category: "urgency",
  },
  {
    highlight: /\bplease read\b/gi,
    keyword: "Please read",
    category: "urgency",
  },
  {
    highlight: /\bpurchase now\b/gi,
    keyword: "Purchase now",
    category: "urgency",
  },
  {
    highlight: /\bsign up free\b/gi,
    keyword: "Sign up free",
    category: "urgency",
  },
  {
    highlight: /\bsign up free today\b/gi,
    keyword: "Sign up free today",
    category: "urgency",
  },
  {
    highlight: /\bsupplies are limited\b/gi,
    keyword: "Supplies are limited",
    category: "urgency",
  },
  {
    highlight: /\btake action\b/gi,
    keyword: "Take action",
    category: "urgency",
  },
  {
    highlight: /\btake action now\b/gi,
    keyword: "Take action now",
    category: "urgency",
  },
  {
    highlight: /\bthis won’t last\b/gi,
    keyword: "This won’t last",
    category: "urgency",
  },
  {
    highlight: /\btime limited\b/gi,
    keyword: "Time limited",
    category: "urgency",
  },
  { highlight: /\btoday\b/gi, keyword: "Today", category: "urgency" },
  { highlight: /\btop urgent\b/gi, keyword: "Top urgent", category: "urgency" },
  { highlight: /\btrial\b/gi, keyword: "Trial", category: "urgency" },
  { highlight: /\burgent\b/gi, keyword: "Urgent", category: "urgency" },
  {
    highlight: /\bwhat are you waiting for\?\b/gi,
    keyword: "What are you waiting for?",
    category: "urgency",
  },
  {
    highlight: /\bwhile supplies last\b/gi,
    keyword: "While supplies last",
    category: "urgency",
  },
  {
    highlight: /\byou are a winner\b/gi,
    keyword: "You are a winner",
    category: "urgency",
  },
  { highlight: /\b0 down\b/gi, keyword: "0 down", category: "shady" },
  { highlight: /\ball\b/gi, keyword: "All", category: "shady" },
  { highlight: /\ball natural\b/gi, keyword: "All natural", category: "shady" },
  {
    highlight: /\ball (natural|new)\b/gi,
    keyword: "All natural/new",
    category: "shady",
  },
  { highlight: /\ball new\b/gi, keyword: "All new", category: "shady" },
  { highlight: /\ball-natural\b/gi, keyword: "All-natural", category: "shady" },
  { highlight: /\ball-new\b/gi, keyword: "All-new", category: "shady" },
  { highlight: /\ballowance\b/gi, keyword: "Allowance", category: "shady" },
  {
    highlight: /\bas seen on oprah\b/gi,
    keyword: "As seen on Oprah",
    category: "shady",
  },
  { highlight: /\bas seen on\b/gi, keyword: "As seen on", category: "shady" },
  { highlight: /\bat no cost\b/gi, keyword: "At no cost", category: "shady" },
  {
    highlight: /\bauto email removal\b/gi,
    keyword: "Auto email removal",
    category: "shady",
  },
  {
    highlight: /\bavoice bankruptcy\b/gi,
    keyword: "Avoice bankruptcy",
    category: "shady",
  },
  { highlight: /\bavoid\b/gi, keyword: "Avoid", category: "shady" },
  {
    highlight: /\bbeneficial offer\b/gi,
    keyword: "Beneficial offer",
    category: "shady",
  },
  { highlight: /\bbeneficiary\b/gi, keyword: "Beneficiary", category: "shady" },
  { highlight: /\bbill 1618\b/gi, keyword: "Bill 1618", category: "shady" },
  {
    highlight: /\bbrand new pager\b/gi,
    keyword: "Brand new pager",
    category: "shady",
  },
  { highlight: /\bbulk email\b/gi, keyword: "Bulk email", category: "shady" },
  {
    highlight: /\bbuying judgements\b/gi,
    keyword: "Buying judgements",
    category: "shady",
  },
  {
    highlight: /\bbuying judgments\b/gi,
    keyword: "Buying judgments",
    category: "shady",
  },
  {
    highlight: /\bcable converter\b/gi,
    keyword: "Cable converter",
    category: "shady",
  },
  {
    highlight: /\bcalling creditors\b/gi,
    keyword: "Calling creditors",
    category: "shady",
  },
  {
    highlight: /\bcan you help us\?\b/gi,
    keyword: "Can you help us?",
    category: "shady",
  },
  {
    highlight: /\bcancel at any time\b/gi,
    keyword: "Cancel at any time",
    category: "shady",
  },
  {
    highlight: /\bcannot be combined\b/gi,
    keyword: "Cannot be combined",
    category: "shady",
  },
  { highlight: /\bcelebrity\b/gi, keyword: "Celebrity", category: "shady" },
  {
    highlight: /\bcell phone cancer scam\b/gi,
    keyword: "Cell phone cancer scam",
    category: "shady",
  },
  { highlight: /\bcertified\b/gi, keyword: "Certified", category: "shady" },
  { highlight: /\bchance\b/gi, keyword: "Chance", category: "shady" },
  { highlight: /\bcheap\b/gi, keyword: "Cheap", category: "shady" },
  { highlight: /\bcheap meds\b/gi, keyword: "Cheap meds", category: "shady" },
  { highlight: /\bcialis\b/gi, keyword: "Cialis", category: "shady" },
  { highlight: /\bclaims\b/gi, keyword: "Claims", category: "shady" },
  {
    highlight: /\bclaims not to be selling anything\b/gi,
    keyword: "Claims not to be selling anything",
    category: "shady",
  },
  {
    highlight: /\bclaims to be in accordance with some spam law\b/gi,
    keyword: "Claims to be in accordance with some spam law",
    category: "shady",
  },
  {
    highlight: /\bclaims to be legal\b/gi,
    keyword: "Claims to be legal",
    category: "shady",
  },
  { highlight: /\bclearance\b/gi, keyword: "Clearance", category: "shady" },
  { highlight: /\bcollect\b/gi, keyword: "Collect", category: "shady" },
  {
    highlight: /\bcollect child support\b/gi,
    keyword: "Collect child support",
    category: "shady",
  },
  { highlight: /\bcompare\b/gi, keyword: "Compare", category: "shady" },
  { highlight: /\bcompare now\b/gi, keyword: "Compare now", category: "shady" },
  {
    highlight: /\bcompare online\b/gi,
    keyword: "Compare online",
    category: "shady",
  },
  {
    highlight: /\bcompare rates\b/gi,
    keyword: "Compare rates",
    category: "shady",
  },
  {
    highlight: /\bcompete for your business\b/gi,
    keyword: "Compete for your business",
    category: "shady",
  },
  {
    highlight: /\bconfidentiality\b/gi,
    keyword: "Confidentiality",
    category: "shady",
  },
  {
    highlight: /\bcongratulations\b/gi,
    keyword: "Congratulations",
    category: "shady",
  },
  {
    highlight: /\bconsolidate debt and credit\b/gi,
    keyword: "Consolidate debt and credit",
    category: "shady",
  },
  {
    highlight: /\bconsolidate your debt\b/gi,
    keyword: "Consolidate your debt",
    category: "shady",
  },
  {
    highlight: /\bcopy accurately\b/gi,
    keyword: "Copy accurately",
    category: "shady",
  },
  { highlight: /\bcopy dvds\b/gi, keyword: "Copy DVDs", category: "shady" },
  { highlight: /\bcovid\b/gi, keyword: "COVID", category: "shady" },
  { highlight: /\bcures\b/gi, keyword: "Cures", category: "shady" },
  {
    highlight: /\bcures baldness\b/gi,
    keyword: "Cures baldness",
    category: "shady",
  },
  { highlight: /\bdiagnostic\b/gi, keyword: "Diagnostic", category: "shady" },
  { highlight: /\bdiagnostics\b/gi, keyword: "DIAGNOSTICS", category: "shady" },
  { highlight: /\bdiet\b/gi, keyword: "Diet", category: "shady" },
  {
    highlight: /\bdig up dirt on friends\b/gi,
    keyword: "Dig up dirt on friends",
    category: "shady",
  },
  {
    highlight: /\bdirect email\b/gi,
    keyword: "Direct email",
    category: "shady",
  },
  {
    highlight: /\bdirect marketing\b/gi,
    keyword: "Direct marketing",
    category: "shady",
  },
  {
    highlight: /\beliminate debt\b/gi,
    keyword: "Eliminate debt",
    category: "shady",
  },
  {
    highlight: /\bexplode your business\b/gi,
    keyword: "Explode your business",
    category: "shady",
  },
  {
    highlight: /\bfast viagra delivery\b/gi,
    keyword: "Fast viagra delivery",
    category: "shady",
  },
  { highlight: /\bfinance\b/gi, keyword: "Finance", category: "shady" },
  { highlight: /\bfinancial\b/gi, keyword: "Financial", category: "shady" },
  {
    highlight: /\bfinancial advice\b/gi,
    keyword: "Financial advice",
    category: "shady",
  },
  {
    highlight: /\bfinancial independence\b/gi,
    keyword: "Financial independence",
    category: "shady",
  },
  {
    highlight: /\bfinancially independent\b/gi,
    keyword: "Financially independent",
    category: "shady",
  },
  // {
  //   highlight: /\bfor new customers only\b/gi,
  //   keyword: "For new customers only",
  //   category: "shady",
  // },
  { highlight: /\bforeclosure\b/gi, keyword: "Foreclosure", category: "shady" },
  { highlight: /\bfree\b/gi, keyword: "Free", category: "shady" },
  {
    highlight: /\bfree (access|money|gift)\b/gi,
    keyword: "Free access/money/gift",
    category: "shady",
  },
  { highlight: /\bfree bonus\b/gi, keyword: "Free bonus", category: "shady" },
  {
    highlight: /\bfree cell phone\b/gi,
    keyword: "Free cell phone",
    category: "shady",
  },
  { highlight: /\bfree dvd\b/gi, keyword: "Free DVD", category: "shady" },
  {
    highlight: /\bfree grant money\b/gi,
    keyword: "Free grant money",
    category: "shady",
  },
  {
    highlight: /\bfree information\b/gi,
    keyword: "Free information",
    category: "shady",
  },
  {
    highlight: /\bfree installation\b/gi,
    keyword: "Free installation",
    category: "shady",
  },
  {
    highlight: /\bfree instant\b/gi,
    keyword: "Free Instant",
    category: "shady",
  },
  { highlight: /\bfree iphone\b/gi, keyword: "Free iPhone", category: "shady" },
  { highlight: /\bfree laptop\b/gi, keyword: "Free laptop", category: "shady" },
  { highlight: /\bfree leads\b/gi, keyword: "Free leads", category: "shady" },
  {
    highlight: /\bfree macbook\b/gi,
    keyword: "Free Macbook",
    category: "shady",
  },
  { highlight: /\bfree offer\b/gi, keyword: "Free offer", category: "shady" },
  {
    highlight: /\bfree priority mail\b/gi,
    keyword: "Free priority mail",
    category: "shady",
  },
  { highlight: /\bfree sample\b/gi, keyword: "Free sample", category: "shady" },
  {
    highlight: /\bfree website\b/gi,
    keyword: "Free website",
    category: "shady",
  },
  { highlight: /\bfree!\b/gi, keyword: "Free!", category: "shady" },
  { highlight: /\bget\b/gi, keyword: "Get", category: "shady" },
  { highlight: /\bgift card\b/gi, keyword: "Gift card", category: "shady" },
  {
    highlight: /\bgift certificate\b/gi,
    keyword: "Gift certificate",
    category: "shady",
  },
  {
    highlight: /\bgift included\b/gi,
    keyword: "Gift included",
    category: "shady",
  },
  {
    highlight: /\bgive it away\b/gi,
    keyword: "Give it away",
    category: "shady",
  },
  { highlight: /\bgiving away\b/gi, keyword: "Giving away", category: "shady" },
  {
    highlight: /\bthey’re just giving it away\b/gi,
    keyword: "They’re just giving it away",
    category: "shady",
  },
  {
    highlight: /\bgiving it away\b/gi,
    keyword: "Giving it away",
    category: "shady",
  },
  { highlight: /\bgold\b/gi, keyword: "Gold", category: "shady" },
  { highlight: /\bgreat\b/gi, keyword: "Great", category: "shady" },
  { highlight: /\bgreat deal\b/gi, keyword: "Great deal", category: "shady" },
  {
    highlight: /\bgreetings of the day\b/gi,
    keyword: "Greetings of the day",
    category: "shady",
  },
  {
    highlight: /\bgrowth hormone\b/gi,
    keyword: "Growth hormone",
    category: "shady",
  },
  { highlight: /\bguarantee\b/gi, keyword: "Guarantee", category: "shady" },
  {
    highlight: /\bguaranteed deposit\b/gi,
    keyword: "Guaranteed deposit",
    category: "shady",
  },
  {
    highlight: /\bguaranteed income\b/gi,
    keyword: "Guaranteed income",
    category: "shady",
  },
  {
    highlight: /\bguaranteed payment\b/gi,
    keyword: "Guaranteed payment",
    category: "shady",
  },
  {
    highlight: /\bhave you been turned down\?\b/gi,
    keyword: "Have you been turned down?",
    category: "shady",
  },
  {
    highlight: /\bhello (with no name included)\b/gi,
    keyword: "Hello (with no name included)",
    category: "shady",
  },
  {
    highlight: /\btalks about hidden charges\b/gi,
    keyword: "Talks about hidden charges",
    category: "shady",
  },
  {
    highlight: /\bhidden charges\b/gi,
    keyword: "Hidden charges",
    category: "shady",
  },
  // {
  //   highlight: /\bhidden costs\b/gi,
  //   keyword: "Hidden costs",
  //   category: "shady",
  // },
  // { highlight: /\bhidden fees\b/gi, keyword: "Hidden fees", category: "shady" },
  { highlight: /\bhigh score\b/gi, keyword: "High score", category: "shady" },
  {
    highlight: /\bhome based business\b/gi,
    keyword: "Home based business",
    category: "shady",
  },
  {
    highlight: /\bhome mortgage\b/gi,
    keyword: "Home mortgage",
    category: "shady",
  },
  { highlight: /\bhuman\b/gi, keyword: "Human", category: "shady" },
  {
    highlight: /\bhuman growth hormone\b/gi,
    keyword: "Human growth hormone",
    category: "shady",
  },
  {
    highlight: /\bif only it were that easy\b/gi,
    keyword: "If only it were that easy",
    category: "shady",
  },
  {
    highlight: /\bimportant information\b/gi,
    keyword: "Important information",
    category: "shady",
  },
  {
    highlight: /\bimportant notification\b/gi,
    keyword: "Important notification",
    category: "shady",
  },
  {
    highlight: /\binstant weight loss\b/gi,
    keyword: "Instant weight loss",
    category: "shady",
  },
  {
    highlight: /\binsurance lose weight\b/gi,
    keyword: "Insurance Lose weight",
    category: "shady",
  },
  {
    highlight: /\binternet marketing\b/gi,
    keyword: "Internet marketing",
    category: "shady",
  },
  {
    highlight: /\binvestment decision\b/gi,
    keyword: "Investment decision",
    category: "shady",
  },
  { highlight: /\binvoice\b/gi, keyword: "Invoice", category: "shady" },
  {
    highlight: /\bit’s effective\b/gi,
    keyword: "It’s effective",
    category: "shady",
  },
  { highlight: /\bjob alert\b/gi, keyword: "Job alert", category: "shady" },
  { highlight: /\bjunk\b/gi, keyword: "Junk", category: "shady" },
  { highlight: /\blambo\b/gi, keyword: "Lambo", category: "shady" },
  {
    highlight: /\blaser printer\b/gi,
    keyword: "Laser printer",
    category: "shady",
  },
  { highlight: /\blast day\b/gi, keyword: "Last Day", category: "shady" },
  { highlight: /\blegal\b/gi, keyword: "Legal", category: "shady" },
  {
    highlight: /\blegal notice\b/gi,
    keyword: "Legal notice",
    category: "shady",
  },
  { highlight: /\blife\b/gi, keyword: "Life", category: "shady" },
  {
    highlight: /\blife insurance\b/gi,
    keyword: "Life insurance",
    category: "shady",
  },
  {
    highlight: /\blifetime access\b/gi,
    keyword: "Lifetime access",
    category: "shady",
  },
  {
    highlight: /\blifetime deal\b/gi,
    keyword: "Lifetime deal",
    category: "shady",
  },
  { highlight: /\blimited\b/gi, keyword: "Limited", category: "shady" },
  {
    highlight: /\blimited amount\b/gi,
    keyword: "Limited amount",
    category: "shady",
  },
  {
    highlight: /\blimited number\b/gi,
    keyword: "Limited number",
    category: "shady",
  },
  {
    highlight: /\blimited offer\b/gi,
    keyword: "Limited offer",
    category: "shady",
  },
  {
    highlight: /\blimited supply\b/gi,
    keyword: "Limited supply",
    category: "shady",
  },
  // {
  //   highlight: /\blimited time offer\b/gi,
  //   keyword: "Limited time offer",
  //   category: "shady",
  // },
  // {
  //   highlight: /\blimited time only\b/gi,
  //   keyword: "Limited time only",
  //   category: "shady",
  // },
  { highlight: /\bloan\b/gi, keyword: "Loan", category: "shady" },
  {
    highlight: /\blong distance phone number\b/gi,
    keyword: "Long distance phone number",
    category: "shady",
  },
  {
    highlight: /\blong distance phone offer\b/gi,
    keyword: "Long distance phone offer",
    category: "shady",
  },
  {
    highlight: /\blose weight fast\b/gi,
    keyword: "Lose weight fast",
    category: "shady",
  },
  {
    highlight: /\blose weight spam\b/gi,
    keyword: "Lose weight spam",
    category: "shady",
  },
  { highlight: /\blose weight\b/gi, keyword: "Lose weight", category: "shady" },
  { highlight: /\blottery\b/gi, keyword: "Lottery", category: "shady" },
  {
    highlight: /\blower interest rate\b/gi,
    keyword: "Lower interest rate",
    category: "shady",
  },
  {
    highlight: /\blower interest rates\b/gi,
    keyword: "Lower interest rates",
    category: "shady",
  },
  {
    highlight: /\blower monthly payment\b/gi,
    keyword: "Lower monthly payment",
    category: "shady",
  },
  {
    highlight: /\blower your mortgage rate\b/gi,
    keyword: "Lower your mortgage rate",
    category: "shady",
  },
  {
    highlight: /\blowest insurance rates\b/gi,
    keyword: "Lowest insurance rates",
    category: "shady",
  },
  {
    highlight: /\blowest interest rate\b/gi,
    keyword: "Lowest interest rate",
    category: "shady",
  },
  { highlight: /\blowest rate\b/gi, keyword: "Lowest rate", category: "shady" },
  {
    highlight: /\blowest rates\b/gi,
    keyword: "Lowest rates",
    category: "shady",
  },
  { highlight: /\bluxury\b/gi, keyword: "Luxury", category: "shady" },
  { highlight: /\bluxury car\b/gi, keyword: "Luxury car", category: "shady" },
  {
    highlight: /\bmail in order form\b/gi,
    keyword: "Mail in order form",
    category: "shady",
  },
  {
    highlight: /\bmain in order form\b/gi,
    keyword: "Main in order form",
    category: "shady",
  },
  {
    highlight: /\bmark this as not junk\b/gi,
    keyword: "Mark this as not junk",
    category: "shady",
  },
  { highlight: /\bmass email\b/gi, keyword: "Mass email", category: "shady" },
  { highlight: /\bmedical\b/gi, keyword: "Medical", category: "shady" },
  { highlight: /\bmedicine\b/gi, keyword: "Medicine", category: "shady" },
  { highlight: /\bmeet girls\b/gi, keyword: "Meet girls", category: "shady" },
  { highlight: /\bmeet me\b/gi, keyword: "Meet me", category: "shady" },
  {
    highlight: /\bmeet singles\b/gi,
    keyword: "Meet singles",
    category: "shady",
  },
  { highlight: /\bmeet women\b/gi, keyword: "Meet women", category: "shady" },
  { highlight: /\bmember\b/gi, keyword: "Member", category: "shady" },
  {
    highlight: /\bmember stuff\b/gi,
    keyword: "Member stuff",
    category: "shady",
  },
  {
    highlight: /\bmessage contains disclaimer\b/gi,
    keyword: "Message contains disclaimer",
    category: "shady",
  },
  {
    highlight: /\bmessage from\b/gi,
    keyword: "Message from",
    category: "shady",
  },
  { highlight: /\bmillionaire\b/gi, keyword: "Millionaire", category: "shady" },
  { highlight: /\bmillions\b/gi, keyword: "Millions", category: "shady" },
  { highlight: /\bmlm\b/gi, keyword: "MLM", category: "shady" },
  {
    highlight: /\bmulti-level marketing\b/gi,
    keyword: "Multi-level marketing",
    category: "shady",
  },
  { highlight: /\bname\b/gi, keyword: "Name", category: "shady" },
  { highlight: /\bnear you\b/gi, keyword: "Near you", category: "shady" },
  {
    highlight: /\bnever before\b/gi,
    keyword: "Never before",
    category: "shady",
  },
  { highlight: /\bnew\b/gi, keyword: "New", category: "shady" },
  {
    highlight: /\bnew domain extensions\b/gi,
    keyword: "New domain extensions",
    category: "shady",
  },
  { highlight: /\bnigerian\b/gi, keyword: "Nigerian", category: "shady" },
  {
    highlight: /\bno age restrictions\b/gi,
    keyword: "No age restrictions",
    category: "shady",
  },
  { highlight: /\bno catch\b/gi, keyword: "No catch", category: "shady" },
  {
    highlight: /\bno claim forms\b/gi,
    keyword: "No claim forms",
    category: "shady",
  },
  { highlight: /\bno cost\b/gi, keyword: "No cost", category: "shady" },
  {
    highlight: /\bno credit check\b/gi,
    keyword: "No credit check",
    category: "shady",
  },
  {
    highlight: /\bno credit experience\b/gi,
    keyword: "No credit experience",
    category: "shady",
  },
  {
    highlight: /\bno deposit required\b/gi,
    keyword: "No deposit required",
    category: "shady",
  },
  {
    highlight: /\bno disappointment\b/gi,
    keyword: "No disappointment",
    category: "shady",
  },
  {
    highlight: /\bno experience\b/gi,
    keyword: "No experience",
    category: "shady",
  },
  { highlight: /\bno fees\b/gi, keyword: "No fees", category: "shady" },
  { highlight: /\bno gimmick\b/gi, keyword: "No gimmick", category: "shady" },
  {
    highlight: /\bno hidden costs\b/gi,
    keyword: "No hidden costs",
    category: "shady",
  },
  {
    highlight: /\bno hidden сosts\b/gi,
    keyword: "No hidden сosts",
    category: "shady",
  },
  {
    highlight: /\bno hidden fees\b/gi,
    keyword: "No hidden fees",
    category: "shady",
  },
  { highlight: /\bno hidden\b/gi, keyword: "No hidden", category: "shady" },
  {
    highlight: /\bhidden costs\b/gi,
    keyword: "Hidden costs",
    category: "shady",
  },
  { highlight: /\bhidden fees\b/gi, keyword: "Hidden fees", category: "shady" },
  { highlight: /\bno interest\b/gi, keyword: "No interest", category: "shady" },
  {
    highlight: /\bno interests\b/gi,
    keyword: "No interests",
    category: "shady",
  },
  {
    highlight: /\bno inventory\b/gi,
    keyword: "No inventory",
    category: "shady",
  },
  {
    highlight: /\bno investment\b/gi,
    keyword: "No investment",
    category: "shady",
  },
  {
    highlight: /\bno investment required\b/gi,
    keyword: "No investment required",
    category: "shady",
  },
  {
    highlight: /\bno medical exams\b/gi,
    keyword: "No medical exams",
    category: "shady",
  },
  {
    highlight: /\bno middleman\b/gi,
    keyword: "No middleman",
    category: "shady",
  },
  {
    highlight: /\bno obligation\b/gi,
    keyword: "No obligation",
    category: "shady",
  },
  {
    highlight: /\bno payment required\b/gi,
    keyword: "No payment required",
    category: "shady",
  },
  {
    highlight: /\bno purchase necessary\b/gi,
    keyword: "No purchase necessary",
    category: "shady",
  },
  {
    highlight: /\bno questions asked\b/gi,
    keyword: "No questions asked",
    category: "shady",
  },
  { highlight: /\bno selling\b/gi, keyword: "No selling", category: "shady" },
  {
    highlight: /\bno strings attached\b/gi,
    keyword: "No strings attached",
    category: "shady",
  },
  {
    highlight: /\bno-obligation\b/gi,
    keyword: "No-obligation",
    category: "shady",
  },
  {
    highlight: /\bnominated bank account\b/gi,
    keyword: "Nominated bank account",
    category: "shady",
  },
  {
    highlight: /\bnot intended\b/gi,
    keyword: "Not intended",
    category: "shady",
  },
  { highlight: /\bnot junk\b/gi, keyword: "Not junk", category: "shady" },
  { highlight: /\bnot scam\b/gi, keyword: "Not scam", category: "shady" },
  { highlight: /\bnot spam\b/gi, keyword: "Not spam", category: "shady" },
  { highlight: /\bnotspam\b/gi, keyword: "Notspam", category: "shady" },
  { highlight: /\bnumber 1\b/gi, keyword: "Number 1", category: "shady" },
  { highlight: /\bobligation\b/gi, keyword: "Obligation", category: "shady" },
  { highlight: /\boff\b/gi, keyword: "Off", category: "shady" },
  {
    highlight: /\boff everything\b/gi,
    keyword: "Off everything",
    category: "shady",
  },
  { highlight: /\boff shore\b/gi, keyword: "Off shore", category: "shady" },
  {
    highlight: /\boffer extended\b/gi,
    keyword: "Offer extended",
    category: "shady",
  },
  { highlight: /\boffers\b/gi, keyword: "Offers", category: "shady" },
  { highlight: /\boffshore\b/gi, keyword: "Offshore", category: "shady" },
  {
    highlight: /\bone hundred percent guaranteed\b/gi,
    keyword: "One hundred percent guaranteed",
    category: "overpromise",
  },
  {
    highlight: /\bone hundred percent free\b/gi,
    keyword: "One hundred percent free",
    category: "money",
  },
  {
    highlight: /\bone hundred percent\b/gi,
    keyword: "One hundred percent",
    category: "shady",
  },
  { highlight: /\bone-time\b/gi, keyword: "One-time", category: "shady" },
  {
    highlight: /\bonline biz opportunity\b/gi,
    keyword: "Online biz opportunity",
    category: "shady",
  },
  {
    highlight: /\bonline degree\b/gi,
    keyword: "Online degree",
    category: "shady",
  },
  {
    highlight: /\bonline income\b/gi,
    keyword: "Online income",
    category: "shady",
  },
  { highlight: /\bonline job\b/gi, keyword: "Online job", category: "shady" },
  { highlight: /\bopen\b/gi, keyword: "Open", category: "shady" },
  { highlight: /\bopportunity\b/gi, keyword: "Opportunity", category: "shady" },
  { highlight: /\bopt-in\b/gi, keyword: "Opt-in", category: "shady" },
  { highlight: /\border\b/gi, keyword: "Order", category: "shady" },
  {
    highlight: /\border shipped by\b/gi,
    keyword: "Order shipped by",
    category: "shady",
  },
  {
    highlight: /\border status\b/gi,
    keyword: "Order status",
    category: "shady",
  },
  {
    highlight: /\borders shipped by\b/gi,
    keyword: "Orders shipped by",
    category: "shady",
  },
  {
    highlight: /\borders shipped by shopper\b/gi,
    keyword: "Orders shipped by shopper",
    category: "shady",
  },
  {
    highlight: /\boutstanding value\b/gi,
    keyword: "Outstanding value",
    category: "shady",
  },
  {
    highlight: /\boutstanding values\b/gi,
    keyword: "Outstanding values",
    category: "shady",
  },
  { highlight: /\bpassword\b/gi, keyword: "Password", category: "shady" },
  { highlight: /\bpasswords\b/gi, keyword: "Passwords", category: "shady" },
  {
    highlight: /\bpay your bills\b/gi,
    keyword: "Pay your bills",
    category: "shady",
  },
  {
    highlight: /\bearn per month\b/gi,
    keyword: "Earn per month",
    category: "money",
  },
  {
    highlight: /\bearn per week\b/gi,
    keyword: "Earn per week",
    category: "money",
  },
  {
    highlight: /\bper (day|week|year)\b/gi,
    keyword: "Per day/per week/per year",
    category: "shady",
  },
  { highlight: /\bper month\b/gi, keyword: "Per month", category: "shady" },
  { highlight: /\bperfect\b/gi, keyword: "Perfect", category: "shady" },
  { highlight: /\bperformance\b/gi, keyword: "Performance", category: "shady" },
  { highlight: /\bphone\b/gi, keyword: "Phone", category: "shady" },
  { highlight: /\bplease\b/gi, keyword: "Please", category: "shady" },
  { highlight: /\bplease open\b/gi, keyword: "Please open", category: "shady" },
  { highlight: /\bpresently\b/gi, keyword: "Presently", category: "shady" },
  {
    highlight: /\bprint form signature\b/gi,
    keyword: "Print form signature",
    category: "shady",
  },
  {
    highlight: /\bprint from signature\b/gi,
    keyword: "Print from signature",
    category: "shady",
  },
  {
    highlight: /\bprint out and fax\b/gi,
    keyword: "Print out and fax",
    category: "shady",
  },
  {
    highlight: /\bpriority mail\b/gi,
    keyword: "Priority mail",
    category: "shady",
  },
  {
    highlight: /\bprivately owned funds\b/gi,
    keyword: "Privately owned funds",
    category: "shady",
  },
  { highlight: /\bprizes\b/gi, keyword: "Prizes", category: "shady" },
  {
    highlight: /\bproblem with shipping\b/gi,
    keyword: "Problem with shipping",
    category: "shady",
  },
  {
    highlight: /\bproblem with your order\b/gi,
    keyword: "Problem with your order",
    category: "shady",
  },
  {
    highlight: /\bproduced and sent out\b/gi,
    keyword: "Produced and sent out",
    category: "shady",
  },
  { highlight: /\bprofit\b/gi, keyword: "Profit", category: "shady" },
  { highlight: /\bpromise you\b/gi, keyword: "Promise you", category: "shady" },
  { highlight: /\bpurchase\b/gi, keyword: "Purchase", category: "shady" },
  {
    highlight: /\bpure profits\b/gi,
    keyword: "Pure Profits",
    category: "shady",
  },
  { highlight: /\bquotes\b/gi, keyword: "Quotes", category: "shady" },
  { highlight: /\brate\b/gi, keyword: "Rate", category: "shady" },
  { highlight: /\breal thing\b/gi, keyword: "Real thing", category: "shady" },
  { highlight: /\brebate\b/gi, keyword: "Rebate", category: "shady" },
  { highlight: /\breduce debt\b/gi, keyword: "Reduce debt", category: "shady" },
  {
    highlight: /\brefinance home\b/gi,
    keyword: "Refinance home",
    category: "shady",
  },
  {
    highlight: /\brefinanced home\b/gi,
    keyword: "Refinanced home",
    category: "shady",
  },
  { highlight: /\brefund\b/gi, keyword: "Refund", category: "shady" },
  { highlight: /\bregarding\b/gi, keyword: "Regarding", category: "shady" },
  {
    highlight: /\bremoval instructions\b/gi,
    keyword: "Removal instructions",
    category: "shady",
  },
  { highlight: /\bremoves\b/gi, keyword: "Removes", category: "shady" },
  {
    highlight: /\bremoves wrinkles\b/gi,
    keyword: "Removes wrinkles",
    category: "shady",
  },
  {
    highlight: /\breplica watches\b/gi,
    keyword: "Replica watches",
    category: "shady",
  },
  { highlight: /\brequest\b/gi, keyword: "Request", category: "shady" },
  { highlight: /\brequest now\b/gi, keyword: "Request now", category: "shady" },
  {
    highlight: /\brequest today\b/gi,
    keyword: "Request today",
    category: "shady",
  },
  {
    highlight: /\brequires initial investment\b/gi,
    keyword: "Requires initial investment",
    category: "shady",
  },
  {
    highlight: /\brequires investment\b/gi,
    keyword: "Requires investment",
    category: "shady",
  },
  {
    highlight: /\breverses aging\b/gi,
    keyword: "Reverses aging",
    category: "shady",
  },
  { highlight: /\brisk free\b/gi, keyword: "Risk free", category: "shady" },
  { highlight: /\brolex\b/gi, keyword: "Rolex", category: "shady" },
  {
    highlight: /\bround the world\b/gi,
    keyword: "Round the world",
    category: "shady",
  },
  { highlight: /\bs 1618\b/gi, keyword: "S 1618", category: "shady" },
  {
    highlight: /\bsafeguard notice\b/gi,
    keyword: "Safeguard notice",
    category: "shady",
  },
  { highlight: /\bsale\b/gi, keyword: "Sale", category: "shady" },
  { highlight: /\bsales\b/gi, keyword: "Sales", category: "shady" },
  { highlight: /\bsave\b/gi, keyword: "Save", category: "shady" },
  {
    highlight: /\bsave [\$£€¥]+, save [\$£€¥]+\b/gi,
    keyword: "Save $, Save €",
    category: "shady",
  },
  {
    highlight: /\bsave big month\b/gi,
    keyword: "Save big month",
    category: "shady",
  },
  {
    highlight: /\bsave big money\b/gi,
    keyword: "Save big money",
    category: "overpromise",
  },
  { highlight: /\bsave big\b/gi, keyword: "Save big", category: "shady" },
  { highlight: /\bsave money\b/gi, keyword: "Save money", category: "shady" },
  { highlight: /\bsave now\b/gi, keyword: "Save now", category: "shady" },
  {
    highlight: /\bscore with babes\b/gi,
    keyword: "Score with babes",
    category: "shady",
  },
  {
    highlight: /\bsearch engine optimisation\b/gi,
    keyword: "Search engine optimisation",
    category: "shady",
  },
  { highlight: /\bsection 301\b/gi, keyword: "Section 301", category: "shady" },
  {
    highlight: /\bsee for yourself\b/gi,
    keyword: "See for yourself",
    category: "shady",
  },
  { highlight: /\bseen on\b/gi, keyword: "Seen on", category: "shady" },
  { highlight: /\bserious\b/gi, keyword: "Serious", category: "shady" },
  {
    highlight: /\bserious case\b/gi,
    keyword: "Serious case",
    category: "shady",
  },
  {
    highlight: /\bserious offer\b/gi,
    keyword: "Serious offer",
    category: "shady",
  },
  {
    highlight: /\bserious only\b/gi,
    keyword: "Serious only",
    category: "shady",
  },
  { highlight: /\bsex\b/gi, keyword: "Sex", category: "shady" },
  { highlight: /\bshop now\b/gi, keyword: "Shop now", category: "shady" },
  { highlight: /\bshopper\b/gi, keyword: "Shopper", category: "shady" },
  {
    highlight: /\bshopping spree\b/gi,
    keyword: "Shopping spree",
    category: "shady",
  },
  { highlight: /\bsnoring\b/gi, keyword: "Snoring", category: "shady" },
  {
    highlight: /\bsocial security number\b/gi,
    keyword: "Social security number",
    category: "shady",
  },
  { highlight: /\bsoon\b/gi, keyword: "Soon", category: "shady" },
  { highlight: /\bspam\b/gi, keyword: "Spam", category: "shady" },
  { highlight: /\bspam free\b/gi, keyword: "Spam free", category: "shady" },
  {
    highlight: /\bspecial deal\b/gi,
    keyword: "Special deal",
    category: "shady",
  },
  {
    highlight: /\bspecial discount\b/gi,
    keyword: "Special discount",
    category: "shady",
  },
  // {
  //   highlight: /\bspecial for you\b/gi,
  //   keyword: "Special for you",
  //   category: "shady",
  // },
  {
    highlight: /\bspecial offer\b/gi,
    keyword: "Special offer",
    category: "shady",
  },
  {
    highlight: /\bstainless steel\b/gi,
    keyword: "Stainless steel",
    category: "shady",
  },
  { highlight: /\bstock alert\b/gi, keyword: "Stock alert", category: "shady" },
  {
    highlight: /\bstock disclaimer statement\b/gi,
    keyword: "Stock disclaimer statement",
    category: "shady",
  },
  { highlight: /\bstock pick\b/gi, keyword: "Stock pick", category: "shady" },
  {
    highlight: /\bstocks? (pick|alert)\b/gi,
    keyword: "Stocks/stock pick/stock alert",
    category: "shady",
  },
  {
    highlight: /\bstop calling me\b/gi,
    keyword: "Stop calling me",
    category: "shady",
  },
  {
    highlight: /\bstop emailing me\b/gi,
    keyword: "Stop emailing me",
    category: "shady",
  },
  {
    highlight: /\bstop further distribution\b/gi,
    keyword: "Stop further distribution",
    category: "shady",
  },
  {
    highlight: /\bstop snoring\b/gi,
    keyword: "Stop snoring",
    category: "shady",
  },
  { highlight: /\bstrong buy\b/gi, keyword: "Strong buy", category: "shady" },
  {
    highlight: /\bstuff on sale\b/gi,
    keyword: "Stuff on sale",
    category: "shady",
  },
  {
    highlight: /\bsubject to cash\b/gi,
    keyword: "Subject to cash",
    category: "shady",
  },
  { highlight: /\bsubject to\b/gi, keyword: "Subject to", category: "shady" },
  { highlight: /\bsubscribe\b/gi, keyword: "Subscribe", category: "shady" },
  {
    highlight: /\bsubscribe for free\b/gi,
    keyword: "Subscribe for free",
    category: "shady",
  },
  {
    highlight: /\bsubscribe now\b/gi,
    keyword: "Subscribe now",
    category: "shady",
  },
  { highlight: /\bsuper promo\b/gi, keyword: "Super promo", category: "shady" },
  { highlight: /\bsupplies\b/gi, keyword: "Supplies", category: "shady" },
  {
    highlight: /\btack action now\b/gi,
    keyword: "Tack action now",
    category: "shady",
  },
  // {
  //   highlight: /\btalks about hidden charges\b/gi,
  //   keyword: "Talks about hidden charges",
  //   category: "shady",
  // },
  {
    highlight: /\btalks about prizes\b/gi,
    keyword: "Talks about prizes",
    category: "shady",
  },
  {
    highlight: /\btells you it’s an ad\b/gi,
    keyword: "Tells you it’s an ad",
    category: "shady",
  },
  { highlight: /\bterms\b/gi, keyword: "Terms", category: "shady" },
  {
    highlight: /\bthe best rates\b/gi,
    keyword: "The best rates",
    category: "shady",
  },
  {
    highlight: /\bthe email asks for a credit card\b/gi,
    keyword: "The email asks for a credit card",
    category: "shady",
  },
  {
    highlight: /\bthe following form\b/gi,
    keyword: "The following form",
    category: "shady",
  },
  {
    highlight:
      /\bthey make a claim or claims that they're in accordance with spam law\b/gi,
    keyword:
      "They make a claim or claims that they're in accordance with spam law",
    category: "shady",
  },
  {
    highlight: /\bthey try to keep your money no refund\b/gi,
    keyword: "They try to keep your money no refund",
    category: "shady",
  },
  // {
  //   highlight: /\bthey’re just giving it away\b/gi,
  //   keyword: "They’re just giving it away",
  //   category: "shady",
  // },
  {
    highlight: /\bthis isn't junk\b/gi,
    keyword: "This isn't junk",
    category: "shady",
  },
  {
    highlight: /\bthis isn't spam\b/gi,
    keyword: "This isn't spam",
    category: "shady",
  },
  {
    highlight: /\bthis isn’t a scam\b/gi,
    keyword: "This isn’t a scam",
    category: "shady",
  },
  {
    highlight: /\bthis isn’t junk\b/gi,
    keyword: "This isn’t junk",
    category: "shady",
  },
  {
    highlight: /\bthis isn’t spam\b/gi,
    keyword: "This isn’t spam",
    category: "shady",
  },
  { highlight: /\btimeshare\b/gi, keyword: "Timeshare", category: "shady" },
  {
    highlight: /\btimeshare offers\b/gi,
    keyword: "Timeshare offers",
    category: "shady",
  },
  { highlight: /\btraffic\b/gi, keyword: "Traffic", category: "shady" },
  {
    highlight: /\btrial unlimited\b/gi,
    keyword: "Trial unlimited",
    category: "shady",
  },
  {
    highlight: /\bu.s. dollars\b/gi,
    keyword: "U.S. dollars",
    category: "shady",
  },
  { highlight: /\bundisclosed\b/gi, keyword: "Undisclosed", category: "shady" },
  {
    highlight: /\bundisclosed recipient\b/gi,
    keyword: "Undisclosed recipient",
    category: "shady",
  },
  {
    highlight: /\buniversity diplomas\b/gi,
    keyword: "University diplomas",
    category: "shady",
  },
  {
    highlight: /\bunsecured credit\b/gi,
    keyword: "Unsecured credit",
    category: "shady",
  },
  {
    highlight: /\bunsecured debt\b/gi,
    keyword: "Unsecured debt",
    category: "shady",
  },
  { highlight: /\bunsolicited\b/gi, keyword: "Unsolicited", category: "shady" },
  { highlight: /\bunsubscribe\b/gi, keyword: "Unsubscribe", category: "shady" },
  {
    highlight: /\burgent response\b/gi,
    keyword: "Urgent response",
    category: "shady",
  },
  {
    highlight: /\bus dollars|euros\b/gi,
    keyword: "US dollars / Euros",
    category: "shady",
  },
  { highlight: /\bvacation\b/gi, keyword: "Vacation", category: "shady" },
  {
    highlight: /\bvacation offers\b/gi,
    keyword: "Vacation offers",
    category: "shady",
  },
  { highlight: /\bvalium\b/gi, keyword: "Valium", category: "shady" },
  { highlight: /\bviagra\b/gi, keyword: "Viagra", category: "shady" },
  { highlight: /\bvicodin\b/gi, keyword: "Vicodin", category: "shady" },
  { highlight: /\bvip\b/gi, keyword: "VIP", category: "shady" },
  {
    highlight: /\bvisit our website\b/gi,
    keyword: "Visit our website",
    category: "shady",
  },
  {
    highlight: /\bwants credit card\b/gi,
    keyword: "Wants credit card",
    category: "shady",
  },
  {
    highlight: /\bwarranty expired\b/gi,
    keyword: "Warranty expired",
    category: "shady",
  },
  {
    highlight: /\bwe hate spam\b/gi,
    keyword: "We hate spam",
    category: "shady",
  },
  {
    highlight: /\bwe honor all\b/gi,
    keyword: "We honor all",
    category: "shady",
  },
  {
    highlight: /\bwebsite visitors\b/gi,
    keyword: "Website visitors",
    category: "shady",
  },
  {
    highlight: /\bweekend getaway\b/gi,
    keyword: "Weekend getaway",
    category: "shady",
  },
  { highlight: /\bweight loss\b/gi, keyword: "Weight loss", category: "shady" },
  {
    highlight: /\bwhat’s keeping you\?\b/gi,
    keyword: "What’s keeping you?",
    category: "shady",
  },
  {
    highlight: /\bwhile available\b/gi,
    keyword: "While available",
    category: "shady",
  },
  {
    highlight: /\bwhile in stock\b/gi,
    keyword: "While in stock",
    category: "shady",
  },
  {
    highlight: /\bwhile stocks last\b/gi,
    keyword: "While stocks last",
    category: "shady",
  },
  {
    highlight: /\bwhile you sleep\b/gi,
    keyword: "While you sleep",
    category: "shady",
  },
  {
    highlight: /\bwho really wins\?\b/gi,
    keyword: "Who really wins?",
    category: "shady",
  },
  { highlight: /\bwin\b/gi, keyword: "Win", category: "shady" },
  { highlight: /\bwinner\b/gi, keyword: "Winner", category: "shady" },
  { highlight: /\bwinning\b/gi, keyword: "Winning", category: "shady" },
  { highlight: /\bwon\b/gi, keyword: "Won", category: "shady" },
  { highlight: /\bxanax\b/gi, keyword: "Xanax", category: "shady" },
  { highlight: /\bxxx\b/gi, keyword: "XXX", category: "shady" },
  {
    highlight: /\byou have been chosen\b/gi,
    keyword: "You have been chosen",
    category: "shady",
  },
  {
    highlight: /\byou have been selected\b/gi,
    keyword: "You have been selected",
    category: "shady",
  },
  { highlight: /\byour chance\b/gi, keyword: "Your chance", category: "shady" },
  { highlight: /\byour status\b/gi, keyword: "Your status", category: "shady" },
  { highlight: /\bzero chance\b/gi, keyword: "Zero chance", category: "shady" },
  {
    highlight: /\bzero percent\b/gi,
    keyword: "Zero percent",
    category: "shady",
  },
  { highlight: /\bzero risk\b/gi, keyword: "Zero risk", category: "shady" },
  { highlight: /\b#1\b/gi, keyword: "#1", category: "overpromise" },
  { highlight: /[0-9]+%/gi, keyword: "%", category: "overpromise" },
  { highlight: /[0-9]+% free/gi, keyword: "% free", category: "overpromise" },
  {
    highlight: /\b[0-9]+% satisfied\b/gi,
    keyword: "% Satisfied",
    category: "overpromise",
  },
  {
    highlight: /\b[0-9]+% risk\b/gi,
    keyword: "0% risk",
    category: "overpromise",
  },
  {
    highlight: /\b100% free\b/gi,
    keyword: "100% free",
    category: "overpromise",
  },
  {
    highlight: /\b100% more\b/gi,
    keyword: "100% more",
    category: "overpromise",
  },
  { highlight: /\b100% off\b/gi, keyword: "100% off", category: "overpromise" },
  {
    highlight: /\b100% satisfied\b/gi,
    keyword: "100% satisfied",
    category: "overpromise",
  },
  { highlight: /\b100%\b/gi, keyword: "100%", category: "overpromise" },
  { highlight: /\b99.90%\b/gi, keyword: "99.90%", category: "overpromise" },
  { highlight: /\b99%\b/gi, keyword: "99%", category: "overpromise" },
  { highlight: /\b[0-9]+%\b/gi, keyword: "0%", category: "overpromise" },
  {
    highlight: /\baccess for free\b/gi,
    keyword: "Access for free",
    category: "overpromise",
  },
  {
    highlight: /\badditional income\b/gi,
    keyword: "Additional income",
    category: "overpromise",
  },
  { highlight: /\bamazed\b/gi, keyword: "Amazed", category: "overpromise" },
  { highlight: /\bamazing\b/gi, keyword: "Amazing", category: "overpromise" },
  {
    highlight: /\bamazing offer\b/gi,
    keyword: "Amazing offer",
    category: "overpromise",
  },
  {
    highlight: /\bamazing stuff\b/gi,
    keyword: "Amazing stuff",
    category: "overpromise",
  },
  {
    highlight: /\bbe amazed\b/gi,
    keyword: "Be amazed",
    category: "overpromise",
  },
  {
    highlight: /\bbe surprised\b/gi,
    keyword: "Be surprised",
    category: "overpromise",
  },
  {
    highlight: /\bbe your own boss\b/gi,
    keyword: "Be your own boss",
    category: "overpromise",
  },
  {
    highlight: /\bbelieve me\b/gi,
    keyword: "Believe me",
    category: "overpromise",
  },
  {
    highlight: /\bbest bargain\b/gi,
    keyword: "Best bargain",
    category: "overpromise",
  },
  {
    highlight: /\bbest deal\b/gi,
    keyword: "Best deal",
    category: "overpromise",
  },
  {
    highlight: /\bbest offer\b/gi,
    keyword: "Best offer",
    category: "overpromise",
  },
  {
    highlight: /\bbest price\b/gi,
    keyword: "Best price",
    category: "overpromise",
  },
  {
    highlight: /\bbest rates\b/gi,
    keyword: "Best rates",
    category: "overpromise",
  },
  {
    highlight: /\bbig bucks\b/gi,
    keyword: "Big bucks",
    category: "overpromise",
  },
  { highlight: /\bbonus\b/gi, keyword: "Bonus", category: "overpromise" },
  { highlight: /\bboss\b/gi, keyword: "Boss", category: "overpromise" },
  {
    highlight: /\bcan’t live without\b/gi,
    keyword: "Can’t live without",
    category: "overpromise",
  },
  { highlight: /\bcancel\b/gi, keyword: "Cancel", category: "overpromise" },
  {
    highlight: /\bconsolidate debt\b/gi,
    keyword: "Consolidate debt",
    category: "overpromise",
  },
  {
    highlight: /\bdouble your cash\b/gi,
    keyword: "Double your cash",
    category: "overpromise",
  },
  {
    highlight: /\bdouble your income\b/gi,
    keyword: "Double your income",
    category: "overpromise",
  },
  {
    highlight: /\bdrastically reduced\b/gi,
    keyword: "Drastically reduced",
    category: "overpromise",
  },
  {
    highlight: /\bearn extra cash\b/gi,
    keyword: "Earn extra cash",
    category: "overpromise",
  },
  {
    highlight: /\bearn money\b/gi,
    keyword: "Earn money",
    category: "overpromise",
  },
  {
    highlight: /\beliminate bad credit\b/gi,
    keyword: "Eliminate bad credit",
    category: "overpromise",
  },
  {
    highlight: /\bexpect to earn\b/gi,
    keyword: "Expect to earn",
    category: "overpromise",
  },
  { highlight: /\bextra\b/gi, keyword: "Extra", category: "overpromise" },
  {
    highlight: /\bextra cash\b/gi,
    keyword: "Extra cash",
    category: "overpromise",
  },
  {
    highlight: /\bearn extra income\b/gi,
    keyword: "Earn extra income",
    category: "money",
  },
  {
    highlight: /\bextra income\b/gi,
    keyword: "Extra income",
    category: "overpromise",
  },
  {
    highlight: /\bfantastic\b/gi,
    keyword: "Fantastic",
    category: "overpromise",
  },
  {
    highlight: /\bfantastic deal\b/gi,
    keyword: "Fantastic deal",
    category: "overpromise",
  },
  {
    highlight: /\bfantastic offer\b/gi,
    keyword: "Fantastic offer",
    category: "overpromise",
  },
  { highlight: /\bfast\b/gi, keyword: "FAST", category: "overpromise" },
  {
    highlight: /\bfast cash\b/gi,
    keyword: "Fast cash",
    category: "overpromise",
  },
  {
    highlight: /\bfinancial freedom\b/gi,
    keyword: "Financial freedom",
    category: "overpromise",
  },
  {
    highlight: /\bfree access\b/gi,
    keyword: "Free access",
    category: "overpromise",
  },
  {
    highlight: /\bfree consultation\b/gi,
    keyword: "Free consultation",
    category: "overpromise",
  },
  {
    highlight: /\bfree gift\b/gi,
    keyword: "Free gift",
    category: "overpromise",
  },
  {
    highlight: /\bfree hosting\b/gi,
    keyword: "Free hosting",
    category: "overpromise",
  },
  {
    highlight: /\bfree info\b/gi,
    keyword: "Free info",
    category: "overpromise",
  },
  {
    highlight: /\bfree investment\b/gi,
    keyword: "Free investment",
    category: "overpromise",
  },
  {
    highlight: /\bfree membership\b/gi,
    keyword: "Free membership",
    category: "overpromise",
  },
  {
    highlight: /\bfree money\b/gi,
    keyword: "Free money",
    category: "overpromise",
  },
  {
    highlight: /\bfree preview\b/gi,
    keyword: "Free preview",
    category: "overpromise",
  },
  {
    highlight: /\bfree quote\b/gi,
    keyword: "Free quote",
    category: "overpromise",
  },
  {
    highlight: /\bfree trial\b/gi,
    keyword: "Free trial",
    category: "overpromise",
  },
  {
    highlight: /\bfull refund\b/gi,
    keyword: "Full refund",
    category: "overpromise",
  },
  {
    highlight: /\bget out of debt\b/gi,
    keyword: "Get out of debt",
    category: "overpromise",
  },
  { highlight: /\bgiveaway\b/gi, keyword: "Giveaway", category: "overpromise" },
  {
    highlight: /\bguaranteed\b/gi,
    keyword: "Guaranteed",
    category: "overpromise",
  },
  {
    highlight: /\bincrease sales\b/gi,
    keyword: "Increase sales",
    category: "overpromise",
  },
  {
    highlight: /\bincrease traffic\b/gi,
    keyword: "Increase traffic",
    category: "overpromise",
  },
  {
    highlight: /\bincredible deal\b/gi,
    keyword: "Incredible deal",
    category: "overpromise",
  },
  {
    highlight: /\bjoin billions\b/gi,
    keyword: "Join billions",
    category: "overpromise",
  },
  {
    highlight: /\bjoin millions\b/gi,
    keyword: "Join millions",
    category: "overpromise",
  },
  {
    highlight: /\bjoin millions of americans\b/gi,
    keyword: "Join millions of Americans",
    category: "overpromise",
  },
  {
    highlight: /\bjoin thousands\b/gi,
    keyword: "Join thousands",
    category: "overpromise",
  },
  {
    highlight: /\blower rates\b/gi,
    keyword: "Lower rates",
    category: "overpromise",
  },
  {
    highlight: /\blowest price\b/gi,
    keyword: "Lowest price",
    category: "overpromise",
  },
  {
    highlight: /\bmake money\b/gi,
    keyword: "Make money",
    category: "overpromise",
  },
  { highlight: /\bmillion\b/gi, keyword: "Million", category: "overpromise" },
  {
    highlight: /\bmillion dollars\b/gi,
    keyword: "Million dollars",
    category: "overpromise",
  },
  { highlight: /\bmiracle\b/gi, keyword: "Miracle", category: "overpromise" },
  {
    highlight: /\bmoney back\b/gi,
    keyword: "Money back",
    category: "overpromise",
  },
  {
    highlight: /\bmonth trial offer\b/gi,
    keyword: "Month trial offer",
    category: "overpromise",
  },
  {
    highlight: /\bmore internet traffic\b/gi,
    keyword: "More Internet Traffic",
    category: "overpromise",
  },
  {
    highlight: /\bnumber one\b/gi,
    keyword: "Number one",
    category: "overpromise",
  },
  {
    highlight: /\bonce in a lifetime\b/gi,
    keyword: "Once in a lifetime",
    category: "overpromise",
  },
  // {
  //   highlight: /\bone hundred percent guaranteed\b/gi,
  //   keyword: "One hundred percent guaranteed",
  //   category: "overpromise",
  // },
  { highlight: /\bone time\b/gi, keyword: "One time", category: "overpromise" },
  {
    highlight: /\bpennies a day\b/gi,
    keyword: "Pennies a day",
    category: "overpromise",
  },
  {
    highlight: /\bpotential earnings\b/gi,
    keyword: "Potential earnings",
    category: "overpromise",
  },
  { highlight: /\bprize\b/gi, keyword: "Prize", category: "overpromise" },
  { highlight: /\bpromise\b/gi, keyword: "Promise", category: "overpromise" },
  {
    highlight: /\bpure profit\b/gi,
    keyword: "Pure profit",
    category: "overpromise",
  },
  {
    highlight: /\brisk-free\b/gi,
    keyword: "Risk-free",
    category: "overpromise",
  },
  {
    highlight: /\bsatisfaction guaranteed\b/gi,
    keyword: "Satisfaction guaranteed",
    category: "overpromise",
  },
  // {
  //   highlight: /\bsave big money\b/gi,
  //   keyword: "Save big money",
  //   category: "overpromise",
  // },
  {
    highlight: /\bsave up to\b/gi,
    keyword: "Save up to",
    category: "overpromise",
  },
  {
    highlight: /\bspecial promotion\b/gi,
    keyword: "Special promotion",
    category: "overpromise",
  },
  { highlight: /\bthe best\b/gi, keyword: "The best", category: "overpromise" },
  {
    highlight: /\bthousands\b/gi,
    keyword: "Thousands",
    category: "overpromise",
  },
  {
    highlight: /\bunbeatable offer\b/gi,
    keyword: "Unbeatable offer",
    category: "overpromise",
  },
  {
    highlight: /\bunbelievable\b/gi,
    keyword: "Unbelievable",
    category: "overpromise",
  },
  {
    highlight: /\bunlimited\b/gi,
    keyword: "Unlimited",
    category: "overpromise",
  },
  {
    highlight: /\bunlimited trial\b/gi,
    keyword: "Unlimited trial",
    category: "overpromise",
  },
  {
    highlight: /\bwonderful\b/gi,
    keyword: "Wonderful",
    category: "overpromise",
  },
  {
    highlight: /\byou will not believe your eyes\b/gi,
    keyword: "You will not believe your eyes",
    category: "overpromise",
  },
  { highlight: /[\$£€¥]+[0-9\.\,]+/gi, keyword: "$$$", category: "money" },
  { highlight: /[0-9\.\,]+[\$£€¥]+/gi, keyword: "€€€", category: "money" },
  { highlight: /[\$£€¥]{2,}/gi, keyword: "£££", category: "money" },
  {
    highlight: /\b[0-9\.,]+%( off)?\b/gi,
    keyword: "50% off",
    category: "money",
  },
  { highlight: /\ba few bob\b/gi, keyword: "A few bob", category: "money" },
  {
    highlight: /\baccept cash cards\b/gi,
    keyword: "Accept cash cards",
    category: "money",
  },
  {
    highlight: /\baccept credit cards\b/gi,
    keyword: "Accept credit cards",
    category: "money",
  },
  { highlight: /\baffordable\b/gi, keyword: "Affordable", category: "money" },
  {
    highlight: /\baffordable deal\b/gi,
    keyword: "Affordable deal",
    category: "money",
  },
  {
    highlight: /\bavoid bankruptcy\b/gi,
    keyword: "Avoid bankruptcy",
    category: "money",
  },
  { highlight: /\bbad credit\b/gi, keyword: "Bad credit", category: "money" },
  { highlight: /\bbank\b/gi, keyword: "Bank", category: "money" },
  { highlight: /\bbankruptcy\b/gi, keyword: "Bankruptcy", category: "money" },
  { highlight: /\bbargain\b/gi, keyword: "Bargain", category: "money" },
  { highlight: /\bbilling\b/gi, keyword: "Billing", category: "money" },
  {
    highlight: /\bbilling address\b/gi,
    keyword: "Billing address",
    category: "money",
  },
  { highlight: /\bbillion\b/gi, keyword: "Billion", category: "money" },
  {
    highlight: /\bbillion dollars\b/gi,
    keyword: "Billion dollars",
    category: "money",
  },
  { highlight: /\bbillionaire\b/gi, keyword: "Billionaire", category: "money" },
  {
    highlight: /\bcard accepted\b/gi,
    keyword: "Card accepted",
    category: "money",
  },
  {
    highlight: /\bcards accepted\b/gi,
    keyword: "Cards accepted",
    category: "money",
  },
  { highlight: /\bcash\b/gi, keyword: "Cash", category: "money" },
  { highlight: /\bcash bonus\b/gi, keyword: "Cash bonus", category: "money" },
  { highlight: /\bcash out\b/gi, keyword: "Cash out", category: "money" },
  { highlight: /\bcash-out\b/gi, keyword: "Cash-out", category: "money" },
  {
    highlight: /\bcashcashcash\b/gi,
    keyword: "Cashcashcash",
    category: "money",
  },
  { highlight: /\bcasino\b/gi, keyword: "Casino", category: "money" },
  {
    highlight: /\bcents on the dollar\b/gi,
    keyword: "Cents on the dollar",
    category: "money",
  },
  { highlight: /\bcheck\b/gi, keyword: "Check", category: "money" },
  {
    highlight: /\bcheck or money order\b/gi,
    keyword: "Check or money order",
    category: "money",
  },
  {
    highlight: /\bclaim your discount\b/gi,
    keyword: "Claim your discount",
    category: "money",
  },
  { highlight: /\bcost\b/gi, keyword: "Cost", category: "money" },
  { highlight: /\bcosts\b/gi, keyword: "Costs", category: "money" },
  { highlight: /\bcredit\b/gi, keyword: "Credit", category: "money" },
  {
    highlight: /\bcredit bureaus\b/gi,
    keyword: "Credit bureaus",
    category: "money",
  },
  {
    highlight: /\bcredit card offers\b/gi,
    keyword: "Credit card offers",
    category: "money",
  },
  { highlight: /\bcredit card\b/gi, keyword: "Credit card", category: "money" },
  {
    highlight: /\bcredit or debit\b/gi,
    keyword: "Credit or Debit",
    category: "money",
  },
  { highlight: /\bdeal\b/gi, keyword: "Deal", category: "money" },
  { highlight: /\bdebt\b/gi, keyword: "Debt", category: "money" },
  { highlight: /\bdiscount\b/gi, keyword: "Discount", category: "money" },
  { highlight: /\bdollars\b/gi, keyword: "Dollars", category: "money" },
  {
    highlight: /\bdouble your wealth\b/gi,
    keyword: "Double your wealth",
    category: "money",
  },
  { highlight: /\bdouble your\b/gi, keyword: "Double your", category: "money" },
  { highlight: /\bearn\b/gi, keyword: "Earn", category: "money" },
  { highlight: /\bearn [\$£€¥]+\b/gi, keyword: "Earn $", category: "money" },
  { highlight: /\bearn cash\b/gi, keyword: "Earn cash", category: "money" },
  // {
  //   highlight: /\bearn extra income\b/gi,
  //   keyword: "Earn extra income",
  //   category: "money",
  // },
  {
    highlight: /\bearn from home\b/gi,
    keyword: "Earn from home",
    category: "money",
  },
  {
    highlight: /\bearn monthly\b/gi,
    keyword: "Earn monthly",
    category: "money",
  },
  // {
  //   highlight: /\bearn per month\b/gi,
  //   keyword: "Earn per month",
  //   category: "money",
  // },
  // {
  //   highlight: /\bearn per week\b/gi,
  //   keyword: "Earn per week",
  //   category: "money",
  // },
  {
    highlight: /\bearn your degree\b/gi,
    keyword: "Earn your degree",
    category: "money",
  },
  { highlight: /\beasy income\b/gi, keyword: "Easy income", category: "money" },
  { highlight: /\beasy terms\b/gi, keyword: "Easy terms", category: "money" },
  { highlight: /\bf r e e\b/gi, keyword: "F r e e", category: "money" },
  { highlight: /\bfor free\b/gi, keyword: "For free", category: "money" },
  {
    highlight: /\bfor just [\$£€¥]+\b/gi,
    keyword: "For just $",
    category: "money",
  },
  {
    highlight: /\bfor just [\$£€¥]+[0-9]+\b/gi,
    keyword: "For just $ (amount)",
    category: "money",
  },
  {
    highlight: /\bfor just [\$£€¥]+xxx\b/gi,
    keyword: "For just $xxx",
    category: "money",
  },
  { highlight: /\bget money\b/gi, keyword: "Get Money", category: "money" },
  {
    highlight: /\bget your money\b/gi,
    keyword: "Get your money",
    category: "money",
  },
  {
    highlight: /\bhidden assets\b/gi,
    keyword: "Hidden assets",
    category: "money",
  },
  {
    highlight: /\bhuge discount\b/gi,
    keyword: "Huge discount",
    category: "money",
  },
  { highlight: /\bincome\b/gi, keyword: "Income", category: "money" },
  {
    highlight: /\bincome from home\b/gi,
    keyword: "Income from home",
    category: "money",
  },
  {
    highlight: /\bincrease revenue\b/gi,
    keyword: "Increase revenue",
    category: "money",
  },
  {
    highlight: /\bincrease (sales|traffic)\b/gi,
    keyword: "Increase sales/traffic",
    category: "money",
  },
  {
    highlight: /\bincrease your chances\b/gi,
    keyword: "Increase your chances",
    category: "money",
  },
  {
    highlight: /\binitial investment\b/gi,
    keyword: "Initial investment",
    category: "money",
  },
  {
    highlight: /\binstant earnings\b/gi,
    keyword: "Instant earnings",
    category: "money",
  },
  {
    highlight: /\binstant income\b/gi,
    keyword: "Instant income",
    category: "money",
  },
  { highlight: /\binsurance\b/gi, keyword: "Insurance", category: "money" },
  { highlight: /\binvestment\b/gi, keyword: "Investment", category: "money" },
  {
    highlight: /\binvestment advice\b/gi,
    keyword: "Investment advice",
    category: "money",
  },
  { highlight: /\blifetime\b/gi, keyword: "Lifetime", category: "money" },
  { highlight: /\bloans\b/gi, keyword: "Loans", category: "money" },
  { highlight: /\bmake [\$£€¥]+\b/gi, keyword: "Make $", category: "money" },
  { highlight: /\bmoney\b/gi, keyword: "Money", category: "money" },
  {
    highlight: /\bmoney making\b/gi,
    keyword: "Money making",
    category: "money",
  },
  {
    highlight: /\bmoney-back guarantee\b/gi,
    keyword: "Money-back guarantee",
    category: "money",
  },
  {
    highlight: /\bmoney-making\b/gi,
    keyword: "Money-making",
    category: "money",
  },
  {
    highlight: /\bmonthly payment\b/gi,
    keyword: "Monthly payment",
    category: "money",
  },
  { highlight: /\bmortgage\b/gi, keyword: "Mortgage", category: "money" },
  {
    highlight: /\bmortgage rates\b/gi,
    keyword: "Mortgage rates",
    category: "money",
  },
  { highlight: /\boffer\b/gi, keyword: "Offer", category: "money" },
  // {
  //   highlight: /\bone hundred percent free\b/gi,
  //   keyword: "One hundred percent free",
  //   category: "money",
  // },
  { highlight: /\bonly [\$£€¥]+\b/gi, keyword: "Only $", category: "money" },
  { highlight: /\bprice\b/gi, keyword: "Price", category: "money" },
  {
    highlight: /\bprice protection\b/gi,
    keyword: "Price protection",
    category: "money",
  },
  { highlight: /\bprices\b/gi, keyword: "Prices", category: "money" },
  { highlight: /\bprofits\b/gi, keyword: "Profits", category: "money" },
  { highlight: /\bquote\b/gi, keyword: "Quote", category: "money" },
  { highlight: /\brates\b/gi, keyword: "Rates", category: "money" },
  { highlight: /\brefinance\b/gi, keyword: "Refinance", category: "money" },
  { highlight: /\bsave [\$£€¥]+\b/gi, keyword: "Save $", category: "money" },
  {
    highlight: /\bserious cash\b/gi,
    keyword: "Serious cash",
    category: "money",
  },
  {
    highlight: /\bsubject to credit\b/gi,
    keyword: "Subject to credit",
    category: "money",
  },
  { highlight: /\bus dollars\b/gi, keyword: "US dollars", category: "money" },
  {
    highlight: /\bwhy pay more\?\b/gi,
    keyword: "Why pay more?",
    category: "money",
  },
  { highlight: /\byour income\b/gi, keyword: "Your income", category: "money" },
  {
    highlight: /\bacceptance\b/gi,
    keyword: "Acceptance",
    category: "unnatural",
  },
  {
    highlight: /\baccordingly\b/gi,
    keyword: "Accordingly",
    category: "unnatural",
  },
  {
    highlight: /\baccount-based marketing (abm)\b/gi,
    keyword: "Account-based marketing (ABM)",
    category: "unnatural",
  },
  { highlight: /\baccounts\b/gi, keyword: "Accounts", category: "unnatural" },
  { highlight: /\baddresses\b/gi, keyword: "Addresses", category: "unnatural" },
  {
    highlight: /\baddresses on cd\b/gi,
    keyword: "Addresses on CD",
    category: "unnatural",
  },
  { highlight: /\bbeverage\b/gi, keyword: "Beverage", category: "unnatural" },
  {
    highlight: /\bconfidentiality on all orders\b/gi,
    keyword: "Confidentiality on all orders",
    category: "unnatural",
  },
  {
    highlight: /\bconfidentially on all orders\b/gi,
    keyword: "Confidentially on all orders",
    category: "unnatural",
  },
  {
    highlight: /\bcontent marketing\b/gi,
    keyword: "Content marketing",
    category: "unnatural",
  },
  {
    highlight: /\bdear(est)? (.+@.+)\b/gi,
    keyword: "Dear [email address]",
    category: "unnatural",
  },
  {
    highlight: /\bdear(est)? (email|friend|somebody)\b/gi,
    keyword: "Dear [email/friend/somebody]",
    category: "unnatural",
  },
  {
    highlight: /\bdear [first name]\b/gi,
    keyword: "Dear [first name]",
    category: "unnatural",
  },
  {
    highlight: /\bdear [wrong name]\b/gi,
    keyword: "Dear [wrong name]",
    category: "unnatural",
  },
  {
    highlight: /\bdigital marketing\b/gi,
    keyword: "Digital marketing",
    category: "unnatural",
  },
  { highlight: /\bdormant\b/gi, keyword: "Dormant", category: "unnatural" },
  {
    highlight: /\bemail extractor\b/gi,
    keyword: "Email extractor",
    category: "unnatural",
  },
  {
    highlight: /\bemail harvest\b/gi,
    keyword: "Email harvest",
    category: "unnatural",
  },
  {
    highlight: /\bemail marketing\b/gi,
    keyword: "Email marketing",
    category: "unnatural",
  },
  {
    highlight: /\bextract email\b/gi,
    keyword: "Extract email",
    category: "unnatural",
  },
  { highlight: /\bform\b/gi, keyword: "Form", category: "unnatural" },
  { highlight: /\bfreedom\b/gi, keyword: "Freedom", category: "unnatural" },
  { highlight: /\bfriend\b/gi, keyword: "Friend", category: "unnatural" },
  { highlight: /\bhere\b/gi, keyword: "Here", category: "unnatural" },
  { highlight: /\bhidden\b/gi, keyword: "Hidden", category: "unnatural" },
  { highlight: /\bhome\b/gi, keyword: "Home", category: "unnatural" },
  {
    highlight: /\bhome based\b/gi,
    keyword: "Home based",
    category: "unnatural",
  },
  {
    highlight: /\bhome employment\b/gi,
    keyword: "Home employment",
    category: "unnatural",
  },
  {
    highlight: /\bhome-based\b/gi,
    keyword: "Home-based",
    category: "unnatural",
  },
  {
    highlight: /\bhome-based business\b/gi,
    keyword: "Home-based business",
    category: "unnatural",
  },
  {
    highlight: /\bhomebased business\b/gi,
    keyword: "Homebased business",
    category: "unnatural",
  },
  {
    highlight: /\bif you no longer wish to receive\b/gi,
    keyword: "If you no longer wish to receive",
    category: "unnatural",
  },
  {
    highlight: /\bimportant information regarding\b/gi,
    keyword: "Important information regarding",
    category: "unnatural",
  },
  {
    highlight: /\bin accordance with laws\b/gi,
    keyword: "In accordance with laws",
    category: "unnatural",
  },
  {
    highlight: /\bincrease your sales\b/gi,
    keyword: "Increase your sales",
    category: "unnatural",
  },
  {
    highlight: /\binternet market\b/gi,
    keyword: "Internet market",
    category: "unnatural",
  },
  { highlight: /\bleave\b/gi, keyword: "Leave", category: "unnatural" },
  { highlight: /\blose\b/gi, keyword: "Lose", category: "unnatural" },
  {
    highlight: /\bmaintained\b/gi,
    keyword: "Maintained",
    category: "unnatural",
  },
  { highlight: /\bmarketing\b/gi, keyword: "Marketing", category: "unnatural" },
  {
    highlight: /\bmarketing solution\b/gi,
    keyword: "Marketing solution",
    category: "unnatural",
  },
  {
    highlight: /\bmarketing solutions\b/gi,
    keyword: "Marketing solutions",
    category: "unnatural",
  },
  { highlight: /\bmedium\b/gi, keyword: "Medium", category: "unnatural" },
  {
    highlight: /\bmessage contains\b/gi,
    keyword: "Message contains",
    category: "unnatural",
  },
  {
    highlight: /\bmulti level marketing\b/gi,
    keyword: "Multi level marketing",
    category: "unnatural",
  },
  { highlight: /\bnever\b/gi, keyword: "Never", category: "unnatural" },
  {
    highlight: /\bone time mailing\b/gi,
    keyword: "One time mailing",
    category: "unnatural",
  },
  {
    highlight: /\bonline marketing\b/gi,
    keyword: "Online marketing",
    category: "unnatural",
  },
  {
    highlight: /\bonline pharmacy\b/gi,
    keyword: "Online pharmacy",
    category: "unnatural",
  },
  { highlight: /\bopt in\b/gi, keyword: "Opt in", category: "unnatural" },
  { highlight: /\bper day\b/gi, keyword: "Per day", category: "unnatural" },
  { highlight: /\bper week\b/gi, keyword: "Per week", category: "unnatural" },
  {
    highlight: /\bpre-approved\b/gi,
    keyword: "Pre-approved",
    category: "unnatural",
  },
  { highlight: /\bproblem\b/gi, keyword: "Problem", category: "unnatural" },
  { highlight: /\bremoval\b/gi, keyword: "Removal", category: "unnatural" },
  { highlight: /\bremove\b/gi, keyword: "Remove", category: "unnatural" },
  {
    highlight: /\breserves the right\b/gi,
    keyword: "Reserves the right",
    category: "unnatural",
  },
  { highlight: /\breverses\b/gi, keyword: "Reverses", category: "unnatural" },
  { highlight: /\bsample\b/gi, keyword: "Sample", category: "unnatural" },
  {
    highlight: /\bsatisfaction\b/gi,
    keyword: "Satisfaction",
    category: "unnatural",
  },
  { highlight: /\bscore\b/gi, keyword: "Score", category: "unnatural" },
  {
    highlight: /\bsearch engine\b/gi,
    keyword: "Search engine",
    category: "unnatural",
  },
  {
    highlight: /\bsearch engine listings\b/gi,
    keyword: "Search engine listings",
    category: "unnatural",
  },
  {
    highlight: /\bsearch engines\b/gi,
    keyword: "Search engines",
    category: "unnatural",
  },
  {
    highlight: /\bsent in compliance\b/gi,
    keyword: "Sent in compliance",
    category: "unnatural",
  },
  { highlight: /\bsolution\b/gi, keyword: "Solution", category: "unnatural" },
  { highlight: /\bstop\b/gi, keyword: "Stop", category: "unnatural" },
  { highlight: /\bsuccess\b/gi, keyword: "Success", category: "unnatural" },
  { highlight: /\bteen\b/gi, keyword: "Teen", category: "unnatural" },
  {
    highlight: /\bterms and conditions\b/gi,
    keyword: "Terms and conditions",
    category: "unnatural",
  },
  { highlight: /\bwarranty\b/gi, keyword: "Warranty", category: "unnatural" },
  {
    highlight: /\bweb traffic\b/gi,
    keyword: "Web traffic",
    category: "unnatural",
  },
  { highlight: /\bwife\b/gi, keyword: "Wife", category: "unnatural" },
  {
    highlight: /\bwork at home\b/gi,
    keyword: "Work at home",
    category: "unnatural",
  },
  {
    highlight: /\bwork from home\b/gi,
    keyword: "Work from home",
    category: "unnatural",
  },
];
const colorCode = {
  urgency: "spam-urgency",
  shady: "spam-shady",
  overpromise: "spam-overpromise",
  unnatural: "spam-unnatural",
  money: "spam-money",
};
const multiWordEntries = spamArray.filter((e) => !/^\w+$/.test(e.keyword));
const singleWordEntries = spamArray.filter((e) => /^\w+$/.test(e.keyword));
export const SPAM_WORDS = multiWordEntries
  .concat(singleWordEntries)
  .map((e) => {
    return { ...e, className: colorCode[e.category] };
  });

export const emailDataStructure = [
  { title: "Creativeness", options: CREATIVENESS, id: "creativeness" },
  { title: "Length", options: LENGTH, id: "length" },
  { title: "Tone", options: TONE, id: "tone" },
  { title: "Custom Language", options: LANGUAGE, id: "customLanguage" },
];
export const emailContentData = [
  { title: "Opening Lines", options: OPENING, id: "opening" },
  { title: "Connecting Statement", options: CONNECTING, id: "connecting" },
  { title: "Body Paragraph", options: BODY, id: "body" },
];

export const callToActionModels = {
  personlizedVideo: "Personalized Video + AI Chat Agent",
  chatAgentOnly: "AI Chat Agent",
  customNoLink: "Traditional / Reply-Based Approach",
  customLinkable: "Redirect Link",
};
