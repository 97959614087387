import { useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  FormControl,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import {
  CloseTwoTone,
  CloudUploadTwoTone,
  TaskAlt,
  WarningAmberOutlined,
} from "@mui/icons-material";
import LinearProgress from "@mui/material/LinearProgress";
import { toast } from "react-hot-toast";
import AWS from "aws-sdk";
import { UploadColoredIcon } from "src/components/logos/UploadColoredIcon";
import Papa from "papaparse";
import axios from "axios";
import { API_SERVICE_BACKEND } from "src/config";
import SampleEmails from "src/assets/samples/sample-emails.csv";
import SampleDomains from "src/assets/samples/sample-domains.csv";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const selections = [
  { label: "Do not import", value: "doNotImport" },
  { label: "Email", value: "email" },
];

const FileUploadRow = ({
  column,
  sample,
  selections,
  onChange,
  isUploading,
}) => {
  const initialSelected =
    selections.find((s) => s.label === column) || selections[0];

  const [selected, setSelected] = useState(initialSelected);

  const handleChange = (event) => {
    const selection = selections.find((s) => s.value === event.target.value);
    console.log(selection,"selection")
    setSelected(selection);
    onChange(selection);
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 240,
      },
    },
  };

  return (
    <TableRow>
      <TableCell>{column}</TableCell>
      <TableCell width={240}>
        <FormControl fullWidth size="small" sx={{ maxHeight: "300px" }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selected.value}
            onChange={handleChange}
            variant="outlined"
            MenuProps={MenuProps}
            disabled={isUploading}
          >
            {selections.map((selection, index) => (
              <MenuItem value={selection.value} key={index}>
                {selection.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>{sample}</TableCell>
    </TableRow>
  );
};

const CsvImport = ({
  setIsImportLeadsDialogOpen,
  selectedFile,
  setSelectedFile,
  fileInputKey,
  value,
  userEmail,
  fetchBlackListedData,
}) => {
  const fileInputRef = useRef(null);
  const [isDragActive, setIsDragActive] = useState(false);
  const [closeButtonMouseEnter, setCloseButtonMouseEnter] = useState(false);
  const [samples, setSamples] = useState([]);
  const [fileColumns, setFileColumns] = useState([]);
  const [progress, setProgress] = useState(0);
  const [csvData, setCsvData] = useState([]);

  const [isUploading, setIsUploading] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragActive(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragActive(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragActive(false);

    const file = e.dataTransfer.files[0];
    handleFileUpload(file);
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    handleFileUpload(file);
  };

  const isValidDomain = (domain) => {
    const regex =
      /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
    return domain !== "" ? regex.test(domain) : false;
  };
  const isValidEmail = (email) => {
    const regex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
    return email !== "" ? regex.test(email) : false;
  };

  const handleFileUpload = (file) => {
    setProgress(0);
    if (file) {
      const allowedTypes = ["text/csv"];

      if (allowedTypes.includes(file.type)) {
        //setSelectedFile(file);

        const reader = new FileReader();

        reader.onprogress = (event) => {
          const { total, loaded } = event;
          setProgress((loaded / total) * 100);
        };

        reader.onload = (event) => {
          // const csv = Papa.parse(event.target.result, { skipEmptyLines: true });
          // if (csv?.data?.[0]?.length > 1) {
          //   alert("Invalid File Format! Please refer to the sample file!");
          //   return;
          // }
          // setSelectedFile(file);
          // let cleanArray = [];
          // const parsedData = csv?.data;
          // parsedData.forEach((arr) => (cleanArray = cleanArray.concat(arr)));
          // setSamples(cleanArray.slice(0, 5));
          // setCsvData(cleanArray);
          const contents = event.target.result;
          const lines = contents?.split("\n");
          if (lines?.[0]?.split(",")?.length > 1) {
            alert("Invalid File Format! Please refer to the sample file!");
            const input = document.getElementById('import-file-input');
            input.value = null;
            setSelectedFile(null)
            return;
          }
          if (lines?.length > 10000) {
            alert(`Cannot import more than 10,000 ${value === 1 ? "domains" : "emails"}!`);
            const input = document.getElementById('import-file-input');
            input.value = null;
            setSelectedFile(null)
            return;
          }
          setSelectedFile(file);

          let filteredCsvData = [];

          for (const line of lines) {
            const isValidData =
              value === 1
                ? isValidDomain(line.trim())
                : isValidEmail(line.trim());
            if (isValidData) {
              filteredCsvData = filteredCsvData?.concat(line.trim());
            }
          }
          if (!filteredCsvData.length) {
            setSamples([]);
            setCsvData([]);
            return toast.error(
              `${value === 0 ? "Emails" : "Domains"} not found.`,
              {
                duration: 4000,
                position: "top-center",

                // Custom Icon
                icon: "❌",

                // Change colors of success/error/loading icon
                iconTheme: {
                  primary: "#000",
                  secondary: "#fff",
                },

                // Aria
                ariaProps: {
                  role: "status",
                  "aria-live": "polite",
                },
              }
            );
          }
          setSamples(filteredCsvData?.slice(0, 5));
          setCsvData(filteredCsvData);
        };

        reader.readAsText(file);
      } else {
        alert("Only .csv files are allowed.");
      }
    }
  };

  const handleDeleteFile = () => {
    fileInputRef.current.value = "";
    setSelectedFile(null);
    setCloseButtonMouseEnter(false);
    setProgress(0);
    setFileColumns([]);
    setCsvData([]);
  };

  const handleSubmit = () => {
    handleFileUploadAll();
  };

  AWS.config.update({
    region: "us-east-1",
    accessKeyId: process.env.REACT_APP_WASABI_ID,
    secretAccessKey: process.env.REACT_APP_WASABI_ACCESS,
  });

  const s3 = new AWS.S3({
    endpoint: "s3.wasabisys.com",
    s3ForcePathStyle: true,
    signatureVersion: "v4",
  });

  const uploadToS3 = async (file, fileName) => {
    const campaignId = false;
    let newFileName = `${value === 1 ? "domains" : "emails"}_${fileName}`;
    if (campaignId) {
      newFileName = `${campaignId}_` + newFileName;
    }
    const uploadParams = {
      Bucket: "b2b-rocket",
      Key: `exclusionList/${userEmail}/${newFileName}`,
      Body: file,
    };

    return new Promise((resolve, reject) => {
      s3.upload(uploadParams, (err, data) => {
        if (err) {
          console.error("Upload failed:", err);
          reject(err);
        } else {
          console.log("Upload Success:", data);
          resolve(data.Location);
        }
      });
    });
  };

  const getS3SignedUrl = async (key) => {
    const campaignId = false;
    let newFileName = `${value === 1 ? "domains" : "emails"}_${key}`;
    if (campaignId) {
      newFileName = `${campaignId}_` + newFileName;
    }
    const params = {
      Bucket: "b2b-rocket",
      Key: `exclusionList/${userEmail}/${newFileName}`,
      Expires: 60 * 60 * 24 * 7,
      // Expires: 60 * 5
    };

    return new Promise((resolve, reject) => {
      s3.getSignedUrl("getObject", params, (err, url) => {
        if (err) {
          reject(err);
        } else {
          resolve(url);
        }
      });
    });
  };

  const handleFileUploadAll = async () => {
    if (!selectedFile) {
      console.log("No file selected.");
      return;
    }
    try {
      setIsUploading(true);
      const now = new Date().toISOString();
      const newName = `${now}_${selectedFile.name}`;
      const uploadUrl = await uploadToS3(selectedFile, newName);
      console.log("File uploaded:", uploadUrl);
      const signedUrl = await getS3SignedUrl(newName);
      console.log(fileColumns);
      console.log("Signed URL:", signedUrl);
      const body =
        value === 0
          ? {
              clientEmail: userEmail,
              uploadedExclusionList: [
                { url: signedUrl, operation: "addEmails" },
              ],
            }
          : value === 1
          ? {
              clientEmail: userEmail,
              uploadedExclusionList: [
                { url: signedUrl, operation: "addDomains" },
              ],
            }
          : {};

      const response = await axios.put(
        `${API_SERVICE_BACKEND}/exclusionlist/updateExclusionList`,
        body
      );
      console.log("response", response);
      setIsUploading(false);
      setIsImportLeadsDialogOpen(false);
      const type = value === 1 ? "domains" : "emails";
      fetchBlackListedData(type);
      toast.success("Successfully uploaded!");
    } catch (error) {
      setIsUploading(false);
      console.error("Error during file upload:", error);
      toast.success("Something went wrong!");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Stack
        sx={{
          borderRadius: 1,
          border: "1px solid red",
          width: "auto",
          p: 1,
        }}
        rowGap={1}
      >
        <Typography
          sx={{
            fontSize: "14px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 0.5,
          }}
        >
          <WarningAmberOutlined sx={{ color: "red", fontSize: "18px" }} />
          Please ensure that the uploaded file is in the correct format.
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography sx={{ fontSize: "14px" }}>
            Refer to the sample format provided here:
          </Typography>
          <a
            href={value === 1 ? SampleDomains : SampleEmails}
            download
            style={{
              textDecoration: "none",
              fontSize: "14px",
              fontWeight: 500,
            }}
          >
            Sample {value === 1 ? "Domains" : "Emails"}
          </a>
        </Box>
      </Stack>

      <Paper
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        sx={{
          width: "100%",
          height: "260px",
          borderRadius: "12px",
          border: "1px dashed #0071F6",
          mt: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          position: "relative",
          boxShadow: "0px 12px 15px 0px #4B71970D",
          backgroundColor: "#F2F4F6",
        }}
        onClick={() => {
          !closeButtonMouseEnter && fileInputRef.current.click();
        }}
      >
        <input
          key={fileInputKey}
          type="file"
          // multiple
          id="import-file-input"
          style={{ display: "none" }}
          onChange={handleFileInputChange}
          ref={fileInputRef}
          accept=".csv"
          disabled={isUploading}
        />

        {selectedFile ? (
          <>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 700,
                lineHeight: "25px",
                color: "#0071F6",
              }}
            >
              Size: {(selectedFile.size / 1000).toFixed(2)} KB
            </Typography>
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <UploadColoredIcon />
          </Box>
        )}

        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "25px",
            color: "#0071F6",
          }}
        >
          {selectedFile
            ? selectedFile.name
            : isDragActive
            ? "Drop the files here ..."
            : "Drag files to this area or click to pick files."}
        </Typography>
        {selectedFile && (
          <IconButton
            onMouseEnter={() => {
              setCloseButtonMouseEnter(true);
            }}
            onMouseLeave={() => {
              setCloseButtonMouseEnter(false);
            }}
            sx={{ position: "absolute", right: 0, top: 0, m: 1 }}
            onClick={handleDeleteFile}
            disabled={isUploading}
          >
            <CloseTwoTone sx={{ color: "black" }} />
          </IconButton>
        )}
        {selectedFile && (
          <Box
            sx={{ width: "80%", mt: 2, display: progress === 100 && "none" }}
          >
            <LinearProgress variant="determinate" value={progress} />
          </Box>
        )}
      </Paper>
      {selectedFile && progress === 100 && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              mt: 2,
            }}
          >
            <TaskAlt sx={{ color: "rgb(33, 111, 237)", mr: 1 }} />
            <Typography sx={{ fontWeight: 600, color: "rgb(33, 111, 237)" }}>
              Files Processed
            </Typography>
          </Box>
          {csvData?.length > 0 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 1,
                m: 2,
              }}
            >
              <Typography
                sx={{ fontSize: "16px", fontWeight: 500, minWidth: "150px" }}
              >
                Sample {value === 1 ? "Domains" : "Emails"}
              </Typography>
              <Typography sx={{ fontSize: "14px" }}>
                {samples?.join(", ")}...
              </Typography>
            </Box>
          )}

          <Button
            variant="contained"
            sx={{
              fontSize: "16px",
              px: 3,
              py: 1.5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 1,
            }}
            onClick={handleSubmit}
            disabled={!csvData.length}
          >
            {isUploading ? (
              <CircularProgress size={20} sx={{ color: "white", mr: 1 }} />
            ) : (
              <CloudUploadTwoTone sx={{ mr: 1 }} />
            )}
            Submit
          </Button>
        </>
      )}
    </Box>
  );
};

export default CsvImport;
