import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Radio,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { apollo_COUNTRIES, apollo_country_list } from "src/data/countries";
import { CustomCompSize } from "./job-details-step";
import { FILTERS, FUNDING } from "src/data/campaign";
import AddIcon from "@mui/icons-material/Add";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ApolloCustomCompSize } from "./apolloCustomCompSize";
import { API_SERVICE_BACKEND } from "src/config";
import axios from "axios";

const CustomRenderOption = (props) => {
  const {
    extra,
    option,
    state,
    ownerState,
    setOwnerState,
    setValue: onChange,
    vid,
    locationValue,
    setShowSubHeadings,
    singleSelect,
    setInputValue,
  } = props;

  const [expanded, setExpanded] = useState(false);
  const [selected, setSelected] = useState(
    locationValue.findIndex(({ title }) => title === option.title) !== -1
  );

  useEffect(() => {
    setSelected(
      locationValue.findIndex(({ title }) => title === option.title) !== -1
    );
  }, [locationValue]);

  const handleChange = (e) => {
    setSelected(e.target?.checked ? e.target?.checked : e);
    setInputValue("");
    if (e.target?.checked) {
      if (!option.grpId) {
        let allSelected = apollo_country_list.filter((countryObj) => {
          if (option.title) {
            return countryObj.title === option.title;
          } else {
            return countryObj.title === option;
          }
        });
        setOwnerState((prev) => {
          const curr = prev[vid];
          // console.log(curr, "setOwnerState");
          const uniqueCheck = new Set(
            curr
              .filter((val) => typeof val === "object" && val.title)
              .map((obj) => obj.title)
          );
          curr
            .filter((val) => typeof val === "string")
            .forEach((str) => uniqueCheck.add(str));
          allSelected = allSelected.filter(
            ({ title }) => !uniqueCheck.has(title)
          );
          let newValue = [...curr, ...allSelected];
          return {
            ...prev,
            [vid]: singleSelect ? newValue.slice(-1) : newValue,
          };
        });
      } else {
        setOwnerState((prev) => {
          const newValue = [...prev[vid], option.title];
          const totCount = apollo_country_list.filter(
            ({ grpId }) => grpId === option.grpId
          ).length;

          let headSubSelCount = 0;
          newValue.forEach(({ title, grpId }) => {
            if (grpId === option.grpId) {
              headSubSelCount += 1;
            }
          });
          if (totCount === headSubSelCount) {
            newValue.push(option.grpId);
          }
          return {
            ...prev,
            [vid]: singleSelect ? newValue.slice(-1) : newValue,
          };
        });
      }
    } else {
      if (!option.grpId) {
        let allSelected = apollo_country_list.filter((countryObj) => {
          if (option.title) {
            return countryObj.title === option.title;
          } else {
            return countryObj.title === option;
          }
        });
        setOwnerState((prev) => {
          const curr = prev[vid];
          const uniqueCheck = new Set(
            curr
              .filter((val) => typeof val === "object" && val.title)
              .map((obj) => obj.title)
          );
          curr
            .filter((val) => typeof val === "string")
            .forEach((str) => uniqueCheck.add(str));
          allSelected = allSelected.filter(
            ({ title }) => !uniqueCheck.has(title)
          );
          let newValue = [...curr, ...allSelected];
          return {
            ...prev,
            [vid]: singleSelect ? newValue.slice(-1) : newValue,
          };
        });
      } else {
        setOwnerState((prev) => {
          let newValue = prev[vid].filter(
            ({ title }) => title !== option.title
          );

          const totCount = apollo_country_list.filter(
            ({ grpId }) => grpId === option.grpId
          ).length;

          let headSubSelCount = 0;
          newValue.forEach(({ title, grpId }) => {
            if (grpId === option.grpId) {
              headSubSelCount += 1;
            }
          });
          if (totCount !== headSubSelCount) {
            newValue = newValue.filter(
              ({ title, grpId }) => title !== option.grpId
            );
          }
          return {
            ...prev,
            [vid]: singleSelect ? newValue.slice(-1) : newValue,
          };
        });
      }
    }
  };

  useEffect(() => {
    if (expanded) {
      setShowSubHeadings((prev) => [...prev, option.title]);
    } else {
      setShowSubHeadings((prev) => prev.filter((val) => option.title !== val));
    }
  }, [expanded]);

  const handleTextClick = (e) => {
    handleChange(e);
  };

  return (
    <>
      <Stack sx={{ width: "100%", ml: Boolean(option.grpId) ? 2 : 0 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center">
            <Checkbox
              style={{ marginRight: 8 }}
              checked={selected}
              onChange={handleChange}
            />
            <Typography
              sx={{ fontSize: "14px" }}
              onClick={() => handleTextClick(option.title)}
            >
              {option.title}{" "}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

const mapTitleToValue = (options, title) => {
  const found = options.find((option) => option.title === title);
  return found ? found.value : null;
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Filter = (props) => {
  const {
    name,
    view,
    type,
    vid,
    customVID,
    state,
    setState,
    onChange,
    singleSelect,
    options,
    fields,
    setFields,
    editCampaign,
    locationError,
    valueField,
    ismdUp,
  } = props;
  const [employeOption, setEmployeOption] = useState(options);
  const [domain, setDomain] = useState([]);
  const [domainError, setDomainError] = useState(false);
  const [loading, setLoading] = useState(false);
  const disabled = view === true;
  const isRangeSlider = type === "range-slider";
  const isCustomRevenueExist =
    vid === "revenue" &&
    (state.revenue.length === 0 ||
      FILTERS[2].options.values.findIndex(
        (rev) => rev[0] === state.revenue?.[0] && rev[1] === state.revenue?.[1]
      ) === -1);
  const [customRevenue, setCustomRevenue] = useState(isCustomRevenueExist);

  useEffect(() => {
    setCustomRevenue(isCustomRevenueExist);
  }, [isCustomRevenueExist]);
  const [revInpRes, setRevInpRes] = useState(
    isRangeSlider ? Boolean(state[vid]?.length) : false
  );
  const [temp, setTemp] = useState();
  const [revenueCheck, setRevenueCheck] = useState(true);
  const [minRevError, setMinRevError] = useState("");
  const [maxRevError, setMaxRevError] = useState("");
  const [minFundError, setMinFundError] = useState("");
  const [maxFundError, setMaxFundError] = useState("");

  useEffect(() => {
    if (!isRangeSlider || Boolean(state[vid]?.length)) return;
    onChange(vid, revenueCheck ? [options.range?.min, options.range?.max] : []);
  }, [revenueCheck]);

  useEffect(() => {
    setRevInpRes(isRangeSlider ? Boolean(state[vid]?.length) : false);
  }, [state]);

  useEffect(() => {
    setRevenueCheck(true);
  }, [revInpRes]);
  const domainFun = () => {
    const allPayload = {
      q_organization_name: "",
    };

    const res = axios
      .post(`${API_SERVICE_BACKEND}/search-block-domains`, {
        allPayload,
      })
      .then((res) => {
        // console.log("res::::::::::::::::::::: ", res);
        setDomain(res.data);
      });
  };

  useEffect(() => {
    domainFun();
  }, []);

  const [showSubHeadings, setShowSubHeadings] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [checkedOption, setCheckedOption] = useState(null);
  const [funding, setFunding] = useState([]);
  const [blockedDomains, setBlockedDomains] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [locationInputValue, setLocationInputValue] = useState("");
  const callToActionModels = [
    { label: "Predefined Range", value: "predefined" },
    { label: "Custom Range", value: "cutomRange" },
  ];

  useEffect(() => {
    if (
      state.customCompSize &&
      state.customCompSize !== undefined &&
      (state.customCompSize.min !== 0 || state.customCompSize.max !== 0)
    ) {
      setCheckedOption(callToActionModels[1].value);
    } else if (
      state.compSize &&
      state.compSize !== undefined &&
      state.compSize?.length !== 0
    ) {
      setCheckedOption(callToActionModels[0].value);
    }
  }, [state.compSize]);

  const handleCtaChange = (e, option) => {
    const isChecked = e.target.checked;
    const value = option.value;
    if (isChecked) {
      setCheckedOption(value);
    }
  };

  const handleChange = (e, newValue) => {
    const selectedValue = newValue[0]?.value || [];
    setFunding(selectedValue);
  };
  const aaaaaa = (newValue) => {
    // console.log("ioldvalue :::::: ", newValue);
  };

  const handleChangess = async (newValue) => {
    // console.log("newValue: ", newValue);

    if (newValue.toString() !== "") {
      const allPayload = {
        q_organization_name: newValue.toString(),
      };
      // const debounceTimer = setTimeout(async () => {
      setLoading(true);
      const res = await axios.post(
        `${API_SERVICE_BACKEND}/search-block-domains`,
        {
          allPayload,
        }
      );

      // console.log(res.data, "handleChangess");
      if (res.data.length === 0) {
        setDomainError(true);
      } else {
        setLoading(false);
        setDomainError(false);
        setDomain(res.data);
      }
    }
  };

  useEffect(() => {
    // Define a function to handle debouncing
    const debounceTimer = setTimeout(async () => {
      handleChangess(inputValue);
    }, 2000);
    return () => clearTimeout(debounceTimer);
  }, [inputValue]); // Run effect whenever inputValue changes

  const hadleBlockedDomainChange = (e, value) => {
    setBlockedDomains(value);
    if (e.key !== "Enter") {
      onChange("blockedDomains", value);
    }
  };
  // console.log("state :: ::", state);
  // console.log("state[vid] :: ::", state[vid]);
  switch (true) {
    case ["location", "blockedLocations"].includes(vid):
      let tempValue = state[customVID ? customVID : vid];
      const locationValue = tempValue.map((location) => {
        if (typeof location.title === "string") {
          return location;
        } else if (typeof location.title === "object" && location.title.title) {
          if (typeof location.title.title === "string") {
            return location;
          }
        } else {
          const country = location?.split("::~")[0];
          return {
            title: country,
            value: mapTitleToValue(apollo_country_list, country),
          };
        }
      });

      const handleClear = (e) => {
        console.log("clicked TARGET ELEMENT: ", e.target);
        setInputValue("");
      };

      return (
        <Box sx={{ width: "100%" }}>
          <Autocomplete
            freeSolo
            disabled={disabled}
            multiple
            id="filter-location"
            sx={{ width: "100%", mt: 0 }}
            options={apollo_country_list}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            disableCloseOnSelect
            getOptionLabel={(option) => option.title || option}
            renderOption={(props, option, persState) => {
              if (
                option.grpId &&
                !showSubHeadings.includes(option.grpId) &&
                !isSearching
              )
                return;
              return (
                <CustomRenderOption
                  extra={props}
                  option={option}
                  state={persState}
                  ownerState={state}
                  vid={customVID ? customVID : vid}
                  setShowSubHeadings={setShowSubHeadings}
                  setValue={onChange}
                  setOwnerState={setState}
                  locationValue={locationValue}
                  singleSelect={singleSelect}
                  setInputValue={setInputValue}
                />
              );
            }}
            renderInput={(params) => (
              <Stack spacing={1}>
                <TextField
                  {...params}
                  label={
                    vid === "blockedLocations"
                      ? "Exclude Locations"
                      : view
                      ? "Locations"
                      : "Choose Locations"
                  }
                  variant="filled"
                  style={{ backgroundColor: "white", borderRadius: "8px" }}
                  onClick={handleClear}
                />
              </Stack>
            )}
            value={locationValue ? locationValue : ""}
            onChange={(event, newValue, reason) => {
              if (reason === "removeOption") {
                onChange(
                  customVID ? customVID : vid,
                  singleSelect ? newValue.slice(-1) : newValue
                );
              }
              if (reason === "clear") {
                onChange(customVID ? customVID : vid, []);
              }
            }}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  label={option.title}
                  {...getTagProps({ index })}
                  sx={{
                    backgroundColor: "#111927",
                    color: "white",
                    "& .MuiChip-deleteIcon": {
                      backgroundColor: "transparent !important",
                      color: "white !important",
                      display: view ? "none" : "",
                      "&:hover": {
                        color: "white",
                      },
                    },
                  }}
                />
              ))
            }
          />
          {locationError && (
            <Typography color="error" sx={{ fontSize: "12px" }}>
              {locationError}
            </Typography>
          )}

          {!view && !singleSelect && (
            <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
              <Button
                sx={{
                  cursor: "pointer",
                  fontSize: "14px",
                  fontWeight: 600,
                  mr: 0.5,
                  // mt: 1,
                  color: "blue",
                  "&:hover": { color: "darkblue" },
                }}
                onClick={() => {
                  onChange(vid, apollo_country_list);
                }}
              >
                Select all
              </Button>
              <Button
                sx={{
                  cursor: "pointer",
                  fontSize: "14px",
                  fontWeight: 600,
                  mr: 0.5,
                  // mt: 1,
                  color: "blue",
                  "&:hover": { color: "red" },
                }}
                onClick={() => {
                  onChange(vid, []);
                }}
              >
                Clear all
              </Button>
            </Box>
          )}
        </Box>
      );
    case ["funding"].includes(vid):
      let fundingValue = state[vid];
      const titles = fundingValue?.map((value) => {
        const funding = FUNDING.find((item) => item.value === value);
        return funding ? funding : [];
      });

      return (
        <Box
          sx={{
            borderRadius: 1,
          }}
        >
          {state[vid] && (
            <>
              {view && (
                <Typography
                  sx={{
                    color: view ? " #a19e9e" : "#111927",
                    fontSize: "14px",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  Funding
                </Typography>
              )}
              <Autocomplete
                multiple
                freeSolo
                disabled={disabled}
                id="funding"
                onChange={(event, value) => onChange("funding", value)}
                value={titles ? titles : []}
                options={FUNDING}
                getOptionLabel={(option) => option.title}
                disableCloseOnSelect
                // value={}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.title} {/* Render the 'title' property here */}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="filled"
                    label="Funding"
                    sx={{ mt: 1, backgroundColor: "white", borderRadius: 1 }}
                  />
                )}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      key={index}
                      label={option.title}
                      {...getTagProps({ index })}
                      sx={{
                        backgroundColor: "#111927",
                        color: "white",
                        "& .MuiChip-deleteIcon": {
                          backgroundColor: "transparent !important",
                          color: "white !important",
                          display: disabled ? "none" : "",
                          "&:hover": {
                            color: "white",
                          },
                        },
                      }}
                    />
                  ))
                }
              />

              <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                <Button
                  sx={{
                    cursor: "pointer",
                    fontSize: "14px",
                    fontWeight: 600,
                    mr: 0.5,
                    color: "blue",
                    "&:hover": { color: "darkblue" },
                  }}
                  onClick={() => {
                    onChange(vid, FUNDING);
                    setMaxFundError("");
                    setMinFundError("");
                  }}
                >
                  Select all
                </Button>
                <Button
                  sx={{
                    cursor: "pointer",
                    fontSize: "14px",
                    fontWeight: 600,
                    mr: 0.5,
                    color: "blue",
                    "&:hover": { color: "red" },
                  }}
                  onClick={() => {
                    onChange(vid, []);
                    setMaxFundError("");
                    setMinFundError("");
                  }}
                >
                  Clear all
                </Button>
              </Box>
            </>
          )}
        </Box>
      );
    case ["revenue"].includes(vid):
      return (
        <Box
          sx={{
            borderRadius: 1,
          }}
        >
          {revenueCheck && (
            <>
              {/* <Typography
                                  sx={{
                                      color: "#050C46",
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      marginBottom: "10px",
                                  }}
                              >
                                  Revenue
                              </Typography> */}
              <Stack
                direction="row"
                justifyContent="space-between"
                gap={1}
                sx={{ mt: 1 }}
              >
                <TextField
                  variant="outlined"
                  value={
                    state.vid?.[0] === 0
                      ? state[vid]?.[0]
                      : state[vid]?.[0] || ""
                  }
                  // disabled={view ? true : false}
                  placeholder="From"
                  // value={minRevenue}
                  size="small"
                  fullWidth
                  onChange={(e) => {
                    if (e.target.value < 0) {
                      setMinRevError("Number is too small");
                    } else {
                      setMinRevError("");
                      onChange(vid, [parseInt(e.target.value), state[vid][1]]);
                    }
                  }}
                  inputProps={{
                    min: 0,
                    max: 1000000000,
                    type: "number",
                  }}
                  sx={{
                    "&.MuiTextField-root .MuiOutlinedInput-root": {
                      backgroundColor: "white",
                    },
                  }}
                  error={!!minRevError}
                  helperText={!!minRevError ? minRevError : "Min"}
                />
                <TextField
                  variant="outlined"
                  value={
                    state[vid]?.[1] === 0
                      ? state[vid]?.[1]
                      : state[vid]?.[1] || ""
                  }
                  // disabled={view ? true : false}
                  placeholder="To"
                  size="small"
                  onChange={(e) => {
                    if (e.target.value > 10000000000) {
                      setMaxRevError("Number is too large");
                    } else {
                      setMaxRevError("");
                      onChange(vid, [state[vid][0], parseInt(e.target.value)]);
                    }
                  }}
                  fullWidth
                  inputProps={{
                    min: 0,
                    max: 1000000000,
                    type: "number",
                  }}
                  sx={{
                    "&.MuiTextField-root .MuiOutlinedInput-root": {
                      backgroundColor: "white",
                    },
                  }}
                  error={!!maxRevError}
                  helperText={!!maxRevError ? maxRevError : "Max"}
                />
              </Stack>

              <Stack direction={"row"} justifyContent={"flex-end"}>
                <Button
                  sx={{
                    cursor: "pointer",
                    fontSize: "14px",
                    fontWeight: 600,
                    mr: 0.5,
                    color: "blue",
                    "&:hover": { color: "red" },
                  }}
                  onClick={() => {
                    onChange(vid, []);
                    setMaxRevError("");
                    setMinRevError("");
                  }}
                >
                  Clear
                </Button>
              </Stack>
            </>
          )}
        </Box>
      );
    case ["compSize"].includes(vid):
      let emplyCount;

      if (editCampaign) {
        emplyCount = state.compSize;
        state.compSize.map((item) => {
          if (
            item === `${state.customCompSize?.min},${state.customCompSize?.max}`
          ) {
            return "Custom";
          }
          // If not "Custom", leave the item unchanged
          return item;
        });
      } else {
        let compSizeArray = state.compSize;
        // if (Array.isArray(compSizeArray) && compSizeArray?.length > 0) {
        //   let compSizeString = compSizeArray[0];
        //   let [lowerBound, upperBound] = compSizeString.split(",");
        //   let formattedCompSize = [`${lowerBound}-${upperBound}`];
        //   console.log(formattedCompSize,"formattedCompSize")
        //   emplyCount = formattedCompSize;
        // } else {
        emplyCount = state.compSize;
        // }
      }

      const handleCustomSize = (e, name) => {
        const { value } = e.target;
        setFields((pre) => ({
          ...pre,
          customCompSize: { ...pre.customCompSize, [name]: value },
        }));

        // setFields((prevCompSize) => {
        //     customCompSize:
        //     return [...prevCompSize, { [name]: value }];
        // });
      };

      return (
        <Box sx={{ width: "100%" }}>
          <>
            {callToActionModels.map((option, index) => (
              <>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Radio
                    checked={option.value === checkedOption}
                    onChange={(e) => handleCtaChange(e, option)}
                    name="radio-buttons"
                    inputProps={{ "aria-label": "A" }}
                  />
                  <Typography
                    sx={{
                      color: "#111927",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    {option.label}
                  </Typography>
                </div>
                {checkedOption === "predefined" &&
                  option.value === checkedOption && (
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        disabled={disabled}
                        id="company-size"
                        sx={{ backgroundColor: "white" }}
                        multiple
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        value={emplyCount || []}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          const filteredValue = newValue.filter(
                            (item) => item !== "Custom"
                          );
                          const isCustomSelected =
                            filteredValue.length !== newValue.length;
                          const updatedState = isCustomSelected
                            ? newValue
                            : newValue;
                          // console.log("updatedState: ", updatedState);

                          onChange(vid, updatedState);
                          setFields((pre) => ({
                            ...pre,
                            customCompSize: { min: 0, max: 0 },
                          }));
                        }}
                        input={<OutlinedInput id="select-multiple-chip" />}
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {selected?.length === 0 ? (
                              <span>Select</span>
                            ) : (
                              selected.map((range, index) => {
                                const arrRange = range.split(",");
                                let label;
                                if (range === "Custom") {
                                  label = range;
                                } else {
                                  label = range.split(",").join(" to ");
                                }
                                return (
                                  <Chip
                                    key={index}
                                    label={label}
                                    sx={{
                                      backgroundColor: "#111927",
                                      color: "white",
                                      "& .MuiChip-deleteIcon": {
                                        backgroundColor:
                                          "transparent !important",
                                        color: "white !important",
                                        "&:hover": {
                                          color: "white",
                                        },
                                      },
                                    }}
                                    onMouseDown={(e) => {
                                      e.stopPropagation();
                                    }}
                                    onDelete={(e) => {
                                      const temp = emplyCount.filter(
                                        (r) => !(r === range.toString())
                                      );
                                      // Check if the label is "Custom" and set state[vid][1] to null if true
                                      const updatedState =
                                        label === "Custom" ? temp : temp;
                                      onChange(vid, updatedState);
                                      if (label === "Custom") {
                                        setState((pre) => ({
                                          ...pre,
                                          customCompSize: { min: 0, max: 0 },
                                        }));
                                      }
                                    }}
                                  />
                                );
                              })
                            )}
                          </Box>
                        )}
                        // MenuProps={MenuProps}
                      >
                        <MenuItem key="placeholder-compsize" disabled value="">
                          Select
                        </MenuItem>
                        {employeOption.map((range, index) => {
                          let label;
                          if (index === employeOption.length - 1) {
                            label = range[0];
                          } else {
                            label = `${range[0]} - ${range[1]}`;
                          }
                          return (
                            <MenuItem key={index} value={range.toString()}>
                              {label}
                            </MenuItem>
                          );
                        })}
                        {/* <MenuItem key="Custom" value="Custom">
                      Custom
                    </MenuItem> */}
                      </Select>
                    </FormControl>
                  )}
                {checkedOption === "cutomRange" &&
                  option.value === checkedOption && (
                    <>
                      <ApolloCustomCompSize
                        handleCustomSize={handleCustomSize}
                        fields={fields}
                        customCompSize={state}
                        setFields={setFields}
                        onChange={onChange}
                        vid={vid}
                      />
                    </>
                  )}
              </>
            ))}
          </>
          {/* {((state?.compSize && state?.compSize.includes("Custom")) ||
              state?.compSize.includes(
                `${state?.customCompSize?.min},${state?.customCompSize?.max}`
              )) && (
                <CustomCompSize
                  handleCustomSize={handleCustomSize}
                  fields={fields}
                  setFields={setFields}
                />
              )} */}

          <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
            <Button
              sx={{
                cursor: "pointer",
                fontSize: "14px",
                fontWeight: 600,
                mr: 0.5,
                // mt: 1,
                color: "blue",
                "&:hover": { color: "darkblue" },
              }}
              onClick={() => {
                onChange(
                  vid,
                  options.map((range) => range.toString())
                );
              }}
            >
              Select all
            </Button>
            <Button
              sx={{
                cursor: "pointer",
                fontSize: "14px",
                fontWeight: 600,
                mr: 0.5,
                // mt: 1,
                color: "blue",
                "&:hover": { color: "red" },
              }}
              onClick={() => {
                setState((pre) => ({
                  ...pre,
                  customCompSize: { min: "", max: "" },
                }));
                onChange(vid, []);
              }}
            >
              Clear all
            </Button>
          </Box>
        </Box>
      );
    case [
      "keywords",
      "blockedKeywords",
      "allKeywords",
      "andCompanyKeywords",
    ].includes(vid):
      const keywordLimit = 25;
      return (
        <>
          {/* <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#050C46",
                          fontWeight: "500",
                        }}
                      >
                        {name}
                      </Typography> */}
          <Stack
            // mt={1}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <TextField
              variant="outlined"
              disabled={disabled}
              spellCheck={true}
              sx={{ backgroundColor: "white", borderRadius: 1 }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  document.getElementById(`${vid}newButton`).click();
                }
              }}
              InputProps={{
                startAdornment:
                  view &&
                  state[vid]?.map((val, index) => (
                    <Chip
                      disabled={view ? true : false}
                      key={index}
                      label={val}
                      onDelete={() => {
                        const temp = state[vid];
                        temp.splice(index, 1);
                        onChange(vid, temp);
                      }}
                      variant="filled"
                      sx={{
                        backgroundColor: "#111927",
                        color: "white",
                        "& .MuiChip-deleteIcon": {
                          backgroundColor: "transparent !important",
                          color: "white !important",
                          display: view ? "none" : "",
                          "&:hover": {
                            color: "white",
                          },
                        },
                      }}
                    />
                  )),
                endAdornment: (
                  <>
                    {!view && (
                      <InputAdornment position="end">
                        <Button
                          disabled={
                            state[vid] !== undefined &&
                            state[vid].length === keywordLimit
                          }
                          variant="contained"
                          id={`${vid}newButton`}
                          sx={{
                            ml: 2,
                            borderRadius: 1,
                            backgroundColor: "#5761FE",
                            padding: "8px 14px 8px 10px",
                            "& .MuiSvgIcon-root": { color: "#FFFFFF" },
                          }}
                          onClick={(e) => {
                            const inpEle = document.getElementById(
                              `${vid}newInput`
                            );
                            const keywords = inpEle.value
                              .split(",")
                              .map((val) => val.trim().toLowerCase());
                            let uniqueKeywords;
                            if (state[vid] !== undefined) {
                              uniqueKeywords = new Set([
                                ...keywords,
                                ...state[vid],
                              ]);
                            } else {
                              uniqueKeywords = new Set([
                                ...keywords,
                                state[vid],
                              ]);
                            }
                            const temp = [];
                            for (let keyword of uniqueKeywords) {
                              if (!keyword) continue;
                              temp.push(keyword);
                            }
                            onChange(vid, temp.sort());
                            inpEle.value = "";
                          }}
                        >
                          <AddIcon />
                          Add
                        </Button>
                      </InputAdornment>
                    )}
                  </>
                ),
              }}
              id={`${vid}newInput`}
              fullWidth
              title={name}
              label={
                view
                  ? name
                  : ismdUp
                  ? name
                  : name.length <= 5
                  ? name
                  : name.slice(0, 8) + " ..."
              }
            />
          </Stack>
          {state[vid] !== undefined && state[vid].length === keywordLimit && (
            <Typography color="error" style={{ fontSize: "12px" }}>
              {name} limit reached
            </Typography>
          )}
          <Stack
            direction="row"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            {!view && (
              <Stack
                mt={1}
                alignItems="center"
                direction="row"
                flexWrap="wrap"
                gap={1}
              >
                {state[vid]?.map((val, index) => (
                  <Chip
                    disabled={view ? true : false}
                    key={index}
                    label={val}
                    onDelete={() => {
                      const temp = state[vid];
                      temp.splice(index, 1);
                      onChange(vid, temp);
                    }}
                    variant="outlined"
                    sx={{
                      backgroundColor: "#111927",
                      color: "white",
                      "& .MuiChip-deleteIcon": {
                        backgroundColor: "transparent !important",
                        color: "white !important",
                        display: view ? "none" : "",
                        "&:hover": {
                          color: "white",
                        },
                      },
                    }}
                  />
                ))}
              </Stack>
            )}
            {/* <Box sx={{ display: "flex", flexDirection: "row-reverse" }}> */}
            {!view && (
              <Button
                sx={{
                  display: state[vid]?.length === 0 && "none",
                  cursor: "pointer",
                  fontSize: "14px",
                  fontWeight: 600,
                  mr: 0.5,
                  // mt: 1,
                  color: "blue",
                  "&:hover": { color: "red" },
                }}
                onClick={() => {
                  onChange(vid, []);
                }}
              >
                Clear all
              </Button>
            )}
            {/* </Box> */}
          </Stack>
        </>
      );
    case ["blockedDomains"].includes(vid):
      const limit = 15;
      let domainValue = state[vid];
      // console.log("domain :: ", domain);
      // const optionvalue=searchDataLIstState
      const domainTitles = domainValue.map((value) => {
        const matchedDomain = state?.selectedDomains?.find(
          (item) => item.id === value
        );
        return matchedDomain ? matchedDomain : null;
      });
      return (
        <>
          <Autocomplete
            multiple
            freeSolo
            loading={loading}
            disabled={disabled}
            options={domain}
            id="blockedDomains"
            onChange={hadleBlockedDomainChange}
            value={domainTitles ? domainTitles : []}
            // filterOptions={filterOptions}
            onInputChange={(e, newInputValue) => {
              setInputValue([newInputValue]);
            }}
            getOptionLabel={(option) => option?.primary_domain || option}
            disableCloseOnSelect
            renderOption={(props, option, { selected }) => {
              const isChecked = domainTitles.some(
                (title) => title.id === option.id
              );
              return (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={isChecked}
                    onChange={(event) => {
                      if (event.target.checked) {
                        hadleBlockedDomainChange(event, [
                          ...domainTitles,
                          option,
                        ]);
                      } else {
                        hadleBlockedDomainChange(
                          event,
                          domainTitles.filter((title) => title.id !== option.id)
                        );
                      }
                    }}
                  />
                  {option?.primary_domain ? option.primary_domain : option}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                id="outlined-basic"
                fullWidth
                variant="outlined"
                // label={label}
                placeholder="Enter blocked domains..."
                sx={{ mt: 1, backgroundColor: "white", borderRadius: 1 }}
                // onClick={aaaaaa}
              />
            )}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  label={
                    option?.primary_domain ? option?.primary_domain : option
                  }
                  {...getTagProps({ index })}
                  sx={{
                    backgroundColor: "#111927",
                    color: "white",
                    "& .MuiChip-deleteIcon": {
                      backgroundColor: "transparent !important",
                      color: "white !important",
                      display: disabled ? "none" : "",
                      "&:hover": {
                        color: "white",
                      },
                    },
                  }}
                />
              ))
            }
          />
          {domainError && (
            <Typography color="error" sx={{ fontSize: "12px", mt: "5px" }}>
              "No Results Found"
            </Typography>
          )}
        </>
      );
    default:
      return <Typography variant="subtitle1">{name} (Coming Soon)</Typography>;
  }
};

export default Filter;
