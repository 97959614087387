import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { CustomExperience } from "./job-details-step";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  MenuItem,
  Select,
  Stack,
  SvgIcon,
  TextField,
  Typography,
  Radio,
  InputLabel,
} from "@mui/material";
import {
  FILTERS,
  SIC_CODES,
  DEPARTMENTSOPTIONS,
  FUNDING,
  newIndustryTypes,
  technologies,
  MANAGEMENT_OPTIONS,
  YearsOfExperience,
  TITLES,
  INDUSTRY_OPTIONS,
  initApolloAddFilters,
  employeRange,
  TECHNOLOGYOPTIONS,
} from "../../../data/campaign";
import { alpha } from "@mui/system";
import Settings from "src/components/logos/settings.png";
import MultiSelect from "./multi-select";
import MultiInput from "./multi-input";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import LayerFilter from "./layer-filter";
import Filter from "./filter";
import Wrapper from "./wrapper";
import { ApolloCustomExperience } from "./apollocustomExperience";
import ApolloFilter from "./aplloFilter";
import _ from "lodash";
import ApolloTechFilter from "./apollotechFilters";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const ApolloUpdates = (props) => {
  const {
    // onBack,
    // onNext,
    // handleClearAllFilters,
    leadSource,
    fields,
    apolloFormData,
    locationError,
    selectedFilters,
    extraFilters,
    setExtraFilters,
    onFiltersChange,
    onClear,
    editCampaign,
    // occupationCount,
    // managementLevels,
    handleKeyPress,
    setApolloAdditionalFilters,
    setApolloIndustry,
    setApolloTag,
    SetSicCode,
    setApolloTechnology,
    setApolloAllTechnology,
    industryError,
    onChange,
    otherFiltersCount,
    limit,
    titleError,
    setManagementLevel,
    // setManagementLevels,
    // employerFilterCount,
    noEmployError,
    ismdUp,
    ...other
  } = props;

  const [industry, setIndustry] = useState(fields.apollo.industry);
  const [location, setLocation] = useState(null);
  const [jobChange, setJobChange] = useState("");
  const [keywordChange, setKeywordChange] = useState([]);
  const [radius, setRadius] = useState(
    fields.apollo?.additionalFilters?.radius || [0]
  );
  const [industries, setIndustries] = useState(fields.apollo.industry);
  const [blockedIndustries, setBlockedIndustries] = useState(
    fields?.apollo?.addFilters?.blockedIndustries
  );
  const [tag, setTag] = useState(fields.apollo?.targets);
  const [tags, setTags] = useState(fields.apollo?.targets);
  const [sicCode, setSicCode] = useState([]);
  const [sic, setSic] = useState(fields.apollo?.sicCode);
  const [technology, setTechnology] = useState(fields.apollo?.technology || []);
  const [allTechnology, setAllTechnology] = useState(
    fields.apollo?.allTechnology || []
  );
  const [changedJob, setchangedJob] = useState(fields.apollo?.changedJob);
  const [zipCode, setZipCode] = useState({
    code: fields?.apollo?.addFilters?.zip_code?.code || "",
    radius: fields?.apollo?.addFilters?.zip_code?.radius || "",
  });

  const [departments, setDepartments] = useState(
    fields.apollo?.additionalFilters?.departments || []
  );

  const [departmentsTitle, setDepartmentsTitle] = useState([]);
  const [managementTitle, setManagementTitle] = useState([]);
  const [managementLevels, setManagementLevels] = useState(
    fields?.apollo?.additionalFilters?.managementLevels || []
  );
  const [techTitle, setTechTitle] = useState(technology || []);
  const [deptTitle, setDeptTitle] = useState(departments || []);
  const [mangamentTitle, setMangamentTitle] = useState(managementLevels || []);
  const [jobChangeValue, setJobChangeValue] = useState(
    fields?.apollo?.additionalFilters?.changedJob || ""
  );

  const [industriesTitle, setIndustriesTitle] = useState([]);

  const [blockedindustriestitle, setBlockedindustriestitle] = useState([]);

  const initialExp = fields?.apollo?.additionalFilters?.experience;
  const [experience, setExperience] = useState(initialExp || "");
  const [experiencedata, setExperienceData] = useState([]);

  const [keywords, setkeywords] = useState(initialExp || "");

  const isCustomExp =
    !YearsOfExperience.includes(experience) && experience !== "";
  const [customExperience, setCustomExperience] = useState(isCustomExp);

  const [companies, setCompanies] = useState(
    fields.apollo?.additionalFilters?.companies || []
  );
  const [includePast, setIncludePast] = useState(
    fields.apollo?.additionalFilters?.includePast || false
  );

  const [excludeContacts, setExcludeContacts] = useState(
    fields.apollo?.additionalFilters?.excludeContacts || false
  );
  const employerFilterCount =
    fields?.apollo?.additionalFilters?.companies?.length +
    fields?.apollo?.addFilters?.blockedDomains?.length +
    fields?.apollo?.addFilters?.compSize?.length +
    fields?.apollo?.additionalFilters?.companies?.length +
    fields?.apollo?.addFilters?.funding?.length +
    (fields?.apollo?.addFilters?.revenue?.length !== 0 ? 1 : 0) +
    (((fields?.apollo?.addFilters?.customCompSize?.min !== "" && fields?.apollo?.addFilters?.customCompSize?.min !== 0) || (fields?.apollo?.addFilters?.customCompSize?.max !== "" && fields?.apollo?.addFilters?.customCompSize?.max !== 0)) ? 1 : 0);

  const industryFilterCount =
    fields?.apollo?.industry?.length +
    fields?.apollo?.addFilters?.blockedIndustries?.length +
    fields?.apollo?.sicCode?.length +
    fields?.apollo?.addFilters?.keywords?.length +
    fields?.apollo?.addFilters?.blockedKeywords?.length +
    fields?.apollo?.addFilters?.allKeywords?.length +
    fields?.apollo?.addFilters?.andCompanyKeywords?.length;

  const personCount =
    fields?.apollo?.targets?.length +
    fields?.apollo?.additionalFilters?.departments?.length +
    fields?.apollo?.additionalFilters?.managementLevels?.length;

  useEffect(() => {
    onFiltersChange("blockedIndustries", blockedIndustries);
  }, [blockedIndustries]);

  useEffect(() => {
    onFiltersChange("zip_code", zipCode);
  }, [zipCode]);

  useEffect(() => {
    let deartmentlist = [];
    departments.forEach((item) => {
      deartmentlist.push(item?.title);
    });
    setDepartmentsTitle(deartmentlist);
  }, [departments]);

  useEffect(() => {
    let deartmentlist = [];
    managementLevels.forEach((item) => {
      deartmentlist.push(item?.title);
    });
    setManagementTitle(deartmentlist);
  }, [managementLevels]);

  useEffect(() => {
    if (!_.isEmpty(fields?.apollo)) {
    const techtitlelist = fields?.apollo?.technology?.map((techKey) => {
      const titles = TECHNOLOGYOPTIONS.find((value) => value.key === techKey);
      return titles ? titles : null;
    });
    setTechTitle(techtitlelist);
  }
  }, [fields?.apollo?.technology]);

  useEffect(() => {
    if (!_.isEmpty(fields?.apollo)) {
      const deptTitlelist = fields?.apollo?.additionalFilters?.departments?.map((techKey) => {
        const titles = DEPARTMENTSOPTIONS.find((value) => value.key === techKey);
        return titles ? titles : null;
      });
      setDeptTitle(deptTitlelist);
    }
  }, [fields?.apollo?.additionalFilters?.departments]);

  useEffect(() => {
    if (!_.isEmpty(fields?.apollo)) {
      const deptTitlelist = fields?.apollo?.additionalFilters?.managementLevels?.map((techKey) => {
        const titles = MANAGEMENT_OPTIONS.find((value) => value.key === techKey);
        return titles ? titles : null;
      });
      setMangamentTitle(deptTitlelist);
    }
  }, [fields?.apollo?.additionalFilters?.managementLevels]);

  useEffect(() => {
    const blockedIndustryTitles =
      fields?.apollo?.addFilters?.blockedIndustries.map((blockedId) => {
        const blockedIndustry = newIndustryTypes.find(
          (industry) => industry.id === blockedId
        );
        return blockedIndustry ? blockedIndustry : null;
      });

    setBlockedindustriestitle(blockedIndustryTitles);
  }, [fields?.apollo?.addFilters?.blockedIndustries]);

  useEffect(() => {
    const blockedIndustryTitles = fields?.apollo?.industry?.map((blockedId) => {
      const blockedIndustry = newIndustryTypes.find(
        (industry) => industry.id === blockedId
      );
      return blockedIndustry ? blockedIndustry : null;
    });

    setIndustriesTitle(blockedIndustryTitles);
  }, [fields?.apollo?.industry]);

  useEffect(() => {
    if (fields.apollo.targets) {
      setTags(fields.apollo.targets);
    }
    if (fields.apollo.technology) {
      setTechnology(fields.apollo.technology);
    }
    if (fields.apollo.industry) {
      setIndustry(fields.apollo.industry);
    }
    if (fields.apollo.addFilters) {
      // setExtraFilters(fields.addFilters);
      setExtraFilters({
        ...fields.apollo.addFilters,
        funding: fields.apollo.addFilters.funding ?? [],
        blockedDomains: fields.apollo.addFilters.blockedDomains ?? []
      });
    }
  }, []);

  const handleClearAllFilters = () => {
    setExtraFilters(initApolloAddFilters);
    onClear({
      addFilters: {
        location: [],
        revenue: [],
        funding: [],
        compSize: [],
        customCompSize: { min: 0, max: 0 },
        keywords: [],
        allKeywords: [],
        andCompanyKeywords: [],
        blockedDomains: [],
        blockedLocations: [],
        blockedIndustries: [],
        blockedKeywords: [],
      },
      additionalFilters: {
        name: "",
        managementLevels: [],
        changedJob: "",
        departments: [],
        skills: [],
        schools: [],
        majors: [],
        degree: [],
        experience: "",
        companies: [],
        includePast: false,
        // companyType: [],
        excludeContacts: false,
        // contactInfo: "",
        socialLink: "",
        radius: [],
      },
      industry: [],
      targets: [],
      naicsCode: [],
      sicCode: [],
    });
    // setCompanyType("");
    setManagementLevels([]);
    setIndustry([]);
    // setBlockedIndustries([]);
    // setSicCode([]);
    // setApolloTechnology([]);
    setTechnology([]);
    setJobChangeValue('')
    setchangedJob([])
    setAllTechnology([]);
    setSic([]);
    setTag([]);
    setTags([]);
    setJobChange('')
    // setIndustry([]);
    // setChangedJob("");
    setDepartments([]);
    // setSkills([]);
    setExperience("");
    setExperienceData([]);
    setCompanies([]);
    setIncludePast(false);
    setExcludeContacts(false);
    setIndustries([]);
    setBlockedIndustries([]);
    // setContactInfo("");
    setRadius([]);
    setCustomExperience(false);
    // setMinExp("");
    // setMaxExp("");
    setExtraFilters((pre) => ({
      ...pre,
      customCompSize: { min: 0, max: 0 },
    }));
  };

  const handleTagAdd = useCallback((tag) => {
    setTags((prevState) => {
      const newTags = [];
      const uniqueTags = new Set([...prevState, tag]);
      for (let value of uniqueTags.values()) {
        newTags.push(value);
      }
      return newTags;
    });
  }, []);

  const handleTagDelete = useCallback((tag) => {
    let isEmpty = false;
    setTags((prevState) => {
      const newTags = prevState.filter((t) => t !== tag);
      if (newTags.length === 0) {
        isEmpty = true;
      }
      return newTags;
    });
    if (isEmpty) {
      onChange("targets", []);
    }
  }, []);

  const handleIndustryChange = (e, value) => {
    const industryValues = [];
    value.forEach((item) => {
      industryValues.push(item.id);
    });
    setIndustry(industryValues);
  };

  const onAdditionalFiltersChange = (field, value) => {
    setApolloAdditionalFilters((prev) => ({ ...prev, [field]: value }));
  };
  const onSICCodeChange = (field, value) => {
    SetSicCode((prev) => value);
  };

  const onTitlesChange = (field, value) => {
    setApolloTag((prev) => value);
  };
  const onIndustriesChange = (field, value) => {
    setApolloIndustry((prev) => value);
  };
  const onTechnologyChange = (field, value) => {
    setApolloTechnology((prev) => value);
  };
  const onSelectAllTech = (field, value) => {
    setApolloAllTechnology((prev) => value);
  };
  const onJobChange = (field, value) => {
    setApolloTag((prev) => value);
  };

  const getdepartmenttitle = () => {
    let deartmentlist = [];
    departments.forEach((item) => {
      if (_.isObject(item)) {
        deartmentlist.push(item?.key);
      } else {
        deartmentlist.push(item);
      }
    });
    return deartmentlist;
  };
  const getManagementtitle = () => {
    let deartmentlist = [];
    managementLevels.forEach((item) => {
      if (_.isObject(item)) {
        deartmentlist.push(item?.key);
      } else {
        deartmentlist.push(item);
      }
    });
    return deartmentlist;
  };

  useEffect(() => {
    onAdditionalFiltersChange("managementLevels", getManagementtitle());
    onAdditionalFiltersChange("changedJob", jobChangeValue);
    onAdditionalFiltersChange("departments", getdepartmenttitle());
    onAdditionalFiltersChange("experience", experiencedata);
    onAdditionalFiltersChange("companies", companies);
    onAdditionalFiltersChange("includePast", includePast);
    onAdditionalFiltersChange("excludeContacts", excludeContacts);
    onAdditionalFiltersChange("radius", radius);
    onIndustriesChange("industry", industry);
    onTechnologyChange("technology", getTechnologyttitle());
    onSelectAllTech("allTech", getAllTechnologyttitle());
    onJobChange("changedJob", changedJob);
    onSICCodeChange("sicCode", sic);
    onTitlesChange("targets", tag);
  }, [
    radius,
    managementTitle,
    departmentsTitle,
    experiencedata,
    jobChangeValue,
    companies,
    includePast,
    excludeContacts,
    sic,
    technology,
    changedJob,
    industry,
    tag,
  ]);

  const callToLocationModels = [
    { label: "Select Region", value: "selectRegion" },
    { label: "Select ZIP code radius", value: "selectZIPCode" },
  ];

  const jobChangeModels = [
    { label: "Contacts who have changed jobs", value: "jobChanged" },
    { label: "Contacts who have not changed jobs", value: "jobNotChanged" },
  ];

  const handleLocationChange = (e, option) => {
    if (e.target.checked) {
      setLocation(option.value);
    } else {
      setLocation("");
    }
  };

  useEffect(() => {
    if (
      fields?.apollo &&
      fields?.apollo?.addFilters?.location !== undefined &&
      fields?.apollo?.addFilters?.location.length !== 0 &&
      fields?.apollo?.addFilters?.blockedLocations !== undefined &&
      fields?.apollo?.addFilters?.blockedLocations !== 0
    ) {
      setLocation(callToLocationModels[0].value);
    }
  }, [
    fields?.apollo?.addFilters?.location,
    fields?.apollo?.addFilters?.blockedLocations,
  ]);

  const getTechnologyttitle = () => {
    let technolist = [];
    technology?.forEach((item) => {
      if (_.isObject(item)) {
        technolist.push(item?.key);
      } else {
        technolist.push(item);
      }
    });
    return technolist;
  };
  const getAllTechnologyttitle = () => {
    return allTechnology;
  };

  const keywordChangeModels = [
    { label: "Include Prospect Keywords (AND)", value: "includeKeyword" },
    {
      label: "Include Company Keywords (OR)",
      value: "includeAllCompany",
    },
    {
      label: "Include All Company Keywords (AND)",
      value: "includeAll",
    },
    { label: "Exclude Company Keywords", value: "excludeKeyword" },
  ];

  const handleKeywordChange = (e, option) => {
    const isChecked = e.target.checked;
    const value = option.value;

    if (isChecked) {
      setKeywordChange((prevState) => [...prevState, value]);
    } else {
      setKeywordChange((prevState) => {
        return prevState.filter((item) => item !== value);
      });
    }
  };

  useEffect(() => {
    if (
      fields?.apollo?.addFilters?.keywords?.length > 0 &&
      fields?.apollo?.addFilters?.blockedKeywords?.length > 0 &&
      fields?.apollo?.addFilters?.allKeywords?.length > 0 &&
      fields?.apollo?.addFilters?.andCompanyKeywords?.length > 0
    ) {
      setKeywordChange([
        keywordChangeModels[0].value,
        keywordChangeModels[3].value,
        keywordChangeModels[1].value,
        keywordChangeModels[2].value,
      ]);
    }
    if (fields?.apollo?.addFilters?.keywords?.length > 0) {
      setKeywordChange([keywordChangeModels[0].value]);
    }
    if (fields?.apollo?.addFilters?.andCompanyKeywords?.length > 0) {
      setKeywordChange((pre) => [...pre, keywordChangeModels[1].value]);
    }
    if (fields?.apollo?.addFilters?.allKeywords?.length > 0) {
      setKeywordChange((pre) => [...pre, keywordChangeModels[2].value]);
    }

    if (fields?.apollo?.addFilters?.blockedKeywords?.length > 0) {
      setKeywordChange((pre) => [...pre, keywordChangeModels[3].value]);
    }
  }, [
    fields?.apollo?.addFilters?.keywords,
    fields?.apollo?.addFilters?.blockedKeywords,
    fields?.apollo?.addFilters?.allKeywords,
    fields?.apollo?.addFilters?.andCompanyKeywords,
  ]);

  const handleJobChange = (e, option) => {
    if (e.target.checked) {
      setJobChange(option.value);
      setJobChangeValue(option.value);
    } else {
      setJobChange("");
      setJobChangeValue("");
    }
  };

  const handleSelectAll = () => {
    let selectAll = [];
    newIndustryTypes.map((option) => {
      selectAll.push(option.id);
    });
    setIndustry(selectAll);
  };

  const handleCodeChange = (e) => {
    setZipCode((prevState) => ({
      ...prevState,
      code: e.target.value,
    }));
  };

  const handleRadiusChange = (e) => {
    setZipCode((prevState) => ({
      ...prevState,
      radius: e.target.value,
    }));
  };

  return (
    <Stack
      spacing={3}
      {...other}
      className="contentBorder"
      sx={{ marginTop: "10px", display: leadSource === "csv" ? "none" : "block" }}
    >
      <Stack
        sx={{
          "& .MuiAccordion-root:last-of-type": {
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
          },
          "& .MuiAccordion-root:first-of-type": {
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          },
        }}
      >
        <Stack direction="row" alignItems="center" sx={{ my: 1 }}>
          <>
            <img
              src={Settings}
              style={{ marginRight: "8px", height: "20px" }}
            />
          </>
          <Stack
            sx={{ flexGrow: 1 }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6" sx={{color: "#111927"}}>
              Targeting Filters
            </Typography>
            <Button
              sx={{
                cursor: "pointer",
                fontSize: "14px",
                fontWeight: 600,
                mr: 0.5,
                color: "blue",
                "&:hover": { color: "red" },
              }}
              onClick={handleClearAllFilters}
            >
              Clear all
            </Button>
          </Stack>
        </Stack>
        <Divider sx={{ borderColor: alpha("#111927", 0.1), mb: 1 }} />
        <Wrapper
          label="Location"
          style={{
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          }}
          count={
            fields?.apollo?.addFilters?.location?.length +
            fields?.apollo?.addFilters?.blockedLocations?.length
          }
          isError={locationError !== ""}
          variant="h1"
        >
          {callToLocationModels.map((option, index) => (
            <>
              <div
                key={index}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Radio
                  checked={option.value === location}
                  onChange={(e) => handleLocationChange(e, option)}
                  name="location-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
                <Typography
                  sx={{ fontSize: "14px", fontWeight: "600" }}
                >
                  {option.label}
                </Typography>
              </div>
              {location === "selectRegion" && option.value === location && (
                <>
                  {selectedFilters &&
                    selectedFilters.includes(FILTERS[0].id) && (
                      <Box sx={{ marginTop: "10px" }}>
                        <Filter
                          key={FILTERS[0].name}
                          name={FILTERS[0].name}
                          vid={FILTERS[0].id}
                          type={FILTERS[0].type}
                          options={FILTERS[0].options}
                          state={extraFilters}
                          setState={setExtraFilters}
                          onChange={onFiltersChange}
                          fields={extraFilters.customCompSize}
                          setFields={setExtraFilters}
                          editCampaign={editCampaign}
                          locationError={locationError}
                          valueField={apolloFormData}
                        />
                      </Box>
                    )}
                  {/* <Wrapper label="Exclude Locations" variant="h2"> */}
                  <Box sx={{ marginTop: "10px" }}>
                    <Filter
                      key={FILTERS[7].name}
                      name={FILTERS[7].name}
                      vid={FILTERS[7].id}
                      type={FILTERS[7].type}
                      options={FILTERS[7].options}
                      state={extraFilters}
                      setState={setExtraFilters}
                      onChange={onFiltersChange}
                      fields={extraFilters.blockedLocations}
                      setFields={setExtraFilters}
                      editCampaign={editCampaign}
                      locationError={locationError}
                    />
                  </Box>
                  {/* </Wrapper> */}
                </>
              )}
              {location === "selectZIPCode" && option.value === location && (
                <Stack spacing={1}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    Address/City/ZIP
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={zipCode.code}
                    placeholder="e.g.94105"
                    onChange={handleCodeChange}
                  />
                  <FormControl fullWidth>
                    <InputLabel>Select</InputLabel>
                    <Select
                      id="location"
                      sx={{ backgroundColor: "white" }}
                      onChange={handleRadiusChange}
                      value={zipCode.radius}
                    >
                      <MenuItem value={"25"}>within 25 miles</MenuItem>
                      <MenuItem value={"50"}>within 50 miles</MenuItem>
                      <MenuItem value={"100"}>within 100 miles</MenuItem>
                      <MenuItem value={"500"}>within 500 miles</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              )}
            </>
          ))}
        </Wrapper>
        <Wrapper
          label="Industries"
          count={industryFilterCount}
          isError={industryError !== ""}
          variant="h1"
        >
          <Wrapper
            label="Industry"
            isDot={
              fields?.apollo?.industry?.length ||
                fields?.apollo?.addFilters?.blockedIndustries?.length
                ? "true"
                : "false"
            }
            count={
              fields?.apollo?.industry?.length +
              fields?.apollo?.addFilters?.blockedIndustries?.length
            }
            isError={industryError !== ""}
            variant="h2"
          >
            <div id="select-industries">
              <Autocomplete
                multiple
                freeSolo
                id="select-industries"
                onChange={(event, value) =>
                  handleIndustryChange("selectedIndustries", value)
                }
                options={newIndustryTypes}
                // value={industriesTitle ? industriesTitle : fields?.apollo?.industry}
                getOptionLabel={(option) => option.cleaned_name || option}
                disableCloseOnSelect
                value={industriesTitle ? industriesTitle : []}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option?.cleaned_name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    id="outlined-basic"
                    variant="filled"
                    label="Industries"
                    sx={{ mt: 1, backgroundColor: "white", borderRadius: 1 }}
                  />
                )}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      key={index}
                      label={
                        option?.cleaned_name
                          ? option?.cleaned_name
                          : option?.cleaned_name
                      }
                      {...getTagProps({ index })}
                      sx={{
                        backgroundColor: "#111927",
                        color: "white",
                        "& .MuiChip-deleteIcon": {
                          backgroundColor: "transparent !important",
                          color: "white !important",
                          display: "",
                          "&:hover": {
                            color: "white",
                          },
                        },
                      }}
                    />
                  ))
                }
              />

              {industryError && (
                <Typography color="error" sx={{ fontSize: "12px" }}>
                  {industryError}
                </Typography>
              )}
              <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                <Button
                  sx={{
                    cursor: "pointer",
                    fontSize: "14px",
                    fontWeight: 600,
                    mr: 0.5,
                    color: "blue",
                    "&:hover": { color: "darkblue" },
                  }}
                  onClick={handleSelectAll}
                >
                  Select all
                </Button>
                <Button
                  sx={{
                    cursor: "pointer",
                    fontSize: "14px",
                    fontWeight: 600,
                    mr: 0.5,
                    color: "blue",
                    "&:hover": { color: "red" },
                  }}
                  onClick={() => {
                    setIndustry([]);
                  }}
                >
                  Clear all
                </Button>
              </Box>
            </div>
            <div id="blocked-industries">
              <Autocomplete
                multiple
                freeSolo
                id="blocked-industries"
                onChange={(event, value) =>
                  onFiltersChange("blockedIndustries", value)
                }
                options={newIndustryTypes}
                getOptionLabel={(option) => option?.cleaned_name || option}
                disableCloseOnSelect
                value={blockedindustriestitle ? blockedindustriestitle : []}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.cleaned_name}{" "}
                    {/* Render the 'title' property here */}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="filled"
                    label="Blocked Industries"
                    sx={{ mt: 1, backgroundColor: "white", borderRadius: 1 }}
                  />
                )}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      key={index}
                      label={
                        option?.cleaned_name
                          ? option?.cleaned_name
                          : option?.cleaned_name
                      }
                      {...getTagProps({ index })}
                      sx={{
                        backgroundColor: "#111927",
                        color: "white",
                        "& .MuiChip-deleteIcon": {
                          backgroundColor: "transparent !important",
                          color: "white !important",
                          display: "",
                          "&:hover": {
                            color: "white",
                          },
                        },
                      }}
                    />
                  ))
                }
              />
              <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                <Button
                  sx={{
                    cursor: "pointer",
                    fontSize: "14px",
                    fontWeight: 600,
                    mr: 0.5,
                    color: "blue",
                    "&:hover": { color: "red" },
                  }}
                  onClick={() => {
                    setBlockedIndustries([]);
                  }}
                >
                  Clear all
                </Button>
              </Box>
            </div>
          </Wrapper>
          <Wrapper
            label="SIC Code"
            isDot={sic.length ? "true" : "false"}
            count={sic.length}
            variant="h2"
          >
            <div id="industry-code">
              <div id="select-designations">
                <MultiSelect
                  id="select-sic-code"
                  style={{ marginTop: "8px" }}
                  label="SIC Code"
                  name="select-sic-code"
                  placeholder={"Enter sic code..."}
                  options={SIC_CODES}
                  selected={sic}
                  setSelected={setSic}
                  ismdUp={ismdUp}
                />
                {sic.length === limit && (
                  <Typography color="error" sx={{ fontSize: "12px" }}>
                    SIC limit reached
                  </Typography>
                )}
                <Stack
                  direction="row"
                  alignItems="flex-start"
                  justifyContent="flex-end"
                >
                  <Button
                    sx={{
                      display: sic.length === 0 && "none",
                      cursor: "pointer",
                      fontSize: "14px",
                      fontWeight: 600,
                      mr: 0.5,
                      // mt: 1,
                      color: "blue",
                      "&:hover": { color: "red" },
                    }}
                    onClick={() => {
                      setSic([]);
                      onChange("sicCode", []);
                    }}
                  >
                    Clear all
                  </Button>
                </Stack>
              </div>
            </div>
          </Wrapper>
          <Wrapper
            label="Keywords"
            isDot={
              fields?.apollo?.addFilters?.keywords?.length ||
                fields?.apollo?.addFilters?.allKeywords?.length ||
                fields?.apollo?.addFilters?.andCompanyKeywords?.length ||
                fields?.apollo?.addFilters?.blockedKeywords?.length
                ? "true"
                : "false"
            }
            count={
              fields?.apollo?.addFilters?.keywords?.length +
              fields?.apollo?.addFilters?.allKeywords?.length +
              fields?.apollo?.addFilters?.andCompanyKeywords?.length +
              fields?.apollo?.addFilters?.blockedKeywords?.length
            }
            variant="h2"
            style={{
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            }}
          >
            {keywordChangeModels.map((option, index) => (
              <>
                <div
                  key={index}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Checkbox
                    // key={option.value}
                    checked={keywordChange?.includes(option.value) || false}
                    onChange={(e) => handleKeywordChange(e, option)}
                  />
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    {option.label}
                  </Typography>
                </div>

                {keywordChange.includes("includeKeyword") &&
                  option.value === "includeKeyword" && (
                    <>
                      {selectedFilters &&
                        selectedFilters.includes(FILTERS[4].id) && (
                          <Box>
                            <Filter
                              key={FILTERS[4].name}
                              name={FILTERS[4].name}
                              vid={FILTERS[4].id}
                              type={FILTERS[4].type}
                              options={FILTERS[4].options}
                              state={extraFilters}
                              setState={setExtraFilters}
                              onChange={onFiltersChange}
                              fields={extraFilters.keywords}
                              setFields={setExtraFilters}
                              editCampaign={editCampaign}
                              ismdUp={ismdUp}
                            />
                          </Box>
                        )}
                    </>
                  )}
                {keywordChange.includes("includeAll") &&
                  option.value === "includeAll" && (
                    <>
                      {selectedFilters &&
                        selectedFilters.includes(FILTERS[8].id) && (
                          <Box sx={{ mt: 3 }}>
                            <Filter
                              key={FILTERS[8].name}
                              name={FILTERS[8].name}
                              vid={FILTERS[8].id}
                              type={FILTERS[8].type}
                              options={FILTERS[8].options}
                              state={extraFilters}
                              setState={setExtraFilters}
                              onChange={onFiltersChange}
                              fields={extraFilters.allKeywords}
                              setFields={setExtraFilters}
                              editCampaign={editCampaign}
                              ismdUp={ismdUp}
                            />
                          </Box>
                        )}
                    </>
                  )}
                {keywordChange.includes("includeAllCompany") &&
                  option.value === "includeAllCompany" && (
                    <>
                      {selectedFilters &&
                        selectedFilters.includes(FILTERS[9].id) && (
                          <Box sx={{ mt: 3 }}>
                            <Filter
                              key={FILTERS[9].name}
                              name={FILTERS[9].name}
                              vid={FILTERS[9].id}
                              type={FILTERS[9].type}
                              options={FILTERS[9].options}
                              state={extraFilters}
                              setState={setExtraFilters}
                              onChange={onFiltersChange}
                              fields={extraFilters.andCompanyKeywords}
                              setFields={setExtraFilters}
                              editCampaign={editCampaign}
                              ismdUp={ismdUp}
                            />
                          </Box>
                        )}
                    </>
                  )}
                {keywordChange.includes("excludeKeyword") &&
                  option.value === "excludeKeyword" && (
                    <>
                      {selectedFilters &&
                        selectedFilters.includes(FILTERS[6].id) && (
                          <Box sx={{ mt: 3 }}>
                            <Filter
                              key={FILTERS[6].name}
                              name={FILTERS[6].name}
                              vid={FILTERS[6].id}
                              type={FILTERS[6].type}
                              options={FILTERS[6].options}
                              state={extraFilters}
                              setState={setExtraFilters}
                              onChange={onFiltersChange}
                              fields={extraFilters.blockedKeywords}
                              setFields={setExtraFilters}
                              editCampaign={editCampaign}
                              ismdUp={ismdUp}
                            />
                          </Box>
                        )}
                    </>
                  )}
              </>
            ))}
          </Wrapper>
        </Wrapper>
        <Wrapper
          label="Company"
          count={employerFilterCount}
          isError={industryError !== ""}
          variant="h1"
        >
          <Wrapper
            label="Company Domain"
            isDot={
              fields?.apollo?.additionalFilters?.companies?.length ||
                fields?.apollo?.addFilters?.blockedDomains?.length
                ? "true"
                : "false"
            }
            count={
              fields?.apollo?.additionalFilters?.companies?.length +
              fields?.apollo?.addFilters?.blockedDomains?.length
            }
            variant="h2"
            style={{
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            }}
          >
            <MultiInput
              id="apollocompany"
              isApollo={true}
              placeholder="Enter Company name..."
              name="apollocompany"
              selectedState={companies}
              setSelectedState={setCompanies}
              onChange={onChange}
              vid={"company"}
              buttonId="apolloCompanyButton"
              limit={limit}
              style={{ marginBottom: "16px" }}
              ismdUp={ismdUp}
            />

            {/* <Wrapper label="Exclude Domains" variant="h2"> */}
            <Box sx={{ marginTop: "10px" }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  mb: 0.8,
                }}
              >
                Exclude Domains
              </Typography>
              {selectedFilters.includes(FILTERS[5].id) && (
                <Filter
                  key={FILTERS[5].name}
                  name={FILTERS[5].name}
                  vid={FILTERS[5].id}
                  type={FILTERS[5].type}
                  options={FILTERS[5].options}
                  state={extraFilters}
                  setState={setExtraFilters}
                  onChange={onFiltersChange}
                  fields={extraFilters.blockedDomains}
                  setFields={setExtraFilters}
                  editCampaign={editCampaign}
                />
              )}
            </Box>
            {/* </Wrapper> */}
          </Wrapper>
          <Wrapper
            label="Number of Employees"
            isDot={
              fields?.apollo?.addFilters?.compSize.length ||
              ((fields?.apollo?.addFilters?.customCompSize?.min !== "" && fields?.apollo?.addFilters?.customCompSize?.min !== 0) || 
              (fields?.apollo?.addFilters?.customCompSize?.max !== "" && fields?.apollo?.addFilters?.customCompSize?.max !== 0))? "true" : "false"
            }
            count={fields?.apollo?.addFilters?.compSize.length+
              ((fields?.apollo?.addFilters?.customCompSize?.min !== "" && fields?.apollo?.addFilters?.customCompSize?.min !== 0) ||
              (fields?.apollo?.addFilters?.customCompSize?.max !== "" && fields?.apollo?.addFilters?.customCompSize?.max !== 0 )? 1 : 0)}
            variant="h2"
          >
            {selectedFilters && selectedFilters.includes(FILTERS[1].id) && (
              <Box>
                <Filter
                  key={FILTERS[1].name}
                  name={FILTERS[1].name}
                  vid={FILTERS[1].id}
                  type={FILTERS[1].type}
                  options={employeRange}
                  state={extraFilters}
                  setState={setExtraFilters}
                  onChange={onFiltersChange}
                  fields={extraFilters.compSize}
                  setFields={setExtraFilters}
                  editCampaign={editCampaign}
                />
                {noEmployError && (
                  <Typography color="error">{noEmployError}</Typography>
                )}
              </Box>
            )}
          </Wrapper>
          <Wrapper
            label="Revenue"
            isDot={
              fields?.apollo?.addFilters?.revenue?.length ? "true" : "false"
            }
            count={fields?.apollo?.addFilters?.revenue?.length}
            variant="h2"
          >
            {selectedFilters && selectedFilters.includes(FILTERS[2].id) && (
              <Box>
                <Filter
                  key={FILTERS[2].name}
                  name={FILTERS[2].name}
                  vid={FILTERS[2].id}
                  type={FILTERS[2].type}
                  options={FILTERS[2].options}
                  state={extraFilters}
                  setState={setExtraFilters}
                  onChange={onFiltersChange}
                  fields={extraFilters.revenue}
                  setFields={setExtraFilters}
                  editCampaign={editCampaign}
                />
              </Box>
            )}
          </Wrapper>
          <Wrapper
            label="Funding"
            isDot={
              fields?.apollo?.addFilters?.funding?.length ? "true" : "false"
            }
            count={fields?.apollo?.addFilters?.funding?.length}
            variant="h2"
          >
            {selectedFilters && selectedFilters.includes(FILTERS[3].id) && (
              <Box>
                <Filter
                  key={FILTERS[3].name}
                  name={FILTERS[3].name}
                  vid={FILTERS[3].id}
                  type={FILTERS[3].type}
                  options={FUNDING}
                  state={extraFilters}
                  setState={setExtraFilters}
                  onChange={onFiltersChange}
                  fields={extraFilters.funding}
                  setFields={setExtraFilters}
                  editCampaign={editCampaign}
                />
              </Box>
            )}
          </Wrapper>

          <Wrapper
            label="Job Change"
            variant="h2"
            isDot={
              fields?.apollo?.additionalFilters?.changedJob ? "true" : "false"
            }
            count={fields?.apollo?.additionalFilters?.changedJob}
          >
            <div>
              {jobChangeModels.map((option, index) => (
                <div
                  key={index}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Checkbox
                    checked={
                      option.value === jobChange ||
                      option.value === jobChangeValue
                    }
                    onChange={(e) => handleJobChange(e, option)}
                    name="radio-buttons"
                    inputProps={{ "aria-label": "A" }}
                  />
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    {option.label}
                  </Typography>
                </div>
              ))}
            </div>
          </Wrapper>
        </Wrapper>
        <Wrapper label="Person" count={personCount} variant="h1">
          <Wrapper
            variant="h2"
            isDot={
              fields?.apollo?.targets?.length ||
                fields?.apollo?.additionalFilters?.departments?.length ||
                fields?.apollo?.additionalFilters?.managementLevels?.length
                ? "true"
                : "false"
            }
            count={
              fields?.apollo?.targets?.length +
              fields?.apollo?.additionalFilters?.departments?.length +
              fields?.apollo?.additionalFilters?.managementLevels?.length
            }
            label={"Role & Department"}
            style={{
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            }}
          >
            <div id="apolloTitles">
              <Typography
                sx={{ fontSize: "14px", fontWeight: "500" }}
              >
                Titles / Roles
              </Typography>
              <Autocomplete
                multiple
                freeSolo
                id="titles"
                onChange={(event, newValue) => setTag(newValue)}
                options={TITLES}
                value={tag ? tag : ""}
                getOptionLabel={(option) => option}
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 1,
                      backgroundColor: "white",
                      borderRadius: 1,
                      mb: 2,
                    }}
                    spellCheck={true}
                  />
                )}
                renderTags={(tags, getTagProps) =>
                  tags.map((option, index) => {
                    const optionExists = TITLES.some((item) => item === option);
                    return (
                      <Chip
                        key={index}
                        label={optionExists ? option : option}
                        onDelete={() => handleTagDelete(option)}
                        {...getTagProps({ index })}
                        sx={{
                          backgroundColor: "#111927",
                          color: "white",
                          "& .MuiChip-deleteIcon": {
                            backgroundColor: "transparent !important",
                            color: "white !important",
                            display: "",
                            "&:hover": {
                              color: "white",
                            },
                          },
                        }}
                      />
                    );
                  })
                }
              />

              {tags.length === limit && (
                <Typography color="error" sx={{ fontSize: "12px" }}>
                  Titles / Roles limit reached
                </Typography>
              )}
              {titleError && (
                <Typography color="error">{titleError}</Typography>
              )}
              <Stack
                direction="row"
                alignItems="flex-start"
                justifyContent="space-between"
              >
                <Button
                  sx={{
                    display: tags.length === 0 && "none",
                    cursor: "pointer",
                    fontSize: "14px",
                    fontWeight: 600,
                    mr: 0.5,
                    color: "blue",
                    "&:hover": { color: "red" },
                  }}
                  onClick={() => {
                    setTags([]);
                    onChange("targets", []);
                  }}
                >
                  Clear all
                </Button>
              </Stack>
            </div>
            <div id="select-department" style={{ marginTop: "8px" }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  mb: 0.8,
                }}
              >
                Department
              </Typography>
              <ApolloFilter
                key={"department"}
                vid={"department"}
                type={"multi-select"}
                placeholder="Departments"
                options={DEPARTMENTSOPTIONS}
                state={{ department: deptTitle }}
                setState={setDepartments}
                onChange={onAdditionalFiltersChange}
              />
            </div>
            <div id="select-department" style={{ marginTop: "8px" }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  mb: 0.8,
                }}
              >
                Management Levels
              </Typography>
              <ApolloFilter
                key={"management"}
                vid={"managementLevels"}
                type={"multi-select"}
                placeholder="Management Levels"
                options={MANAGEMENT_OPTIONS}
                state={{ managementLevels: mangamentTitle }}
                setState={setManagementLevels}
                onChange={onAdditionalFiltersChange}
              />
            </div>
          </Wrapper>
        </Wrapper>

        <Wrapper
          label="Advanced Filters"
          count={fields?.apollo?.technology?.length}
          variant="h1"
        >
          <Wrapper
            label="Technologies"
            variant="h2"
            count={fields?.apollo?.technology?.length}
            isDot={fields?.apollo?.technology?.length ? "true" : "false"}
          >
            <ApolloTechFilter
              key={"technology"}
              vid={"technology"}
              type={"multi-select"}
              placeholder="Technology"
              options={TECHNOLOGYOPTIONS}
              state={{ technology: technology }}
              setState={setTechnology}
              onChange={onTechnologyChange}
              isTechnology={true}
              techTitle={techTitle}
              allTechnology={allTechnology}
              setAllTechnology={setAllTechnology}
            />
          </Wrapper>
        </Wrapper>
      </Stack>
    </Stack>
  );
};
